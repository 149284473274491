import React, { useEffect, useState, useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { AuthContext } from '../AuthContext';

const BookView = (props) => {
  const { appID, applicationData, setLoading, setModalPayload, setModalStatus, axiosInstance } = useContext(AuthContext);
  const history = useHistory();

  const [recordData, setRecordData] = useState('');
  const [recordID, setRecordID] = useState('');

  useEffect(() => {
    if (appID) {
      var urlArray = props.location.pathname.split('/')
      if (urlArray[2]) {
        // console.log(urlArray[2]);
        fetchRecord(urlArray[2]);
      }
    }
    return () => {
      setRecordData('');
    }
  }, [appID])

  useEffect(() => {
    if (recordID) {
      setModalStatus(true);
      setModalPayload({ isBook: true, isDirectURL: true, active: true, canCancel: true, onCancel: hideModalBookHandler, recordData: recordData, recordID: recordID });
    }
    return () => {
      setModalStatus(false);
    }
  }, [recordID])

  const hideModalBookHandler = () => {
    setModalStatus(false);
    history.push("/");
  }

  const fetchRecord = async (record_id) => {
    setLoading(true);
    try {
      const resp = await axiosInstance.get(`/Manifestations/${record_id}?source=${recordData.source}`)
      if (resp.status === 200 && resp.data.title) {
        setRecordData(resp.data);
        setRecordID(resp.data);
        setLoading(false);
      } else {
        console.error(resp.message);
        history.push("/");
        setLoading(false);
      }
    } catch (err) {
      console.error(err)
      history.push("/");
      setLoading(false);
    }
  }

  return (
    <div className="wcard-outer-container" role="main">
      <div className="wcard-container">
        <h1 className="visuallyhidden">{applicationData.publishedTitle} Book view screen</h1>

        {(applicationData && recordData) &&
          <Helmet>
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`https://${applicationData.primaryDNS}/manifestations/${recordData.recordID}`} />
            <meta property="og:title" content={`${applicationData.publishedTitle} | Title lookup: ${recordData.title}`} />
            <meta property="og:description" content={recordData.description} />
            <meta property="og:image" content={recordData.imageUrl} />
          </Helmet>
        }
      </div>
    </div>
  );
}

export default BookView;
