import React, { Component } from 'react';
import './UserMenu.css';
import { NavLink, withRouter } from 'react-router-dom';
import { SyncLoader } from 'react-spinners'
import FocusTrap from 'focus-trap-react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../../AuthContext';


class UserMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clicked: false,
      selectedAvatar: '',
      avatarURL: ''
    }
    this.escFunction = this.escFunction.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    if (this.state.clicked) {
      this.setState({ clicked: false });
    } else {
      this.setState({ clicked: true });
    }
  }

  componentDidMount() {
    window.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.setState({
        clicked: false
      })
    }
  }

  render() {
    return (
      <AuthContext.Consumer>{(authContext) => {
        const { user, isAuthenticated, userAccountTab, userSavedAvatar, logOut, logoutWaiting, isTop, setModalLoginStatus, setModalContactUsStatus,
          checkCurrentUserStatus, toast, patronFields } = authContext;

        const handleSignUpModal = () => {
          if (Object.keys(patronFields).length > 0) {
            this.props.history.push({ pathname: `/user/register` });
          } else {
            toast.info("Sorry, registration is not open yet for this web app.")
          }
        }

        return (
          <div className="user-menu-outer">
            <button
              type="button"
              aria-controls="user-menu"
              aria-haspopup="menu"
              aria-expanded={this.state.clicked ? "true" : "false"}
              onClick={this.handleClick}
              className={this.props.location.pathname === '/user/account' ? "active-nav" : "inactive-nav"}
            >
              <section className="nav-links user-nav-outer" style={{ color: isTop ? "var(--navbar-icons-color)" : "var(--sticky-navbar-icons-color)" }}>
                {logoutWaiting &&
                  <span className="logout-waiting">
                    <SyncLoader size={6} margin={1} color='var(--primary)' />
                  </span>
                }
                {userSavedAvatar ?
                  <img className="avatar-img" src={`data:image/svg+xml;base64,${userSavedAvatar}`}
                    alt='User&apos;s avatar' style={{ paddingTop: "10px" }} /> :
                  <FontAwesomeIcon className="user-avatar" icon={userAccountTab.buttonIconName ? [userAccountTab.buttonIconName.substring(0, 3), userAccountTab.buttonIconName.substring(7)] : ["fad", "user"]} />
                }
                <p className={this.props.location.pathname === '/user/account' ? "user-name active" : "user-name"}>{userAccountTab.title}</p>
              </section>
            </button>

            <div
              id="user-menu"
              role="menu"
              aria-orientation="vertical"
              aria-label="User Menu"
              aria-hidden={this.state.clicked ? "false" : "true"}
              className={this.state.clicked ? 'inner-menu-wrap active' : 'inner-menu-wrap'}
            >
              <FocusTrap
                active={this.state.clicked}
                focusTrapOptions={{
                  onDeactivate: () => this.handleClick,
                  initialFocus: false,
                  clickOutsideDeactivates: true,
                  escapeDeactivates: false
                }}>
                <div className="inner-menu" style={isTop ? { backgroundColor: "var(--card-color)", color: "var(--sticky-navbar-icons-color)" } : { backgroundColor: "var(--background-color)", color: "var(--navbar-icons-color)" }}>
                  <div className="inner-icons-container">

                    {/* Contact Us */}
                    <button role="menuitem" tabIndex={!this.state.clicked ? "-1" : ""} title="Contact us" onClick={() => { this.setState({ clicked: false }); setModalContactUsStatus(true); checkCurrentUserStatus() }}>
                      <FontAwesomeIcon className="user-icons" icon={['fal', 'envelope']} />
                      <p className="user-icons-labels">Contact us</p>
                    </button>

                    {/* Patrons: My Account */}
                    {isAuthenticated &&
                      <NavLink role="menuitem" title="My Account" tabIndex={!this.state.clicked ? "-1" : ""} to='/user/account' onClick={() => { this.setState({ clicked: false }); checkCurrentUserStatus() }}>
                        <FontAwesomeIcon className="user-icons" icon={['fas', 'cog']} />
                        <p className="user-icons-labels">{userAccountTab.title}</p>
                      </NavLink>
                    }

                    {/* Join In */}
                    {!isAuthenticated &&
                      <button role="menuitem" title="Join the library" tabIndex={!this.state.clicked ? "-1" : ""}
                        onClick={() => { this.setState({ clicked: false }); handleSignUpModal(); }}>
                        <FontAwesomeIcon className="user-icons" icon={['fal', 'address-card']} />
                        <p className="user-icons-labels">Join in</p>
                      </button>
                    }

                    {/* Log In */}
                    {!isAuthenticated &&
                      <button role="menuitem" title="Sign in" tabIndex={!this.state.clicked ? "-1" : ""} onClick={() => { this.setState({ clicked: false }); setModalLoginStatus(true) }}>
                        <FontAwesomeIcon className="user-icons" icon={['fad', 'sign-in-alt']} />
                        <p className="user-icons-labels">Login</p>
                      </button>
                    }

                    {/* Patrons: Logout */}
                    {isAuthenticated &&
                      <button role="menuitem" tabIndex={!this.state.clicked ? "-1" : ""} title="Sign out" onClick={() => { this.setState({ clicked: false }); logOut(user.userID) }}>
                        <FontAwesomeIcon className="user-icons" icon={['fad', 'sign-out-alt']} />
                        <p className="user-icons-labels">Logout</p>
                      </button>
                    }
                  </div>
                </div>
              </FocusTrap>
            </div>
          </div>
        )
      }
      }</AuthContext.Consumer>
    )
  }
}

export default withRouter(UserMenu);