import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import './Search.css';
import { AuthContext } from '../../AuthContext';
import Meta from '../common/Meta';

import Collapse from 'rc-collapse';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animateScroll as scroll } from 'react-scroll';

import MiniJacket from '../common/book-jackets/MiniJacket';
import FilterPanel from './FilterPanel.js';

const Search = (props) => {
  const { appID, loading, setLoading, isAuthenticated, applicationData, isTop, topTabs, isTabletOrMobile, libraryOGimage,
    searchConfiguration, checkCurrentUserStatus, axiosInstance, toast } = useContext(AuthContext);
  let history = useHistory();

  const [filtersPanelStatus, setFiltersPanelStatus] = useState(true);
  const [searchKeyWords, setSearchKeyWords] = useState(props.match.params.handle);
  const [searchKeywordsDisplay, setSearchKeywordsDisplay] = useState(props.match.params.handle);
  // const [suggestions, setSuggestions] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const [facets, setFacets] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [searchingField, setSearchingField] = useState('');
  const [searchingFieldSelection, setSearchingFieldSelection] = useState('');
  const [searchingLimit, setSearchingLimit] = useState('');
  const [searchingLimitSelection, setSearchingLimitSelection] = useState(''); // 10
  const [sortingTerm, setSortingTerm] = useState('any');
  const [sortingSelection, setSortingSelection] = useState('any');
  const [hasMore, setHasMore] = useState(false);
  const [lazyLoadingCounter, setLazyLoadingCounter] = useState(0);

  const [filtersPool, setFiltersPool] = useState([]);
  const [filtersPoolDynamic, setFiltersPoolDynamic] = useState([]);

  const [removeAllButtonStatus, setRemoveAllButtonStatus] = useState(false);
  const [refreshResultsButtonStatus, setRefreshResultsButtonStatus] = useState(false);

  const [filterAuthors, setFilterAuthors] = useState(new Set());
  const [filterAuthorsCounter, setFilterAuthorsCounter] = useState(0); //20
  const [filterYears, setFilterYears] = useState(new Set());
  const [filterYearsCounter, setFilterYearsCounter] = useState(0);
  const [filterFormats, setFilterFormats] = useState(new Set());
  const [filterFormatsCounter, setFilterFormatsCounter] = useState(0);
  const [filterLanguages, setFilterLanguages] = useState(new Set());
  const [filterLanguagesCounter, setFilterLanguagesCounter] = useState(0);
  const [filterSubjects, setFilterSubjects] = useState(new Set());
  const [filterSubjectsCounter, setFilterSubjectsCounter] = useState(0);
  const [filterReaders, setFilterReaders] = useState(new Set());
  const [filterReadersCounter, setFilterReadersCounter] = useState(0);
  const [filterLibraries, setFilterLibraries] = useState(new Set());
  const [filterLibrariesCounter, setFilterLibrariesCounter] = useState(0);
  const [filterElectronicFormats, setFilterElectronicFormats] = useState(new Set());
  const [filterElectronicFormatsCounter, setFilterElectronicFormatsCounter] = useState(0);
  const [filterMaterialTypes, setFilterMaterialTypes] = useState(new Set());
  const [filterMaterialTypesCounter, setFilterMaterialTypesCounter] = useState(0);
  const [filterCategories, setFilterCategories] = useState(new Set());
  const [filterCategoriesCounter, setFilterCategoriesCounter] = useState(0);
  const [filterGenres, setFilterGenres] = useState(new Set());
  const [filterGenresCounter, setFilterGenresCounter] = useState(0);

  const [availability, setAvailability] = useState(false);
  const [hidePastLoans, setHidePastLoans] = useState(false);
  const [pastLoansCheckbox, setPastLoansCheckbox] = useState(false);
  // const [detailedSearchOn, setDetailedSearchOn] = useState(false);
  const [facetsSearchArray, setFacetsSearchArray] = useState([]);

  const [pageTitle, setPageTitle] = useState('');

  const metaData = {
    title: `${pageTitle}`,
    description: `Welcome to ${applicationData.publishedTitle} | Catalogue Search`,
    type: 'website',
    image: libraryOGimage,
    canonical: ''
  }

  // Get the defaut Search limit
  useEffect(() => {
    if (searchConfiguration && searchConfiguration !== [] && searchConfiguration !== undefined) {
      topTabs.filter(tab => {
        if (tab.type === "LMS_Search") {
          const limitObject = JSON.parse(tab.contentText)
          if (Object.keys(limitObject).length > 0) {
            let unkownKey = Object.keys(limitObject)[0]
            let limitValue = limitObject[unkownKey].SearchLimitDefault
            if (limitValue !== searchingLimit) {
              setSearchingLimitSelection(limitValue)
              setSearchingLimit(limitValue)
            }
          }
        }
      })
    }
    return () => {
      setSearchingLimit('')
    }
  }, [searchConfiguration]);

  // Refresh page title when searching term changes
  useEffect(() => {
    if (applicationData || searchKeywordsDisplay || searchingField) {
      setPageTitle(`${applicationData.publishedTitle} | Catalogue Search ${searchingField}: ${searchKeywordsDisplay}`)
    }
  }, [applicationData, searchKeywordsDisplay, searchingField]);

  useEffect(() => {
    // to activate the navbar highlighting function
    window.scrollTo(0, 0);

    checkScreenSize();
    activateDefaultFilters();

    checkCurrentUserStatus();

  }, []);

  useEffect(() => {
    if (Object.keys(facetsSearchArray).length > 0) {
      updateTheSearch(searchKeyWords)
      fetchRecords(facetsSearchArray, sortingTerm);
    }
  }, [facetsSearchArray]);

  useEffect(() => {
    if (lazyLoadingCounter > 0) {
      moreRecords(facetsSearchArray);
    }
  }, [lazyLoadingCounter]);

  // when user logged out reset those
  useEffect(() => {
    if (isAuthenticated === false) {
      setAvailability(false)
      setHidePastLoans(false)
      setPastLoansCheckbox(false)
    }
  }, [isAuthenticated]);

  // Refresh results button status updater
  useEffect(() => {
    if (searchKeyWords !== searchKeywordsDisplay || searchingField !== searchingFieldSelection || searchingLimit !== searchingLimitSelection || JSON.stringify(filtersPool) !== JSON.stringify(filtersPoolDynamic)) {
      setRefreshResultsButtonStatus(true)
    } else {
      setRefreshResultsButtonStatus(false)
    }
  }, [searchKeyWords, searchKeywordsDisplay, searchingField, searchingFieldSelection, searchingLimit, searchingLimitSelection, filtersPool, filtersPoolDynamic]);

  // Remove all button status updater
  useEffect(() => {
    if (filtersPoolDynamic.length > 0) {
      setRemoveAllButtonStatus(true)
    } else {
      setRemoveAllButtonStatus(false)
    }
  }, [filtersPoolDynamic]);

  // Each time the facets change then update the filters area
  useEffect(() => {
    updateFacets();
  }, [facets]);

  // If the user clear the filters one by one then clear the memory and fetch
  // useEffect(() => {
  //   if (detailedSearchOn && filtersPoolDynamic.length === 0) {
  //     setFacetsSearchArray([]);
  //     setDetailedSearchOn(false);
  //     fetchRecords();
  //   }
  // }, [filtersPoolDynamic]);

  useEffect(() => {
    var pathArray = props.location.pathname.split('/');
    // console.log(pathArray[2])
    var urlUpdate = pathArray[2].replace(/[^a-zA-Z0-9\s]/, '') // remove any strange characters
    if (urlUpdate !== '') {
      updateTheSearch(urlUpdate)
    }
  }, [appID, props.location.pathname]);

  const updateTheSearch = (keyWord) => {
    setSearchKeyWords(keyWord);
    setSearchKeywordsDisplay(keyWord);
    history.push({
      pathname: `/search/${keyWord}/`
    })
  }

  // Default Filters
  const activateDefaultFilters = () => {
    let defaultFilters = { "name": "LANGUAGE", "Selected": ["ENG"] };
    setFacetsSearchArray([defaultFilters]);
    setFiltersPoolDynamic([{
      "facetType": "LANGUAGE",
      "facetName": "English",
      "facetLabel": "Language",
      "facetValue": "ENG",
      "facetCount": 0,
      "checked": true
    }]);
    setFilterLanguagesCounter(1);
    // setDetailedSearchOn(true);
  }

  // the main api query to populate the search result area
  const fetchRecords = async (criteria, sorting) => {
    setHasMore(false);
    // setSearchResults([]);
    setFiltersPool(filtersPoolDynamic);

    const searchObject = {
      "searchTerm": searchKeyWords,
      "searchTarget": "",
      "searchField": searchingField || '',
      "sortField": sorting || 'any',
      "searchLimit": searchingLimit || '',
      "facets": criteria || [],
      "offset": 0,
      "count": 24
    }
    if (appID !== '' && appID !== undefined) {
      setLoading(true);

      await axiosInstance.post(`/manifestations/searchresult`, searchObject)
        .then(response => {
          if (response.status === 429) {
            toast.warning(response.data)
          } else if (response.status === 200) {
            setSearchResults(response.data.records)
            setFacets(response.data.facets)
            setTotalResults(response.data.totalResults)
            setHasMore(response.data.hasMore)
          }
          setLoading(false)
        })
        .catch(error => {
          console.error(error)
          setLoading(false)
        })
    }
  }

  // load more to the result area
  const moreRecords = async (criteria) => {
    const searchObject = {
      "searchTerm": searchKeyWords,
      "searchTarget": "",
      "searchField": searchingField,
      "sortField": sortingTerm,
      "searchLimit": searchingLimit,
      "facets": criteria || [],
      "offset": lazyLoadingCounter,
      "count": 24
    }
    setLoading(true);
    await axiosInstance.post(`/manifestations/searchresult`, searchObject)
      .then(response => {
        if (response.status === 429) {
          toast.warning(response.data)
        } else if (response.status === 200) {
          setSearchResults(searchResults.concat(response.data.records));
          setHasMore(response.data.hasMore);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
      })
  }

  // Function to filter the search results to show only available items
  const checkAvailability = () => {
    setAvailability(!availability)
  }

  // Function to filter the search results to show only available items
  const checkHidePastLoans = () => {
    setHidePastLoans(!hidePastLoans)
  }

  // Function to update sorting options from the dropdown 
  const activateSorting = () => {
    setLazyLoadingCounter(0)
    setSearchKeywordsDisplay(searchKeyWords)
    fetchRecords(facetsSearchArray, sortingSelection)
    setSortingTerm(sortingSelection)
  }

  // when clicking on X over an item in the filtersPool area
  const removeSelectedFromThePool = (value, type) => {
    // remove it first from the pool
    const index = filtersPoolDynamic.findIndex(obj => (obj.facetValue === value && obj.facetType === type));
    const newState = [
      ...filtersPoolDynamic.slice(0, index),
      ...filtersPoolDynamic.slice(index + 1)
    ]
    setFiltersPoolDynamic(newState);

    // remove it also from the facet sets if the results already narrowed
    removeSelectedFromTheFacets(value, type);
  }
  // update the facet.checked value to false for the target facet in the list after removing it from the pool
  const removeSelectedFromTheFacets = (value, type) => {
    // local function
    const doTheUpdate = (state, setState, counter, setCounter) => {
      const currState = [...state];
      const newState = currState.filter(format => format.facetValue === value);
      if (newState[0] !== undefined) {
        // flip checked status
        newState[0].checked = !newState[0].checked
        setState([...state], newState[0]);
      }
      // decrease the counter
      setCounter(counter - 1)
    }

    // console.log(name + ' ' + type)
    if (type === 'AUTHOR') {
      doTheUpdate(filterAuthors, setFilterAuthors, filterAuthorsCounter, setFilterAuthorsCounter)
    } else if (type === 'PUBDATE') {
      doTheUpdate(filterYears, setFilterYears, filterYearsCounter, setFilterYearsCounter)
    } else if (type === 'LANGUAGE') {
      doTheUpdate(filterLanguages, setFilterLanguages, filterLanguagesCounter, setFilterLanguagesCounter)
    } else if (type === 'FORMAT') {
      doTheUpdate(filterFormats, setFilterFormats, filterFormatsCounter, setFilterFormatsCounter)
    } else if (type === 'LIBRARY') {
      doTheUpdate(filterLibraries, setFilterLibraries, filterLibrariesCounter, setFilterLibrariesCounter)
    } else if (type === 'SUBJECT') {
      doTheUpdate(filterSubjects, setFilterSubjects, filterSubjectsCounter, setFilterSubjectsCounter)
    } else if (type === 'ITEMCAT1') {
      doTheUpdate(filterCategories, setFilterCategories, filterCategoriesCounter, setFilterCategoriesCounter)
    } else if (type === 'ITEMCAT2') {
      doTheUpdate(filterGenres, setFilterGenres, filterGenresCounter, setFilterGenresCounter)
    } else if (type === 'ITYPE') {
      doTheUpdate(filterMaterialTypes, setFilterMaterialTypes, filterMaterialTypesCounter, setFilterMaterialTypesCounter)
    } else if (type === 'ERC_FORMAT') {
      doTheUpdate(filterElectronicFormats, setFilterElectronicFormats, filterElectronicFormatsCounter, setFilterElectronicFormatsCounter)
    } else if (type === 'EREADER') {
      doTheUpdate(filterReaders, setFilterReaders, filterReadersCounter, setFilterReadersCounter)
    }
  }
  // when clicking on the checkboxes in the facet list
  // we add/remove it to/from the pool
  const updateFiltersPool = (event, object, type) => {
    const foundItem = filtersPoolDynamic.filter(item => (item.facetValue === object.facetValue && item.facetType === object.facetType))

    // remove the facet from the pool if it exists
    if (!foundItem.length > 0) {
      setFiltersPoolDynamic(filtersPoolDynamic.concat(object));
    } else {
      // add the facet from the pool if not exists
      const index = filtersPoolDynamic.findIndex(obj => (obj.facetValue === object.facetValue && obj.facetType === object.facetType));
      const newState = [
        ...filtersPoolDynamic.slice(0, index),
        ...filtersPoolDynamic.slice(index + 1)
      ]
      setFiltersPoolDynamic(newState);
    }

    // update the array's state
    updateFacetsList(event, type)
  }
  // update the facet.checked value when clicking on the checkboxes in the facet list
  const updateFacetsList = (event, type) => {
    // local function
    const doTheUpdate = (state, setState, counter, setCounter) => {
      // update the state
      const currState = [...state];
      const newState = currState.filter(item => item.facetValue === event.target.value);
      newState[0].checked = !newState[0].checked
      setState([...state], newState[0]);
      // update its counter
      if (newState[0].checked) {
        setCounter(counter + 1)
      } else {
        setCounter(counter - 1)
      }
    }

    if (type === 'AUTHOR') {
      doTheUpdate(filterAuthors, setFilterAuthors, filterAuthorsCounter, setFilterAuthorsCounter)
    } else if (type === 'PUBDATE') {
      doTheUpdate(filterYears, setFilterYears, filterYearsCounter, setFilterYearsCounter)
    } else if (type === 'LANGUAGE') {
      doTheUpdate(filterLanguages, setFilterLanguages, filterLanguagesCounter, setFilterLanguagesCounter)
    } else if (type === 'FORMAT') {
      doTheUpdate(filterFormats, setFilterFormats, filterFormatsCounter, setFilterFormatsCounter)
    } else if (type === 'SUBJECT') {
      doTheUpdate(filterSubjects, setFilterSubjects, filterSubjectsCounter, setFilterSubjectsCounter)
    } else if (type === 'ITEMCAT1') {
      doTheUpdate(filterCategories, setFilterCategories, filterCategoriesCounter, setFilterCategoriesCounter)
    } else if (type === 'LIBRARY') {
      doTheUpdate(filterLibraries, setFilterLibraries, filterLibrariesCounter, setFilterLibrariesCounter)
    } else if (type === 'ITEMCAT2') {
      doTheUpdate(filterGenres, setFilterGenres, filterGenresCounter, setFilterGenresCounter)
    } else if (type === 'ITYPE') {
      doTheUpdate(filterMaterialTypes, setFilterMaterialTypes, filterMaterialTypesCounter, setFilterMaterialTypesCounter)
    } else if (type === 'ERC_FORMAT') {
      doTheUpdate(filterElectronicFormats, setFilterElectronicFormats, filterElectronicFormatsCounter, setFilterElectronicFormatsCounter)
    } else if (type === 'EREADER') {
      doTheUpdate(filterReaders, setFilterReaders, filterReadersCounter, setFilterReadersCounter)
    }
  }

  // when click on "Clear All" button or when submit a new search
  const clearFilters = () => {

    setFilterAuthorsCounter(0)
    setFilterYearsCounter(0)
    setFilterFormatsCounter(0)
    setFilterLanguagesCounter(0)
    setFilterSubjectsCounter(0)
    setFilterCategoriesCounter(0)
    setFilterLibrariesCounter(0)
    setFilterReadersCounter(0)
    setFilterGenresCounter(0)
    setFilterMaterialTypesCounter(0)
    setFilterElectronicFormatsCounter(0)

    setSortingTerm('any')
    setSortingSelection('any')
    setSearchingField('')
    setSearchingFieldSelection('')
    setSearchingLimit('')
    setSearchingLimitSelection('')
    setAvailability(false)
    setHidePastLoans(false)
    setPastLoansCheckbox(false)

    // if (!detailedSearchOn) {
    filtersPoolDynamic.forEach((item) => {
      removeSelectedFromTheFacets(item.facetValue, item.facetType)
    })
    setFiltersPoolDynamic([])
    // } else {
    setFacetsSearchArray([])
    // setDetailedSearchOn(false)
    // fetchRecords()
    // }
  }

  // when clicking on "Refresh Results" button
  const sendQueryWithFilters = () => {
    if (filtersPoolDynamic.length === 0) {
      setFacetsSearchArray([]);
      // setDetailedSearchOn(false);
      setSearchKeywordsDisplay(searchKeyWords);
      fetchRecords();
    } else {
      // create empty arrays
      var facetsArray = [];
      var authorsArray = []
        , languagesArray = []
        , yearsArray = []
        , subjectsArray = []
        , formatsArray = []
        , librariesArray = []
        , genresArray = []
        , categoriesArray = []
        , eReadersArray = []
        , eFormatsArray = []
        , materialsArray = [];

      // collect the items from the filtersPool and populate the arrays
      filtersPoolDynamic.forEach((item) => {
        if (item.facetType === 'AUTHOR') {
          authorsArray.push(item.facetValue)
        } else if (item.facetType === 'LANGUAGE') {
          languagesArray.push(item.facetValue)
        } else if (item.facetType === 'PUBDATE') {
          yearsArray.push(item.facetValue)
        } else if (item.facetType === 'SUBJECT') {
          subjectsArray.push(item.facetValue)
        } else if (item.facetType === 'FORMAT') {
          formatsArray.push(item.facetValue)
        } else if (item.facetType === 'LIBRARY') {
          librariesArray.push(item.facetValue)
        } else if (item.facetType === 'ITEMCAT2') {
          genresArray.push(item.facetValue)
        } else if (item.facetType === 'ITEMCAT1') {
          categoriesArray.push(item.facetValue)
        } else if (item.facetType === 'EREADER') {
          eReadersArray.push(item.facetValue)
        } else if (item.facetType === 'ERC_FORMAT') {
          eFormatsArray.push(item.facetValue)
        } else if (item.facetType === 'ITYPE') {
          materialsArray.push(item.facetValue)
        }
      })

      // create the search objects, one for each facet
      if (authorsArray.length > 0) {
        const facetObject = {
          "Name": "AUTHOR",
          "Selected": authorsArray
        }
        facetsArray.push(facetObject)
      }
      if (yearsArray.length > 0) {
        const facetObject = {
          "Name": "PUBDATE",
          "Selected": yearsArray
        }
        facetsArray.push(facetObject)
      }
      if (languagesArray.length > 0) {
        const facetObject = {
          "Name": "LANGUAGE",
          "Selected": languagesArray
        }
        facetsArray.push(facetObject)
      }
      if (subjectsArray.length > 0) {
        const facetObject = {
          "Name": "SUBJECT",
          "Selected": subjectsArray
        }
        facetsArray.push(facetObject)
      }
      if (formatsArray.length > 0) {
        const facetObject = {
          "Name": "FORMAT",
          "Selected": formatsArray
        }
        facetsArray.push(facetObject)
      }
      if (librariesArray.length > 0) {
        const facetObject = {
          "Name": "LIBRARY",
          "Selected": librariesArray
        }
        facetsArray.push(facetObject)
      }
      if (genresArray.length > 0) {
        const facetObject = {
          "Name": "ITEMCAT2",
          "Selected": genresArray
        }
        facetsArray.push(facetObject)
      }
      if (categoriesArray.length > 0) {
        const facetObject = {
          "Name": "ITEMCAT1",
          "Selected": categoriesArray
        }
        facetsArray.push(facetObject)
      }
      if (eReadersArray.length > 0) {
        const facetObject = {
          "Name": "EREADER",
          "Selected": eReadersArray
        }
        facetsArray.push(facetObject)
      }
      if (eFormatsArray.length > 0) {
        const facetObject = {
          "Name": "ERC_FORMAT",
          "Selected": eFormatsArray
        }
        facetsArray.push(facetObject)
      }
      if (materialsArray.length > 0) {
        const facetObject = {
          "Name": "ITYPE",
          "Selected": materialsArray
        }
        facetsArray.push(facetObject)
      }

      // to reset the sorting refresh-button status if the user didn't 
      // refresh the results with the new sorting term
      if (sortingSelection !== sortingTerm) {
        setSortingSelection(sortingTerm)
      }

      setLazyLoadingCounter(0);
      // setDetailedSearchOn(true);
      setSearchingField(searchingFieldSelection);
      setSearchingLimit(searchingLimitSelection);
      setFacetsSearchArray(facetsArray); // this will cause fetching the records
    }
    // setFiltersPool(filtersPoolDynamic);
    // setFiltersPanelStatus(false); // this to close the filtering panel once done
  }

  // populate the filtering checkboxes
  const updateFacets = () => {
    setFilterYears(new Set());
    setFilterAuthors(new Set());
    setFilterCategories(new Set());
    setFilterFormats(new Set());
    setFilterLanguages(new Set());
    setFilterReaders(new Set());
    setFilterLibraries(new Set());
    setFilterSubjects(new Set());
    setFilterMaterialTypes(new Set());
    setFilterGenres(new Set());
    setFilterElectronicFormats(new Set());

    const years = new Set()
    const categories = new Set()
    const authors = new Set()
    const formats = new Set()
    const languages = new Set()
    const readers = new Set()
    const libraries = new Set()
    const subjects = new Set()
    const materialTypes = new Set()
    const genres = new Set()
    const electronicFormats = new Set()

    // local function
    const listRecreate = (facetsArray, type, localArray) => {
      if (facetsArray.length > 0) {
        facetsArray.forEach(facetFormat => {
          facetFormat.checked = false;
          localArray.add(facetFormat)
          // to re create elements from the pool after executing the filter
          if (filtersPoolDynamic.length > 0) {
            const filtered = filtersPoolDynamic.filter(item => (item.facetType === type && item.facetValue === facetFormat.facetValue))
            if (filtered[0] !== undefined) {
              // console.log(filtered[0].facetValue)
              facetFormat.checked = true;
              localArray.add(facetFormat)
            }
          }
        });
        // to re create elements from the pool if only one facet in the pool of its type
      } else {
        if (filtersPoolDynamic.length > 0) {
          const filtered = filtersPoolDynamic.filter(item => item.facetType === type)
          if (filtered[0] !== undefined) {
            filtered[0].checked = true;
            localArray.add(filtered[0])
          }
        }
      }
    }

    const facetsAuthors = facets.filter(item => item.facetType === 'AUTHOR');
    listRecreate(facetsAuthors, "AUTHOR", authors);
    setFilterAuthors(sortByName(authors))
    const facetsYears = facets.filter(item => item.facetType === 'PUBDATE');
    listRecreate(facetsYears, "PUBDATE", years);
    setFilterYears(sortByName(years).reverse())
    const facetsSubjects = facets.filter(item => item.facetType === 'SUBJECT');
    listRecreate(facetsSubjects, "SUBJECT", subjects);
    setFilterSubjects(sortByCount(subjects))
    const facetsLanguages = facets.filter(item => item.facetType === 'LANGUAGE');
    listRecreate(facetsLanguages, "LANGUAGE", languages);
    setFilterLanguages(sortByCount(languages))
    const facetsFormats = facets.filter(item => item.facetType === 'FORMAT');
    listRecreate(facetsFormats, "FORMAT", formats);
    setFilterFormats(sortByCount(formats))
    const facetsLibraries = facets.filter(item => item.facetType === 'LIBRARY');
    listRecreate(facetsLibraries, "LIBRARY", libraries);
    setFilterLibraries(sortByName(libraries))
    const facetsCategories = facets.filter(item => item.facetType === 'ITEMCAT1');
    listRecreate(facetsCategories, "ITEMCAT1", categories);
    setFilterCategories(sortByName(categories))
    const facetsReaders = facets.filter(item => item.facetType === 'EREADER');
    listRecreate(facetsReaders, "EREADER", readers);
    setFilterReaders(sortByCount(readers))
    const facetsGenres = facets.filter(item => item.facetType === 'ITEMCAT2');
    listRecreate(facetsGenres, "ITEMCAT2", genres);
    setFilterGenres(sortByCount(genres))
    const facetsMaterialTypes = facets.filter(item => item.facetType === 'ITYPE');
    listRecreate(facetsMaterialTypes, "ITYPE", materialTypes);
    setFilterMaterialTypes(sortByCount(materialTypes))
    const facetsElectronicFormats = facets.filter(item => item.facetType === 'ERC_FORMAT');
    listRecreate(facetsElectronicFormats, "ERC_FORMAT", electronicFormats);
    setFilterElectronicFormats(sortByCount(electronicFormats))
  };

  const sortByName = (theSetName) => {
    const array = Array.from(theSetName);
    return array.sort((a, b) => (a.facetValue > b.facetValue ? 1 : -1));
  }

  const sortByCount = (theSetName) => {
    const array = Array.from(theSetName);
    return array.sort((a, b) => (a.facetCount < b.facetCount ? 1 : -1));
  }

  const onChangeHandler = (event) => {
    var userInput = event.target.value.replace(/[^a-zA-Z0-9\s]/, '')

    setSearchKeyWords(userInput);
    // fetchSuggestions();
    // setSuggestions(event.target.value);
  }

  // when the user searchs for a new keyword(s)
  const onSubmit = (event) => {
    event.preventDefault();
    updateTheSearch(searchKeyWords);
    setSearchingField(searchingFieldSelection);
    setSearchingLimit(searchingLimitSelection);
    setSortingTerm('any');
    setSortingSelection('any');
    setLazyLoadingCounter(0);
    activateDefaultFilters();
    setAvailability(false)
    setHidePastLoans(false)
    setPastLoansCheckbox(false)
  };

  const handleScrollUp = (elementID) => {
    scroll.scrollMore(99, { containerId: elementID, duration: 200, smooth: true })
  }

  const handleScrollDown = (elementID) => {
    scroll.scrollMore(-99, { containerId: elementID, duration: 200, smooth: true })
  }

  const checkScreenSize = () => {
    if (isTabletOrMobile) {
      setFiltersPanelStatus(false);
    }
  }

  const panelsList = [
    {
      _id: "VlNXAwQqYPvdhTIhdEFkefFJS",
      forKey: "author",
      title: "Author",
      plural: "authors",
      source: filterAuthors,
      counter: filterAuthorsCounter
    },
    {
      _id: "BDMQUZKLpYAaJTnvadgmTjYMS",
      forKey: "publishing-year",
      title: "Pub. Year",
      plural: "years",
      source: filterYears,
      counter: filterYearsCounter
    },
    {
      _id: "xRRYLLADkjOpNbkescRCNEAMD",
      forKey: "subject",
      title: "Subject",
      plural: "subjects",
      source: filterSubjects,
      counter: filterSubjectsCounter
    },
    {
      _id: "MdrFYbwLhGpNlkMzcdXfqIiQi",
      forKey: "language",
      title: "Language",
      plural: "languages",
      source: filterLanguages,
      counter: filterLanguagesCounter
    },
    {
      _id: "TyXvaIrfQBwEYBcaiYjOYVMtJ",
      forKey: "format",
      title: "Format",
      plural: "formats",
      source: filterFormats,
      counter: filterFormatsCounter
    },
    {
      _id: "ilTpURUuLuEuOnhFYyfcRUmGs",
      forKey: "library",
      title: "Library",
      plural: "libraries",
      source: filterLibraries,
      counter: filterLibrariesCounter
    },
    {
      _id: "IaxnGvVxfeZrUFlAvRiHjkSRV",
      forKey: "category",
      title: "Category",
      plural: "categories",
      source: filterCategories,
      counter: filterCategoriesCounter
    },
    {
      _id: "UZLLewKpHzuNKoBXTjYHUoUMY",
      forKey: "genre",
      title: "Genre",
      plural: "genres",
      source: filterGenres,
      counter: filterGenresCounter
    },
    {
      _id: "ABTeFRdsAnUqtmsXqRaMIMdLn",
      forKey: "materialType",
      title: "Material Type",
      plural: "types",
      source: filterMaterialTypes,
      counter: filterMaterialTypesCounter
    },
    {
      _id: "gBRxAxaJUaEuyARheOcSTFkOv",
      forKey: "reader",
      title: "eReader",
      plural: "eReaders",
      source: filterReaders,
      counter: filterReadersCounter
    },
    {
      _id: "fUUoPIGMDBacgViOzclDAXCMk",
      forKey: "electronicFormat",
      title: "Elec. Format",
      plural: "eFormats",
      source: filterElectronicFormats,
      counter: filterElectronicFormatsCounter
    },
  ]

  return (
    <Fragment>
      <Meta data={metaData} />
      <h1 className="visuallyhidden">{applicationData.publishedTitle} Search screen</h1>

      {/* the skipping link */}
      <div className="skip-link-div">
        <a className="lsp-skip-link" href='#results-start'>Skip to the results</a>
      </div>
      {/* the sorting bar */}
      <div className="sorting-bar-wrp" style={!isTop ? { boxShadow: '0 0px 6px 0px var(--shadow-dark)', backgroundColor: "var(--mobile-navbar-color)" } : {}}>
        <div className="sorting-bar" style={!isTop ? { borderBottom: "none" } : {}}>
          {/* the arrow button to open/close filtering area */}
          <div className="sorting-bar-element">
            <button className="filters-panel-btn" onClick={() => setFiltersPanelStatus(!filtersPanelStatus)}
              title={filtersPanelStatus ? "Hide filters panel" : "Show filters panel"}> {filtersPanelStatus ? "Hide filters" : "Show filters"}
              <FontAwesomeIcon icon={filtersPanelStatus ? ["fas", "arrow-alt-left"] : ["fas", "arrow-alt-right"]} />
            </button>
          </div>

          {searchKeywordsDisplay &&
            <div className="sorting-bar-element">
              <p className="sorting-title">Searching for: "<span>{searchKeywordsDisplay}</span>" </p>
            </div>
          }
          {searchResults &&
            <Fragment>
              {searchConfiguration.sortFields &&
                <div className="sorting-bar-element">
                  <label className="sorting-title" htmlFor="sorting_by">Sort by:
                    <select aria-labelledby="sorting_by" title="Sort the results below by.." name="sorting_by" id="sorting_by" value={sortingSelection}
                      onChange={(e) => { setSortingSelection(e.target.value) }}
                      style={{ color: "#000" }}
                    // disabled={searchResults.length > 0 ? false : true}
                    >
                      {searchConfiguration.sortFields.map((item, index) => (
                        <option value={item.fieldValue} key={`sortField-${index}`}>{item.fieldName}</option>
                      ))}
                    </select>
                  </label>

                  {(sortingTerm !== sortingSelection) &&
                    <button onClick={() => activateSorting()} className="refresh_results_btn" aria-labelledby="sorting_by" title="Click to refresh the results">Refresh
                      <FontAwesomeIcon icon={['far', 'sync']} />
                    </button>
                  }
                </div>
              }
              {/* only show available items by checking for its copies array */}
              {/* <div className="sorting-bar-element">
                <div className="checkbox-wrapper" style={{ margin: "0" }}>
                  <input type="checkbox" id="availability_checkbox" checked={availability} aria-checked={availability ? "true" : "false"} onChange={checkAvailability} />
                  <label htmlFor="availability_checkbox">Only available</label>
                </div>
              </div> */}

              {/* show past loans for the current user in low opacity */}
              {(isAuthenticated && pastLoansCheckbox) &&
                <div className="sorting-bar-element">
                  <div className="checkbox-wrapper" style={{ margin: "0" }}>
                    <input type="checkbox" id="pastloans_checkbox" checked={hidePastLoans} aria-checked={hidePastLoans ? "true" : "false"} onChange={checkHidePastLoans} />
                    <label htmlFor="pastloans_checkbox">Hide past loans</label>
                  </div>
                </div>
              }

              <div className="sorting-bar-element">
                {totalResults > 0 ?
                  <p className="sorting-title">Showing<span> {searchResults.length}</span> of <span> {totalResults} </span>results</p> :
                  <p className="sorting-title">Results: <span> {totalResults} </span></p>
                }
              </div>
            </Fragment>
          }
        </div>
      </div>


      <div className="search-wrapper" role="main">
        {/* the filters area on the left side */}
        <div aria-haspopup="true" aria-expanded={filtersPanelStatus ? "true" : "false"} className={filtersPanelStatus ? 'filters-panel active' : 'filters-panel'}>

          {filtersPanelStatus &&
            <Fragment>
              <div className="filters-search-container">

                {/* the searchbox on the left */}
                <form className="filters-search-form" onSubmit={onSubmit}>
                  <label className="filters-labels" htmlFor="search-input">Search keyword(s):
                    <FontAwesomeIcon className="filters-search-icon" icon={["fad", "search"]} />
                    <input
                      type="text"
                      id="search-input"
                      name="search-input"
                      onChange={onChangeHandler}
                      autoComplete="off"
                      value={searchKeyWords}
                      placeholder="Search catalogue"
                    />
                    {/* <input name="search-input" list="suggest" id="search-input" onChange={onChangeHandler} autoComplete="off" value={searchKeyWords} placeholder="Search catalogue" type="text" />
                    {suggestions &&
                      <datalist id="suggest">
                        {suggestions.map((book, index) => (
                          <option style={{ color: "white" }} key={`suggest-${index}`} value={book.volumeInfo.title} />
                        )
                        )}
                      </datalist>
                    } */}
                  </label>
                  {(searchConfiguration.searchFields && searchConfiguration.searchFields.length > 0) &&
                    <div className="searchfield-wrapper">
                      <label className="filters-labels" htmlFor="search_within" >Search field:</label>
                      <select aria-labelledby="search_within" title="Select a search field for your keyword(s)" name="search_within" id="search_within" value={searchingFieldSelection}
                        onChange={(e) => { setSearchingFieldSelection(e.target.value) }}>
                        <option value=''>KEYWORD</option>
                        {searchConfiguration.searchFields.map((item, index) => (
                          <option value={item.fieldValue} key={`searchField-${index}`}>{item.fieldName}</option>
                        ))}
                      </select>
                    </div>
                  }
                  {(searchConfiguration.searchLimits && searchConfiguration.searchLimits.length > 0) &&
                    <div className="searchfield-wrapper">
                      <label className="filters-labels" htmlFor="search_limit" >Search Limit:</label>
                      <select aria-labelledby="search_limit" title="Select a search limit for your keyword(s)" name="search_limit" id="search_limit" value={searchingLimitSelection}
                        onChange={(e) => { setSearchingLimitSelection(e.target.value) }}>
                        {searchConfiguration.searchLimits.map((item, index) => (
                          <option value={item.limitValue} key={`searchLimit-${index}`}>{item.limitName}</option>
                        ))}
                        <option value=''>All locations</option>
                      </select>
                    </div>
                  }
                </form>

                <div className="filters-categories">
                  <div className="filters-container">
                    {filtersPoolDynamic.length > 0 ?
                      <Fragment>
                        {filtersPoolDynamic.map((item, index) => (
                          <button className="filters-single" key={`${item.facetType}-${index}`} title={`Remove ${item.facetLabel} filter-item; ${item.facetName}`}
                            onClick={() => removeSelectedFromThePool(item.facetValue, item.facetType)}>
                            <p>{item.facetType === 'ITEMCAT1' ? 'Category' : (item.facetType === 'ITEMCAT2' ? 'Genre' : item.facetLabel)}: <span>{item.facetName}</span></p>
                            <FontAwesomeIcon icon={["far", "times-circle"]} />
                          </button>
                        ))}
                      </Fragment> :
                      <p className="filters-text">No filters!</p>
                    }
                  </div>
                  <Collapse accordion={true} collapsible={true}>
                    {panelsList.map(item => (
                      <FilterPanel
                        key={item._id}
                        forKey={item.forKey}
                        title={item.title}
                        plural={item.plural}
                        source={item.source}
                        counter={item.counter}

                        handleScrollDown={handleScrollDown}
                        handleScrollUp={handleScrollUp}
                        updateFiltersPool={updateFiltersPool}
                        clearFilters={clearFilters}
                        loading={loading}
                      />
                    )
                    )}
                  </Collapse>
                </div>
              </div>
            </Fragment>
          }
        </div>

        {/* the new floating buttons */}
        <div className={refreshResultsButtonStatus ? "floating-div active" : "floating-div "}>
          <div className="filters-container-buttons">
            <button
              className="filters-container-btn"
              title="Clear All filters"
              onClick={() => clearFilters()}
              disabled={!removeAllButtonStatus}
            >Clear all<FontAwesomeIcon icon={["fas", "times"]} /></button>
            <button
              className="filters-container-btn"
              title="Refresh the search results"
              onClick={() => sendQueryWithFilters()}
              disabled={!refreshResultsButtonStatus}
            >Apply filters<FontAwesomeIcon icon={["fas", "caret-right"]} /></button>
          </div>
        </div>

        {/* The results area on the right hand */}
        <div className={filtersPanelStatus ? 'results-panel active' : 'results-panel'}>
          {/* result jackets div */}
          <div className="search-rows-container">
            <div className="search-row" id="results-start">
              {searchResults.length > 0 ?
                <Fragment>
                  {/* {console.log('Search-results rendered')} */}
                  {searchResults.map((record, index) => (
                    <MiniJacket
                      isBook
                      visible
                      isSearchResult
                      availability={availability}
                      hidePastLoans={hidePastLoans}
                      setPastLoansCheckbox={setPastLoansCheckbox}
                      recordData={record}
                      recordID={record.recordID}
                      loadingJackets={loading}
                      key={`Search${index}_` + record.recordID}
                      id={`Search${index}_` + record.recordID}
                    />
                  ))}
                </Fragment> : <Fragment>
                  {!loading &&
                    <p className="no-results">No results could be found for: <b>{searchKeywordsDisplay}</b>
                      {filtersPoolDynamic.length > 0 && " with the provided search criteria."}
                    </p>
                  }
                </Fragment>
              }
              {/* load more button */}
              {hasMore &&
                <button title="Load more search results" onClick={() => setLazyLoadingCounter(lazyLoadingCounter + 24)} className="load-more-container">
                  <FontAwesomeIcon icon={['far', 'sync']} spin={loading ? true : false} /><br />
                  <p>Load more</p>
                </button>
              }
            </div>
          </div>

        </div>

      </div>
    </Fragment>
  );
}

export default Search;
