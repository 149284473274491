import React, { Component, Fragment } from 'react'

import PreferencesOne from './PreferencesOne';
import PreferencesTwo from './PreferencesTwo';
import PreferencesThree from './PreferencesThree';

import { AuthContext } from '../../../AuthContext';

export class Preferences extends Component {

  state = { step: this.props.location.step || 1 };

  // next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  }
  // previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  }
  // handle fields change
  handleChange = input => e => {
    this.setState({ [input]: e.target.value })
  }

  render() {
    const { step } = this.state;

    switch (step) {
      case 1:
        return (
          <Fragment>
            <AuthContext.Consumer>{(authContext) => {
              const { setUserSavedAvatar, savedAvatarCode, saveUserAvatar } = authContext;
              return (
                <Fragment>
                  <PreferencesOne
                    setUserSavedAvatar={setUserSavedAvatar}
                    savedAvatarCode={savedAvatarCode}
                    saveUserAvatar={saveUserAvatar}

                    nextStep={this.nextStep}
                    handleChange={this.handleChange}
                  />
                </Fragment>
              )
            }
            }</AuthContext.Consumer>
          </Fragment>
        )
      case 2:
        return (
          <Fragment>
            <AuthContext.Consumer>{(authContext) => {
              const { userSavedGenres, saveUserPreferenceGenres } = authContext;
              return (
                <Fragment>
                  <PreferencesTwo
                    userSavedGenres={userSavedGenres}
                    saveUserPreferenceGenres={saveUserPreferenceGenres}

                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                  />
                </Fragment>
              )
            }
            }</AuthContext.Consumer>
          </Fragment>
        )
      case 3:
        return (
          <Fragment>
            <AuthContext.Consumer>{(authContext) => {
              const { userSavedFormats, saveUserPreferenceFormats } = authContext;
              return (
                <Fragment>
                  <PreferencesThree
                    saveUserPreferenceFormats={saveUserPreferenceFormats}
                    userSavedFormats={userSavedFormats}

                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                  />
                </Fragment>
              )
            }
            }</AuthContext.Consumer>
          </Fragment>
        )
      default:
        return (
          <div><p>No options selected</p></div>
        )
    }
  }
}

export default Preferences
