import React, { useState, useEffect, Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import './Preferences.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../../../AuthContext';

import luciData from '../../../luciConfig.json';

function PreferencesThree(props) {
  const { convertToString, convertToArray } = useContext(AuthContext);

  const [selectedFormats, setSelectedFormats] = useState([]);
  const [savedFormats, setSavedFormats] = useState([]);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0); // to activate the navbar highlighting function

    setSavedFormats(convertToArray(props.userSavedFormats).sort());
    setSelectedFormats(convertToArray(props.userSavedFormats).sort());
  }, [])

  const addAndRemoveFormats = (format) => {
    if (!selectedFormats.includes(format)) {
      setSelectedFormats(selectedFormats.concat(format));
    } else {
      const currState = [...selectedFormats];
      var index = currState.indexOf(format)
      if (index !== -1 && selectedFormats.length > 1) { // at least two condition
        currState.splice(index, 1);
        setSelectedFormats(currState);
      }
    }
  }

  const onPrevious = () => {
    if (JSON.stringify(selectedFormats) !== JSON.stringify(savedFormats)) {
      props.saveUserPreferenceFormats(convertToString(selectedFormats));
    }
    // go to the previous screen
    props.prevStep();
  }
  const onNext = () => {
    if (JSON.stringify(selectedFormats) !== JSON.stringify(savedFormats)) {
      props.saveUserPreferenceFormats(convertToString(selectedFormats));
    }
    // go to bookshelf screen
    history.push("/user/account");
  }
  const onSkip = () => {
    // go to bookshelf screen directly
    history.push("/user/account");
  }
  const onCancel = () => {
    history.push("/user/account");
  }
  return (
    <div className="preferences-outer-container" role="main">
      <div className="preferences-background-overly"></div>
      <div className="preferences-inner-container">
        <h3>Do you prefer to..</h3>
        <div className="preferences-formats-container">
          {luciData.userOptions[2].formats.map((format, index) => {
            return (
              <div key={format.code} onClick={() => addAndRemoveFormats(`${format.code}`)}
                className={selectedFormats.includes(`${format.code}`) ? "formats-outer formats-chosen" : "formats-outer"} >
                <img className="image-fit" src={format.photoURL} alt={format.code} />
                <p>{format.name}</p>
              </div>
            )
          })}
        </div>

        {/*  BUTTONS  */}
        <div className="preferences-buttons">
          <div className="short-container justify-center">
            <div className="short">
              {selectedFormats.length > 0 &&
                <Fragment>
                  <button className="next-btn" type='button' onClick={onPrevious}>Save &amp; back to Genres<FontAwesomeIcon className="prev-icon" icon={['far', 'chevron-left']} /></button>
                  <button className="next-btn" type='button' onClick={onNext}>Save &amp; back to Account<FontAwesomeIcon className="next-icon" icon={['far', 'chevron-right']} /></button>
                </Fragment>
              }
              <button className="next-btn" type='button' onClick={onCancel}>Cancel<FontAwesomeIcon className="next-icon" icon={['far', 'chevron-right']} /></button>
            </div>
          </div>
        </div>
        {/* <div className="skip-link">
          <a onClick={() => onSkip()}>Complete preferences later on... 	&gt;</a>
        </div> */}
      </div>
    </div>
  );
}

export default PreferencesThree;
