import React, { Component, Fragment } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { AuthContext } from "../../AuthContext";
import EventsCard from './EventsCard';
import Arrow from "../common/Arrow";

export default class EventsCarousel extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);

    this.state = {
      activeIndex: 0,
      displayRightArrow: false,
      displayLeftArrow: false,
      eventsCardsToScroll: 0,
    };

    this.sliderSetting = {
      dots: false,
      arrows: false,
      accessibility: true,
      draggable: true,
      speed: process.env.REACT_APP_CAROUSEL_SPEED,
      slidesToScroll: 2,
      infinite: false,
      swipeToSlide: true,
      variableWidth: true,
      adaptiveHeight: false,
      focusOnSelect: false,
      nextArrow: null,
      prevArrow: null,
      afterChange: currentSlide => { this.setArrowDisplay(currentSlide) }
    };
  }

  setArrowDisplay = (currentSlide) => {
    const displayLeftArrow = currentSlide !== 0;
    const displayRightArrow =
      currentSlide + this.context.tabsToShow <=
      this.props.source.length;

    this.setState({ displayRightArrow, displayLeftArrow });
  };

  componentDidMount() {
    this.setArrowDisplay(0);
  }
  componentDidUpdate(prevProps) {
    if (this.props.source !== prevProps.source) {
      this.setArrowDisplay(0);
    }
  }

  clickHandler = (direction) => {
    if (direction === "left") {
      this.slider.slickPrev();
    } else if (direction === "right") {
      this.slider.slickNext();
    }
  };

  render() {
    return (
      <Fragment>
        {this.props.rowTitle &&
          <h2 className="cards-row-title">{this.props.rowTitle}</h2>
        }
        <div className="events-row">
          {this.props.source.length > 0 ? (
            <Fragment>
              <Arrow
                styleClassName={
                  this.state.displayRightArrow
                    ? "lsp-rightbtn-containter"
                    : "lsp-rightbtn-containter hidden"
                }
                direction="right"
                clickHandler={this.clickHandler}
                isTabletOrMobile={this.context.isTabletOrMobile}
                carouselName="Events carousel"
              />
              <Arrow
                styleClassName={
                  this.state.displayLeftArrow
                    ? "lsp-leftbtn-containter"
                    : "lsp-leftbtn-containter hidden"
                }
                direction="left"
                clickHandler={this.clickHandler}
                isTabletOrMobile={this.context.isTabletOrMobile}
                carouselName="Events carousel"
              />
              <Slider
                {...this.sliderSetting}
                ref={(eventsCarouselSlider) =>
                  (this.slider = eventsCarouselSlider)
                }
              >
                {this.props.source.map(event => {
                  return (
                    <EventsCard
                      applicationData={this.context.applicationData} event={event} key={`event-card-${event.event_id}`}
                    />
                  );
                })}
              </Slider>
            </Fragment>
          ) : (
            <div className="placeholder-container" style={this.context.isTabletOrMobile ? {} : { width: this.context.jacketsWidth + "px", height: this.context.jacketsHeight + "px" }}>
              {!this.props.loading && (
                <p>{this.props.noEventsMessage}</p>
              )}
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}
