import './Footer.css';
import React, { useContext, useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import JoinBanner from './JoinBanner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../../../AuthContext';

function Footer(props) {
  const { applicationData, channelData, socialLinks, isAuthenticated, patronFields, toast } = useContext(AuthContext);
  const { location, history } = props;

  const [facebookLink, setFacebookLink] = useState('');
  const [twitterLink, setTwitterLink] = useState('');
  const [instagramLink, setInstagramLink] = useState('');
  const [youTubeLink, setYouTubeLink] = useState('');
  const [serverName, setServerName] = useState('');

  useEffect(() => {
    if (socialLinks !== undefined && socialLinks.length > 0) {
      getSocialMediaLinks();
    }
  }, [applicationData, socialLinks])

  useEffect(() => {
    if (process.env.REACT_APP_MAIN_API_ENDPOINT) {
      const urlElements = process.env.REACT_APP_MAIN_API_ENDPOINT.split('/')
      setServerName(urlElements[2].slice(4, 10));
    }
  }, []);

  const getSocialMediaLinks = () => {
    if (socialLinks !== []) {
      const facebook = socialLinks.filter(link => link.key === 'facebook')
      const twitter = socialLinks.filter(link => link.key === 'twitter')
      const instagram = socialLinks.filter(link => link.key === 'instagram')
      const youtube = socialLinks.filter(link => link.key === 'youtube')

      if (facebook && facebook.length > 0) {
        setFacebookLink(facebook[0].value)
      }
      if (twitter && twitter.length > 0) {
        setTwitterLink(twitter[0].value)
      }
      if (instagram && instagram.length > 0) {
        setInstagramLink(instagram[0].value)
      }
      if (youtube && youtube.length > 0) {
        setYouTubeLink(youtube[0].value)
      }
    }
  }

  // no footer in home screen
  if (location.pathname === '/') {
    return null;
  }

  const handleSignUpModal = () => {
    if (Object.keys(patronFields).length > 0) {
      history.push({ pathname: `/user/register` });
    } else {
      toast.info("Sorry, registration is not open yet for this web app.")
    }
  }

  return (
    <Fragment>
      {channelData.title &&
        <footer role="contentinfo" id="footer">
          {!isAuthenticated &&
            <JoinBanner handleSignUpModal={handleSignUpModal} libraryname={applicationData.publishedTitle} toast={toast} />
          }
          {channelData.additionalContent !== "<div><div></div></div>" ?
            <div className="footer block">
              <div className="footer-container pt-5 pb-3">
                <div dangerouslySetInnerHTML={{ __html: channelData.additionalContent }} />
              </div>
              <div className="copyright">
                <p>&copy; {new Date().getFullYear()} LUCi - v {process.env.REACT_APP_VERSION} {serverName}</p>
              </div>
            </div>
            : <div className="footer" >
              {channelData.id !== '00000000-0000-0000-0000-000000000000' &&
                <Fragment>
                  <div className='row'>
                    <div className="col">
                      {channelData.openHours &&
                        <div className="footer-container desktop-only pt-5 pb-3">
                          <h3 className="footer-titles left-aligned">Opening Hours</h3>
                          <div className="hours-container">
                            <ul>
                              {channelData.openHours.map((line, index) => (
                                <li key={`opening-hours-${index}`}><b>{line.name}:</b> {line.hours === 0 ? <Fragment>Closed</Fragment> : <Fragment>
                                  {line.opens} - {line.closes}</Fragment>}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      }
                    </div>
                    <div className="col">
                      <div className="footer-container pt-5 pb-3">
                        <h3 className="footer-titles">{applicationData.publishedTitle || channelData.title || ''} </h3>
                        <ul className="address">
                          {channelData.address &&
                            <li>{channelData.address}</li>
                          }
                          {channelData.phoneNumber &&
                            <li className="phone-number"><span className="desktop-only">T: </span><a href={`tel:${channelData.phoneNumber}`}>{channelData.phoneNumber}</a></li>
                          }
                          {channelData.emailAddress &&
                            <li className="e-mail"><span className="desktop-only">E: </span><a href={`mailto:${channelData.emailAddress}`}>{channelData.emailAddress}</a> </li>
                          }
                        </ul>
                        <div className="social-media">
                          {facebookLink &&
                            <a href={facebookLink} target="_blank" rel="noopener noreferrer" title="Check our Facebook page out" aria-label="Check our Facebook page out">
                              <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                            </a>
                          }
                          {twitterLink &&
                            <a href={twitterLink} target="_blank" rel="noopener noreferrer" title="Check our Twitter out" aria-label="Check our Twitter out">
                              <FontAwesomeIcon icon={["fab", "twitter"]} />
                            </a>
                          }
                          {instagramLink &&
                            <a href={instagramLink} target="_blank" rel="noopener noreferrer" title="Check our Instagram out" aria-label="Check our Instagram out">
                              <FontAwesomeIcon icon={["fab", "instagram"]} />
                            </a>
                          }
                          {youTubeLink &&
                            <a href={youTubeLink} target="_blank" rel="noopener noreferrer" title="Check our YouTube channel out" aria-label="Check our YouTube channel out">
                              <FontAwesomeIcon icon={["fab", "youtube"]} />
                            </a>
                          }
                        </div>
                      </div>

                    </div>
                    <div className="col">
                      {(channelData && channelData.locationLat !== 0 && channelData.locationLng !== 0) &&
                        <div className="footer-container desktop-only">
                          {/* <iframe src={libaryData.googleMapsLocation} width="100%" height="100%" title="Location on Google-Maps" aria-hidden="false"></iframe> */}
                          <iframe
                            title="Library location on Google-Maps"
                            frameBorder="0"
                            scrolling="no"
                            marginHeight="0"
                            marginWidth="0"
                            aria-hidden="false"
                            src={`https://maps.google.com/maps?width=100%25&height=100%25&q=${channelData.locationLat},${channelData.locationLng}&z=15&output=embed`} width="100%" height="100%" ></iframe>
                        </div>
                      }
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className="copyright">
                        <p>&copy; {new Date().getFullYear()} LUCi - v {process.env.REACT_APP_VERSION} {serverName}</p>
                      </div>
                    </div>
                  </div>
                </Fragment>
              }
            </div>
          }
        </footer>
      }
    </Fragment>
  )
}

export default withRouter(Footer);
