import React, { useContext, useEffect, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SyncLoader } from 'react-spinners'
import { AuthContext } from '../../../AuthContext';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

export default function Register() {
  const { loading, waiting, isTabletOrMobile, setModalLoginStatus, clearMessages, patronFields, registerUser, signUpMessage, errorsArray, isAuthenticated } = useContext(AuthContext);
  const history = useHistory();

  const { formState: { errors }, handleSubmit, formState, control } = useForm({ mode: 'all' });
  const { fields, append } = useFieldArray({
    control,
    name: 'patronForm',
  });

  useEffect(() => {
    if (isAuthenticated) {
      handleExit()
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (patronFields.length > 0) {
      patronFields.forEach(element => {
        append(element)
      });
    }
  }, [patronFields]);


  // reCAPTCHA handling
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
      if (!isScriptExist && patronFields.length > 0) {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
      if (isScriptExist && callback) callback();
    }

    // loading the recaptcha script
    loadScriptByURL("recaptcha-script", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`, function () {
      // console.log("Recaptcha script loaded successfully!");
    });

    // cleaning up the script and badge after use
    return () => {
      let script = document.getElementById("recaptcha-script");
      let badge = document.querySelector(".grecaptcha-badge");
      if (script && badge) {
        script.remove();
        let nextNode = badge.nextSibling;
        if (nextNode) {
          nextNode.remove();
        }
        badge.remove();
        // console.log("Recaptcha script removed!");
      }
    }
  }, []);

  const onSubmitSignupForm = (data) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(token => {
        submitSignup(data, token)
      });
    });
  };

  const submitSignup = (data, token) => {
    // call a backend API to verify reCAPTCHA response
    fetch('/verify', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        'g-recaptcha-response': token
      })
    }).then(res => res.json()).then(res => {
      // console.log(res);
      if (res.success) {
        let arr = []
        for (var key of Object.keys(data)) {
          let obj = { "key": key, "value": data[key].toString() }
          arr.push(obj)
        }
        // console.log(arr);
        registerUser(arr);
        // handleExit();
      }
    });
  }

  const handleExit = () => {
    history.push('/')
    clearMessages()
  }

  return (
    <div className="wcard-outer-container">
      <div className="wcard-container">
        <h2 className="form-title">Patron Sign-Up Form</h2>
        {patronFields.length > 0 ?
          <form className="signup-form" onSubmit={handleSubmit(onSubmitSignupForm)}>
            <div className="row justify-content-center">
              {fields.map(item =>
                <Fragment key={item.id}>

                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="select-container">
                      <Controller
                        control={control}
                        name={item.code}
                        rules={item.maxLength > 0 ? {
                          required: {
                            value: item.required,
                            message: `You must enter your ${item.label}`
                          },
                          minLength: {
                            value: item.minLength,
                            message: `Your ${item.label} must be ${item.minLength} characters long at least`
                          },
                          maxLength: {
                            value: item.maxLength,
                            message: `Your ${item.label} must be of maximum ${item.maxLength} characters long`
                          }
                        } : {
                          required: {
                            value: item.required,
                            message: `You must enter your ${item.label}`
                          },
                          minLength: {
                            value: item.minLength,
                            message: `Your ${item.label} must be ${item.minLength} characters long at least`
                          }
                        }}
                        render={({ field }) => (
                          <>
                            {item.fieldType !== 3 && // Checkbox Labels should come after the input tag an inside a special div
                              <label className="input-label" htmlFor={item.code}>{item.label}:</label>
                            }
                            {/* text & phone input */}
                            {(item.fieldType === 0 || item.fieldType === 5) &&
                              <input {...field}
                                className="input-field narrow"
                                title={`Please enter your ${item.label}`}
                                placeholder={`Your ${item.label}`}
                                autoComplete="on"
                                type="text"
                                defaultValue=""
                                onChange={(e) => field.onChange(e.target.value)}
                              />
                            }
                            {/* select options */}
                            {(item.fieldType === 1 && item.optionList && item.optionList.length > 1) &&
                              <Fragment>
                                <FontAwesomeIcon className="arrow-icon" icon={['far', 'chevron-down']} />
                                <select {...field}
                                  title={`Please choose your ${item.label}`}
                                  className="input-field narrow"
                                  defaultValue="default"
                                >
                                  <option value="default" disabled>Please select</option>
                                  {item.optionList.map((option, index) => (
                                    <option value={option.value} key={`${item.code}-option-${index}`}>{option.value}</option>
                                  )
                                  )}
                                </select>
                              </Fragment>
                            }
                            {/* number input */}
                            {item.fieldType === 4 &&
                              <input {...field}
                                className="input-field narrow"
                                title={`Please enter your ${item.label}`}
                                placeholder={`Your ${item.label}`}
                                autoComplete="on"
                                type="number"
                                defaultValue=""
                                onChange={(e) => field.onChange(e.target.value)}
                              />
                            }
                            {/* email input */}
                            {item.fieldType === 7 &&
                              <input {...field}
                                className="input-field narrow"
                                title={`Please enter your ${item.label}`}
                                placeholder={`Your ${item.label}`}
                                autoComplete="on"
                                type="email"
                                defaultValue=""
                                onChange={(e) => field.onChange(e.target.value)}
                              />
                            }
                            {/* Checkbox input */}
                            {item.fieldType === 3 &&
                              <div className="checkbox-wrapper">
                                <input {...field}
                                  className="input-field narrow"
                                  title={`Please tick the box ${item.label}`}
                                  placeholder={`Your ${item.label}`}
                                  type="checkbox"
                                  defaultChecked={false}
                                  value={false}
                                  onChange={(e) => field.onChange(e.target.value)}
                                />
                                <label htmlFor={item.code}>{item.additionalInfo ? item.additionalInfo : item.label}</label>
                              </div>
                            }
                            {/* password input */}
                            {item.fieldType === 8 &&
                              <input {...field}
                                className="input-field narrow"
                                title={`Please enter your ${item.label}`}
                                autoComplete="off"
                                type="password"
                                defaultValue=""
                                onChange={(e) => field.onChange(e.target.value)}
                              />
                            }
                            <ErrorMessage errors={errors} name={item.code} render={({ message }) => <div className="error-msg">{message}</div>} />
                            {item.additionalInfo &&
                              <p className="form-add-info">{item.additionalInfo}</p>
                            }
                          </>
                        )}
                      />
                    </div>
                  </div>
                </Fragment>
              )}

              {/* Error messages back from the server */}
              <div className="col-md-12">
                {signUpMessage !== "" && <p className="error-msg" style={{ color: signUpMessage === 'Success!' && 'var(--success-green)' }}>{signUpMessage}</p>}
              </div>

              {!loading &&
                <div className="col-md-12 pt-4">
                  <p className="dialogue-message centered">Already have an account? Please <button type="button" onClick={() => setModalLoginStatus(true)}>login</button> </p>
                  <div className="login-dialogue-buttons">
                    <button
                      type="submit"
                      className="contact-btn"
                      disabled={waiting || !formState.isValid}
                    >
                      {!waiting &&
                        <Fragment> Register <FontAwesomeIcon icon={['fad', 'sign-in-alt']} /> </Fragment>
                      }
                      {waiting &&
                        <span className="login-waiting">
                          {isTabletOrMobile ?
                            <SyncLoader size={5} margin={0} color='var(--primary)' /> :
                            <SyncLoader size={10} margin={1} color='var(--primary)' />
                          }
                        </span>
                      }
                    </button>
                    <button className="contact-btn" type="reset">Reset<FontAwesomeIcon icon={['fas', 'undo']} /></button>
                    <button className="contact-btn" type="button" onClick={handleExit}>Cancel<FontAwesomeIcon icon={['fas', 'times']} /></button>
                  </div>
                </div>
              }
            </div>
          </form> :
          <div className="row justify-content-center">
            {!loading &&
              <div className="col-md-12">
                <p className="dialogue-message centered">No data found for the form!</p>
                <p className="dialogue-message centered">Already have an account? Please <button type="button" onClick={() => setModalLoginStatus(true)}>login</button> </p>
              </div>
            }
          </div>
        }
      </div>
    </div >
  );
}