import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppContext } from '../../../AppContext';

const OuterAvailableFormats = (props) => {
  const [recordFormat, setRecordFormat] = useState('');
  const { appFormats } = useContext(AppContext);

  useEffect(() => {
    if (props.format) {
      const searchingFormat = props.format
      const obj = appFormats.filter(item => item.formatVariety.includes(searchingFormat))
      if (obj.length > 0) {
        setRecordFormat(obj[0])
      }
    }
    // else if (props.mainMetadata) {
    //   const searchingFormat = props.mainMetadata
    //   const formatObj = searchingFormat.filter(item => item.key === "ERC_FORMAT")
    //   if (formatObj.length > 0) {
    //     const obj = appFormats.filter(item => item.formatVariety.includes(formatObj[0].value))
    //     if (obj.length > 0) {
    //       setRecordFormat(obj[0])
    //     }
    //   }
    // }
  }, [props])

  return (
    <div className={props.mini ? "mini-outer-formats-container" : "outer-formats-container"} style={!props.isRecommendation ? { width: "100%" } : {}}>
      {(recordFormat && recordFormat !== '') &&
        <div title={props.format}
          className={props.mini ? (props.isAvailable ? "mini-formats-outside active" : "mini-formats-outside") :
            (props.isAvailable ? "formats-outside active" : "formats-outside")}>
          <FontAwesomeIcon className={props.mini ? "mini-outer-icons" : "outer-icons"} icon={[recordFormat.formatIcon.substring(0, 3), recordFormat.formatIcon.substring(4)]} />
        </div>
      }
    </div>
  )
};

export default OuterAvailableFormats;