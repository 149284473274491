import React, { Fragment } from 'react'


function TheArrowUnderneath() {
  return (
    <Fragment>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 261.65 17.8">
        <polygon fill="var(--secondary)" points="130.8,17.8 116.1,3 0,3 0,0 117.4,0 130.8,13.5 144.3,0 261.7,0 261.7,3 145.5,3 " />
      </svg>
    </Fragment>
  );
}

export default TheArrowUnderneath;