import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SyncLoader } from 'react-spinners'
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TheArrowUnderneath from '../TheArrowUnderneath'
import Arrow from '../Arrow';
import { AuthContext } from '../../../AuthContext';

export default class ServicesCatBar extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.changeSlide = this.changeSlide.bind(this)
    this.getIndex = this.getIndex.bind(this)
    this.clickHandler = this.clickHandler.bind(this)

    this.state = {
      activeIndex: 0,
      displayRightArrow: false,
      displayLeftArrow: false,
    }

    this.sliderSetting = {
      dots: false,
      arrows: false,
      accessibility: true,
      draggable: true,
      speed: process.env.REACT_APP_CAROUSEL_SPEED,
      slidesToScroll: 2,
      infinite: false,
      swipeToSlide: true,
      variableWidth: true,
      adaptiveHeight: false,
      focusOnSelect: false,
      nextArrow: null,
      prevArrow: null,
      afterChange: currentSlide => { this.setArrowDisplay(currentSlide) }
    }
  }

  setArrowDisplay = currentSlide => {
    const displayLeftArrow = currentSlide !== 0;
    const displayRightArrow = currentSlide + this.context.tabsToShow <= this.props.subcategories.length;

    this.setState({ displayRightArrow, displayLeftArrow });
  };

  componentDidMount() {
    this.setArrowDisplay(0)
  }

  componentDidUpdate(prevProps) {
    if (this.props.secondRowSelectedID === '' || this.props.secondRowSelectedID === undefined) {
      return
    }
    if (this.props.secondRowSelectedID !== prevProps.secondRowSelectedID) {
      let index = this.getIndex(this.props.secondRowSelectedID);
      let factor = (this.context.tabsToShow / 2).toFixed(0) - 1

      this.setState({
        activeIndex: index
      });

      this.setArrowDisplay(index);

      if (factor >= 3) {
        this.slider.slickGoTo(index - factor);
      } else if (factor <= 2) {
        this.slider.slickGoTo(index - factor + 1);
      }
    }
  }

  clickHandler = (direction) => {
    if (direction === 'left') {
      this.slider.slickPrev();
    } else if (direction === 'right') {
      this.slider.slickNext();
    }
  };

  getIndex = (id) => {
    return this.props.subcategories.findIndex(tab => tab.id === id);
  }

  changeSlide = (index) => {
    this.setState({
      activeIndex: index
    });
  }

  render() {
    return (
      <div className="lib-slider-bar">
        <div className="lsp-slider-wrap" style={{ minHeight: "100px" }}>
          <Arrow
            styleClassName={this.state.displayLeftArrow ? 'lsp-leftbtn-containter' : 'lsp-leftbtn-containter hidden'}
            direction="left"
            clickHandler={this.clickHandler}
            isTabletOrMobile={this.context.isTabletOrMobile}
            carouselName="library digital-content level three tabs navbar"
          />
          <Arrow
            styleClassName={this.state.displayRightArrow ? 'lsp-rightbtn-containter' : 'lsp-rightbtn-containter hidden'}
            direction="right"
            clickHandler={this.clickHandler}
            isTabletOrMobile={this.context.isTabletOrMobile}
            carouselName="library digital-content level three tabs navbar"
          />
          <Slider {...this.sliderSetting} ref={subCatSlider => this.slider = subCatSlider}>
            {this.props.subcategories.map((subcategory, index) => {
              return (
                <div key={subcategory.id} style={{ width: this.props.tabWidth + "px", marginRight: "2px" }}>
                  <Link
                    to={`/${this.context.digitalContentTab.canonicalTitle}/${this.props.levelOneCanonicalTitle}/${this.props.levelTwoCanonicalTitle}/${subcategory.canonicalTitle}/`}
                    onClick={() => { scroll.scrollTo(300); this.changeSlide(index); this.props.action(subcategory.id, subcategory); this.props.setChannelExtraction([]); }}
                  >
                    <div onClick={this.props.secondRowShrinked ? () => this.props.action(subcategory.id, subcategory) : undefined} >
                      <div className={
                        this.props.secondRowShrinked ? (this.props.secondRowSelectedID === subcategory.id ? "second-row-items shorten selected vignette"
                          : "second-row-items shorten vignette") :
                          this.props.secondRowSelectedID === subcategory.id ? "second-row-items selected vignette"
                            : "second-row-items vignette"
                      }
                        style={subcategory.image ? {
                          "backgroundImage": `url(data:image/jpeg;base64,${subcategory.image})`
                        }
                          : { "backgroundImage": `url("/no-img-ph2.jpg")` }}>
                        {this.props.secondRowShrinked && <div className="pointy"></div>}
                        <div className="sub-service-name">
                          <p>{subcategory.title}</p>
                          {(this.props.loadingLevelThree && this.props.selectedIDForLoading === subcategory.id) &&
                            <span className="subtabs-loading">
                              {this.context.isTabletOrMobile ?
                                <SyncLoader size={8} margin={0} color='var(--primary)' /> :
                                <SyncLoader size={10} margin={1} color='var(--primary)' />
                              }
                            </span>
                          }
                          <div className="hover-line"></div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    );
  }
}
