import React, { useContext } from 'react';
import Helmet from 'react-helmet'
import { AppContext } from '../../AppContext';

const locales = {
  "en": "en_GB"
}

const Meta = (props) => {
  const { applicationData } = useContext(AppContext);

  const lang = props.data.lang || "en"
  const title = props.data.title
  const basePath = `http://${applicationData.primaryDNS}` || '' // TODO: change this into https in production
  const description = props.data.description
  const image = props.data.image !== undefined && `${basePath}/${props.data.image}`
  const canonical = props.data.canonical !== undefined && `${basePath}${props.data.canonical}`
  const type = props.data.type === undefined ? "article" : "website"
  const width = props.data.image && (props.data.width || 1200)
  const height = props.data.image && (props.data.height || 630)

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      {canonical ? <link rel="canonical" href={canonical} /> : null}
      {image ? <link rel="image_src" href={image} /> : null}
      {image ? <meta itemprop="image" content={image} /> : null}

      <meta property="og:site_name" content="..." />
      <meta property="og:title" content={title} />
      {description ? <meta property="og:description" content={description} /> : null}
      {canonical ? <meta property="og:url" content={canonical} /> : null}
      <meta property="og:locale" content={locales[lang]} />
      <meta property="og:type" content={type} />
      {image ? <meta property="og:image" content={image} /> : null}
      {width ? <meta property="og:image:width" content={width} /> : null}
      {height ? <meta property="og:image:height" content={height} /> : null}
      <meta property="fb:pages" content="..." />

      {/* change type of twitter if there is no image? */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      {description ? <meta name="twitter:description" content={description} /> : null}
      {image ? <meta name="twitter:image" content={image} /> : null}
      <meta name="twitter:site" content="@..." />
      {canonical ? <link rel="alternate" href={`${basePath}${props.data.canonical}`} hreflang={lang} /> : null}
    </Helmet>
  )
}

export default Meta
