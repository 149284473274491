import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './EventsCard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment';

import Modal from '../common/modal/Modal'
import SocialShareButton from '../common/social-share-button/SocialShareButton'
import { AuthContext } from '../../AuthContext';

const EventsCardNew = (props) => {
  const history = useHistory();

  const { eventsTab, setModalPayload, setModalStatus } = useContext(AuthContext);

  const [buttonLikeStatus, setButtonLikeStatus] = useState(false);

  const eventFullStartTime = props.event.start_time;
  const eventFullEndTime = props.event.end_time;
  const [eventDate, setEventDate] = useState('');
  const [eventTimeStart, setEventTimeStart] = useState('');
  const [eventTimeEnd, setEventTimeEnd] = useState('');

  useEffect(() => {
    setEventDate(getUTCDate(props.event.start_time))
    setEventTimeStart(getUTCTime(props.event.start_time))
    setEventTimeEnd(getUTCTime(props.event.end_time))
  }, [props.event])

  const toggleLikeDislike = () => {
    setButtonLikeStatus(!buttonLikeStatus);
  }

  // Function to get the date in a readable format 
  const getUTCDate = (timestamp) => {
    return moment(timestamp).format('ddd D-MMM-YY');
  }
  // Function to get the time in a readable format 
  const getUTCTime = (timestamp) => {
    return moment(timestamp).format('kk:mm');
  }

  const showModalEventHandler = (e) => {
    e.preventDefault(); // we don't want the hyperlink to work here

    setModalStatus(true);
    setModalPayload({
      ...props, isEvent: true, eventDate, eventTimeStart, eventTimeEnd, buttonLikeStatus,
      toggleLikeDislike, eventFullStartTime, eventFullEndTime, canCancel: true, onCancel: hideModalEventHandler
    });
  }

  const hideModalEventHandler = () => {
    setModalStatus(false);
    history.push({
      pathname: `/${eventsTab.canonicalTitle}`
    })
  }

  return (
    <div className="event-card-wrap" >
      <Link to={`/${eventsTab.canonicalTitle}/view/${props.event.event_id}`} onClick={showModalEventHandler}>
        <div className="event-image"
          style={props.event.image_url_banner ? {
            "backgroundImage": `url(${props.event.image_url_banner})`
          } : { "backgroundImage": "url(/image-placeholder.jpg)" }}>
          <p className="event-cat">{props.event.category_name}</p>
        </div>
        <div className="event-titles">
          <h1>
            {props.event.name.split(" ").length >= 10 ?
              props.event.name.slice(0, 60) + ' ...' : props.event.name
            }
          </h1>
        </div>
        <div className="event-card-date">
          <FontAwesomeIcon className="promo-small-icons" icon={['far', 'calendar-alt']} />
          <p>{eventDate}</p>
          <span>{eventTimeStart} - {eventTimeEnd}</span>
        </div>
        <div className="event-card-venue">
          <FontAwesomeIcon className="promo-small-icons" icon={['fas', 'map-marker-alt']} />
          <p>{props.event.location_name}</p>
        </div>
        {/* <div className="event-keywords">
          {props.event.eventHashtags.map((keyword, index) => (<p key={index}>#{keyword}</p>))}
        </div> */}
      </Link>
      <div className="event-interaction-icons">
        <div className="edge over" title="View details">
          <span onClick={showModalEventHandler}>
            <FontAwesomeIcon icon={['far', 'eye']} />
          </span>
        </div>
        <div className="edge">
          <SocialShareButton isRadial applicationData={props.applicationData} isEvent id={props.event.event_id} event={props.event} />
        </div>
        <div style={props.event.is_fully_booked ? { cursor: "unset" } : {}} className="edge over" >
          {!props.event.is_fully_booked ?
            <a href={`${props.event.url}#bookingsContainer`} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon title="Book Now" icon={['far', 'ticket']} />
            </a> : <FontAwesomeIcon title="Fully booked!" style={{ opacity: .3 }} icon={['far', 'ticket']} />
          }
        </div>
        <div className="over" title="Add to favourite">
          <span onClick={() => toggleLikeDislike()} >
            {buttonLikeStatus ?
              <FontAwesomeIcon icon={['fas', 'heart']} /> : <FontAwesomeIcon icon={['fal', 'heart']} />
            }
          </span>
        </div>
      </div>
      <div className="event-below-icons">
        {props.event.is_featured &&
          <FontAwesomeIcon icon={['fas', 'star']} title="Featured event" />
        }
        {props.event.location_name === "Online Event" &&
          <FontAwesomeIcon icon={['fas', 'satellite']} title="Online event" />
        }
        {props.event.ticket_cost !== 0 &&
          <FontAwesomeIcon icon={['far', 'pound-sign']} title="Payable event" />
        }
      </div>
    </div>
  )
};

export default EventsCardNew

