import React, { useState, useEffect, useContext, Fragment } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import moment from 'moment';

import { AuthContext } from '../../../AuthContext';

import OuterAvailableFormats from './OuterAvailableFormats';
import OuterLoanWarnings from './OuterLoanWarnings';

const MiniJacket = (props) => {
  const { setModalPayload, setModalStatus, userArchive, isAuthenticated } = useContext(AuthContext);

  const record_id = props.recordID;
  const record = props.recordData;

  const [isAvailable, setIsAvailable] = useState(false);
  const [recordPubYear, setRecordPubYear] = useState('');
  const [loanDue, setLoanDue] = useState([]);
  const [isPastLoan, setIsPastLoan] = useState(false);
  const [lastCheckoutDate, setLastCheckoutDate] = useState('');

  useEffect(() => {

    if (props.isBook) {
      getRecordPubYear();
      // checkPastLoans();
    }
    if (props.isBook || props.isArchive) {
      getRecordAvailability();
    }

    if (props.isLoan && props.loanDetails) {
      var diffDays = getDifference(props.loanDetails.dueObject);
      var object = {}
      if (diffDays > 3) {
        object = { alert: 'normal', message: diffDays + ' days' }
        setLoanDue(object);
      } else if (diffDays > 0 && diffDays <= 3) {
        object = { alert: 'attention', message: 'Only ' + diffDays + ' days left!' }
        setLoanDue(object);
      } else if (diffDays === 0) {
        object = { alert: 'attention', message: 'Today!' }
        setLoanDue(object);
      } else {
        object = { alert: 'warning', message: 'Overdue' }
        setLoanDue(object);
      }
    }
  }, [props.isLoan, props.loanDetails, props.isBook, props.isArchive])

  useEffect(() => {
    if (props.isSearchResult && userArchive.length > 0) {
      checkPastLoans();
    }
  }, [props.isSearchResult, userArchive])

  useEffect(() => {
    if (!isAuthenticated) {
      setIsPastLoan(false)
      setLastCheckoutDate('')
    }
  }, [isAuthenticated])

  const getDifference = (date) => {
    let today = new Date();
    var dueDate = new Date(date);
    today.setHours(0, 0, 0, 0);
    dueDate.setHours(0, 0, 0, 0);
    // console.log(today.getTime(), dueDate.getTime())
    if (today.getTime() === dueDate.getTime()) {
      return 0;
    } else {
      var Difference_In_Time = dueDate.getTime() - today.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      return Difference_In_Days.toFixed(0);
    }
  }

  const getRecordAvailability = () => {
    if (!record.copies) {
      return
    }
    if (record.copies && record.copies.length > 0) {
      const filteredAvailable = record.copies.filter(x => x.available === true);
      if (filteredAvailable.length > 0) {
        setIsAvailable(true);
      }
    }
  }

  const getRecordPubYear = () => {
    if (!record.mainMetadata) {
      return
    }
    const year = record.mainMetadata.filter(d => d.key === 'PUBDATE' || d.key === 'PERIOD_DATE');
    if (year.length > 0) {
      setRecordPubYear(year[0].value);
    }
  }

  const showModalBookHandler = (event) => {
    event.preventDefault(); // we don't want the hyperlink to work here

    setModalStatus(true);
    setModalPayload({ ...props, recordPubYear, isAvailable, loanDue, canCancel: true, onCancel: hideModalBookHandler });
  }

  const hideModalBookHandler = () => {
    setModalStatus(false);
  }
  const checkPastLoans = () => {
    let recordIDPortion = record_id;
    recordIDPortion = recordIDPortion.substring(recordIDPortion.indexOf(":") + 1); // to remove the part before the colon (:)

    let found = userArchive.filter(archiveItem => (archiveItem.recordID === recordIDPortion))

    if (found && found.length > 0) {
      let obj = found[0]
      setIsPastLoan(true)
      if (obj.dueDisplay) {
        setLastCheckoutDate(fixUTCDate(obj.dueDisplay))
      }
      if (props.isSearchResult) {
        props.setPastLoansCheckbox(true)
      }
    }
  }

  const fixUTCDate = (timestamp) => {
    return moment(timestamp).format('DD-MM-YYYY');
  }

  return (
    <Fragment>
      <div className={(props.hidePastLoans && isAuthenticated && isPastLoan) ? 'to-hide' : (props.availability ? (isAvailable ? 'mini-card-container' : 'to-hide') : 'mini-card-container')} title={record.title} key={record_id}>
        <Link tabIndex={props.visible ? "" : "-1"} to={`/manifestations/${record.recordID}`} onClick={(e) => { showModalBookHandler(e) }} className="mini-outer-container">
          {(isAuthenticated && isPastLoan) &&
            <div className="past-loan-wrp">
              <p>You last checked this item out on: {lastCheckoutDate}</p>
            </div>
          }
          {/* <div className="mini-book-shading"></div> */}
          {record.imageUrl !== '' ?
            <img className={props.loadingJackets ? "mini-cover grayscale" : "mini-cover"} src={record.imageUrl} alt={`${record.title} book cover`} aria-describedby={`${record.recordID}_description`} /> :
            <img className={props.loadingJackets ? "mini-cover grayscale" : "mini-cover"} src='/image-placeholder-bookcover.jpg' alt={`${record.title} book cover`} aria-describedby={`${record.recordID}_description`} />
          }

          {/* rating stars when the bookjacket is still closed */}
          {/* {(!props.isLoan && record.volumeInfo.averageRating) &&
            <div className="stars-mini-container-wrap">
              <div className={`stars-mini-container stars-${(record.volumeInfo.averageRating) * 2}`}>☆☆☆☆☆</div>
              <span> {record.volumeInfo.ratingsCount}</span>
              <img className="mini-stars-background" src="/strip-rating.png" alt="strip-rating" />
            </div>
          } */}

          {/* accessibility description aid */}
          <div className="hidden-info" id={`${record.recordID}_description`}>
            {record.title && <Fragment> <h3>Title:</h3><p> {record.title} </p></Fragment>}
            {record.author && <Fragment> <h3>Author:</h3> <p>{record.author} </p></Fragment>}
            {record.format && <Fragment> <h3>Format:</h3> <p>{record.format} </p></Fragment>}
            {recordPubYear && <Fragment> <h3>Publication Year:</h3> <p>{recordPubYear} </p></Fragment>}
          </div>

          {/* the publication year & format icons underneath the bookjacket for search results */}
          {(!props.isLoan && !props.isHold) &&
            <div className="mini-icons-beneath">
              {(!props.isArchive && !props.isHold) &&
                <div className={props.loadingJackets ? "pub-year grayscale" : "pub-year"} style={recordPubYear ? { padding: "2% 18% 1% 3%" } : { padding: "0px 30px", height: "5px", boxShadow: "inset 0px 2px 2px 0px rgb(0 0 0 / 75%)" }}>
                  {recordPubYear && <p>{recordPubYear}</p>}
                </div>
              }

              <OuterAvailableFormats mini isAvailable={isAvailable} format={record.format} />

            </div>
          }

          {/* the warnings below the bookjacket for loans and reservations */}
          {((props.isLoan && props.loanDetails) || (props.isHold && props.holdDetails)) &&
            <OuterLoanWarnings mini {...props} loanDue={loanDue} />
          }
        </Link>

      </div>
    </Fragment>
  )
};

export default MiniJacket

