import React, { useState, useEffect, useContext, createContext } from 'react';
import axios from 'axios';
import SecureLS from 'secure-ls';
import { AppContext } from './AppContext'
import Modal from './components/common/modal/Modal'
import imageToBase64 from 'image-to-base64/browser';
import luciData from './luciConfig.json';

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  const { appID, apiURL, applicationData, channelData, adCaruselData, searchConfiguration, theApiTheme, socialLinks, loading, setLoading,
    libraryRecommendations, globalRecommendations, jacketsWidth, jacketsHeight, appFormats, pageHeight, pageWidth, toast,
    width0, isTabletOrMobile, isPortrait, isRetina, tabsToShow, isTop, libraryOGimage, originalEventsCategories, originalEventsLocations,
    loadingLibraryRecommendations, loadingGlobalRecommendations, jacketsCountPerCarousel, patronFields } = useContext(AppContext);

  const luciContactingPreferences = luciData.userOptions.contacting_preferences;
  const luciUserInterests = luciData.userOptions.user_interests;
  const luciUserFormats = luciData.userOptions.formats;
  const luciUserGenres = luciData.userOptions.genres;
  const ls = new SecureLS({ encodingType: 'aes', isCompression: false }); // encrypted local storage without compression

  const [user, setUser] = useState('');
  const [userPreferences, setUserPreferences] = useState('');

  const [userSavedAvatar, setUserSavedAvatar] = useState('');
  const [userSavedTheme, setUserSavedTheme] = useState('');
  const [theUserChoiceTheme, setTheUserChoiceTheme] = useState('');
  const [userSavedGenres, setUserSavedGenres] = useState('');
  const [userSavedFormats, setUserSavedFormats] = useState('');

  const [userSavedContactOptions, setUserSavedContactOptions] = useState('');
  const [userPreferredLocation, setUserPreferredLocation] = useState('');

  const [isAuthenticated, setIsAuthenticated] = useState(false);// 10
  const [token, setToken] = useState('');
  const [sessionExpiryTime, setSessionExpiryTime] = useState('');

  const [userLoans, setUserLoans] = useState([]);
  const [lazyLoansLoadingCounter, setLazyLoansLoadingCounter] = useState(0);
  const [currentLoans, setCurrentLoans] = useState([]);
  const [userHolds, setUserHolds] = useState([]);
  const [lazyHoldsLoadingCounter, setLazyHoldsLoadingCounter] = useState(0);
  const [currentHolds, setCurrentHolds] = useState([]);
  const [userArchive, setUserArchive] = useState([]);
  const [lazyArchiveLoadingCounter, setLazyArchiveLoadingCounter] = useState(0);// 20
  const [currentArchive, setCurrentArchive] = useState([]);
  const [userRecommendations, setUserRecommendations] = useState([]);

  const [contactUsResponseMessage, setContactUsResponseMessage] = useState('');

  const [modalPayload, setModalPayload] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalLogoutStatus, setModalLogoutStatus] = useState(false);
  const [modalPasswordStatus, setModalPasswordStatus] = useState(false);
  const [modalLoginStatus, setModalLoginStatus] = useState(false);
  const [modalContactUsStatus, setModalContactUsStatus] = useState(false);
  const [modalRenewAllStatus, setModalRenewAllStatus] = useState(false);// 30

  const [waiting, setWaiting] = useState(false);
  const [logoutWaiting, setLogoutWaiting] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState('');
  const [signUpMessage, setSignUpMessage] = useState('');
  const [errorsArray, setErrorsArray] = useState([]);
  const [logInMessage, setLogInMessage] = useState('');
  const [loadingPreference, setLoadingPreference] = useState(false);
  const [loadingLoans, setLoadingLoans] = useState(false);
  const [loadingHolds, setLoadingHolds] = useState(false);
  const [loadingArchive, setLoadingArchive] = useState(false);// 40
  const [loadingUserRecommendations, setLoadingUserRecommendations] = useState(false);

  const [topTabs, setTopTabs] = useState([]);
  const [parentTabs, setParentTabs] = useState([]);
  const [digitalContentTab, setDigitalContentTab] = useState('');
  const [bookshelfTab, setBookshelfTab] = useState('');
  const [eventsTab, setEventsTab] = useState('');
  const [wildCard, setWildCard] = useState('');
  const [userAccountTab, setUserAccountTab] = useState('');
  const [searchTab, setSearchTab] = useState('');

  const [userDataTrigger, setUserDataTrigger] = useState(false);// 50
  const [loansTrigger, setLoansTrigger] = useState(false);
  const [holdsTrigger, setHoldsTrigger] = useState(false);
  const [archiveTrigger, setArchiveTrigger] = useState(false);

  useEffect(() => {
    if (userDataTrigger) {
      fetchUserLoans()
      fetchUserHolds()
      fetchUserArchive()
      fetchUserRecommendations(user.userID)
    }
    return () => {
      setUserDataTrigger(false)
    }
  }, [userDataTrigger])

  useEffect(() => {
    if (loansTrigger) {
      fetchUserLoans()
    }
    return () => {
      setLoansTrigger(false)
    }
  }, [loansTrigger])

  useEffect(() => {
    if (holdsTrigger) {
      fetchUserHolds()
    }
    return () => {
      setHoldsTrigger(false)
    }
  }, [holdsTrigger])

  useEffect(() => {
    if (archiveTrigger) {
      fetchUserArchive()
    }
    return () => {
      setArchiveTrigger(false)
    }
  }, [archiveTrigger])

  const clearMessages = () => {
    setPasswordMessage('');
    setSignUpMessage('');
    setErrorsArray([]);
    setLogInMessage('');
    setContactUsResponseMessage('');
  }

  useEffect(() => {
    if (appID && appID !== '') {
      assignSections() // the top right hand navbar icons validation process
      checkCurrentUserStatus(); // when the user refreshs the page only if there is an appID in place
    }
  }, [appID])

  const getMoreLoans = (i, increment, data) => {
    let array = data || userLoans;
    if (array[i]) {
      getLoanDetails(array[i], array[i].recordID, 'loan')
      // console.log(`getting loan ${i}: ` + array[i].recordID)
    }
    if (i < array.length && i < lazyLoansLoadingCounter + increment) {
      setTimeout(() => {
        i++;
        getMoreLoans(i, increment, array);
      }, 100);
    } else {
      // console.log('Finished loading more Loans')
      setLazyLoansLoadingCounter(i + 1)
    }
  }

  const getMoreHolds = (i, increment, data) => {
    let array = data || userHolds;
    if (array[i]) {
      getLoanDetails(array[i], array[i].recordID, 'hold')
      // console.log(`getting hold ${i}: ` + array[i].recordID)
    }
    if (i < array.length && i < lazyHoldsLoadingCounter + increment) {
      setTimeout(() => {
        i++;
        getMoreHolds(i, increment, array);
      }, 100);
    } else {
      // console.log('Finished loading more Holds')
      setLazyHoldsLoadingCounter(i + 1)
    }
  }

  const getMoreArchives = (i, increment, data) => {
    let array = data || userArchive;
    if (array[i]) {
      getLoanDetails(array[i], array[i].recordID, 'archive')
      // console.log(`getting archive ${i}: ` + array[i].recordID)
    }
    if (i < array.length && i < lazyArchiveLoadingCounter + increment) {
      // console.log("i= " + i, "array.length= " + array.length, "lazyArchiveLoadingCounter= " + lazyArchiveLoadingCounter, "increment= " + increment)
      setTimeout(() => {
        i++;
        getMoreArchives(i, increment, array);
      }, 100);
    } else {
      // console.log('Finished loading more archives, lazyArchiveLoadingCounter= ' + parseInt(i + 1))
      setLazyArchiveLoadingCounter(i + 1)
    }
  }

  useEffect(() => {
    if (token !== '' && appID !== '' && !isAuthenticated && user && user.userID !== undefined) {
      fetchUserPreferences(setTheUser)
    }
  }, [token, appID, isAuthenticated, user])

  useEffect(() => {
    if (user && user.userID !== undefined && Object.keys(userPreferences).length > 0) {
      const userFromStorage = ls.get('CU');
      if (userFromStorage && JSON.stringify(userFromStorage) !== JSON.stringify(user)) {
        saveUserLocally();
      }
    }
  }, [user, userPreferences])

  // the callback function to set the user after extract their preferences
  function setTheUser(obj) {
    setUserPreferences(obj)
  }

  const checkCurrentUserStatus = () => {
    // console.log("User-status checked")
    try {
      const userFromStorage = ls.get('CU');
      const tokenFromStorage = localStorage.getItem('T');
      const expiryFromStorage = ls.get('TE');

      const userLoansFromStorage = ls.get('UL');
      const userHoldsFromStorage = ls.get('UH');
      const userArchiveFromStorage = ls.get('UA');
      const userRecommendationsFromStorage = ls.get('UR');


      if (tokenFromStorage && userFromStorage && expiryFromStorage) { // if there is a user signed in then carry on, otherwise wipe the local storage off
        // console.table(userFromStorage.homeLocation)
        setUser(userFromStorage)
        setToken(tokenFromStorage)
        setIsAuthenticated(true);

        setUserPreferredLocation(userFromStorage.homeLocation || '');
        setSessionExpiryTime(expiryFromStorage)

        if (userLoansFromStorage) {
          setUserLoans(userLoansFromStorage)

          if (JSON.stringify(userLoansFromStorage) !== JSON.stringify(userLoans)) { // only if there is a difference to prevent fetching each time we check the user status
            if (userLoansFromStorage.length > 11) {
              getMoreLoans(0, 12, userLoansFromStorage);
            } else {
              getMoreLoans(0, userLoansFromStorage.length, userLoansFromStorage);
            }
          }
        }
        if (userHoldsFromStorage) {
          setUserHolds(userHoldsFromStorage)

          if (JSON.stringify(userHoldsFromStorage) !== JSON.stringify(userHolds)) { // only if there is a difference to prevent fetching each time we check the user status
            if (userHoldsFromStorage.length > 11) {
              getMoreHolds(0, 12, userHoldsFromStorage);
            } else {
              getMoreHolds(0, userHoldsFromStorage.length, userHoldsFromStorage);
            }
          }
        }
        if (userArchiveFromStorage) {
          setUserArchive(userArchiveFromStorage)

          if (JSON.stringify(userArchiveFromStorage) !== JSON.stringify(userArchive)) { // only if there is a difference to prevent fetching each time we check the user status
            if (userArchiveFromStorage.length > 11) {
              getMoreArchives(0, 12, userArchiveFromStorage);
            } else {
              getMoreArchives(0, userArchiveFromStorage.length, userArchiveFromStorage);
            }
          }
        }
        if (userRecommendationsFromStorage) {
          setUserRecommendations(userRecommendationsFromStorage)
        }

        // current user preferences
        if (userFromStorage.preferences) {
          setUserSavedTheme(userFromStorage.preferences.theme);
          setTheUserChoiceTheme(userFromStorage.preferences.theme);
          setUserSavedAvatar(userFromStorage.preferences.userAvatar);
          setUserSavedGenres(userFromStorage.preferences.genres);
          setUserSavedFormats(userFromStorage.preferences.formats);
          setUserSavedContactOptions(userFromStorage.preferences.contacts);
        }

        checkExpiryTime(expiryFromStorage);
      } else {
        wipeUserData();
      }
    } catch (error) {
      console.error(error)
      wipeUserData();
    }
  }

  const assignSections = async () => {
    const tabsResponse = await axiosInstance.get(`/Tabs/${appID}/children`)

    const filteredTabs = tabsResponse.data.filter(item => validateTab(item))
    setTopTabs(filteredTabs)

    const theBookshelfTab = filteredTabs.filter(item => item.type === "Section" && item.contentText === "bookshelf")
    const theDigitalContentTab = filteredTabs.filter(item => item.type === "Section" && item.contentText === "content")
    const theEventsTab = filteredTabs.filter(item => item.type === "Section" && item.contentText === "events")
    const wildCardTab = filteredTabs.filter(item => item.type === "Section" && item.contentText === "blog")
    const userTab = filteredTabs.filter(item => item.type === "Section" && item.contentText === "account")
    const theSearchTab = filteredTabs.filter(item => item.type === "LMS_Search")

    if (theBookshelfTab.length > 0 && validateTab(theBookshelfTab[0])) {
      setBookshelfTab(theBookshelfTab[0])
    }

    if (theDigitalContentTab.length > 0 && validateTab(theDigitalContentTab[0])) {
      setDigitalContentTab(theDigitalContentTab[0])
      const theDigitalContentID = theDigitalContentTab[0].id
      let childrenResponse = await axiosInstance.get(`/Tabs/${theDigitalContentID}/children`)
      const filteredTabs = childrenResponse.data.filter(item => validateTab(item))
      setParentTabs(filteredTabs)
    }

    if (theEventsTab.length > 0 && validateTab(theEventsTab[0])) {
      setEventsTab(theEventsTab[0])
    }

    if (wildCardTab.length > 0 && validateTab(wildCardTab[0])) {
      setWildCard(wildCardTab[0])
    }

    if (userTab.length > 0 && validateTab(userTab[0])) {
      setUserAccountTab(userTab[0])
    }

    if (theSearchTab.length > 0 && validateTab(theSearchTab[0])) {
      setSearchTab(theSearchTab[0])
    }
  }

  const axiosInstance = axios.create({
    baseURL: apiURL,
    headers: {
      'solus-app-id': appID
    }
  });

  const axiosInstanceWithPatronToken = axios.create({
    baseURL: apiURL,
    headers: {
      'solus-app-id': appID, 'solus-user-token': token
    }
  });

  const axiosPatronInstance = axios.create({
    baseURL: `${apiURL}/patrons/${user.userID}`,
    headers: { 'solus-app-id': appID, 'solus-user-token': token }
  });

  const getTimeDiference = (date) => {
    var now = new Date();
    var end = new Date(date);
    var diffMs = (end - now); // in milliseconds
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // dif in minutes
    var time = { "hours": diffHrs, "minutes": diffMins }
    return time
  }

  const checkExpiryTime = (time) => {
    // console.log("checkExpiryTime function: " + time);
    var difference = getTimeDiference(time);
    var differenceHours = difference.hours;
    var differenceMinutes = difference.minutes;
    // console.log("checkExpiryTime function _ the difference is: " + differenceHours + "hours and " + differenceMinutes + " minutes!");
    if (differenceHours <= 0) {
      if (differenceMinutes <= 0) {
        setIsAuthenticated(false);
        wipeUserData();
        setModalLogoutStatus(true);
      }
    } else {
      // console.log("Session will expire in " + differenceHours + " hours and " + differenceMinutes + " minutes!");
      setTimeout(function () {
        setModalLogoutStatus(true)
      }.bind(this), (differenceHours * 60 * 60 * 1000) + (differenceMinutes * 60 * 1000));
    }
  }

  const openLoginDialogue = () => {
    setModalLogoutStatus(false)
    setModalLoginStatus(true)
    logOut()
  }

  const setTimer = () => {
    var now = new Date();
    // var exp = now.setMinutes(now.getMinutes() + 1);  // temporary timestamp shifted 3 minutes for testing
    var exp = now.setHours(now.getHours() + 12);  // temporary timestamp shifted 12 hours until the exp time comes from the API call
    var expiryTime = new Date(exp);

    ls.set('TE', expiryTime); //Token Expiry Time
  }

  const registerUser = async (patronInfo) => {
    setSignUpMessage(''); // to delete the old msg if there was any
    setWaiting(true);

    await axiosInstance.post(`/patrons/`, patronInfo)
      .then(response => {
        const res = response.data
        setUser(res.user);
        setToken(res.token);

        localStorage.setItem('T', res.token);
        ls.set('CU', res);

        setTimer();
        setSignUpMessage('Success!')
        toast.success('Welcome, ' + res.user.firstName);
        setWaiting(false);
        setIsAuthenticated(true);
      })
      .catch((err) => {
        // console.error(err.response.data.message);
        toast.error(err.response.data.message);
        setSignUpMessage(err.response.data.message)
        setWaiting(false);
      });
  }

  const resetPassword = async (borrowerNumber) => {
    setPasswordMessage(''); // to delete the old msg if there was any
    setWaiting(true);

    await axiosInstance.post(`/patrons/resetaccount?Login=${borrowerNumber}&Url=https://${applicationData.primaryDNS}/user/resetaccount/<RESET_PIN_TOKEN>`)
      .then(response => {
        // console.log(response)
        setPasswordMessage('Success! If there is a user associated with that login, you will receive an email with a link to reset your PIN')
        setWaiting(false);
      })
      .catch((err) => {
        // console.error(err.response.data.message);
        setPasswordMessage(err.response.data.message)
        setErrorsArray(err.response.data.detail)
        setWaiting(false);
      });
  }

  const newPassword = async (password, resetToken) => {
    setPasswordMessage(''); // to delete the old msg if there was any
    setWaiting(true);
    // console.log(password, resetToken)

    await axiosInstance.post(`/patrons/resetaccountcomplete?Token=${resetToken}&NewPassword=${password}`)
      .then(response => {
        // console.log(response)
        const res = response.data

        setUser(res.user);
        setToken(res.token);
        setUserPreferredLocation(res.user.homeLocation);

        localStorage.setItem('T', res.token);
        ls.set('CU', res);

        setTimer();
        setPasswordMessage('Success!')
        toast.success('Welcome back, ' + res.user.userName + ', your password/pin has updated successfully.');
        setWaiting(false);
        setIsAuthenticated(true);

        setTimeout(() => {
          setUserDataTrigger(true);
        }, 300);

      })
      .catch((err) => {
        // console.error(err.response.data);
        setPasswordMessage(err.response.data.message)
        setWaiting(false);
      });
  }

  const logIn = async (patronInfo) => {
    setLogInMessage(''); // to delete the old msg if there was any
    setWaiting(true);

    await axiosInstance.post(`/Patrons/login`, patronInfo)
      .then(response => {
        const res = response.data

        setUser(res.user);
        setToken(res.token);
        setUserPreferredLocation(res.user.homeLocation);

        localStorage.setItem('T', res.token);
        ls.set('CU', res);

        setTimer();
        setLogInMessage('Success!')
        toast.success('Welcome back, ' + res.user.userName);
        setWaiting(false);
        setIsAuthenticated(true);

        setTimeout(() => {
          setUserDataTrigger(true);
        }, 300);
      })
      .catch((err) => {
        console.error(err);
        setLogInMessage('There is an error with your credentials, please try again - ' + err)
        setWaiting(false);
      });
  }

  const logOut = async (patronID) => {
    setModalLogoutStatus(false); // to close the logout dialogue if it was opened
    setLogoutWaiting(true);

    try {
      await axiosInstance.delete(`/Patrons/${patronID}/logout`)
        .then(() => {
          toast.success(`User ${user.userName || ''} has been logged out successfully`);
          wipeUserData();
          setLogoutWaiting(false);
        })
    } catch (err) {
      toast.error("Couldn't log you out, please try again - " + err);
      setLogoutWaiting(false);
      console.error(err);
    }
  }

  const wipeUserData = () => {
    // reset user's auth data
    setUser('');
    setUserPreferences('');
    setToken('');
    setIsAuthenticated(false);
    setSessionExpiryTime('');

    // reset user preferences
    setUserSavedTheme('');
    setTheUserChoiceTheme('');
    setUserSavedAvatar('');
    setUserSavedGenres('');
    setUserSavedFormats('');
    setUserSavedContactOptions('');
    setUserPreferredLocation('');

    setErrorsArray([]);
    setSignUpMessage('');
    setLogInMessage('');
    setContactUsResponseMessage('');
    setModalPayload([]);

    // reset user's loans arrays from the API
    setUserLoans([]);
    setUserHolds([]);
    setUserArchive([]);

    // reset the lazy counters 
    setLazyLoansLoadingCounter(0);
    setLazyHoldsLoadingCounter(0);
    setLazyArchiveLoadingCounter(0);

    // reset the current loans arrays
    setCurrentLoans([]);
    setCurrentHolds([]);
    setCurrentArchive([]);

    // reset user's recommendations
    setUserRecommendations([]);

    localStorage.clear(); // clear the local storage
    ls.removeAll(); // clear the encrypted local storage
  }

  const fetchUserRecommendations = async (patronID) => {
    setLoadingUserRecommendations(true);

    try {
      const response = await axiosInstanceWithPatronToken.get(`/Patrons/${patronID}/recommendations`)

      setUserRecommendations(shuffleArray(response.data))
      setLoadingUserRecommendations(false);
      ls.set('UR', response.data); // save user's Recommendations locally 

    } catch (err) {
      setLoadingUserRecommendations(false);
      console.error(err);
    }
  }

  const fetchUserPreferences = async (callback) => {

    let userPreferencesObject = {} // temporary object to collect stuff locally

    // the theme
    try {
      const response = await axiosPatronInstance.get('/storage/theme')
      setUserSavedTheme(response.data)
      userPreferencesObject.theme = response.data
      if (response.status === 204) {
        console.error("Error with getting user's Theme preference, the default theme was activated");
        setUserSavedTheme('auto')
        userPreferencesObject.theme = 'auto'
      }
      // the avatar URI data
      try {
        const response = await axiosPatronInstance.get('/storage/avatardata')
        setUserSavedAvatar(response.data)
        userPreferencesObject.userAvatar = response.data
        if (response.status === 204) {
          console.error("No data found for user's Avatar preference");
          setUserSavedAvatar('');
          userPreferencesObject.userAvatar = ''
        }
        // the Contact
        try {
          const response = await axiosPatronInstance.get('/storage/contact')
          setUserSavedContactOptions(response.data)
          userPreferencesObject.contacts = response.data.toString()
          if (response.status === 204) {
            console.error("No data found for user's Contact Preferences");
            setUserSavedContactOptions('');
            userPreferencesObject.contacts = ''
          }
          // the Formats
          try {
            const response = await axiosPatronInstance.get('/storage/formats')
            setUserSavedFormats(response.data)
            userPreferencesObject.formats = response.data.toString()
            if (response.status === 204) {
              console.error("No data found for user's Formats preferences");
              setUserSavedFormats('');
              userPreferencesObject.formats = ''
            }
            // the Genres
            try {
              const response = await axiosPatronInstance.get('/storage/genres')
              setUserSavedGenres(response.data)
              userPreferencesObject.genres = response.data.toString()

              callback(userPreferencesObject); // send the object to the state

              if (response.status === 204) {
                console.error("No data found for user's Genres preferences");
                setUserSavedGenres('');
                userPreferencesObject.genres = ''

                callback(userPreferencesObject); // also here incase the last step failed 
              }
            } catch (error) {
              console.error("No data found for user's Genres preferences - " + error);
              setUserSavedGenres('');
            }
          } catch (error) {
            console.error("No data found for user's Formats preferences - " + error);
            setUserSavedFormats('');
          }
        } catch (error) {
          console.error("No data found for user's Contact Preferences - " + error);
          setUserSavedContactOptions('');
        }
      } catch (error) {
        console.error("No data found for user's Avatar preference - " + error);
        setUserSavedAvatar('');
      }
    } catch (error) {
      console.error("Error with getting user's Theme preference, the default theme was activated - " + error);
      setUserSavedTheme('auto')
    }
  }

  const refreshUserLoans = () => {
    setLazyLoansLoadingCounter(0)
    setCurrentLoans([])
    setUserLoans([])

    setLoansTrigger(true)
  }
  const refreshUserHolds = () => {
    setLazyHoldsLoadingCounter(0)
    setCurrentHolds([])
    setUserHolds([])

    setHoldsTrigger(true)
  }
  const refreshUserArchive = () => {
    setLazyArchiveLoadingCounter(0)
    setCurrentArchive([])
    setUserArchive([])

    setArchiveTrigger(true)
  }

  const fetchUserLoans = async () => {
    setLoadingLoans(true)
    try {
      await axiosPatronInstance.get('/loans').then(response => {
        const data = response.data
        setUserLoans(data)
        ls.set('UL', data); // save user's loans locally 

        if (data.length > 11) {
          getMoreLoans(0, 12, data);
        } else {
          getMoreLoans(0, data.length, data);
        }
        setLoadingLoans(false)
      })
    } catch (err) {
      console.error("Error with getting user's loans - " + err);
      setLoadingLoans(false)
    }
  }

  const fetchUserHolds = async () => {
    setLoadingHolds(true)
    try {
      await axiosPatronInstance.get('/reservations').then(response => {
        const data = response.data
        setUserHolds(data);
        ls.set('UH', data); // save user's reservations locally 

        if (data.length > 11) {
          getMoreHolds(0, 12, data);
        } else {
          getMoreHolds(0, data.length, data);
        }
        setLoadingHolds(false)
      })
    } catch (err) {
      console.error("Error with getting user's reservations - " + err);
      setLoadingHolds(false)
    }
  }

  const fetchUserArchive = async () => {
    setLoadingArchive(true)
    try {
      await axiosPatronInstance.get('/loans?status=08').then(response => {
        const data = response.data
        setUserArchive(data);
        ls.set('UA', data); // save user's archive locally 

        if (data.length <= 11) {
          getMoreArchives(0, data.length, data);
        } else {
          getMoreArchives(0, 12, data);
        }
        setLoadingArchive(false)
      })
    } catch (err) {
      console.error("Error with getting user's loan-history - " + err);
      setLoadingArchive(false)
    }
  }

  const deleteASingleHold = (_holdID) => {
    let found = false;
    for (let index = 0; index < currentHolds.length; index++) {
      const element = currentHolds[index];
      if (element.holdDetails.holdID === _holdID) {
        // console.log("found in: " + index)
        found = true;
        currentHolds.splice(index, 1);
        // return
      }
    }
    if (!found) {
      console.error("Unable to find that reservation to remove it from dom")
    }
    // to update the counter 
    for (let index = 0; index < userHolds.length; index++) {
      const element = userHolds[index];
      if (element.holdID === _holdID) {
        userHolds.splice(index, 1);
        ls.set('UH', userHolds); // update the user's reservations in local storage
        // console.log(userHolds)
        return
      }
    }
  }

  const updateASingleHold = async (_holdID) => {
    try {
      await axiosPatronInstance.get(`/reservations/${_holdID}`).then(response => {
        // console.log('Pass userHolds', data)
        const data = response.data
        const holdToUpdate = currentHolds.filter(item => item.recordID === data.recordID)
        if (holdToUpdate && holdToUpdate.length > 0) {
          holdToUpdate[0].holdDetails = data
        } else {
          console.error("Updated reservation could not be found in dom")
        }
      })
    } catch (err) {
      console.error(`Error with getting user's reservation - ${_holdID} :` + err);
    }
  }

  const updateASingleLoan = async (_loanID) => {
    // console.log('Pass single loan', _loanID)
    try {
      await axiosPatronInstance.get(`/loans/${_loanID}`).then(response => {
        const data = response.data
        // console.log('Pass user loans', data)
        const loanToUpdate = currentLoans.filter(item => item.recordID === data.recordID)
        if (loanToUpdate && loanToUpdate.length > 0) {
          loanToUpdate[0].loanDetails = data
        } else {
          console.error("Updated loan could not be found in dom")
        }
      })
    } catch (err) {
      console.error(`Error with getting user's loan info - ${_loanID} :` + err);
    }
  }

  const saveUserLocally = () => {
    const updatedUser = { ...user }
    updatedUser.preferences = userPreferences

    ls.set('CU', updatedUser); // save user's preferences locally 
    // localStorage.setItem('currentUser', JSON.stringify(updatedUser))
  }

  const saveUserAvatar = (imagePath) => {
    imageToBase64(imagePath)
      .then(res => {
        axiosPatronInstance.post('/storage', { "key": "avatardata", "value": res })
          .then((response) => {
            if (response.status === 200) {
              // update the object in local storage for current user
              const userFromStorage = ls.get('CU');
              userFromStorage.preferences.userAvatar = res;
              ls.set('CU', userFromStorage);

              // update the user selection
              setUserSavedAvatar(res)

              //inform the user
              toast.success('Avatar choice saved successfully!');
            } else {
              console.error(response.message);
            }
          }).catch((err) => {
            console.error(err);
          });
      })
      .catch(
        (error) => {
          console.log(error);
        }
      )
  }

  const deleteUserAvatar = () => {
    axiosPatronInstance.post('/storage', { "key": "avatardata", "value": "" })
      .then((response) => {
        if (response.status === 200) {
          // update the object in local storage for current user
          const userFromStorage = ls.get('CU');
          userFromStorage.preferences.userAvatar = '';
          ls.set('CU', userFromStorage);

          // update the user selection
          setUserSavedAvatar('');

          // inform the user
          toast.success('User avatar deleted successfully!');
        } else {
          console.error(response.message);
        }
      }).catch((err) => {
        console.error(err);
      });
  }

  const saveUserPreferenceTheme = (aTheme) => {
    setLoadingPreference(true)
    axiosPatronInstance.post('/storage', { 'key': 'theme', 'value': aTheme })
      .then((response) => {
        if (response.status === 200) {
          toast.success('Theme preference has been saved successfully!');
          setTheme(aTheme); // after we saved that successfully, we change it locally
        } else {
          console.error(response.message);
        }
        setLoadingPreference(false)
      }).catch((err) => {
        console.error(err);
        setLoadingPreference(false)
      });
  }

  const saveUserPreferenceGenres = (genresString) => {
    setLoadingPreference(true)
    axiosPatronInstance.post('/storage', { 'key': 'genres', 'value': genresString })
      .then((response) => {
        if (response.status === 200) {
          // update the object in local storage for current user
          const userFromStorage = ls.get('CU');
          userFromStorage.preferences.genres = genresString.toString();
          ls.set('CU', userFromStorage);
          // update the user selection
          setUserSavedGenres(genresString);

          toast.success('Genres has been saved successfully!')
        } else {
          console.error(response.message);
        }
        setLoadingPreference(false)
      }).catch((err) => {
        console.error(err);
        setLoadingPreference(false)
      });
  }

  const saveUserPreferenceFormats = (formatsString) => {
    setLoadingPreference(true)
    axiosPatronInstance.post('/storage', { 'key': 'formats', 'value': formatsString })
      .then((response) => {
        if (response.status === 200) {
          // update the object in local storage for current user
          const userFromStorage = ls.get('CU');
          userFromStorage.preferences.formats = formatsString.toString();
          ls.set('CU', userFromStorage);
          // update the user's saved selection rather than request that from the db again
          setUserSavedFormats(formatsString);

          toast.success('Formats has been saved successfully!');
        } else {
          console.error(response.message);
        }
        setLoadingPreference(false)
      }).catch((err) => {
        console.error(err);
        setLoadingPreference(false)
      });
  }

  const saveUserPreferenceContact = (contactString) => {
    setLoadingPreference(true)
    axiosPatronInstance.post('/storage', { 'key': 'contact', 'value': contactString })
      .then((response) => {
        if (response.status === 200) {
          // update the object in local storage for current user
          const userFromStorage = ls.get('CU');
          userFromStorage.preferences.contacts = contactString.toString();
          ls.set('CU', userFromStorage);
          // update the user's saved selection rather than request that from the db again
          setUserSavedContactOptions(contactString);
          toast.success('Contact preferences has been saved successfully!');
        } else {
          console.error(response.message);
        }
        setLoadingPreference(false)
      }).catch((err) => {
        console.error(err);
        setLoadingPreference(false)
      });
  }

  const sendContactForm = (data) => {
    setContactUsResponseMessage(''); // to delete the old msg if there was any
    setWaiting(true);

    axiosInstance.post(`/Applications/${appID}/formresponse`, data)
      .then((response) => {
        if (response.status === 200) {
          setContactUsResponseMessage('Thank you! Your message has been sent successfully, someone will be in touch with you shortly.')
          setWaiting(false)
        } else {
          setContactUsResponseMessage('There was an error with sending your message please try again later! - ' + response.message)
          setWaiting(false)
        }
      }).catch((err) => {
        console.error(err);
      });
  }

  const convertToArray = (string) => {
    const array = string.split("-");
    var filtered = array.filter(function (el) {
      return el !== '';
    });
    return filtered;
  }
  const convertToString = (arrayA) => {
    if (arrayA.length > 1) {
      var arrayB = arrayA.reduce(function (pre, next) {
        return pre + '-' + next;
      });
      return arrayB
    } else {
      return arrayA[0]
    }
  }
  const setTheme = (themeName) => {
    setTheUserChoiceTheme(themeName);
    setUserSavedTheme(themeName);

    // update the object in local storage for current user
    const userFromStorage = ls.get('CU');
    userFromStorage.preferences.theme = themeName;
    ls.set('CU', userFromStorage);
  }

  // this is mainly for the bookjacket URL
  const getLoanDetails = async (record, record_id, type) => {
    if (type === 'loan') {
      setLoadingLoans(true)
      try {
        await axiosInstance.get(`/Manifestations/${record_id}?source=${record.source}`)
          .then(response => {
            let obj = response.data;
            obj.loanDetails = record;
            setCurrentLoans(currentLoans => currentLoans.concat(obj))
            setLoadingLoans(false)
          })
      } catch (err) {
        console.error('No record found for id: ' + record_id + 'Error: ' + err);
        setLoadingLoans(false)
      }
    } else if (type === 'hold') {
      setLoadingHolds(true)
      try {
        await axiosInstance.get(`/Manifestations/${record_id}?source=${record.source}`)
          .then(response => {
            let obj = response.data;
            obj.holdDetails = record;
            setCurrentHolds(currentHolds => currentHolds.concat(obj))
            setLoadingHolds(false)
          })
      } catch (err) {
        console.error('No record found for id: ' + record_id + 'Error: ' + err);
        setLoadingHolds(false)
      }
    } else if (type === 'archive') {
      setLoadingArchive(true)
      try {
        await axiosInstance.get(`/Manifestations/${record_id}?source=${record.source}`)
          .then(response => {
            let obj = response.data;
            obj.archiveDetails = record;
            setCurrentArchive(currentArchive => currentArchive.concat(obj))
            setLoadingArchive(false)
          })
      } catch (err) {
        console.error('No record found for id: ' + record_id + 'Error: ' + err);
        setLoadingArchive(false)
      }
    }
  }

  const validateTab = (tab) => {
    if (tab.authVisibility === "allauth" || tab.authVisibility === null) {
      return true
    } else if (tab.authVisibility === "preauth") {
      if (!isAuthenticated) {
        return true
      } else {
        return false
      }
    } else if (tab.authVisibility === "postauth") {
      if (isAuthenticated) { // no mater userType is 'patron' or 'staff'
        return true
      } else {
        return false
      }
    } else if (tab.authVisibility === "poststaffauth") {
      if (isAuthenticated && user.userType === "staff") {
        return true
      } else {
        return false
      }
    } else if (tab.authVisibility === "neverauth") {
      return false
    }
    return false //just in case
  }

  const shuffleArray = (array) => {
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  return (
    <AuthContext.Provider
      value={{
        appID, apiURL, channelData, adCaruselData, searchConfiguration, loading, setLoading, topTabs, wildCard, eventsTab, digitalContentTab, bookshelfTab, userAccountTab, searchTab,
        user, token, isAuthenticated, logIn, logOut, sendContactForm, checkCurrentUserStatus, setTheme, logInMessage, contactUsResponseMessage, setContactUsResponseMessage,
        waiting, logoutWaiting, applicationData, theApiTheme, parentTabs, socialLinks, clearMessages, pageHeight, pageWidth, setCurrentHolds,
        axiosInstance, axiosInstanceWithPatronToken, axiosPatronInstance, jacketsWidth, jacketsHeight,

        modalLoginStatus, setModalLoginStatus, modalContactUsStatus, setModalContactUsStatus, modalLogoutStatus, modalPayload, modalStatus, setModalPayload, setModalStatus,
        setModalRenewAllStatus, modalPasswordStatus, setModalPasswordStatus, passwordMessage, newPassword,

        refreshUserLoans, refreshUserHolds, refreshUserArchive, updateASingleLoan, deleteASingleHold, updateASingleHold, updateASingleHold,
        currentLoans, currentHolds, currentArchive, loadingPreference, loadingLoans, loadingHolds, loadingArchive,

        width0, isTabletOrMobile, isPortrait, isRetina, tabsToShow, isTop, libraryOGimage, originalEventsCategories, originalEventsLocations,

        userSavedTheme, setUserSavedTheme, setUserSavedAvatar, userSavedAvatar, convertToString, convertToArray,
        luciContactingPreferences, luciUserInterests, luciUserFormats, luciUserGenres,
        saveUserPreferenceFormats, userSavedFormats, saveUserAvatar, deleteUserAvatar, saveUserPreferenceTheme, userSavedGenres, theUserChoiceTheme, userPreferredLocation,
        userSavedContactOptions, saveUserPreferenceContact, saveUserPreferenceGenres,

        userLoans, userHolds, userArchive, userRecommendations, libraryRecommendations, globalRecommendations, appFormats, jacketsCountPerCarousel, toast,
        loadingUserRecommendations, loadingLibraryRecommendations, loadingGlobalRecommendations, registerUser, signUpMessage, errorsArray,

        getMoreLoans, getMoreHolds, getMoreArchives, lazyLoansLoadingCounter, lazyHoldsLoadingCounter, lazyArchiveLoadingCounter, patronFields
      }}>

      {props.children}

    </AuthContext.Provider >
  )
}