import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const OuterLoanWarnings = (props) => {

  return (
    <Fragment>
      {props.mini ?
        <Fragment>
          {/* the warnings below the loan bookjackets  */}
          {props.isLoan &&
            <div className="mini-icons-beneath">
              <div className="mini-outer-formats-container">
                {props.loanDue.alert === 'attention' &&
                  <div className="mini-formats-outside" title="Due soon!">
                    <FontAwesomeIcon className="mini-outer-icons warning-orange" icon={['far', 'exclamation-triangle']} />
                  </div>
                }
                {(props.loanDue.alert === 'warning' || props.loanDetails.overdue) &&
                  <div className="mini-formats-outside" title="Overdue">
                    <FontAwesomeIcon className="mini-outer-icons warning-red" icon={['far', 'exclamation-triangle']} />
                  </div>
                }
              </div>
            </div>
          }
          {/* the warnings below the hold bookjackets  */}
          {props.isHold &&
            <div className="mini-icons-beneath">
              <div className="mini-outer-formats-container">
                {(props.holdDetails && props.holdDetails.canBeCollected) &&
                  <div className="mini-formats-outside" title="Ready, can be collected..">
                    <FontAwesomeIcon className="mini-outer-icons warning-red" icon={['fas', 'shopping-cart']} />
                  </div>
                }
              </div>
            </div>
          }
        </Fragment>
        :
        <div className="icons-beneath">
          <div className="outer-formats-container" style={{ width: "100%" }}>
            {(props.isLoan && props.loanDue.alert === 'attention') &&
              <div className="formats-outside" title="Due soon!">
                <FontAwesomeIcon className="outer-icons warning-orange" icon={['far', 'exclamation-triangle']} />
              </div>
            }
            {((props.isLoan && props.loanDue.alert === 'warning') || (props.isLoan && props.loanDetails.overdue)) &&
              <div className="formats-outside" title="Overdue">
                <FontAwesomeIcon className="outer-icons warning-red" icon={['far', 'exclamation-triangle']} />
              </div>
            }
            {(props.isHold && props.holdDetails.canBeCollected) &&
              <div className="formats-outside" title="Ready, can be collected..">
                <FontAwesomeIcon className="outer-icons warning-red" icon={['fas', 'shopping-cart']} />
              </div>
            }
            {(props.isHold && props.holdDetails.isSuspended) &&
              <div className="formats-outside" title="This item is suspended">
                <FontAwesomeIcon className="outer-icons warning-red" icon={['fas', 'pause']} />
              </div>
            }
          </div>
        </div>
      }
    </Fragment>
  )
};

export default OuterLoanWarnings;