import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../../../AuthContext';
import FocusTrap from 'focus-trap-react';
import { css } from '@emotion/core';
import { ScaleLoader } from 'react-spinners';

const ContactPreferences = () => {
  const { applicationData, convertToString, convertToArray, userSavedContactOptions, saveUserPreferenceContact, luciContactingPreferences, luciUserInterests, loadingPreference } = useContext(AuthContext);

  const [resetTrigger, setResetTrigger] = useState(false);
  const [activeContactsTrap, setActiveContactsTrap] = useState(false);
  const [contactingPreferences, setContactingPreferences] = useState([]);
  const [userInterests, setUserInterests] = useState([]);

  useEffect(() => {
    if (resetTrigger) {
      initiateArrays(userSavedContactOptions);
    }
    return () => {
      setResetTrigger(false);
    }
  }, [resetTrigger])

  useEffect(() => {
    initiateArrays(userSavedContactOptions);
  }, [userSavedContactOptions])

  const initiateArrays = (str) => {
    const originalArray = convertToArray(str);

    if (originalArray.length > 0) {
      let newPreferencesArray = []
      let newInterestsArray = []

      luciContactingPreferences.forEach(item => {
        if (originalArray.includes(item.code)) {
          item.checked = true;
        } else {
          item.checked = false;
        }
        newPreferencesArray.push(item)
      });

      luciUserInterests.forEach(item => {
        if (originalArray.includes(item.code)) {
          item.checked = true;
        } else {
          item.checked = false;
        }
        newInterestsArray.push(item)
      });

      setContactingPreferences(newPreferencesArray)
      setUserInterests(newInterestsArray)

    } else { // reset if no new data been found
      setContactingPreferences(luciContactingPreferences)
      setUserInterests(luciUserInterests)
    }
  }

  const generateArray = () => {
    let newUserPreferencesArray = []

    contactingPreferences.forEach(item => {
      if (item.checked) {
        newUserPreferencesArray.push(item.code)
      }
    });

    userInterests.forEach(item => {
      if (item.checked) {
        newUserPreferencesArray.push(item.code)
      }
    });

    return newUserPreferencesArray.sort();
  }

  const mountMiniTrap = () => {
    setActiveContactsTrap(true);
  }
  const unmountMiniTrap = () => {
    setActiveContactsTrap(false);
  }
  const cancelMiniTrap = () => {
    setResetTrigger(true);
    unmountMiniTrap();
  }

  const onSubmitContacts = (e) => {
    e.preventDefault();
    saveUserPreferenceContact(convertToString(generateArray()))
    if (!loadingPreference) {
      unmountMiniTrap();
    }
  }

  const addRemoveContactOptions = (request_code) => {
    let updatedArray = [...contactingPreferences]
    let index = updatedArray.findIndex(x => x.code === request_code)
    updatedArray[index].checked = !updatedArray[index].checked
    setContactingPreferences(updatedArray)
  }

  const addRemoveInterestsOptions = (request_code) => {
    let updatedArray = [...userInterests]
    let index = updatedArray.findIndex(x => x.code === request_code)
    updatedArray[index].checked = !updatedArray[index].checked
    setUserInterests(updatedArray)
  }

  const loaderCSS = css`
      position: absolute;
      opacity: 0.4;
      top: 50%;
      left: 46%;
      z-index: 1;
    `;

  return (
    <div className={activeContactsTrap ? "settings-column active" : "settings-column"}>
      {loadingPreference && <ScaleLoader css={loaderCSS} height={50} width={6} color='var(--primary)' />}
      <FocusTrap
        active={activeContactsTrap}
        focusTrapOptions={{
          onDeactivate: cancelMiniTrap,
          initialFocus: false,
          clickOutsideDeactivates: true,
          escapeDeactivates: false,
        }}
      >
        <form onSubmit={(e) => onSubmitContacts(e)} onReset={cancelMiniTrap}>
          <section className="settings-title">
            <div className="title-wrp top-aligned">
              <h2>Contact Preferences</h2>
              {!activeContactsTrap &&
                <button title="Edit Contact Preferences" className="edit-btn" onClick={() => mountMiniTrap()}>Edit <FontAwesomeIcon icon={["fas", "pen"]} /></button>
              }
              {activeContactsTrap &&
                <div className="settings-buttons">
                  <button title="Save Contact Preferences" type="submit"
                    disabled={userSavedContactOptions === convertToString(generateArray())}
                  >Save <FontAwesomeIcon icon={["fas", "save"]} /></button>
                  <button title="Cancel" type="reset">Cancel <FontAwesomeIcon icon={["far", "times"]} /></button>
                </div>
              }
            </div>
          </section>

          <div className="row">
            <div className="col-md-6">
              <p className="settings-text">I would like to sign up to receive updates from {applicationData.publishedTitle} by:</p>
              <ul className="preference-list pref-list-ver">
                {contactingPreferences && contactingPreferences.map(item =>
                (<li className="checkbox-wrapper" key={item.id}>
                  <input
                    type="checkbox"
                    id={item.id}
                    onChange={() => addRemoveContactOptions(item.code)}
                    disabled={!activeContactsTrap}
                    checked={item.checked}
                    aria-checked={item.checked ? "true" : "false"}
                  />
                  <label className="preference-label" htmlFor={item.id}>{item.name}</label>
                </li>
                ))
                }
              </ul>
            </div>
            <div className="col-md-6">
              <p className="settings-text">Please notify me about any offers or promotions regarding the following:</p>
              <ul className="preference-list pref-list-ver">
                {userInterests && userInterests.map((item, index) =>
                (<li className="checkbox-wrapper" key={item.id}>
                  <input
                    type="checkbox"
                    id={item.id}
                    onChange={() => addRemoveInterestsOptions(item.code)}
                    disabled={!activeContactsTrap}
                    checked={item.checked}
                    aria-checked={item.checked ? "true" : "false"}
                  />
                  <label className="preference-label" htmlFor={item.id}>{item.name}</label>
                </li>
                ))
                }
              </ul>
            </div>
          </div>
        </form>
      </FocusTrap>
    </div>
  );
}

export default ContactPreferences;