import React, { useState, useEffect, useContext, Fragment } from 'react'
import './BookJacket.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css } from '@emotion/core'
import { ScaleLoader } from 'react-spinners'
import moment from 'moment';

import { AuthContext } from '../../../AuthContext';
import PickupLocations from './PickupLocations';
import FlippingCover from './FlippingCover';
import UserInteractionIcons from './UserInteractionIcons';
import CloseButton from '../../common/CloseButton';

const loaderCSS = css`
  position: absolute;
  opacity: 0.4;
  top: 40%;
  left: 40%;
  z-index: 9;
`;
const loaderCSShold = css`
  position: absolute;
  opacity: 0.4;
  left: 40%;
  z-index: 9;
`;

const BookInsider = (props) => {
  const { user, applicationData, isAuthenticated, isTabletOrMobile, axiosInstance, axiosInstanceWithPatronToken,
    userPreferredLocation, setModalLoginStatus, socialLinks, refreshUserHolds, appFormats, toast } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [loadingHold, setLoadingHold] = useState(false);
  const [expanded, setExpanded] = useState(props.active || false);
  const [infoPanelOpened, setInfoPanelOpened] = useState(false);
  const [remainingIconIndex, setRemainingIconIndex] = useState(0);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [record, setRecord] = useState(props.recordData);

  const [pickupLocation, setPickupLocation] = useState(userPreferredLocation);
  const [availableCopies, setAvailableCopies] = useState([]);
  const [pLocations, setpLocations] = useState([]);
  const [recordFormat, setRecordFormat] = useState('');

  useEffect(() => {
    if (!props.isDirectURL && !props.isArchive) {
      fetchRecord();
    }
  }, [expanded])

  useEffect(() => {
    if (record) {
      getAvailableCopies();
    }
    if (record.format) {
      appFormats.forEach((obj, index) => {
        if (obj.formatVariety.includes(record.format)) {
          setRecordFormat(obj)
          setRemainingIconIndex(index + 1)
        }
      });
    } else if (record.mainMetadata) {
      const searchingArray = record.mainMetadata
      searchingArray.forEach(item => {
        if (item.key === "ERC_FORMAT") {
          appFormats.forEach((obj, index) => {
            if (obj.formatVariety.includes(item.value)) {
              setRecordFormat(obj)
              setRemainingIconIndex(index + 1)
            }
          });
        }
      })
    }
  }, [record])

  const fetchRecord = async () => {
    setLoading(true);
    let theURL
    if (record.source) {
      theURL = `/Manifestations/${record.recordID}?source=${record.source}`
    } else {
      theURL = `/Manifestations/${record.recordID}`
    }

    await axiosInstance.get(theURL)
      .then(response => {
        if (response.status === 429) {
          toast.warning(response.data)
        } else if (response.status === 200) {
          setRecord(response.data);
        }
        setLoading(false);
      })
      .catch(err => {
        console.error(err.message)
        toast.error("Error with getting item details: " + err.message)
        setLoading(false);
      })
  }

  const toggleInfoPanel = (index) => {
    if (infoPanelOpened) {
      closeInfoPanel();
    } else {
      setInfoPanelOpened(true);
      setRemainingIconIndex(index);
    }
  }

  const closeInfoPanel = () => {
    setInfoPanelOpened(false);
    // setRemainingIconIndex(0);
  }

  const getAvailableCopies = () => {
    if (record.copies && record.copies.length > 0) {
      const array = record.copies;
      const filteredCopies = array.filter(copy => copy.available === true);
      if (filteredCopies.length > 0) {
        setAvailableCopies(filteredCopies);
      }
    }
  }

  const Copies = () => {
    return (
      <div className="copies-div">
        <div className="copies-overlay"></div>
        <ul className="copies-list">
          {record.copies.length > 0 && record.copies.map((copy, index) => (
            <li key={copy.itemID}><div className="copies-count">{index + 1}</div>
              {copy.location.locationName} <br />
              <p>Status:<span className={copy.available ? "available" : ""}>&nbsp;{copy.status}</span></p>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const placeAHold = async (recordID, pickupLocation) => {
    setLoadingHold(true);
    var data = {
      "userID": user.userID,
      "recordID": recordID,
      "pickupLocation": pickupLocation
    }
    try {
      await axiosInstanceWithPatronToken.post('/Reservations', data).then(response => {
        if (response.status === 200) {
          toast.success('Item reservation has been placed successfully.')
          refreshUserHolds()
        } else {
          toast.error(response)
        }
        setLoadingHold(false)
        closeInfoPanel()
        props.onCancel()
      })
    } catch (err) {
      if (err.response) {
        toast.error("Item reservation failed: " + err.response.data ? err.response.data : err);
      } else {
        toast.error("Item reservation failed: " + err);
      }
      setLoadingHold(false)
      closeInfoPanel()
      props.onCancel()
    }
  }

  const handlePlaceAHold = () => {
    placeAHold(record.recordID, pickupLocation);
  }

  // to remove any HTML tags from the description
  const cleanString = (strInputCode) => {
    return strInputCode.replace(/<\/?[^>]+(>|$)/g, "");
  }

  const fixUTCDate = (timestamp) => {
    return moment(timestamp).format('DD-MM-YYYY');
  }

  return (
    <Fragment>
      <div className={isTabletOrMobile ? "mobile-card-container" : "desktop-card-container"}>
        {/* left side  */}
        <div className={isTabletOrMobile ? "ms-book-bookcover" : ""}>
          <FlippingCover recordPubYear={props.recordPubYear} isTabletOrMobile={isTabletOrMobile} recordData={record} />
        </div>

        {/* right side  */}
        <div className={isTabletOrMobile ? "mobile-right-container" : "outer-right-container"}>

          {/* the vertical icons */}
          {!infoPanelOpened &&
            <UserInteractionIcons applicationData={applicationData} socialLinks={socialLinks} record={record} />
          }

          {/* the loading spinner */}
          {loading && <ScaleLoader css={loaderCSS} height={50} width={6} color='var(--primary)' />}

          {/* The close button */}
          {!infoPanelOpened &&
            <CloseButton styleClassName="closecard-btn dimmed-before" size="2x" title="Close this record view" action={() => props.onCancel()} />
          }

          {/* Item description */}
          <div className={infoPanelOpened ? "info-container dimmed" : "info-container dimmed-before"}>
            {(props.isArchive && props.archiveDetails) &&
              <div className="checkout-note">
                <h1>Past loan
                </h1>
                <b>You last checked this item out on: </b> <p> {fixUTCDate(props.archiveDetails.dueObject)}</p>
              </div>
            }

            {loading ?
              <p>Loading record's details...</p> : (record.description ?
                <p>
                  <span dangerouslySetInnerHTML={{ __html: cleanString(record.description) }} />
                </p> :
                <p>No description found for this item.</p>
              )
            }
          </div>

          {/* The available formats */}
          {!loading &&
            <div className={infoPanelOpened ? "horizontal-format-icons opened" : "horizontal-format-icons"} >
              {(!infoPanelOpened && record.format !== undefined && record.format !== '') &&
                <p className="small-title">Choose a format to place a reservation:</p>
              }

              {/* The clickable format icons inside the jacket when it's opened */}
              {appFormats.map((item, index) => (
                <Fragment key={item.formatCode + index}>
                  {(recordFormat && recordFormat !== '') &&
                    <button
                      title={remainingIconIndex === index + 1 ? (infoPanelOpened ? "Close info panel" : `Open info panel with "${item.formatCode}" format`) : ""}
                      className={(remainingIconIndex === index + 1) ? 'hor-icon selected-icon' : 'hor-icon'}
                      onClick={() => toggleInfoPanel(remainingIconIndex)}
                      disabled={remainingIconIndex !== index + 1}
                    >
                      <div className={(remainingIconIndex !== index + 1 && infoPanelOpened) ? "inter-icon-wrp fade" : "inter-icon-wrp"}>
                        {remainingIconIndex === index + 1 &&
                          <FontAwesomeIcon className="inter-arrow" icon={['fas', infoPanelOpened ? 'caret-down' : 'caret-up']} />
                        }
                        <FontAwesomeIcon className="inter-icons" icon={[item.formatIcon.substring(0, 3), item.formatIcon.substring(4)]} />
                        <p className={remainingIconIndex === index + 1 ? "inter-name bold" : "inter-name"}>{(remainingIconIndex === index + 1) ? (infoPanelOpened ? "Close" : recordFormat.formatCode) : item.formatCode}</p>
                      </div>
                    </button>
                  }
                </Fragment>
              ))
              }


              {/* The X icon to close the details panel */}
              {infoPanelOpened &&
                <CloseButton styleClassName="close-btn-small" size="2x" title="Close info panel" action={closeInfoPanel} />
              }

              {/* The details panel */}
              <div className="details-panel">
                {(infoPanelOpened && (remainingIconIndex !== 0)) &&
                  <Fragment>
                    {/* Tabs on the top when details panel is open */}
                    <div className="details-panel-wrapper">
                      <ul className="details-panel-tabs">
                        <li className={activeTabIndex === 0 ? 'panel-tab selected' : 'panel-tab'}>
                          <button onClick={() => !loading ? setActiveTabIndex(0) : undefined} title="Check for the available copies">Availability</button>
                        </li>
                        <li className={activeTabIndex === 1 ? 'panel-tab selected' : 'panel-tab'}>
                          <button onClick={() => !loading ? setActiveTabIndex(1) : undefined} title="Reserve your copy">Reserve</button>
                        </li>
                      </ul>
                    </div>

                    {/* Availability tab when clicked */}
                    {activeTabIndex === 0 &&
                      <Fragment>
                        {record.copies ?
                          <div className="details-inner">
                            <Fragment>
                              <p className="details-panel-text">
                                {availableCopies && availableCopies.length} / {record.copies.length} copies are available<br />
                              </p>
                            </Fragment>
                            <Copies />
                          </div> :
                          <div className="details-inner">
                            <p className="details-panel-text">Sorry, there are no available copies of this item at the moment.</p>
                          </div>
                        }
                      </Fragment>
                    }

                    {/* Reserve tab when clicked */}
                    {activeTabIndex === 1 &&
                      <Fragment>
                        {record.canReserve ?
                          <Fragment>
                            {isAuthenticated ?
                              <Fragment>
                                <PickupLocations
                                  record={record}
                                  pickupLocation={pickupLocation}
                                  setPickupLocation={setPickupLocation}
                                  setpLocations={setpLocations}
                                  setLoadingHold={setLoadingHold}
                                />
                                <button className="wide-btn" title="Click here to place a reservation" disabled={!pLocations.length > 0} onClick={handlePlaceAHold}>
                                  <p>Place reservation <FontAwesomeIcon icon={['fas', 'arrow-right']} /></p>
                                </button>
                                {/* the loading spinner for placing a Reservation */}
                                {loadingHold && <ScaleLoader css={loaderCSShold} height={50} width={6} color='var(--primary)' />}
                              </Fragment> :
                              <div className="details-panel-msg">
                                <p className="details-panel-text">Please <button title="Click here to login" onClick={() => setModalLoginStatus(true)}>log in</button> first!</p>
                              </div>
                            }
                          </Fragment> :
                          <div className="details-inner">
                            <p className="details-panel-text">This item could not be reserved.</p>
                          </div>
                        }
                      </Fragment>
                    }
                  </Fragment>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </Fragment>
  )
};

export default BookInsider