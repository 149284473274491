import React, { Component } from 'react';
import { SyncLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import TheArrowUnderneath from '../TheArrowUnderneath';
import Arrow from '../Arrow';

import { AuthContext } from '../../../AuthContext';

export default class ServicesCatBar extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.changeSlide = this.changeSlide.bind(this)
    this.getIndex = this.getIndex.bind(this)
    this.clickHandler = this.clickHandler.bind(this)

    this.state = {
      activeIndex: 0,
      displayRightArrow: false,
      displayLeftArrow: false,
    }

    this.sliderSetting = {
      dots: false,
      arrows: false,
      accessibility: true,
      draggable: true,
      speed: process.env.REACT_APP_CAROUSEL_SPEED,
      slidesToScroll: 2,
      infinite: false,
      swipeToSlide: true,
      variableWidth: true,
      adaptiveHeight: false,
      focusOnSelect: false,
      nextArrow: null,
      prevArrow: null,
      afterChange: currentSlide => { this.setArrowDisplay(currentSlide) }
    }
  }

  setArrowDisplay = slideIndex => {
    const displayLeftArrow = slideIndex !== 0;
    const displayRightArrow = slideIndex + this.context.tabsToShow <= this.props.categories.length;

    this.setState({ displayRightArrow, displayLeftArrow });
  };

  componentDidMount() {
    this.setArrowDisplay(0)
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedItemId === '' || this.props.selectedItemId === undefined) {
      return
    }
    if (this.props.selectedItemId !== prevProps.selectedItemId) {
      let index = this.getIndex(this.props.selectedItemId);
      let factor = (this.context.tabsToShow / 2).toFixed(0) - 1

      this.setState({
        activeIndex: index
      });

      this.setArrowDisplay(index);

      if (factor >= 3) {
        this.slider.slickGoTo(index - factor);
      } else if (factor <= 2) {
        this.slider.slickGoTo(index - factor + 1);
      }
    }
  }

  clickHandler = (direction) => {
    if (direction === 'left') {
      this.slider.slickPrev();
    } else if (direction === 'right') {
      this.slider.slickNext();
    }
  };

  getIndex = (id) => {
    return this.props.categories.findIndex(tab => tab.id === id);
  }

  changeSlide = (index) => {
    this.setState({
      activeIndex: index
    });
  }

  render() {
    return (
      <div className="lib-slider-bar">
        <div className="lsp-slider-wrap" style={{ minHeight: "270px" }}>
          <Arrow
            styleClassName={this.state.displayLeftArrow ? 'lsp-leftbtn-containter' : 'lsp-leftbtn-containter hidden'}
            direction="left"
            clickHandler={this.clickHandler}
            isTabletOrMobile={this.context.isTabletOrMobile}
            carouselName="library digital-content level two tabs navbar"
          />
          <Arrow
            styleClassName={this.state.displayRightArrow ? 'lsp-rightbtn-containter' : 'lsp-rightbtn-containter hidden'}
            direction="right"
            clickHandler={this.clickHandler}
            isTabletOrMobile={this.context.isTabletOrMobile}
            carouselName="library digital-content level two tabs navbar"
          />
          <Slider {...this.sliderSetting} ref={catSlider => this.slider = catSlider}>
            {this.props.categories.map((service, index) => {
              return (
                <div key={service.id} style={{ width: this.props.tabWidth + "px", marginRight: "2px" }}>
                  <Link
                    to={`/${this.context.digitalContentTab.canonicalTitle}/${this.props.levelOneCanonicalTitle}/${service.canonicalTitle}/`}
                    onClick={() => { scroll.scrollTo(150); this.changeSlide(index); this.props.action(service.id, service); this.props.setLevelThreeCanonicalTitle(''); this.props.setChannelExtraction([]); }}
                  >
                    <div className={this.props.selectedItemId === service.id ? "first-row-items collapsed the-one" : "first-row-items collapsed others"}
                      style={this.props.selectedItemId === service.id ? { border: "3px solid var(--primary)", height: "84px", cursor: "pointer", marginTop: "0" } : { marginTop: "0", height: "100%" }}>
                      <div className="first-row-inner vignette"
                        style={service.image ? { "backgroundImage": `url(data:image/jpeg;base64,${service.image})` }
                          : { "backgroundImage": `url("/no-img-ph2.jpg")` }}>
                        <div className="pointy"></div>
                        <div className="service-name">
                          <p>{service.title}</p>
                          {(this.props.loadingLevelTwo && this.props.selectedIDForLoading === service.id) &&
                            <span className="subtabs-loading">
                              {this.context.isTabletOrMobile ?
                                <SyncLoader size={8} margin={0} color='var(--primary)' /> :
                                <SyncLoader size={10} margin={1} color='var(--primary)' />
                              }
                            </span>
                          }
                        </div>
                        {this.props.selectedItemId !== service.id &&
                          <div className="arrow-container">
                            <TheArrowUnderneath />
                          </div>
                        }
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    );
  }
}
