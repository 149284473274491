import React, { useContext, Fragment } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Modal from './components/common/modal/Modal'

// CONTEXT
import { AuthContext } from './AuthContext';

// COMPONENTS
// import Welcome from './components/welcome/Welcome';
// import SignupForm from './components/auth/SignupForm';
import Bookshelf from './components/bookshelf/Bookshelf';
import Search from './components/search/Search';
import Events from './components/events/Events';
// import Clubs from './components/clubs/Clubs';
import WildCard from './components/wildcard/WildCard';
import NoPage from './components/NoPage';
import LoadingPage from './components/LoadingPage';
import BookView from './components/BookView';
import LibraryServices from './components/library-services/LibraryServices';
import Settings from './components/user/settings/Settings';
import Preferences from './components/user/preferences/Preferences';
import Register from './components/user/auth/Register';
import ResetPassword from './components/user/auth/ResetPassword';
import Footer from './components/common/footer/Footer';
import Navbar from "./components/navbar/Navbar";
import ScrollToTop from './components/common/ScrollToTop';

// THE LOADING SPINNER
import { css } from '@emotion/core'
import { ScaleLoader } from 'react-spinners'

// ROUTES
import { ProtectedRoute } from './protected-route';

// LIBRARIES
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'

library.add(fab, far, fad, fas, fal);

const App = (props) => {
  const { loading, applicationData, isTabletOrMobile, wildCard, eventsTab, digitalContentTab, userAccountTab, bookshelfTab, searchTab, setModalPasswordStatus, setModalLoginStatus,
    setModalContactUsStatus, logIn, logInMessage, waiting, openLoginDialogue, logOut, resetPassword, passwordMessage, contactUsResponseMessage, setContactUsResponseMessage, isAuthenticated, user,
    modalLogoutStatus, modalPasswordStatus, modalLoginStatus, modalContactUsStatus, modalStatus, modalPayload, modalRenewAllStatus, currentLoans, setModalRenewAllStatus } = useContext(AuthContext);

  const scaleLoaderWidth = isTabletOrMobile ? '70px' : '120px';

  const loaderCSS = css`
  position: fixed;
  opacity: 0.4;
  top: 33vh;
  left: calc((100vw - ${scaleLoaderWidth}) / 2);
  z-index: 999;
    `;
  const loaderMobileCSS = css`
  position: fixed;
  opacity: 0.4;
  top: 15vh;
  left: calc((100vw - ${scaleLoaderWidth}) / 2);
  z-index: 999;
    `;

  const hidePasswordModalEventHandler = () => {
    setModalPasswordStatus(false);
  }

  const hideLogInModalEventHandler = () => {
    setModalLoginStatus(false);
  }

  const hideContactUsModalEventHandler = () => {
    setModalContactUsStatus(false);
  }

  return (
    <Router>
      <div className="app">

        <Navbar />

        {loading &&
          <Fragment>
            {isTabletOrMobile ?
              <ScaleLoader css={loaderMobileCSS} height={50} width={10} radius={0} color={Object.keys(applicationData).length > 0 ? 'var(--primary)' : 'var(--orange)'} /> :
              <ScaleLoader css={loaderCSS} height={140} width={20} radius={0} color={Object.keys(applicationData).length > 0 ? 'var(--primary)' : 'var(--orange)'} />
            }
          </Fragment>
        }
        <Switch>
          {(!loading && bookshelfTab) &&
            <Route exact path="/"  >
              {/* <Redirect to={`/${bookshelfTab.canonicalTitle}`} /> */}
              <Redirect to={
                {
                  pathname: `/${bookshelfTab.canonicalTitle}`,
                  state: { from: props.location }
                }
              } />
            </Route>
          }

          {bookshelfTab &&
            <Route path={`/${bookshelfTab.canonicalTitle}`} component={Bookshelf} />
          }

          {(!loading && digitalContentTab) &&
            <Route path={`/${digitalContentTab.canonicalTitle}`} component={LibraryServices} />
          }

          {(!loading && eventsTab) &&
            <Route path={`/${eventsTab.canonicalTitle}`} component={Events} />
          }

          <Route exact path="/search">
            <Redirect to="/" />
          </Route>
          <Route path="/search/:handle" render={props => (searchTab ?
            <Search {...props} isSearchScreen /> : <LoadingPage />
          )} />

          <Route exact path="/user/register" component={Register} />
          <Route exact path="/user/resetaccount/:resettoken" component={ResetPassword} />

          {(!loading && bookshelfTab) &&
            <ProtectedRoute exact path="/user/account" component={userAccountTab ? Settings : LoadingPage} />
          }

          {(!loading && bookshelfTab) &&
            <ProtectedRoute path="/user/preferences" component={userAccountTab ? Preferences : LoadingPage} />
          }

          {/* <Route path="/home" component={Home} /> */}
          {wildCard &&
            <Route exact path={`/${wildCard.canonicalTitle}`} component={WildCard} />
          }

          {/* to handle direct urls to books */}
          <Route path="/manifestations/:id" component={BookView} />
          <Route exact path="/manifestations">
            <Redirect to="/" />
          </Route>

          {!loading &&
            <Route exact path="*" component={NoPage} />  // to handle any URLs with 404 not-found //
          }
        </Switch>

        {/* back to top button */}
        <ScrollToTop />

        <Footer />

        {/* logout modal */}
        {modalLogoutStatus &&
          <Modal
            isLogoutConfirmation
            canCancel
            canConfirm
            onConfirm={openLoginDialogue}
            onCancel={logOut}
            dialogueMessage="Your login session has expired! Please re-login."
          />
        }

        {/* reset password modal */}
        {modalPasswordStatus &&
          <Modal
            isPasswordReset
            canConfirm
            canCancel
            onCancel={hidePasswordModalEventHandler}
            title="Reset Password"
            resetPassword={resetPassword}
            isWaiting={waiting}
            passwordMessage={passwordMessage}
          />
        }

        {/* login modal */}
        {modalLoginStatus &&
          <Modal
            isLibraryLogin
            canConfirm
            canCancel
            onCancel={hideLogInModalEventHandler}
            logIn={logIn}
            title="User Login"
            logInMessage={logInMessage}
            isWaiting={waiting}
          />
        }

        {/* Contact Us modal */}
        {modalContactUsStatus &&
          <Modal
            isContact
            canCancel
            canConfirm
            contactUsResponseMessage={contactUsResponseMessage}
            isWaiting={waiting}
            setContactUsResponseMessage={setContactUsResponseMessage}
            isAuthenticated={isAuthenticated}
            user={user || undefined}
            onCancel={hideContactUsModalEventHandler}
          />
        }

        {/* book/loan/reservation/archive/events items modal */}
        {modalStatus &&
          <Modal {...modalPayload} />
        }

        {/* Renew all loans modal */}
        {modalRenewAllStatus &&
          <Modal
            isRenewAll
            canCancel
            canConfirm
            onCancel={() => setModalRenewAllStatus(false)}
            source={currentLoans}
          />
        }
      </div>
    </Router>
  );
}

export default App;
