import React, { Component, Fragment } from 'react';
import './Addslider.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { AuthContext } from '../../AuthContext';

import Arrow from "../common/Arrow";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class CustomSlide extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    }

    this.activateBookjacketModal = this.activateBookjacketModal.bind(this);
    this.activateEventModal = this.activateEventModal.bind(this);
    this.hideEventModalHandler = this.hideEventModalHandler.bind(this);
  }

  activateBookjacketModal = (e, promotion) => {
    e.preventDefault(); // we don't want the hyperlink to work here

    let recordData = {}
    recordData.recordID = promotion.extendedInfo
    recordData.imageUrl = promotion.thumbnailImage.imageUrl
    recordData.description = promotion.infoBar
    console.log(recordData)
    this.context.setModalStatus(true);
    this.context.setModalPayload({ ...this.props, recordData, isBook: true, canCancel: true, onCancel: () => this.context.setModalStatus(false) });
  };

  // Function to get the date in a readable format 
  getUTCDate(timestamp) {
    return moment(timestamp).format('ddd D-MMM-YY');
  }
  // Function to get the time in a readable format 
  getUTCTime(timestamp) {
    return moment(timestamp).format('kk:mm');
  }

  hideEventModalHandler = () => {
    this.context.setModalStatus(false);
  }

  activateEventModal = (e, _id) => {
    e.preventDefault(); // we don't want the hyperlink to work here

    const getSingleEvent = async () => {
      this.context.setLoading(true)

      try {
        const response = await this.context.axiosInstance.get(`/Events/${_id}`)
        this.context.setLoading(false)

        const eventData = response.data;
        const eventFullStartTime = eventData.start_time;
        const eventFullEndTime = eventData.end_time;
        const eventDate = this.getUTCDate(eventData.start_time);
        const eventTimeStart = this.getUTCTime(eventData.start_time);
        const eventTimeEnd = this.getUTCTime(eventData.end_time);

        this.context.setModalStatus(true);
        this.context.setModalPayload({
          event: eventData, eventDate, eventTimeStart, eventTimeEnd, eventFullStartTime, eventFullEndTime,
          isEvent: true, canCancel: true, onCancel: () => this.context.setModalStatus(false)
        });

      } catch (error) {
        console.error(error.message);
        this.context.setLoading(false)
      }
    }

    getSingleEvent()
  };

  render() {
    const { index, promotion, ...props } = this.props;
    if (promotion.simpleType === "bib") {
      return (
        <div className="inner-promo">
          <div className="promo-background" style={promotion.thumbnailImage && promotion.thumbnailImage.imageUrl ? { background: `var(--background-color) url(${promotion.thumbnailImage.imageUrl}) no-repeat center center`, backgroundSize: "cover" } : {}}></div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <a
                tabIndex={this.props.visible ? "" : "-1"}
                className={this.props.visible ? "promo-link-surround" : "promo-link-surround disabled-link"}
                href={`/manifestations/${promotion.extendedInfo}`}
                onClick={e => this.activateBookjacketModal(e, promotion)}
              >
                <div className="row justify-content-center">
                  {
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12" >
                      <div className="ad-image-container" style={promotion.thumbnailImage && promotion.thumbnailImage.imageUrl ? { background: `var(--background-color) url(${promotion.thumbnailImage.imageUrl}) no-repeat center center`, backgroundSize: "cover" } : {}} />
                    </div>
                  }
                  <div className="col-lg-9 col-md-6 col-sm-6 col-xs-12">
                    <h1>{promotion.title}</h1>
                    {promotion.description &&
                      <h2>{promotion.description}</h2>
                    }
                    {promotion.infoBar &&
                      <p>
                        {promotion.infoBar.split(" ").length > (this.props.isTabletOrMobile ? 40 : 60) ?
                          promotion.infoBar.slice(0, 250) + ' ...' : promotion.infoBar
                        }
                      </p>
                    }
                  </div>
                </div>
              </a>
              {promotion.moreInfoLink &&
                <div className="row justify-content-center">
                  <div className="promo-btns-wrapper">
                    <a tabIndex={this.props.visible ? "" : "-1"} className={this.props.visible ? "promo-btn" : "promo-btn disabled-link"} href={promotion.moreInfoLink} title="Click to follow the link" target="_blank" rel="noopener noreferrer">{promotion.moreInfoText ? promotion.moreInfoText : "More Info"}
                      <FontAwesomeIcon icon={['fas', 'caret-right']} />
                    </a>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      )
    } else if (promotion.simpleType === "event") {
      return (
        <div className="inner-promo">
          <div className="promo-background" style={promotion.thumbnailImage && promotion.thumbnailImage.imageUrl ? { background: `var(--background-color) url(${promotion.thumbnailImage.imageUrl}) no-repeat center center`, backgroundSize: "cover" } : {}}></div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <a
                tabIndex={this.props.visible ? "" : "-1"}
                className={this.props.visible ? "promo-link-surround" : "promo-link-surround disabled-link"}
                href={promotion.moreInfoLink}
                onClick={e => this.activateEventModal(e, promotion.target)}
              >
                <div className="row justify-content-center">
                  {
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12" >
                      <div className="ad-image-container ad-full-height-img" style={promotion.thumbnailImage && promotion.thumbnailImage.imageUrl ? { background: `var(--background-color) url(${promotion.thumbnailImage.imageUrl}) no-repeat center center`, backgroundSize: "cover" } : {}} />
                    </div>
                  }
                  <div className="col-lg-9 col-md-6 col-sm-6 col-xs-12">
                    <h1>{promotion.title}</h1>
                    {promotion.description &&
                      <h2>{promotion.description}</h2>
                    }
                    {promotion.infoBar &&
                      <p>
                        {promotion.infoBar.split(" ").length > (this.props.isTabletOrMobile ? 40 : 60) ?
                          promotion.infoBar.slice(0, 250) + ' ...' : promotion.infoBar
                        }
                      </p>
                    }
                  </div>
                </div>
              </a>
              {promotion.moreInfoLink &&
                <div className="row justify-content-center">
                  <div className="promo-btns-wrapper">
                    <a tabIndex={this.props.visible ? "" : "-1"}
                      className={this.props.visible ? "promo-btn" : "promo-btn disabled-link"}
                      href={promotion.moreInfoLink}
                      title="Click to follow the link" target="_blank"
                      rel="noopener noreferrer">{promotion.moreInfoText ? promotion.moreInfoText : "More Info"}
                      <FontAwesomeIcon icon={['fas', 'caret-right']} />
                    </a>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      )
    } else if (promotion.simpleType === "url") {
      return (
        <div className="inner-promo">
          <div className="promo-background" style={promotion.thumbnailImage && promotion.thumbnailImage.imageUrl ? { background: `var(--background-color) url(${promotion.thumbnailImage.imageUrl}) no-repeat center center`, backgroundSize: "cover" } : {}}></div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <a tabIndex={this.props.visible ? "" : "-1"} className={this.props.visible ? "promo-link-surround" : "promo-link-surround disabled-link"} href={promotion.target} target="_blank" rel="noopener noreferrer">
                <div className="row justify-content-center">
                  {
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12" >
                      <div className="ad-image-container ad-full-height-img" style={promotion.thumbnailImage && promotion.thumbnailImage.imageUrl ? { background: `url(${promotion.thumbnailImage.imageUrl}) no-repeat top center`, backgroundSize: "contain" } : {}} />
                    </div>
                  }
                  <div className="col-lg-9 col-md-6 col-sm-6 col-xs-12">
                    <h1>{promotion.title}</h1>
                    {promotion.description &&
                      <h2>{promotion.description}</h2>
                    }
                    {promotion.infoBar &&
                      <p>
                        {promotion.infoBar.split(" ").length > (this.props.isTabletOrMobile ? 40 : 60) ?
                          promotion.infoBar.slice(0, 250) + ' ...' : promotion.infoBar
                        }
                      </p>
                    }
                  </div>
                </div>
              </a>
              {promotion.moreInfoLink &&
                <div className="row justify-content-center">
                  <div className="promo-btns-wrapper">
                    <a tabIndex={this.props.visible ? "" : "-1"} className={this.props.visible ? "promo-btn" : "promo-btn disabled-link"} href={promotion.moreInfoLink} title="Click to follow the link" target="_blank" rel="noopener noreferrer">{promotion.moreInfoText ? promotion.moreInfoText : "More Info"}
                      <FontAwesomeIcon icon={['fas', 'caret-right']} />
                    </a>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      )
    } else if (promotion.simpleType === "image") {
      return (
        <div className="inner-promo">
          <div className="promo-background" style={promotion.thumbnailImage && promotion.thumbnailImage.imageUrl ? { background: `var(--background-color) url(${promotion.thumbnailImage.imageUrl}) no-repeat center center`, backgroundSize: "cover" } : {}}></div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <a tabIndex={this.props.visible ? "" : "-1"} className={this.props.visible ? "promo-link-surround" : "promo-link-surround disabled-link"} href={promotion.target} target="_blank" rel="noopener noreferrer">
                <div className="row justify-content-center">
                  {
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12" >
                      <div className="ad-image-container ad-full-height-img" style={promotion.thumbnailImage && promotion.thumbnailImage.imageUrl ? { background: `url(${promotion.thumbnailImage.imageUrl}) no-repeat top center`, backgroundSize: "contain" } : {}} />
                    </div>
                  }
                  <div className="col-lg-9 col-md-6 col-sm-6 col-xs-12">
                    <h1>{promotion.title}</h1>
                    {promotion.description &&
                      <h2>{promotion.description}</h2>
                    }
                    {promotion.infoBar &&
                      <p>
                        {promotion.infoBar.split(" ").length > (this.props.isTabletOrMobile ? 40 : 60) ?
                          promotion.infoBar.slice(0, 250) + ' ...' : promotion.infoBar
                        }
                      </p>
                    }
                  </div>
                </div>
              </a>
              {promotion.moreInfoLink &&
                <div className="row justify-content-center">
                  <div className="promo-btns-wrapper">
                    <a tabIndex={this.props.visible ? "" : "-1"} className={this.props.visible ? "promo-btn" : "promo-btn disabled-link"} href={promotion.moreInfoLink} target="_blank" rel="noopener noreferrer">{promotion.moreInfoText ? promotion.moreInfoText : "More Info"}
                      <FontAwesomeIcon icon={['fas', 'caret-right']} />
                    </a>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      )
    } else if (promotion.simpleType === "video") {
      return (
        <div className="inner-promo" key={`ad-slider-${props.index}`}>
          <div className="promo-background" style={promotion.thumbnailImage && promotion.thumbnailImage.imageUrl ? { background: `var(--background-color) url(${promotion.thumbnailImage.imageUrl}) no-repeat center center`, backgroundSize: "cover" } : {}}></div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <button disabled={!this.props.visible} className="promo-btn-surround" onClick={() => this.props.activateVideoModal(promotion)}>
                <div className="row justify-content-center">
                  {
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12" >
                      <div className="ad-image-container ad-full-height-img" style={promotion.thumbnailImage && promotion.thumbnailImage.imageUrl ? { background: `url(${promotion.thumbnailImage.imageUrl}) no-repeat top center`, backgroundSize: "contain" } : {}} />
                    </div>
                  }
                  <div className="col-lg-9 col-md-6 col-sm-6 col-xs-12">
                    <h1>{promotion.title}</h1>
                    {promotion.description &&
                      <h2>{promotion.description}</h2>
                    }
                    {promotion.infoBar &&
                      <p>
                        {promotion.infoBar.split(" ").length > (this.props.isTabletOrMobile ? 40 : 60) ?
                          promotion.infoBar.slice(0, 250) + ' ...' : promotion.infoBar
                        }
                      </p>
                    }
                  </div>
                </div>
              </button>
              {promotion.moreInfoLink &&
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="promo-btns-wrapper">
                      <a tabIndex={this.props.visible ? "" : "-1"} className={this.props.visible ? "promo-btn" : "promo-btn disabled-link"} href={promotion.moreInfoLink} target="_blank" rel="noopener noreferrer">{promotion.moreInfoText ? promotion.moreInfoText : "More Info"}
                        <FontAwesomeIcon icon={['fas', 'caret-right']} />
                      </a>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div >
      )
    } else if (promotion.isEvent) { // here for reference only, TODO: fix as the others when the events slides are ready
      return (
        <Fragment>
          <div className="inner-promo-right">
            <h1>{promotion.title}</h1>
            <div className="promo-features">
              <div>
                {promotion.icons.map((icon, index) => (
                  <FontAwesomeIcon className="promo-medium-icons" icon={['fas', `${icon}`]} key={`ad-slider2-${index}`} />))}
              </div>
              <div>
                <p>{promotion.venue}
                  <FontAwesomeIcon className="promo-small-icons" icon={['fas', 'map-marker-alt']} />
                </p>
              </div>
              <div>
                <p>{promotion.date}
                  <FontAwesomeIcon className="promo-small-icons" icon={['far', 'calendar-star']} />
                </p>
              </div>
            </div>
            {this.props.isTabletOrMobile ?
              <p>
                {promotion.description.split(" ").length > 50 ?
                  promotion.description.slice(0, 100) + ' ...' : promotion.description
                }
              </p> : <p>{promotion.description}</p>
            }
            <div className="promo-keywords">
              {promotion.keywords.map((keyword, index) => (<p key={`ad-tags-${index}`}>#{keyword}</p>))}
            </div>
          </div>
          <div className="inner-promo-icons-wrapper">
            <button className="promo-btn" href={promotion.readMoreLink}>Book now
              <FontAwesomeIcon icon={['fas', 'caret-right']} />
            </button>
            <div className="inner-promo-icons-container">
              <div title="Like">
                <FontAwesomeIcon className="carousel-icons" icon={['fal', 'thumbs-up']} />
              </div>
              <div title="Dislike">
                <FontAwesomeIcon className="carousel-icons" icon={['fal', 'thumbs-down']} />
              </div>
            </div>
          </div>
          <div className="inner-promo-left">
            <img src={promotion.imageUrl} alt="Covering image" />
          </div>
        </Fragment>
      )
    } else {
      return (null)
    }
  }
}

export default class Addslider extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.activateVideoModal = this.activateVideoModal.bind(this);
    this.hideVideoModalHandler = this.hideVideoModalHandler.bind(this);

    this.state = {
      carouselPlay: false,
      currentSlide: 0
    };
  }


  clickHandler = (direction) => {
    if (direction === "left") {
      this.slider.slickPrev();
    } else if (direction === "right") {
      this.slider.slickNext();
    }
  };

  play() {
    this.slider.slickPlay();
    this.setState({
      carouselPlay: true
    });
  }

  pause() {
    this.slider.slickPause();
    this.setState({
      carouselPlay: false
    });
  }

  activateVideoModal = (obj) => {
    this.props.setModalStatus(true);
    this.props.setModalPayload({ isVideo: true, videoURL: obj.target, videoTitle: obj.title, canCancel: true, onCancel: this.hideVideoModalHandler });
  };

  hideVideoModalHandler = () => {
    this.props.setModalStatus(false);
  }

  render() {
    const sliderSetting = {
      dots: this.props.width0 ? false : true,
      arrows: false,
      accessibility: true,
      draggable: true,
      infinite: true,
      speed: process.env.REACT_APP_CAROUSEL_SPEED,
      autoplay: this.state.carouselPlay,
      autoplaySpeed: 8000,
      centerMode: true,
      swipeToSlide: true,
      nextArrow: null,
      prevArrow: null,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnFocus: true,
      pauseOnHover: true,
      focusOnSelect: true,
      centerPadding: this.props.isTabletOrMobile ? "50px" : "15%",
      // className: "lsp-promo-carousel",
      afterChange: index => { this.setState({ currentSlide: index }) }
    };

    return (
      <Fragment>
        {this.props.source.length > 0 ?
          <Fragment>
            <div className="skip-link-wrp">
              <a className="lsp-skip-link" href='#Library_Recommendations'>Skip to the content</a>
            </div>
            <div className="campaignslider-wrapper">
              {this.props.source.length > 1 &&
                <Fragment>
                  <Arrow
                    styleClassName="lsp-leftbtn-containter ad-carousel-lft"
                    direction="left"
                    clickHandler={this.clickHandler}
                    isTabletOrMobile={this.props.isTabletOrMobile}
                    carouselName="campaigns &amp; promotions"
                  />
                  <Arrow
                    styleClassName="lsp-rightbtn-containter ad-carousel-rht"
                    direction="right"
                    clickHandler={this.clickHandler}
                    isTabletOrMobile={this.props.isTabletOrMobile}
                    carouselName="campaigns &amp; promotions"
                  />
                </Fragment>
              }
              <Slider {...sliderSetting}
                ref={(ADSlider) =>
                  (this.slider = ADSlider)
                }>
                {this.props.source.map((promotion, index) => {
                  return (
                    <CustomSlide visible={this.state.currentSlide === index} key={`ad-slider-${index}`} {...this.props} promotion={promotion} index={index} activateVideoModal={this.activateVideoModal} />
                  )
                })}
              </Slider>
              {/* The pause/play small button */}
              {this.props.source.length > 1 &&
                <button className="carousel-control" onClick={this.state.carouselPlay ? this.pause : this.play}
                  title={this.state.carouselPlay ? 'Pause the ad carousel' : 'Run the ad carousel'}>
                  <FontAwesomeIcon size={this.props.isTabletOrMobile ? "sm" : "1x"} icon={this.state.carouselPlay ? ['fas', 'pause'] : ['fas', 'play']} />
                </button>
              }
            </div>
          </Fragment> :
          <div className="promo-placeholder"></div>
        }
      </Fragment>
    );
  }
}
