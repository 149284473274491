import React, { useState, useContext, useEffect, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../../../AuthContext';
import FocusTrap from 'focus-trap-react';
import { css } from '@emotion/core';
import { ScaleLoader } from 'react-spinners';

const GenrePreferences = () => {
  const { convertToString, convertToArray, userSavedGenres, saveUserPreferenceGenres, luciUserGenres, loadingPreference } = useContext(AuthContext);

  const [resetTrigger, setResetTrigger] = useState(false);
  const [activeGenresTrap, setActiveGenresTrap] = useState(false);
  const [genrePreferences, setGenrePreferences] = useState([]);
  const [selectedGenresCounter, setSelectedGenresCounter] = useState(0);

  useEffect(() => {
    if (genrePreferences) {
      countGenrePreferences();
    }
  }, [genrePreferences])

  useEffect(() => {
    if (resetTrigger) {
      initiateArrays(userSavedGenres);
    }
    return () => {
      setResetTrigger(false);
    }
  }, [resetTrigger])

  useEffect(() => {
    initiateArrays(userSavedGenres)
  }, [userSavedGenres])

  const initiateArrays = (str) => {
    const originalArray = convertToArray(str);

    if (originalArray.length > 0) {
      let newGenresArray = []

      luciUserGenres.forEach(item => {
        if (originalArray.includes(item.code)) {
          item.checked = true;
        } else {
          item.checked = false;
        }
        newGenresArray.push(item)
      });

      setGenrePreferences(newGenresArray);

    } else { // reset if no new data been found
      setGenrePreferences(luciUserGenres)
    }
  }

  const generateArray = () => {
    let newUserGenresArray = []

    genrePreferences.forEach(item => {
      if (item.checked) {
        newUserGenresArray.push(item.code)
      }
    });

    return newUserGenresArray.sort();
  }

  const mountMiniTrap = () => {
    setActiveGenresTrap(true);
  }
  const unmountMiniTrap = () => {
    setActiveGenresTrap(false);
  }
  const cancelMiniTrap = () => {
    setSelectedGenresCounter(0);
    setResetTrigger(true);
    unmountMiniTrap();
  }

  const onSubmitGenre = (e) => {
    e.preventDefault();
    if (selectedGenresCounter > 1) {
      saveUserPreferenceGenres(convertToString(generateArray()));
      if (!loadingPreference) {
        unmountMiniTrap();
      }
    } else {
      console.log("Select one at least")
    }
  }

  const addRemoveGenreOptions = (request_code) => {
    let updatedArray = [...genrePreferences]
    let index = updatedArray.findIndex(x => x.code === request_code)
    updatedArray[index].checked = !updatedArray[index].checked
    setGenrePreferences(updatedArray);
  }

  const countGenrePreferences = () => {
    let counter = 0;
    genrePreferences.forEach(item => {
      if (item.checked) {
        counter = counter + 1;
      }
    });
    setSelectedGenresCounter(counter);
  }


  const getGenreName = (genreID) => {
    if (genreID === '') {
      return
    }
    const genre = luciUserGenres.filter(item => (item.code === genreID))
    if (genre[0] !== undefined) {
      return genre[0].name;
    }
  }

  const loaderCSS = css`
      position: absolute;
      opacity: 0.4;
      top: 40%;
      left: 45%;
      z-index: 1;
    `;

  return (
    <div className={activeGenresTrap ? "settings-column active" : "settings-column"}>
      {loadingPreference && <ScaleLoader css={loaderCSS} height={50} width={6} color='var(--primary)' />}
      <FocusTrap
        active={activeGenresTrap}
        focusTrapOptions={{
          onDeactivate: () => cancelMiniTrap(),
          initialFocus: false,
          clickOutsideDeactivates: true,
          escapeDeactivates: false,
        }}
      >

        <form className="centered-form" onSubmit={(e) => onSubmitGenre(e)} onReset={cancelMiniTrap}>
          <section className="settings-title">
            <div className="title-wrp top-aligned">
              <h2>Preferred Genres</h2>
              {!activeGenresTrap &&
                <button title="Edit user's preferred Genres" className="edit-btn" onClick={() => mountMiniTrap()}>Edit <FontAwesomeIcon icon={["fas", "pen"]} /></button>
              }
              {activeGenresTrap &&
                <div className="settings-buttons">
                  <button title="Save user's preferred Genres" type="submit"
                    disabled={userSavedGenres === convertToString(generateArray()) || selectedGenresCounter < 2}
                  >Save <FontAwesomeIcon icon={["fas", "save"]} /></button>
                  <button title="Cancel" type="reset">Cancel <FontAwesomeIcon icon={["far", "times"]} /></button>
                </div>
              }
            </div>
          </section>
          {!activeGenresTrap ?
            // before pressing the Edit button
            <ul className="preference-list pref-list-hor">
              {userSavedGenres.length > 0 ?
                <Fragment>
                  {convertToArray(userSavedGenres).map((genreID, index) => (
                    <li className="preference-item" key={`genre-${index}`}>{getGenreName(genreID)}</li>
                  ))}
                </Fragment> : <li className="preference-line"> No preferences yet!</li>
              }
            </ul> :
            // after pressing the Edit button
            <ul className="preference-list pref-list-ver p-0 limited">
              {(selectedGenresCounter >= 2 || activeGenresTrap) ?
                <Fragment>
                  {genrePreferences.map(genre =>
                  (<li className="checkbox-wrapper" key={genre.id}>
                    <input
                      type="checkbox"
                      id={genre.id}
                      onChange={() => addRemoveGenreOptions(genre.code)}
                      checked={genre.checked}
                      aria-checked={genre.checked ? "true" : "false"}
                    />
                    <label className="preference-label" htmlFor={genre.id}>{genre.name}</label>
                  </li>
                  ))}
                </Fragment> : <li className="preference-line"> No preferences yet!</li>
              }
            </ul>
          }
          {(selectedGenresCounter < 2 && activeGenresTrap) &&
            <p className="preference-warning"><FontAwesomeIcon icon={['fal', 'exclamation-triangle']} /> Please select at least two of your favourite genres or categories.</p>
          }
        </form>
      </FocusTrap>
    </div>
  );

}

export default GenrePreferences;