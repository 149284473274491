import React, { useState, useEffect, useContext, Fragment } from 'react';
import { FacebookShareButton, TwitterShareButton, EmailShareButton, WhatsappShareButton } from "react-share";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SocialShareButton.css';
import FocusTrap from 'focus-trap-react'
import copy from 'copy-to-clipboard';
import { AuthContext } from '../../../AuthContext';

const SocialShareButton = (props) => {
  const { toast } = useContext(AuthContext);

  const [shareButtonsActive, setButtonsActive] = useState(false);
  const [shareableURL, setShareableURL] = useState('');
  const [twitterAccount, setTwitterAccount] = useState('');
  const [twitterHashTags, setTwitterHashTags] = useState([]);

  const toggleShareMenu = () => {
    setButtonsActive(!shareButtonsActive);
  }

  useEffect(() => {
    if (props.applicationData && props.record) {
      setShareableURL(`https://${props.applicationData.primaryDNS}/manifestations/${props.record.recordID}`);
    }
  }, [props.applicationData, props.record])

  useEffect(() => {
    if (props.applicationData) {
      setTwitterHashTags(getHashtagFromName())
    }
  }, [props.applicationData])

  useEffect(() => {
    // console.log(getTwitterAccount())
    if (props.socialLinks) {
      setTwitterAccount(getTwitterAccount())
    }
  }, [props.socialLinks])

  const getTwitterAccount = () => {
    const accounts = props.socialLinks;
    const filtered = accounts.filter(account => (account.key === "twitter"))
    if (filtered && filtered.length > 0) {
      const array = filtered[0].value.split("/")
      return array[3]
    }
  }

  const getHashtagFromName = () => {
    const name = props.applicationData.publishedTitle;
    if (name !== '' && name !== undefined) {
      const array = [name.replace(/\s/g, '_'), name.replace(/\s/g, '')];
      return array
    }
  }

  const handleCopyToClipboard = (string) => {
    // navigator.clipboard.writeText(string)
    copy(string);

    setButtonsActive(!shareButtonsActive);
    toast.success("A sharable link to the item has been copied sucuessfully to the clipboard")
  }

  return (
    <Fragment>
      {/* ---- Event & Radial ---- */}
      {(props.isEvent && props.isRadial) &&
        <div className="shareButton-center">
          <div className={shareButtonsActive ? "share-menu active" : "share-menu"}>
            <div className="share-btn" onClick={() => toggleShareMenu()}>
              <FontAwesomeIcon className="share-btn-share" icon={['fal', 'share-alt']} title="Share event" />
              <FontAwesomeIcon className="share-btn-close" icon={['fal', 'times']} title="Close" />
            </div>
            <div className="social-btn" title="Copy to the clipboard">
              <FontAwesomeIcon
                onClick={() => { handleCopyToClipboard(props.event.url) }}
                icon={['fad', 'copy']} />
            </div>
            <div className="social-btn" title="Email about the event">
              <EmailShareButton
                subject={`${props.applicationData.publishedTitle} event: " ${props.event.name} "`}
                url={props.event.url}
                body="Check out this event: "
                beforeOnClick={() => toggleShareMenu()}
              >
                <FontAwesomeIcon icon={['fad', 'envelope']} />
              </EmailShareButton>
            </div>
            <div className="social-btn" title="Share the event via WhatsApp">
              <WhatsappShareButton
                url={props.event.url}
                title={`Check out this awesome event " ${props.event.eventTitle} " from ${props.applicationData.publishedTitle}`}
                beforeOnClick={() => toggleShareMenu()}
              >
                <FontAwesomeIcon icon={['fab', 'whatsapp']} />
              </WhatsappShareButton>
            </div>
            <div className="social-btn" title="Tweet about the event">
              <TwitterShareButton
                url={props.event.url}
                title={props.event.name}
                via={twitterHashTags}
                hashtags={[twitterHashTags, `${twitterHashTags}_Events`]}
                beforeOnClick={() => toggleShareMenu()}
              >
                <FontAwesomeIcon icon={['fab', 'twitter']} />
              </TwitterShareButton>
            </div>
            <div className="social-btn" title="Post on Facebook about the event">
              <FacebookShareButton
                url={props.event.url}
                quote={"Check this awesome event out!"}
                hashtag={`#${twitterHashTags}_Events`}
                beforeOnClick={() => toggleShareMenu()}
              >
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              </FacebookShareButton>
            </div>
            <div className="social-btn"></div>
          </div>
        </div>
      }

      {/* ---- Event & Linear ---- */}
      {(props.isEvent && props.isLinear) &&
        <div aria-haspopup="true" aria-expanded={shareButtonsActive ? "true" : "false"} className={shareButtonsActive ? "shareButton-outer active" : "shareButton-outer"}>
          <button className="share-btn" onClick={() => toggleShareMenu()} style={{ top: "-7px", height: "48px", width: "70px" }}>
            <FontAwesomeIcon className="inter-arrow" style={{ marginRight: "5px" }} icon={['fas', 'caret-left']} />
            <FontAwesomeIcon className="share-btn-share" icon={['fal', 'share-alt']} title="Open sharing-buttons panel" />
          </button>
          {shareButtonsActive &&
            <FocusTrap
              active={shareButtonsActive}
              focusTrapOptions={{
                onDeactivate: () => toggleShareMenu(),
                initialFocus: false,
                clickOutsideDeactivates: true,
                escapeDeactivates: false,
              }}
            >
              <div aria-hidden={shareButtonsActive ? "false" : "true"} className={shareButtonsActive ? "share-menu-linear active" : "share-menu-linear"}>
                <button className="share-btn" onClick={() => toggleShareMenu()} style={{ width: "55px" }}>
                  <FontAwesomeIcon className="share-btn-close" icon={['fal', 'times']} title="Close sharing-buttons panel" />
                </button>
                <button className="social-btn" title="Copy event's URL to the clipboard">
                  <FontAwesomeIcon onClick={() => { handleCopyToClipboard(props.event.url) }} icon={['fad', 'copy']} />
                </button>
                <div className="social-btn" title="Email about the event">
                  <EmailShareButton
                    subject={`${props.applicationData.publishedTitle} event: " ${props.event.name} "`}
                    url={props.event.url}
                    body="Check out this event: "
                    beforeOnClick={() => toggleShareMenu()}
                  >
                    <FontAwesomeIcon icon={['fad', 'envelope']} />
                  </EmailShareButton>
                </div>
                <div className="social-btn" title="Tweet about the event">
                  <TwitterShareButton
                    url={props.event.url}
                    title={props.event.name}
                    via={twitterHashTags}
                    hashtags={[twitterHashTags, `${twitterHashTags}_Events`]}
                    beforeOnClick={() => toggleShareMenu()}
                  >
                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                  </TwitterShareButton>
                </div>
                <div className="social-btn" title="Post on Facebook about the event">
                  <FacebookShareButton
                    url={props.event.url}
                    quote={"Check this awesome event out!"}
                    hashtag={`#${twitterHashTags}_Events`}
                    beforeOnClick={() => toggleShareMenu()}
                  >
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                  </FacebookShareButton>
                </div>
                <div className="social-btn" title="Share the event with your friends on WhatsApp">
                  <WhatsappShareButton
                    url={props.event.url}
                    title={`Check out this awesome event " ${props.event.eventTitle} " from ${props.applicationData.publishedTitle}`}
                    beforeOnClick={() => toggleShareMenu()}
                  >
                    <FontAwesomeIcon icon={['fab', 'whatsapp']} />
                  </WhatsappShareButton>
                </div>
              </div>
            </FocusTrap>
          }
        </div>
      }

      {/* ---- Blog & Radial ---- */}
      {(props.isBlog && props.isRadial) &&
        <div className="shareButton-center">
          <div className={shareButtonsActive ? "share-menu active" : "share-menu"}>
            <div className="share-btn" onClick={() => toggleShareMenu()}>
              <FontAwesomeIcon className="share-btn-share" icon={['fal', 'share-alt']} title="Share" />
              <FontAwesomeIcon className="share-btn-close" icon={['fal', 'times']} title="Close" />
            </div>
            <div className="social-btn">

            </div>
            <div className="social-btn" title="Copy to the clipboard">
              <FontAwesomeIcon
                onClick={() => { handleCopyToClipboard(props.blog.url) }}
                icon={['fad', 'copy']} />
            </div>
            <div className="social-btn" title="Email the blog post to a friend">
              <EmailShareButton
                subject={`Sutton blog post: " ${props.blog.title} "`}
                url={props.blog.url}
                body="Check out this blog post: "
                beforeOnClick={() => toggleShareMenu()}
              >
                <FontAwesomeIcon icon={['fad', 'envelope']} />
              </EmailShareButton>
            </div>
            <div className="social-btn" title="Tweet about the Review">
              <TwitterShareButton
                url={props.blog.url}
                title={props.blog.title}
                via='SuttonLibraries'
                hashtags={['SuttonLibraries', 'SuttonReviews']}
                beforeOnClick={() => toggleShareMenu()}
              >
                <FontAwesomeIcon icon={['fab', 'twitter']} />
              </TwitterShareButton>
            </div>
            <div className="social-btn" title="Post on Facebook about the Review">
              <FacebookShareButton
                url={props.blog.url}
                quote={"Check this awesome Review out!"}
                hashtag="#sutton_cultural_services_reviews"
                beforeOnClick={() => toggleShareMenu()}
              >
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              </FacebookShareButton>
            </div>
            <div className="social-btn">

            </div>
          </div>
        </div>
      }

      {/* ---- Blog & Linear ---- */}
      {(props.isBlog && props.isLinear) &&
        <div className={shareButtonsActive ? "shareButton-outer active" : "shareButton-outer"}>
          <div className="share-btn" onClick={() => toggleShareMenu()}>
            <FontAwesomeIcon className="share-btn-share" icon={['fal', 'share-alt']} title="Share" />
            <FontAwesomeIcon className="share-btn-close" icon={['fal', 'times']} title="Close" />
          </div>
          <div className={shareButtonsActive ? "share-menu-linear active" : "share-menu-linear"}>
            <div className="social-btn" title="Copy to the clipboard">
              <FontAwesomeIcon
                onClick={() => { handleCopyToClipboard(props.blog.url) }}
                icon={['fad', 'copy']} />
            </div>
            <div className="social-btn" title="Email the blog post to a friend">
              <EmailShareButton
                subject={`Sutton blog post: " ${props.blog.title} "`}
                url={props.blog.url}
                body="Check out this blog post: "
                beforeOnClick={() => toggleShareMenu()}
              >
                <FontAwesomeIcon icon={['fad', 'envelope']} />
              </EmailShareButton>
            </div>
            <div className="social-btn" title="Tweet about the Review">
              <TwitterShareButton
                url={props.blog.url}
                title={props.blog.title}
                via='SuttonLibraries'
                hashtags={['SuttonLibraries', 'SuttonReviews']}
                beforeOnClick={() => toggleShareMenu()}
              >
                <FontAwesomeIcon icon={['fab', 'twitter']} />
              </TwitterShareButton>
            </div>
            <div className="social-btn" title="Post on Facebook about the Review">
              <FacebookShareButton
                url={props.blog.url}
                quote={"Check this awesome Review out!"}
                hashtag="#sutton_cultural_services_reviews"
                beforeOnClick={() => toggleShareMenu()}
              >
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              </FacebookShareButton>
            </div>
          </div>
        </div>
      }

      {/* ---- Book & Linear ---- */}
      {(props.isBook && props.isLinear) &&
        <div aria-haspopup="true" aria-expanded={shareButtonsActive ? "true" : "false"} className={shareButtonsActive ? "shareButton-outer active" : "shareButton-outer"}>
          <button className="share-btn" onClick={() => toggleShareMenu()} style={{ top: "-7px", height: "48px", width: "70px" }}>
            <FontAwesomeIcon className="inter-arrow" style={{ marginRight: "5px" }} icon={['fas', 'caret-left']} />
            <FontAwesomeIcon className="share-btn-share" icon={['fal', 'share-alt']} title="Open sharing-buttons panel" />
          </button>
          {shareButtonsActive &&
            <FocusTrap
              active={shareButtonsActive}
              focusTrapOptions={{
                onDeactivate: () => toggleShareMenu(),
                initialFocus: false,
                clickOutsideDeactivates: true,
                escapeDeactivates: false,
              }}
            >
              <div aria-hidden={shareButtonsActive ? "false" : "true"} className={shareButtonsActive ? "share-menu-linear active" : "share-menu-linear"}>
                <button className="share-btn" onClick={() => toggleShareMenu()} style={{ width: "55px" }}>
                  <FontAwesomeIcon className="share-btn-close" icon={['fal', 'times']} title="Close sharing-buttons panel" />
                </button>
                <button className="social-btn" title="Copy title's URL to the clipboard">
                  <FontAwesomeIcon onClick={() => { handleCopyToClipboard(shareableURL) }} icon={['fad', 'copy']} />
                </button>
                <div className="social-btn" title="Suggest the title to a friend by Email">
                  <EmailShareButton
                    subject={`${props.applicationData.publishedTitle} title: " ${props.record.title} "`}
                    url={`${shareableURL}`}
                    body={`Check out this title from ${props.applicationData.publishedTitle} `}
                    beforeOnClick={() => toggleShareMenu()}
                    separator='>>> '
                    openShareDialogOnClick={true}
                  >
                    <FontAwesomeIcon icon={['fad', 'envelope']} />
                  </EmailShareButton>
                </div>
                <div className="social-btn" title="Tweet about the title">
                  <TwitterShareButton
                    url={`${shareableURL}`}
                    title={`Check out this title " ${props.record.title} " from ${props.applicationData.publishedTitle}`}
                    via={twitterAccount ? twitterAccount : ''}
                    hashtags={twitterHashTags ? twitterHashTags : []}
                    beforeOnClick={() => toggleShareMenu()}
                  >
                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                  </TwitterShareButton>
                </div>
                <div className="social-btn" title="Post on Facebook about the title">
                  <FacebookShareButton
                    url={`${shareableURL}`}
                    quote={"Check out this awesome title!"}
                    hashtag={twitterHashTags ? `#${twitterHashTags[0]}` : ''}
                    beforeOnClick={() => toggleShareMenu()}
                  >
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                  </FacebookShareButton>
                </div>
                <div className="social-btn" title="Share the title on WhatsApp">
                  <WhatsappShareButton
                    url={`${shareableURL}`}
                    title={`Check out this awesome title " ${props.record.title} " from ${props.applicationData.publishedTitle}`}
                    beforeOnClick={() => toggleShareMenu()}
                  >
                    <FontAwesomeIcon icon={['fab', 'whatsapp']} />
                  </WhatsappShareButton>
                </div>
              </div>
            </FocusTrap>
          }
        </div>
      }
    </Fragment>
  )
}

export default SocialShareButton;