import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const CloseButton = (props) => {
  const { styleClassName, action, size, title } = props;
  return (
    <button className={styleClassName} onClick={action} aria-label={title} title={title}>
      <FontAwesomeIcon icon={['fal', 'times']} size={size} />
    </button>
  );
}

export default CloseButton;