import React, { useState, useEffect } from 'react'

export default function Avatars(props) {
  const [avatarsArray, setAvatarsArray] = useState([])

  useEffect(() => {
    let localArray = []
    for (let index = 1; index < 100; index++) {
      let element = {}
      element.id = `avatarID-${index}`
      element.path = `/avatars/avatar_${index}.svg`
      localArray.push(element)
    }
    setAvatarsArray(localArray)
    return () => {
      setAvatarsArray([])
    }
  }, [])

  return (
    <div className="avatars-container">
      {avatarsArray.map(avatar => {
        return (
          <button key={avatar.id}
            onClick={() => { props.setSelectedAvatar(avatar.path); props.setClearedAvatar(false) }}
            className={(props.selectedAvatar === `${avatar.path}`) ? "avatar-inner avatar-chosen" : "avatar-inner"} >
            <img className="image-fit" src={avatar.path} alt={avatar.id} />
          </button>
        )
      })}
    </div>
  )
}