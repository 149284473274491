
import React, { useState, useEffect, useContext, Fragment } from 'react';
import './LibraryServices.css';
import { useHistory, useLocation } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'bootstrap/dist/css/bootstrap-utilities.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ServicesBar from '../common/carousel-navbars/ServicesBar';
import ServicesCatBar from '../common/carousel-navbars/ServicesCatBar';
import ServicesSubBar from '../common/carousel-navbars/ServicesSubBar';

import Meta from '../common/Meta';

// import NewsCard from '../common/news-card/NewsCard';
// import FacebookCard from '../common/facebook-card/FacebookCard';
// import TweetsCard from '../common/tweets-card/TweetsCard';
// import libraryData from '../../suttonLibraryData.json';

import { AuthContext } from '../../AuthContext';


const LibraryServices = (props) => {
  const { applicationData, appID, digitalContentTab, parentTabs, isTabletOrMobile, libraryOGimage, axiosInstance, jacketsWidth } = useContext(AuthContext);

  const history = useHistory();
  const urlString = useLocation();

  // set the initial ServiceBar-Item preselected item is the first item in the object by default
  const [root, setRoot] = useState([]);
  const [pageTitle, setPageTitle] = useState('');

  const [channelExtraction, setChannelExtraction] = useState([]);
  const [channelContent, setChannelContent] = useState('');

  const [selectedSubcategory, setSelectedSubcategory] = useState([]);
  const [selectedThirdLevel, setSelectedThirdLevel] = useState([]);
  const [secondRowSelectedID, setSecondRowSelectedID] = useState('');
  const [secondRowShrinked, setSecondRowShrinked] = useState(false);

  const [loadingLevelOne, setLoadingLevelOne] = useState(false);
  const [loadingLevelTwo, setLoadingLevelTwo] = useState(false);
  const [loadingLevelThree, setLoadingLevelThree] = useState(false);

  // set the initial category to branch from the default root 
  const [categories, setCategories] = useState([]);

  // set the initial subCategory to branch from the default category 
  const [subcategories, setSubcategories] = useState([]);

  // to hold the selected tab's name when coming from Bookshelf
  const [selectedParentTab, setSelectedParentTab] = useState('');

  // for the arrow below the first row's selected item
  const [selected, setSelected] = useState(false);

  const [selectedItemId, setSelectedItemId] = useState('');
  const [selectedIDForLoading, setSelectedIDForLoading] = useState('');

  const [levelOneCanonicalTitle, setLevelOneCanonicalTitle] = useState('');
  const [levelTwoCanonicalTitle, setLevelTwoCanonicalTitle] = useState('');
  const [levelThreeCanonicalTitle, setLevelThreeCanonicalTitle] = useState('');

  const stripOffTheURL = (URLfrag) => {
    let urlArray = URLfrag.split('/')
    let title = `${applicationData.publishedTitle}`

    if (urlArray && urlArray.length > 0) {
      const levelOne = urlArray[2];
      const levelTwo = urlArray[3];
      const levelThree = urlArray[4];
      if (levelOne) {
        setLevelOneCanonicalTitle(levelOne)
        title += " | " + getTitlePortion(1, levelOne)
        if (levelTwo) {
          setLevelTwoCanonicalTitle(levelTwo)
          title += " | " + getTitlePortion(2, levelTwo)
          if (levelThree) {
            setLevelThreeCanonicalTitle(levelThree)
            title += " | " + getTitlePortion(3, levelThree)
          }
        }
        setPageTitle(title)
      } else {
        handleNoURLfragment();
      }
    }
  }

  const getTitlePortion = (level, canonicalTitle) => {
    if (level === 1) {
      const tab = parentTabs.filter(item => item.canonicalTitle === canonicalTitle)
      if (tab && tab.length > 0) {
        return tab[0].title
      }
    } else if (level === 2) {
      const tab = categories.filter(item => item.canonicalTitle === canonicalTitle)
      if (tab && tab.length > 0) {
        return tab[0].title
      }
    } else if (level === 3) {
      const tab = subcategories.filter(item => item.canonicalTitle === canonicalTitle)
      if (tab && tab.length > 0) {
        return tab[0].title
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0); // to activate the navbar highlighting function
  }, []);

  useEffect(() => {
    if (parentTabs !== undefined && parentTabs !== [] && parentTabs.length > 0 && props.location.pathname) {
      // console.log(props.location.pathname)
      stripOffTheURL(props.location.pathname);
    }
  }, [parentTabs, props.location.pathname]);

  // useEffect(() => {
  //   if (window.location.href) {
  //     const urlElements = window.location.href.split('/')
  //     // console.table(urlElements)
  //     stripOffTheURL(window.location.href);
  //   }
  // }, [window.location.href]);

  const metaData = {
    title: `${pageTitle}`,
    description: `Welcome to ${applicationData.publishedTitle} | Digital Content`,
    type: 'website',
    image: libraryOGimage,
    canonical: ''
  }

  // to fulfil the URL fragment 2
  useEffect(() => {
    if (levelTwoCanonicalTitle && categories.length > 0) {
      // console.log("Categories: " + categories)
      const tab = categories.filter(item => item.canonicalTitle === levelTwoCanonicalTitle)
      if (tab.length > 0) {
        const id = tab[0].id
        const goto = tab[0]
        // console.log('Found Level-Two: ' + goto.canonicalTitle + ' id: ' + id)
        getSecondLevel(id, goto)
      }
    }
  }, [categories]);

  // to fulfil the URL fragment 3
  useEffect(() => {
    // if (levelThreeCanonicalTitle && subcategories.length > 0) {
    // console.log("Sub-Categories: " + subcategories)
    const tab = subcategories.filter(item => item.canonicalTitle === levelThreeCanonicalTitle)
    if (tab.length > 0) {
      const id = tab[0].id
      const goto = tab[0]
      // console.log('Found Level-Three: ' + goto.canonicalTitle + ' id: ' + id)
      getThirdLevel(id, goto)
    }
    // }
  }, [subcategories]);

  useEffect(() => {
    if (levelOneCanonicalTitle) {
      // console.log('levelOneCanonicalTitle is ' + levelOneCanonicalTitle)
      handleURLfragment();
    }
  }, [levelOneCanonicalTitle]);

  useEffect(() => {
    if (Object.keys(channelExtraction).length > 0) {
      setChannelContent(getDataFromAChannel())
    } else {
      setChannelContent([]) // to clear the content when navigate to other tabs
    }
  }, [channelExtraction])

  // function to set the categories based on the Services-Bar selection
  const getFirstLevel = async (id, categorySelection) => {
    setLoadingLevelOne(true);
    // console.log('received ' + categorySelection.canonicalTitle);
    setSelected(true); // for animation on the first row

    // update the URL
    setLevelOneCanonicalTitle(categorySelection.canonicalTitle);
    history.push({
      pathname: `/${digitalContentTab.canonicalTitle}/${categorySelection.canonicalTitle}/`
    })
    // console.log('changed 1 ' + urlString.pathname)

    setRoot(categorySelection)
    setSelectedParentTab(categorySelection.canonicalTitle)
    // console.log('found ' + root + ' and ID: ' + root.id);
    await axiosInstance.get(`/Tabs/${id}/children`).then((response) => {
      if (response.status === 200) {
        const filtered = response.data.filter(item => (item.authVisibility !== 'neverauth' || item.authVisibility === null))
        setCategories(filtered)
        setLoadingLevelOne(false)
      } else {
        console.error(response.message);
        setLoadingLevelOne(false);
      }
    })

    if (categorySelection.tabDetail) {
      if (categorySelection.target) {
        // console.log(categorySelection.target)
        return
      }
      // console.log(categorySelection)
      setChannelExtraction(categorySelection.tabDetail)
    }
    setSubcategories([])
    setSelectedSubcategory([])
    setSelected(false)
    setSecondRowShrinked(false)
    setSelectedItemId('')
    setSelectedThirdLevel([])
    setSecondRowSelectedID('')
  }

  // function to set subcategories based on category selection
  const getSecondLevel = async (id, subCategorySelection) => {
    setSelectedIDForLoading(id);
    setLoadingLevelTwo(true);
    setSubcategories([]);
    setSelectedItemId(id); // assigned here to get the CSS ready early and again below to stay

    // update the URL
    setLevelTwoCanonicalTitle(subCategorySelection.canonicalTitle);
    history.push({
      pathname: `/${digitalContentTab.canonicalTitle}/${levelOneCanonicalTitle}/${subCategorySelection.canonicalTitle}/`
    })
    // console.log('changed 2 ' + urlString.pathname)

    await axiosInstance.get(`/Tabs/${id}/children`).then((response) => {
      if (response.status === 200) {
        const filtered = response.data.filter(item => (item.authVisibility !== 'neverauth' || item.authVisibility === null))
        setSubcategories(filtered)
        setLoadingLevelTwo(false);
      } else {
        console.error(response.message);
        setLoadingLevelTwo(false);
      }
    })

    if (subCategorySelection.tabDetail) {
      setChannelExtraction(subCategorySelection.tabDetail)
    }

    setRoot([])
    setSelectedSubcategory(subCategorySelection);
    setSelected(true)
    setSelectedItemId(id)
    setSelectedThirdLevel([])
    setSecondRowSelectedID('')
    setSecondRowShrinked(false)
  }

  const getDataFromAChannel = () => {
    // console.log("Pass")
    return (
      <div className="row justify-content-center">
        <div className="col-md-12 mb-2">
          {channelExtraction.title &&
            <h1 className="channel-title">{channelExtraction.title}</h1>
          }
          {channelExtraction.additionalContent &&
            <div className="mb-2" dangerouslySetInnerHTML={{ __html: channelExtraction.additionalContent }} />
          }
        </div>
        <div className="col-lg-3 col-md-6 mb-5">
          {channelExtraction.openHours &&
            <Fragment>
              <h3>Opening Hours</h3>
              <ul className="lib-hours-list">
                {channelExtraction.openHours.map((line, index) => (
                  <li key={`library-opening-hour-${index}`}><b>{line.name}:</b> {line.hours === 0 ? <Fragment>Closed</Fragment> : <Fragment>
                    {line.opens} - {line.closes}</Fragment>}</li>
                ))}
              </ul>
            </Fragment>
          }
        </div>
        <div className="col-lg-3 col-md-6 mb-5">
          <dl>
            {channelExtraction.phoneNumber &&
              <Fragment>
                <dt>Telephone</dt>
                <dd><a href={`tel:${channelExtraction.phoneNumber}`}>{channelExtraction.phoneNumber}</a></dd>
              </Fragment>
            }
            {channelExtraction.emailAddress &&
              <Fragment>
                <dt>E-mail Address</dt>
                <dd><a href={`mailto:${channelExtraction.emailAddress}`}>{channelExtraction.emailAddress}</a></dd>
              </Fragment>
            }
            {channelExtraction.webAddress &&
              <Fragment>
                <dt>Website</dt>
                <dd><a href={channelExtraction.webAddress}>{channelExtraction.webAddress}</a></dd>
              </Fragment>
            }
            {channelExtraction.address &&
              <Fragment>
                <dt>Address</dt>
                <dd><pre>{channelExtraction.address}</pre></dd>
              </Fragment>
            }
          </dl>
        </div>
        <div className="col-lg-6 col-md-12">
          {(channelExtraction.locationLat && channelExtraction.locationLng) &&
            <iframe src={`https://maps.google.com/maps?q=${channelExtraction.locationLat},${channelExtraction.locationLng}&z=15&output=embed`} width="100%" height="100%" title="Library location on Google-Maps" aria-hidden="false"></iframe>
          }
        </div>
      </div>
    )
  }

  // function to set third level's children based on the subcategory selection
  const getThirdLevel = async (id, ThirdLevelSelection) => {
    setSelectedIDForLoading(id);
    setLoadingLevelThree(true);
    setSecondRowSelectedID(id); // assigned here to get the CSS ready early and again below to stay

    // update the URL
    setLevelThreeCanonicalTitle(ThirdLevelSelection.canonicalTitle);
    history.push({
      pathname: `/${digitalContentTab.canonicalTitle}/${levelOneCanonicalTitle}/${levelTwoCanonicalTitle}/${ThirdLevelSelection.canonicalTitle}/`
    })
    // console.log('changed 3 ' + urlString.pathname)

    await axiosInstance.get(`/Tabs/${id}`).then((response) => {
      if (response.status === 200) {
        if (response.data.tabDetail) {
          setChannelExtraction(response.data.tabDetail)
        } else {
          setSelectedThirdLevel(response.data)
        }
        setLoadingLevelThree(false);

        setSelectedSubcategory([]);
      } else {
        console.error(response.message);
        setLoadingLevelThree(false);
      }
    })
    // setSelectedItemId(id)
    setSecondRowSelectedID(id);
    setSelectedThirdLevel(ThirdLevelSelection);
    setSecondRowShrinked(true);
  }

  const handleURLfragment = () => {
    // console.log('levelOneCanonicalTitle is ' + levelOneCanonicalTitle);
    const tab = parentTabs.filter(item => item.canonicalTitle === levelOneCanonicalTitle)
    if (tab.length > 0) {
      // console.log('found' + tab[0])
      const id = tab[0].id;
      const goto = tab[0];
      // console.log('Found levelOne: ' + id)
      getFirstLevel(id, goto);
      setSelectedParentTab(goto.canonicalTitle);
      // getSecondLevel(tab[0].id, tab[0])
    } else {
      handleNoURLfragment();
    }
  }

  const handleNoURLfragment = () => {
    const category = parentTabs[0] // at least one parent-tab item is there
    if (category !== undefined && appID !== undefined) {
      // find the libraries 
      const filteredCat = parentTabs.filter(cat => cat.canonicalTitle === "libraries")
      const cat = filteredCat[0]

      if (cat !== undefined) {
        getFirstLevel(cat.id, cat);
      } else {
        // if there was no Libraries item then select the first item of the parent-tabs
        getFirstLevel(parentTabs[0].id, parentTabs[0]);
      }
    }
  }

  return (
    <Fragment>
      <Meta data={metaData} />
      <div className="services-container" role="main">
        <div className="lsp-positioner-srv">
          <h1 className="visuallyhidden">{applicationData.publishedTitle} Digital Content</h1>

          {parentTabs.length > 0 &&
            <ServicesBar
              tabIsLoading={loadingLevelOne}
              tabWidth={isTabletOrMobile ? "100" : jacketsWidth + 3}
              action={getFirstLevel}
              setLevelTwoCanonicalTitle={setLevelTwoCanonicalTitle}
              setLevelThreeCanonicalTitle={setLevelThreeCanonicalTitle}
              selectedTab={selectedParentTab}
              setChannelExtraction={setChannelExtraction}
              source={parentTabs}
            />
          }
        </div>

        {(root && Object.keys(root).length !== 0 && !loadingLevelOne && root.contentText !== '') &&
          <div className={!selected ? "upper-div" : "upper-div folded"} dangerouslySetInnerHTML={{ __html: root.contentText }} />
        }

        {(root && Object.keys(root).length !== 0 && channelContent && channelContent !== '') &&
          <div className={!selected ? "upper-div" : "upper-div folded"}>{channelContent}</div>
        }

        {(categories.length > 0 && !loadingLevelOne) &&
          <div className={selected ? "first-row-outer collapsed" : "first-row-outer"}>
            <ServicesCatBar
              categories={categories}
              tabWidth={isTabletOrMobile ? "100" : jacketsWidth}
              selectedIDForLoading={selectedIDForLoading}
              loadingLevelTwo={loadingLevelTwo}
              action={getSecondLevel}
              selectedItemId={selectedItemId}
              setLevelThreeCanonicalTitle={setLevelThreeCanonicalTitle}
              levelOneCanonicalTitle={levelOneCanonicalTitle}
              setChannelExtraction={setChannelExtraction}
            />
          </div>
        }

        <div className={selected ? "collapsible-div expanded" : "collapsible-div"}>

          <div className={(!selectedSubcategory.contentText && !loadingLevelTwo && !loadingLevelOne) ? "middle-div folded" : "middle-div"} dangerouslySetInnerHTML={{ __html: selectedSubcategory.contentText }} />

          {(Object.keys(selectedSubcategory).length !== 0 && channelContent) &&
            <div className="middle-div">
              {channelContent}
            </div>
          }

          {/* if none selected then to scroll down with selection */}
          {(subcategories && subcategories.length > 0 && !loadingLevelTwo && !loadingLevelOne) &&
            <div className="second-row-outer">
              <ServicesSubBar
                subcategories={subcategories}
                tabWidth={isTabletOrMobile ? "100" : jacketsWidth}
                selectedIDForLoading={selectedIDForLoading}
                loadingLevelThree={loadingLevelThree}
                action={getThirdLevel}
                levelOneCanonicalTitle={levelOneCanonicalTitle}
                levelTwoCanonicalTitle={levelTwoCanonicalTitle}
                secondRowSelectedID={secondRowSelectedID}
                secondRowShrinked={secondRowShrinked}
                setChannelExtraction={setChannelExtraction}
              />
            </div>
          }


          <div className={(!loadingLevelThree && !loadingLevelTwo && !loadingLevelOne && !selectedThirdLevel.contentText) ? "lower-div folded" : "lower-div"} dangerouslySetInnerHTML={{ __html: selectedThirdLevel.contentText }} />


          {(Object.keys(selectedThirdLevel).length !== 0 && channelContent) &&
            <div className="lower-div">
              {channelContent}
            </div>
          }

        </div>
      </div>

      {/* ----------- the rows below ------------- */}
      {/* <div className="rows-container"> */}
      {/* <Recommendations isLibraryRecommendations isTabletOrMobile={isTabletOrMobile} scrollToTheMiddle={false} /> */}
      {/* <Recommendations isGlobalRecommendations isTabletOrMobile={isTabletOrMobile} scrollToTheMiddle={false} /> */}
      {/* <Recommendations isUserRecommendations isTabletOrMobile={isTabletOrMobile} scrollToTheMiddle={false} /> */}

      {/* News */}
      {/* <h2 className="cards-row-title">Sutton latest news</h2>
        <div className="cards-row">
          {libraryData.newsFeed.map((article, index) => (
            <NewsCard article={article} key={`news-${index}`}></NewsCard>
          ))}
        </div> */}
      {/* Facebook */}
      {/* <h2 className="cards-row-title">Latest Facebook feed</h2>
        <div className="cards-row">
          {libraryData.facebookFeed.map((fbpost, index) => (
            <FacebookCard fbpost={fbpost} key={`FB-${index}`}></FacebookCard>
          ))}
        </div> */}
      {/* Twitter */}
      {/* <h2 className="cards-row-title">Latest Twitter feed</h2>
        <div className="cards-row">
          {libraryData.tweetsFeed.map((tweet, index) => (
            <TweetsCard tweet={tweet} key={`TW-${index}`}></TweetsCard>
          ))}
        </div> */}
      {/* </div> */}

    </Fragment>
  );
}

export default LibraryServices;