import React, { Component, Fragment } from 'react';
import './Navbar.css';
import { NavLink, withRouter } from 'react-router-dom';
import FocusTrap from 'focus-trap-react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../../AuthContext';

class SideMenu extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      visible: false,
      searchBoxValue: ''
    };
    // this.handleClick = this.handleClick.bind(this);
  }

  onChangeHandler = (event) => {
    var userInput = event.target.value.replace()
    this.setState({ searchBoxValue: userInput });
  }

  onSubmit = (event) => {
    event.preventDefault();
    // alert(this.state.searchBoxValue);
    if (this.state.searchBoxValue === '') {
      return this.setState({ visible: false });
    }
    this.props.history.push({
      pathname: `/search/${this.state.searchBoxValue}/`
    });
    this.props.closingSideMenuHandler();
  }

  render() {
    const { user, isAuthenticated, toast, wildCard, eventsTab, digitalContentTab, bookshelfTab, userAccountTab, searchTab, logOut,
      setModalLoginStatus, setModalContactUsStatus, checkCurrentUserStatus, patronFields } = this.context;

    const handleSignUpModal = () => {
      if (Object.keys(patronFields).length > 0) {
        this.props.history.push({ pathname: `/user/register` });
        this.props.closingSideMenuHandler();
      } else {
        toast.info("Sorry, registration is not open yet for this web app.")
      }
    }

    return (
      <FocusTrap
        focusTrapOptions={{
          onDeactivate: () => this.props.closingSideMenuHandler(),
          initialFocus: false,
          clickOutsideDeactivates: true,
          escapeDeactivates: false,
        }}
      >
        <div style={{ width: "100%" }}>
          {/* Close button */}
          <button className="menu-icon" title="Close the side menu" style={{ color: "var(--primary)" }} onClick={() => this.props.closingSideMenuHandler()}>
            <FontAwesomeIcon icon={["far", "times"]} />
          </button>

          {searchTab &&
            <Fragment>
              {/* Hide the search icons and field while on Search screen */}
              {this.props.match.params.handle ?
                <div style={{ color: "var(--navbar-icons-color)" }}>
                  <div className='active-nav'>
                    <section className='nav-links' style={{ cursor: "default" }}>
                      <FontAwesomeIcon icon={["fad", "search"]} />
                      <p className='mob-links-titles'>{searchTab.title}</p>
                    </section>
                  </div>
                </div> :
                <div className="search-box-wrap">
                  <div className='search-box-container mobile'>
                    <form id="search-form" onSubmit={this.onSubmit}>
                      {searchTab.buttonIconName ?
                        <FontAwesomeIcon onClick={this.onSubmit} icon={[searchTab.buttonIconName.substring(0, 3), searchTab.buttonIconName.substring(7)]} /> :
                        <FontAwesomeIcon onClick={this.onSubmit} icon={["fad", "search"]} />
                      }
                      <input id="search-box" name="search-box" autoComplete="off" onChange={this.onChangeHandler} value={this.state.searchBoxValue} placeholder="Search catalogue" type="text" />
                    </form>
                  </div>
                </div>
              }
            </Fragment>
          }

          {/* Bookshelf */}
          {bookshelfTab &&
            <NavLink
              to={`/${bookshelfTab.canonicalTitle}`}
              onClick={() => { this.setState({ searchBoxValue: '' }); this.props.closingSideMenuHandler(); checkCurrentUserStatus() }}
              style={{ color: "var(--navbar-icons-color)" }}
              activeClassName="active-nav"
            >
              <section className='nav-links mobile-links' >
                {bookshelfTab.buttonIconName ?
                  <FontAwesomeIcon icon={[bookshelfTab.buttonIconName.substring(0, 3), bookshelfTab.buttonIconName.substring(7)]} /> :
                  <FontAwesomeIcon icon={["fad", "books"]} />
                }
                <p className='mob-links-titles'>{bookshelfTab.title}</p>
              </section>
            </NavLink>
          }

          {/* Digital-Content (Libraries) */}
          {digitalContentTab &&
            <NavLink
              to={`/${digitalContentTab.canonicalTitle}`}
              onClick={() => { this.setState({ searchBoxValue: '' }); this.props.closingSideMenuHandler(); checkCurrentUserStatus() }}
              style={{ color: "var(--navbar-icons-color)" }}
              activeClassName="active-nav"
            >
              <section className='nav-links mobile-links' >
                {digitalContentTab.buttonIconName ?
                  <FontAwesomeIcon icon={[digitalContentTab.buttonIconName.substring(0, 3), digitalContentTab.buttonIconName.substring(7)]} /> :
                  <FontAwesomeIcon icon={["fad", "book-reader"]} />
                }
                <p className='mob-links-titles'>{digitalContentTab.title}</p>
              </section>
            </NavLink>
          }

          {/* Events */}
          {eventsTab &&
            <NavLink
              to={`/${eventsTab.canonicalTitle}`}
              onClick={() => { this.setState({ searchBoxValue: '' }); this.props.closingSideMenuHandler(); checkCurrentUserStatus() }}
              style={{ color: "var(--navbar-icons-color)" }}
              activeClassName="active-nav"
            >
              <section className='nav-links mobile-links' >
                {eventsTab.buttonIconName ?
                  <FontAwesomeIcon icon={[eventsTab.buttonIconName.substring(0, 3), eventsTab.buttonIconName.substring(7)]} /> :
                  <FontAwesomeIcon icon={["fad", "calendar-star"]} />
                }
                <p className='mob-links-titles'>{eventsTab.title}</p>
              </section>
            </NavLink>
          }

          {/* The Wildcard */}
          {wildCard &&
            <NavLink
              to={`/${wildCard.canonicalTitle}`}
              onClick={() => { this.setState({ searchBoxValue: '' }); this.props.closingSideMenuHandler(); checkCurrentUserStatus() }}
              style={{ color: "var(--navbar-icons-color)" }}
              activeClassName="active-nav"
              key={wildCard.id}
            >
              <section className='nav-links mobile-links' >
                {wildCard.buttonIconName ?
                  <FontAwesomeIcon icon={[wildCard.buttonIconName.substring(0, 3), wildCard.buttonIconName.substring(7)]} /> :
                  <FontAwesomeIcon icon={["fas", "star"]} />
                }
                <p className='mob-links-titles'>{wildCard.title}</p>
              </section>
            </NavLink>
          }

          {/* Patrons: My Account */}
          {(isAuthenticated && userAccountTab) &&
            <NavLink className='items-inside' activeClassName="active-nav" to="/user/account" style={{ color: "var(--navbar-icons-color)" }} onClick={() => { this.props.closingSideMenuHandler(); checkCurrentUserStatus() }}>
              <section className='nav-links mobile-links' title="My Account">
                <FontAwesomeIcon icon={['fas', 'cog']} />
                <p className='mob-links-titles'>{userAccountTab.title || "My Account"}</p>
              </section>
            </NavLink>
          }

          {/* Contact Us */}
          <button
            title="Contact us"
            className="items-inside"
            onClick={() => { this.props.closingSideMenuHandler(); setModalContactUsStatus(true); checkCurrentUserStatus() }}
            style={{ color: "var(--navbar-icons-color)" }}>
            <section className='nav-links mobile-links' title="Contact us">
              <FontAwesomeIcon icon={['fal', 'envelope']} />
              <p className='mob-links-titles'>Contact us</p>
            </section>
          </button>


          {/* Join In */}
          {!isAuthenticated &&
            <NavLink
              to={`/user/register`}
              onClick={() => { this.setState({ searchBoxValue: '' }); this.props.closingSideMenuHandler(); checkCurrentUserStatus() }}
              style={{ color: "var(--navbar-icons-color)" }}
              activeClassName="active-nav"
            >
              <section className='nav-links mobile-links' title="Join the library">
                <FontAwesomeIcon icon={['fas', 'address-card']} />
                <p className='mob-links-titles'>Join the library</p>
              </section>
            </NavLink>
          }


          {/* Log In */}
          {!isAuthenticated &&
            <button className="items-inside" onClick={() => { this.props.closingSideMenuHandler(); setModalLoginStatus(true) }}>
              <section className='nav-links mobile-links' title="Log in to your account">
                <FontAwesomeIcon icon={['fad', 'sign-in-alt']} />
                <p className='mob-links-titles'>Login</p>
              </section>
            </button>
          }

          {/* Patrons: Logout */}
          {isAuthenticated &&
            <button className="items-inside" onClick={() => { this.props.closingSideMenuHandler(); logOut(user.userID) }}>
              <section className='nav-links mobile-links' title="Sign out">
                <FontAwesomeIcon icon={['fad', 'sign-in-alt']} />
                <p className='mob-links-titles'>Logout</p>
              </section>
            </button>
          }

          {/* <div className="copyright">
                      <p>&copy; {new Date().getFullYear()} LUCi</p>
                    </div>
                */}
        </div>
      </ FocusTrap>
    )
  }
}


export default withRouter(SideMenu);