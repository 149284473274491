import React, { useState, useEffect, useContext, Fragment } from 'react'
import './BookJacket.css'
import { HashLink as Link } from 'react-router-hash-link';
import { AuthContext } from '../../../AuthContext';
import OuterAvailableFormats from './OuterAvailableFormats';
import OuterLoanWarnings from './OuterLoanWarnings';

const BookJacket = (props) => {
  const { setModalPayload, setModalStatus, jacketsWidth, jacketsHeight, isTabletOrMobile } = useContext(AuthContext);

  const [record, setRecord] = useState('');
  const [recordPubYear, setRecordPubYear] = useState('');

  const [loanDue, setLoanDue] = useState([]);
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    if (props.recordData) {
      setRecord(props.recordData)
    }
    if (props.isLoan && props.loanDetails) {
      var diffDays = getDifference(props.loanDetails.dueObject);
      var object = {}
      if (diffDays > 3) {
        object = { alert: 'normal', message: diffDays + ' days' }
        setLoanDue(object);
      } else if (diffDays > 0 && diffDays <= 3) {
        object = { alert: 'attention', message: 'Only ' + diffDays + ' days left!' }
        setLoanDue(object);
      } else if (diffDays === 0) {
        object = { alert: 'attention', message: 'Today!' }
        setLoanDue(object);
      } else {
        object = { alert: 'warning', message: 'Overdue' }
        setLoanDue(object);
      }
    }
    if (record) {
      getRecordPubYear();
      getRecordAvailability();
    }
  }, [record, props.isLoan, props.loanDetails, props.recordData])

  const activateClass = (event) => {
    event.preventDefault(); // we don't want the hyperlink to work here

    setModalStatus(true);
    setModalPayload({ ...props, recordPubYear, isAvailable, loanDue, canCancel: true, onCancel: hideModalBookHandler });
  };

  const hideModalBookHandler = () => {
    setModalStatus(false);
  }

  const getRecordPubYear = () => {
    if (!props.isBook && !props.isRecommendation && !record.mainMetadata) {
      return
    }
    const year = record.mainMetadata.filter(d => d.key === 'PUBDATE' || d.key === 'PERIOD_DATE');
    if (year.length > 0) {
      setRecordPubYear(year[0].value);
    }
  }

  const getRecordAvailability = () => {
    if (!props.isRecommendation && !record.copies) {
      return
    }
    if (record.copies && record.copies.length > 0) {
      const filteredAvailable = record.copies.filter(x => x.available === true);
      if (filteredAvailable.length > 0) {
        setIsAvailable(true);
      }
    }
  }

  const getDifference = (date) => {
    let today = new Date();
    var dueDate = new Date(date);
    today.setHours(0, 0, 0, 0);
    dueDate.setHours(0, 0, 0, 0);
    // console.log(today.getTime(), dueDate.getTime())
    if (today.getTime() === dueDate.getTime()) {
      return 0;
    } else {
      var Difference_In_Time = dueDate.getTime() - today.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      return Difference_In_Days.toFixed(0);
    }
  }

  return (
    <Link
      to={`/manifestations/${props.recordData.recordID}`}
      className={props.visible ? "card-container" : "card-container disabled-link"}
      onClick={(e) => activateClass(e, props.id)}
      title={record.title}
      id={props.id}
      style={isTabletOrMobile ? { minWidth: "105px", height: "160px" } : { minWidth: jacketsWidth + "px", height: jacketsHeight + "px" }}
      tabIndex={props.visible ? "" : "-1"}
    >
      {/* <div className="book-shading"></div> */}
      <div className="outer-left-container" style={{ width: jacketsWidth + "px" }}>
        {props.recordData.imageUrl !== '' ?
          <img style={!isTabletOrMobile ? { width: jacketsWidth + "px" } : {}} src={props.recordData.imageUrl} alt={`${record.title} book cover`} aria-describedby={`${record.recordID}_description_${props.index}`} /> :
          <img style={!isTabletOrMobile ? { width: jacketsWidth + "px" } : {}} src='/image-placeholder.jpg' alt={`${record.title} book cover`} aria-describedby={`${record.recordID}_description_${props.index}`} />}

        {/* rating stars when the bookjacket is still closed */}
        {/* {props.bookdata.volumeInfo.averageRating &&
            <div className="stars-container-wrap">
              <div className={`stars-container stars-${(props.bookdata.volumeInfo.averageRating) * 2}`}>☆☆☆☆☆</div>
              <span> {props.bookdata.volumeInfo.ratingsCount}</span>
              <img className="stars-background" src="/strip-rating.png" alt="strip-rating" />
            </div>
          } */}

        {/* accessibility description aid */}
        <div className="hidden-info" id={`${record.recordID}_description_${props.index}`}>
          {record.title && <Fragment> <h3>Title:</h3><p> {record.title} </p></Fragment>}
          {record.author && <Fragment> <h3>Author:</h3> <p>{record.author} </p></Fragment>}
          {record.format && <Fragment> <h3>Format:</h3> <p>{record.format} </p></Fragment>}
          {recordPubYear && <Fragment> <h3>Publication Year:</h3> <p>{recordPubYear} </p></Fragment>}
        </div>
      </div>

      {/* the format icons and publication year below the bookjacket  */}
      {props.isRecommendation &&
        <div className="icons-beneath">
          <div className="pub-year">
            {recordPubYear && <p>{recordPubYear}</p>}
          </div>
          <OuterAvailableFormats isRecommendation isAvailable={isAvailable} format={props.recordData.format} />
        </div>
      }

      {/* the warnings below the bookjacket for loans and reservations */}
      {((props.isLoan && props.loanDetails) || (props.isHold && props.holdDetails)) &&
        <OuterLoanWarnings {...props} loanDue={loanDue} />
      }

    </Link>
  )
};

export default BookJacket