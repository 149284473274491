import React, { Component, Fragment } from "react";

import { css } from "@emotion/core";
import { ScaleLoader } from "react-spinners";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { AuthContext } from "../../AuthContext";
import BookJacket from "./book-jackets/BookJacket";
// import TestJacket from "./book-jackets/TestJacket";
import Arrow from "./Arrow";

export default class Recommendations extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
    this.setArrowDisplay = this.setArrowDisplay.bind(this);
    this.setTheVisibleJacketsArray = this.setTheVisibleJacketsArray.bind(this);
    this.getMiniJacketsCount = this.getMiniJacketsCount.bind(this);

    this.state = {
      activeIndex: 0,
      displayRightArrow: false,
      displayLeftArrow: false,
      bookJacketsToScroll: 0,
      visibleJacketsArray: [],
      carouselLimit: 14 // initial items to load when first access the 
    };
  }

  setArrowDisplay = (currentSlide) => {
    const displayLeftArrow = currentSlide !== 0;
    const displayRightArrow = (currentSlide + this.context.jacketsCountPerCarousel) <= this.props.source.length;

    this.setState({ displayRightArrow, displayLeftArrow });
  };

  setTheVisibleJacketsArray = (startingIndex, arraylength) => {
    let localArray = []

    for (let index = 0; index < arraylength; index++) {
      localArray.push(startingIndex)
      startingIndex++
    }

    this.setState({ visibleJacketsArray: localArray });
  };

  componentDidMount() {
    this.setArrowDisplay(0);
    this.setTheVisibleJacketsArray(0, this.context.isTabletOrMobile ? this.getMiniJacketsCount() : this.context.jacketsCountPerCarousel);

    this.setState({
      bookJacketsToScroll:
        this.context.isTabletOrMobile ? 2 : this.context.tabsToShow - 2
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.source !== prevProps.source) {
      this.setArrowDisplay(0);
    }
    if (this.props.tabsToShow !== prevProps.tabsToShow) {
      this.setState({
        bookJacketsToScroll:
          this.context.isTabletOrMobile ? 2 : this.context.tabsToShow - 2
      });
    }
  }

  clickHandler = (direction) => {
    if (direction === "left") {
      this.slider.slickPrev();
    } else if (direction === "right") {
      this.slider.slickNext();
    }
  };

  getMiniJacketsCount = () => {
    const width = window.innerWidth
    return Math.floor(width / 110)
  }

  render() {
    const loaderCSS = css`
      position: absolute;
      opacity: 0.4;
      z-index: 99;
    `;

    const sliderSetting = {
      dots: false,
      arrows: false,
      accessibility: true,
      draggable: true,
      speed: process.env.REACT_APP_CAROUSEL_SPEED,
      slidesToScroll: this.state.bookJacketsToScroll,
      slidesToShow: this.context.isTabletOrMobile ? this.getMiniJacketsCount() : this.context.jacketsCountPerCarousel - 2,
      swipeToSlide: true,
      variableWidth: true,
      infinite: false,
      nextArrow: null,
      prevArrow: null,
      beforeChange: (oldIndex, newIndex) => { this.setTheVisibleJacketsArray(newIndex, this.context.isTabletOrMobile ? this.getMiniJacketsCount() : this.context.jacketsCountPerCarousel) },
      afterChange: newIndex => { this.setState({ activeIndex: newIndex, carouselLimit: this.state.carouselLimit + this.state.bookJacketsToScroll }); this.setArrowDisplay(newIndex); }
    };

    return (
      <Fragment>
        <div className="title-wrp">
          <h2 className="row-title" id={this.props.currentAnchor}>{this.props.rowTitle}</h2>
          <a className="lsp-skip-link" href={`#${this.props.nextAnchor}`}>Skip to the next row</a>
        </div>
        <div className="books-row">
          {this.props.source.length > 0 ? (
            <Fragment>
              <Arrow
                styleClassName={
                  this.state.displayRightArrow
                    ? "lsp-rightbtn-containter"
                    : "lsp-rightbtn-containter hidden"
                }
                direction="right"
                clickHandler={this.clickHandler}
                isTabletOrMobile={this.context.isTabletOrMobile}
                carouselName={this.props.rowTitle}
              />
              <Arrow
                styleClassName={
                  this.state.displayLeftArrow
                    ? "lsp-leftbtn-containter"
                    : "lsp-leftbtn-containter hidden"
                }
                direction="left"
                clickHandler={this.clickHandler}
                isTabletOrMobile={this.context.isTabletOrMobile}
                carouselName={this.props.rowTitle}
              />
              <Slider
                {...sliderSetting}
                ref={(recommendationsSlider) =>
                  (this.slider = recommendationsSlider)
                }
              >
                {this.props.source.slice(0, this.state.carouselLimit).map((record, index) => {
                  return (
                    // <TestJacket
                    //   visible={this.state.visibleJacketsArray.includes(index)}
                    //   key={`${index}-record-single`}
                    // />
                    <BookJacket
                      index={index}
                      isRecommendation
                      active={false}
                      recordData={record}
                      key={`${index}-${record.recordID}`}
                      id={`${index}-${record.recordID}`}
                      isTabletOrMobile={this.context.isTabletOrMobile}
                      visible={this.state.visibleJacketsArray.includes(index)}
                    />
                  );
                })}
              </Slider>
            </Fragment>
          ) :
            <div style={{ width: this.context.jacketsWidth + "px", height: this.context.jacketsHeight + "px" }}>
              {this.props.loading && (
                <div className="loader-container">
                  {this.context.isTabletOrMobile ? (
                    <ScaleLoader
                      css={loaderCSS}
                      height={60}
                      width={3}
                      radius={0}
                      color="var(--primary)"
                    />
                  ) : (
                    <ScaleLoader
                      css={loaderCSS}
                      height={100}
                      width={7}
                      radius={0}
                      color="var(--primary)"
                    />
                  )}
                </div>
              )}
              <div className="placeholder-container">
                {!this.props.loading && (
                  <p>{this.props.noRecommendationsMessage}</p>
                )}
              </div>
            </div>
          }
        </div>
      </Fragment>
    );
  }
}
