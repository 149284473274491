import React, { useContext } from 'react';
import Meta from './common/Meta';
import { AppContext } from '../AppContext';


const NoPage = () => {
  const { applicationData, libraryOGimage, loading } = useContext(AppContext);

  const metaData = {
    title: `${applicationData.publishedTitle} | 404`,
    description: 'Page not found!',
    type: 'website',
    image: libraryOGimage,
    canonical: ''
  }

  return (
    <div className="wcard-outer-container" role="main">
      <Meta data={metaData} />
      <h1 className="visuallyhidden">{applicationData.publishedTitle} 404</h1>
      {(applicationData && !loading) &&
        <div className="wcard-container">
          <h1 className="wcard-screen-title">Lost in the library?</h1>
          <p className="wcard-message">It looks like that page doesn't exist - please check the URL and try again.</p>
        </div>
      }
    </div>
  );
}

export default NoPage;
