import React, { Component, Fragment } from 'react'
import './Settings.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import FocusTrap from 'focus-trap-react'
import { css } from '@emotion/core'
import { ScaleLoader } from 'react-spinners'

import luciData from '../../../luciConfig.json';
import users from '../../../users.json';

import Loans from '../../common/Loans';

import GenrePreferences from './GenrePreferences';
import FormatPreferences from './FormatPreferences';
import ContactPreferences from './ContactPreferences';
import Meta from '../../common/Meta';
import { AuthContext } from '../../../AuthContext';

// TODO get the logged-in user from the actual API
const currentUser = users.users.filter(user => user.user_id === 321321654464);

class Settings extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      activeTabIndex: 0,
      checkBoxesEditable: false,
      activeThemesTrap: false,
      focusID: '',
      localTheme: ''
    }

    this.changeTab = this.changeTab.bind(this);
    // this.handleTheTheme = this.handleTheTheme.bind(this);
    this.getThemeID = this.getThemeID.bind(this);

    this.mountMiniTrap = this.mountMiniTrap.bind(this);
    this.unmountMiniTrap = this.unmountMiniTrap.bind(this);
    this.cancelMiniTrap = this.cancelMiniTrap.bind(this);
    this.showAvatarModalHandler = this.showAvatarModalHandler.bind(this);
    this.hideAvatarModalHandler = this.hideAvatarModalHandler.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0); // to activate the navbar highlighting function
    this.setState({ localTheme: this.context.theUserChoiceTheme })
  }

  cancelMiniTrap(item) {
    if (item === "Themes") {
      this.setState({ localTheme: this.context.theUserChoiceTheme });
      setTimeout(() => {
        this.unmountMiniTrap("Themes");
      }, 200);
    }
  }

  mountMiniTrap(item) {
    if (item === "Themes") {
      this.setState({ activeThemesTrap: true });
      const id = `#${this.getThemeID(this.context.theUserChoiceTheme)}`;
      this.setState({ focusID: id });
    }
  }

  unmountMiniTrap(item) {
    if (item === "Themes") {
      this.setState({ activeThemesTrap: false });
    }
  }

  getThemeID = (name) => {
    if (name === 'auto') {
      return 'theme-auto'
    } else if (name === 'Dark Theme') {
      return 'theme-0'
    } else if (name === 'Light Theme') {
      return 'theme-1'
    }
  }

  changeTab(newActiveTabIndex) {
    this.setState({
      activeTabIndex: newActiveTabIndex
    });
  }

  handleEdit = e => {
    this.props.history.push("/user/preferences");
  };
  showAvatarModalHandler = () => {
    this.context.setModalStatus(true);
    this.context.setModalPayload({
      isAvatarSelector: true, canCancel: true, onCancel: this.hideAvatarModalHandler
    });
  }

  hideAvatarModalHandler = () => {
    this.context.setModalStatus(false);
  }
  onSubmitTheme = (e, theme) => {
    e.preventDefault();
    this.context.saveUserPreferenceTheme(theme);
    if (!this.context.loadingPreference) {
      setTimeout(() => {
        this.unmountMiniTrap("Themes");
      }, 1000);
    }
  }

  getMetaData = () => {
    let data = {
      lang: 'en',
      title: `${this.context.applicationData.publishedTitle} | My Account`,
      description: `Welcome to ${this.context.applicationData.publishedTitle} | My Account`,
      type: 'website',
      image: this.context.libraryOGimage,
      canonical: ''
    }
    return data;
  }

  render() {
    const loaderCSS = css`
      position: absolute;
      opacity: 0.4;
      top: 40%;
      left: 40%;
      z-index: 1;
    `;

    return (
      <div className="settings" role="main">
        <Meta data={this.getMetaData()} />

        <div className="settings-tabs">
          <ul>
            <li>
              <button className={this.state.activeTabIndex === 0 ? 'settings-tabs-item activeTab' : 'settings-tabs-item'}
                title="Access My Shelf section" onClick={!this.context.loading ? this.changeTab.bind(this, 0) : undefined}>My Shelf</button>
            </li>
            <li>
              <button className={this.state.activeTabIndex === 1 ? 'settings-tabs-item activeTab' : 'settings-tabs-item'}
                title="Access My Lists section" onClick={!this.context.loading ? this.changeTab.bind(this, 1) : undefined}>My Lists</button>
            </li>
            <li>
              <button className={this.state.activeTabIndex === 2 ? 'settings-tabs-item activeTab' : 'settings-tabs-item'}
                title="Access My Profile section" onClick={!this.context.loading ? this.changeTab.bind(this, 2) : undefined}>My Profile</button>
            </li>
          </ul>
        </div>
        {this.context.isAuthenticated &&
          <div className="container">
            <div className="settings-outer">
              {/* My Shelf */}
              {this.state.activeTabIndex === 0 &&
                <Fragment>
                  {/* <User Loans /> */}
                  <div className="settings-free">
                    <Loans
                      isLoan
                      isFromUserAccount
                      rowTitle="My Loans"
                      noItemsMessage="You don't have any loans currently"
                      loading={this.context.loadingLoans}
                      source={this.context.currentLoans}
                      counter={this.context.userLoans.length}
                      tabsToShow={this.context.tabsToShow}
                      currentAnchor="User_Loans"
                      nextAnchor="User_Holds"
                    />
                  </div>

                  {/* <User Holds /> */}
                  <div className="settings-free">
                    <Loans
                      isHold
                      isFromUserAccount
                      rowTitle="My Reservations"
                      noItemsMessage="You don't have any reservations currently"
                      loading={this.context.loadingHolds}
                      source={this.context.currentHolds}
                      counter={this.context.userHolds.length}
                      tabsToShow={this.context.tabsToShow}
                      currentAnchor="User_Holds"
                      nextAnchor="User_Loan_History"
                    />
                  </div>

                  {/* <User Archive /> */}
                  <div id="loan-history" className="settings-free">
                    <Loans
                      isArchive
                      isFromUserAccount
                      rowTitle="My Loan History"
                      noItemsMessage="You don't have any previous loans"
                      loading={this.context.loadingArchive}
                      source={this.context.currentArchive}
                      counter={this.context.userArchive.length}
                      tabsToShow={this.context.tabsToShow}
                      currentAnchor="User_Loan_History"
                      nextAnchor="User_Events_Bookings"
                    />
                  </div>
                  {/* <My Events Bookings /> */}
                  <div className="settings-free">
                    <div className="title-wrp" style={{ marginTop: "30px" }}>
                      <h2 className="row-title mini-row-title" id="User_Events_Bookings">My Events Bookings</h2>
                      <a className="lsp-skip-link" href="#footer">Skip to the next row</a>
                    </div>
                    <div className="empty-row">
                      <div className="mini-placeholder-container">
                        <p>You don't have any bookings currently.</p>
                      </div>
                    </div>
                  </div>
                </Fragment>
              }
            </div>

            {/* My Lists */}
            {this.state.activeTabIndex === 1 &&
              <Fragment>
                <div className="settings-middle">
                  {currentUser[0].user_lists.map((list, index, array) => (
                    <div key={`settings-middle-${index}`} className="settings-column settings-long">
                      <div className="title-wrp top-aligned" id={list._id}>
                        <h2>{list.name}</h2>
                        <a className="lsp-skip-link" href={array[index + 1] ? `#${array[index + 1]._id}` : '#footer'}>Skip to the next row</a>
                        <button title={`Edit ${list.name} list`} className="edit-btn">Edit <FontAwesomeIcon icon={["fas", "pen"]} /></button>
                      </div>
                      <ul className="preference-list pref-list-hor">
                        <li className="preference-list-item" >
                          <span>
                            {list.content.map((item, i) => (
                              <p className="preference-item" key={i}>{item}</p> // TODO change the key on final
                            ))}
                          </span>
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>
              </Fragment>
            }

            {/* My Profile */}
            {this.state.activeTabIndex === 2 &&
              <Fragment>
                <div className="settings-column">
                  <div className="row justify-content-center">
                    <h2>Account Details</h2>
                    <div className="col-md-2 col-sm-12">
                      <div className="user-avatar-div">
                        {this.context.userSavedAvatar ?
                          <img className="avatar-sqr-img" src={`data:image/svg+xml;base64,${this.context.userSavedAvatar}`}
                            alt='user avatar' />
                          : <FontAwesomeIcon className="user-avatar-icon" icon={['fas', 'user']} />
                        }
                        <div className="user-avatar-icons">
                          <button
                            title="Edit user avatar preference"
                            className="edit-btn"
                            onClick={this.showAvatarModalHandler}>Change <FontAwesomeIcon icon={["fas", "pen"]} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-6">
                      <div className="settings-account-list">
                        <dl>
                          <dt>Username:</dt>
                          <dd>{this.context.user.userName}</dd>
                          <dt>First name:</dt>
                          <dd>{this.context.user.firstName}</dd>
                          <dt>Last name:</dt>
                          <dd>{this.context.user.lastName}</dd>
                          <dt>DOB:</dt>
                          <dd>--</dd>
                          <dt>Member since:</dt>
                          <dd>--</dd>
                          <dt>Email:</dt>
                          <dd>{this.context.user.emailAddress}</dd>
                          <dt>Mobile phone:</dt>
                          <dd>--</dd>
                          <dt>Borrower number:</dt>
                          <dd>{this.context.user.userID}</dd>
                          <dt>User type:</dt>
                          <dd>{this.context.user.userType}</dd>
                        </dl>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-6">
                      <div className="settings-account-list">
                        <dl>
                          <dt>Address Line 1:</dt>
                          <dd>--</dd>
                          <dt>Address Line 2:</dt>
                          <dd>--</dd>
                          <dt>City:</dt>
                          <dd>--</dd>
                          <dt>Post code:</dt>
                          <dd>--</dd>
                          <dt>Library / Council:</dt>
                          <dd>--</dd>
                          <dt>Home location:</dt>
                          <dd>{this.context.user.homeLocation}</dd>
                          <dt>Preferred pickup location:</dt>
                          <dd>--</dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className={this.state.activeThemesTrap ? "settings-column active" : "settings-column"}>
                      {this.context.loadingPreference && <ScaleLoader css={loaderCSS} height={50} width={6} color='var(--primary)' />}
                      <FocusTrap
                        active={this.state.activeThemesTrap}
                        focusTrapOptions={{
                          onDeactivate: () => this.unmountMiniTrap("Themes"),
                          initialFocus: false,
                          clickOutsideDeactivates: true,
                          escapeDeactivates: false,
                        }}
                      >
                        <form className="centered-form" onSubmit={(e) => this.onSubmitTheme(e, this.state.localTheme)} onReset={() => this.cancelMiniTrap("Themes")}>
                          <section className="settings-title">
                            <div className="title-wrp top-aligned">
                              <h2>App Theme</h2>
                              {!this.state.activeThemesTrap &&
                                <button title="Edit colour theme preference" className="edit-btn" onClick={() => this.mountMiniTrap("Themes")}>Edit <FontAwesomeIcon icon={["fas", "pen"]} /></button>
                              }
                              {this.state.activeThemesTrap &&
                                <div className="settings-buttons">
                                  <button title="Save colour theme preference" type="submit" disabled={this.context.theUserChoiceTheme === this.state.localTheme}>Save <FontAwesomeIcon icon={["fas", "save"]} /></button>
                                  <button title="Cancel" type="reset">Cancel <FontAwesomeIcon icon={["far", "times"]} /></button>
                                </div>
                              }
                            </div>
                          </section>
                          <div className="settings-container">
                            <div className="settings-radio-group">
                              <input
                                id="theme-auto"
                                name="themeselector"
                                type="radio"
                                value={'auto'}
                                defaultChecked={this.context.theUserChoiceTheme === 'auto'}
                                onClick={() => { this.setState({ localTheme: 'auto' }) }}
                                disabled={!this.state.activeThemesTrap} />
                              <label htmlFor="theme-auto">Auto (Library default)</label>
                            </div>
                            {this.context.applicationData.themes.map((theme, index) => {
                              return (
                                <div className="settings-radio-group" key={`settings-radio-${index}`}>
                                  <input
                                    id={`theme-${index}`}
                                    name="themeselector"
                                    type="radio"
                                    value={theme.name}
                                    defaultChecked={this.context.theUserChoiceTheme === theme.name}
                                    onClick={() => { this.setState({ localTheme: theme.name }) }}
                                    disabled={!this.state.activeThemesTrap} />
                                  <label htmlFor={`theme-${index}`}>{theme.name}</label>
                                </div>
                              )
                            })}
                          </div>
                          {(this.state.localTheme === '' && this.state.activeThemesTrap) &&
                            <p className="preference-warning"><FontAwesomeIcon icon={['fal', 'exclamation-triangle']} /> Please select one!</p>
                          }
                        </form>
                      </FocusTrap>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <GenrePreferences />
                  </div>
                  <div className="col-md-4">
                    <FormatPreferences />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <ContactPreferences />
                  </div>
                </div>
              </Fragment>
            }
          </div>
        }
      </div >
    )
  }
}

export default withRouter(Settings);