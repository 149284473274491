import React, { useState, useEffect, useContext, Fragment, useRef } from 'react'
import './Modal.css'
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SyncLoader } from 'react-spinners'
import FocusTrap from 'focus-trap-react'
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext';

import AddToCalendarButton from '../../common/add-to-calendar-button/AddToCalendarButton';
import CloseButton from '../../common/CloseButton';
import SocialShareButton from '../social-share-button/SocialShareButton';
import BookInsider from '../book-jackets/BookInsider';
import LoanInsider from '../book-jackets/LoanInsider';
import RenewAll from './RenewAll.js';
import Avatars from './Avatars.js';
import luciData from '../../../luciConfig.json';

const Modal = (props) => {
  const { clearMessages, applicationData, isTabletOrMobile, isAuthenticated, modalLoginStatus, modalLogoutStatus, modalStatus,
    sendContactForm, currentLoans, axiosInstanceWithPatronToken, userSavedAvatar, saveUserAvatar, deleteUserAvatar, setModalPasswordStatus } = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false)
  const [selectedAvatar, setSelectedAvatar] = useState('');
  const [clearedAvatar, setClearedAvatar] = useState(false);

  const { register, formState: { errors }, handleSubmit, formState } = useForm({ mode: 'all' });

  const onSubmitLoginForm = (data) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(token => {
        submitLogin(data, token)
      });
    });
  };
  const onSubmitPasswordForm = (data) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(token => {
        submitPassword(data, token)
      });
    });
  };

  const submitLogin = (data, token) => {
    // call a backend API to verify reCAPTCHA response
    fetch('/verify', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        'g-recaptcha-response': token
      })
    }).then(res => res.json()).then(res => {
      // console.log(res);
      if (res.success) {
        let loginDetails = { 'userID': data.bNumber, 'password': data.pin }
        props.logIn(loginDetails);
        // alert(JSON.stringify(loginDetails, null, 2)); 
      }
    });
  }

  const submitPassword = (data, token) => {
    // call a backend API to verify reCAPTCHA response
    fetch('/verify', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        'g-recaptcha-response': token
      })
    }).then(res => res.json()).then(res => {
      // console.log(res);
      // console.log(data.user_login);
      if (res.success) {
        props.resetPassword(data.user_login);
      }
    });
  }

  // to disable scrolling the page when modal is open
  useEffect(() => {
    if (modalLoginStatus || modalLogoutStatus || modalStatus || props.isContact || props.isLibraryLogin) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [modalLoginStatus, modalLogoutStatus, modalStatus, props.isContact, props.isLibraryLogin])

  // reCAPTCHA handling
  useEffect(() => {
    if (props.isLibraryLogin || props.isPasswordReset || (props.isContact && !isAuthenticated)) {
      const loadScriptByURL = (id, url, callback) => {
        const isScriptExist = document.getElementById(id);
        if (!isScriptExist) {
          let script = document.createElement("script");
          script.type = "text/javascript";
          script.src = url;
          script.id = id;
          script.onload = function () {
            if (callback) callback();
          };
          document.body.appendChild(script);
        }
        if (isScriptExist && callback) callback();
      }

      // loading the recaptcha script
      loadScriptByURL("recaptcha-script", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`, function () {
        // console.log("Recaptcha script loaded successfully!");
      });

      // cleaning up the script and badge after use
      return () => {
        let script = document.getElementById("recaptcha-script");
        let badge = document.querySelector(".grecaptcha-badge");
        if (script && badge) {
          script.remove();
          let nextNode = badge.nextSibling;
          if (nextNode) {
            nextNode.remove();
          }
          badge.remove();
          // console.log("Recaptcha script removed!");
        }
      }
    }
  }, [isAuthenticated, props.isLibraryLogin, props.isContact, props.isPasswordReset]);

  // to close the login window after a success login
  useEffect(() => {
    if (props.logInMessage === 'Success!') {
      setTimeout(() => {
        props.onCancel();
        clearMessages();
      }, 2000);
    }
  }, [props.logInMessage])

  const onSubmitContactForm = (data) => {
    let contactSubmission = {
      "formID": "contact",
      "deviceID": "",
      "responses": [
        { 'key': 'User_Name', 'value': data.UserName },
        { 'key': 'User_Email', 'value': data.email },
        { 'key': 'Borrower_Number', 'value': data.bNumber },
        { 'key': 'User_Library', 'value': data.library },
        { 'key': 'Subject', 'value': data.subject },
        { 'key': 'Message', 'value': data.message }
      ]
    }
    if (!isAuthenticated) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(token => {
          submitContactData(contactSubmission, token)
        });
      });
    } else {
      submitContactData(contactSubmission)
    }
  };

  const submitContactData = (data, token) => {
    if (!isAuthenticated) {
      // call a backend API to verify reCAPTCHA response
      fetch('/verify', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          'g-recaptcha-response': token
        })
      }).then(res => res.json()).then(res => {
        if (res.success) {
          // console.log(res.score)
          sendContactForm(data);
        }
      });
    } else {
      sendContactForm(data)
    }
  }

  const validateSelection = async (value) => {
    if (value === 'default') {
      return false;
    }
    return true;
  }

  const handleAfterSendingMessage = () => {
    props.setContactUsResponseMessage('')
    props.onCancel();
  }

  // to handle hitting ESC button to close Modal whenever it was opened
  useEffect(() => {
    const close = (event) => {
      if (event.keyCode === 27) {
        props.onCancel();
        clearMessages();
      }
    }
    window.addEventListener('keydown', close)
    return () => { window.removeEventListener('keydown', close) }
  }, [])

  const libraries = luciData.councils[0].libraries;  // this is for Sutton in development only TODO: add an API for all libraries names.

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const history = useHistory();

  const handleShowRegisterModal = () => {
    props.onCancel();
    clearMessages();

    history.push('/user/register')
  }

  const handleShowPasswordResetModal = () => {
    props.onCancel();
    clearMessages();
    setModalPasswordStatus(true);
  }

  const handleHideTheModal = () => {
    props.onCancel();
    clearMessages();
  }

  return (
    <FocusTrap>
      <div>
        {props.isPasswordReset ?
          <Fragment>
            <div className="backdrop" style={{ zIndex: "130" }} onClick={handleHideTheModal} />
            <div className="library-login-modal">
              <CloseButton styleClassName="close-modal" size="2x" title="Close this window" action={handleHideTheModal} />
              <h2 className="form-title">{props.title}</h2>
              <form className="login-form" onSubmit={handleSubmit(onSubmitPasswordForm)}>
                <section className="modal-content" style={{ flexWrap: "wrap", flexDirection: "row" }}>

                  <div className="select-container">
                    <label className="input-label" htmlFor="user_login">Your Borrower number or username:</label>
                    <input aria-labelledby="user_login"
                      name="user_login"
                      className="input-field"
                      type="text"
                      title="Please enter your library card number or username"
                      placeholder="Your username or library card number"
                      autoComplete="on"
                      {...register("user_login", { required: true })} />
                    {errors.user_login?.type === "required" && <p className="error-msg">Your library card number/username is required</p>}
                    {props.passwordMessage &&
                      <p className="error-msg" style={{ color: props.passwordMessage === 'Success! If there is a user associated with that login, you will receive an email with a link to reset your PIN' && 'var(--success-green)' }}>
                        {props.passwordMessage}</p>
                    }
                  </div>

                  <div className="login-dialogue-buttons">
                    {props.canConfirm &&
                      <button
                        className="contact-btn"
                        type="submit"
                        onClick={props.onConfirm}
                        disabled={props.isWaiting || props.passwordMessage !== ""}
                      >
                        {!props.isWaiting &&
                          <Fragment> Reset <FontAwesomeIcon icon={['fas', 'arrow-alt-right']} /> </Fragment>
                        }
                        {props.isWaiting &&
                          <span className="login-waiting">
                            {props.isTabletOrMobile ?
                              <SyncLoader size={5} margin={0} color='var(--primary)' /> :
                              <SyncLoader size={10} margin={1} color='var(--primary)' />
                            }
                          </span>
                        }
                      </button>
                    }
                    {props.canCancel && <button className="contact-btn" type="button" onClick={handleHideTheModal}> {props.passwordMessage === "" ? "Cancel" : "Ok"}<FontAwesomeIcon icon={props.passwordMessage === "" ? ['fas', 'times'] : ['far', 'check']} /></button>}
                  </div>
                </section>
              </form>
            </div>
          </Fragment> :
          (props.isLogin ?
            <Fragment>
              <div className="backdrop" onClick={handleHideTheModal}></div>
              <div className="modal login-modal">
                <header className="modal-header">
                  <h2>{props.title}</h2>
                  <CloseButton styleClassName="close-modal" size="2x" title="Close this window" action={handleHideTheModal} />
                </header>
                <section className="modal-content">
                  {props.children}
                </section>
                <section className="modal-actions">
                  {props.canCancel && <button className="btn-sml" onClick={handleHideTheModal}>Cancel</button>}
                  {props.canConfirm && <button className="btn-sml" onClick={props.onConfirm}>Submit</button>}
                </section>
              </div>
            </Fragment> :
            (props.isLibraryLogin ?
              <Fragment>
                <div className="backdrop" style={{ zIndex: "130" }} onClick={handleHideTheModal}></div>
                <div className="library-login-modal">
                  <CloseButton styleClassName="close-modal" size="2x" title="Close this window" action={handleHideTheModal} />
                  <h2 className="form-title">{props.title || ""}</h2>
                  <form className="login-form" onSubmit={handleSubmit(onSubmitLoginForm)}>
                    <section className="modal-content">
                      {/* username field */}
                      <div className="select-container">
                        <label className="input-label" htmlFor="bNumber">Borrower number or username:</label>
                        <input
                          aria-labelledby="bNumber"
                          name="bNumber"
                          className="input-field"
                          type="text"
                          title="Please enter your library card number or username"
                          placeholder="Your username or library card number"
                          autoComplete="on"
                          {...register("bNumber", { required: true, minLength: 4 })}
                        />
                        {errors.bNumber?.type === "required" && <p className="error-msg">Your library card number/username is required</p>}
                        {errors.bNumber?.type === "minLength" && <p className="error-msg">Your library card number/username must be 4 characters long at least!</p>}
                        {props.logInMessage && <p className="error-msg" style={{ color: props.logInMessage === 'Success!' && 'var(--success-green)' }}>
                          {props.logInMessage}</p>
                        }
                      </div>

                      {/* password field */}
                      <div className="select-container">
                        <label className="input-label" htmlFor="pin">Pin or password:</label>
                        <input
                          aria-labelledby="pin"
                          name="pin"
                          id="login-pass"
                          className="input-field"
                          type={showPassword ? "text" : "password"}
                          style={{ "letterSpacing": "8px" }}
                          title="Please enter your pin or password"
                          {...register("pin", { required: true, minLength: 4 })}
                        />
                        {errors.pin?.type === "required" && <p className="error-msg">Your pin/password is required</p>}
                        {errors.pin?.type === "minLength" && <p className="error-msg">Your pin/password must be 4 characters long at least!</p>}
                        {props.logInMessage && <p className="error-msg" style={{ color: props.logInMessage === 'Success!' && 'var(--success-green)' }}>
                          {props.logInMessage}</p>
                        }
                        <button type="button" className="show-password" onClick={e => { e.preventDefault(); handleShowPassword() }}>
                          <FontAwesomeIcon icon={showPassword ? ['fad', 'eye-slash'] : ['fad', 'eye']} size="2x" />
                        </button>
                      </div>

                      <div className="select-container">
                        <p className="modal-line">Forgot your pin/password? Try to reset it <button onClick={handleShowPasswordResetModal}>here</button> </p>
                        <p className="modal-line">Not yet a user? You can register <button onClick={handleShowRegisterModal}>here</button> </p>
                        {/* <p className="modal-line">Not yet a user? You can register <a href="/user/register">here</a> </p> */}
                      </div>
                      {/* remember me checkbox */}
                      <div className="select-container checkbox-outer" style={{ textAlign: 'left' }}>
                        <div className="checkbox-wrapper">
                          <input id="remember-checkbox" name="remember-checkbox" type="checkbox" value="" />
                          <label htmlFor="remember-checkbox" title="This option will keep you logged in on the current device for the next 12 hours.">Remember me</label>
                        </div>
                      </div>
                    </section>
                    {(props.logInMessage && props.logInMessage === 'Success!') ?
                      <section className="login-dialogue-buttons">
                        <button className="contact-btn" type="button" onClick={handleHideTheModal}>OK</button>
                      </section> :
                      <section className="login-dialogue-buttons">
                        {props.canConfirm &&
                          <button
                            className="contact-btn"
                            type="submit"
                            onClick={props.onConfirm}
                            disabled={props.isWaiting}
                          >
                            {!props.isWaiting &&
                              <Fragment> Login <FontAwesomeIcon icon={['fad', 'sign-in-alt']} /> </Fragment>
                            }
                            {props.isWaiting &&
                              <span className="login-waiting">
                                {props.isTabletOrMobile ?
                                  <SyncLoader size={5} margin={0} color='var(--primary)' /> :
                                  <SyncLoader size={10} margin={1} color='var(--primary)' />
                                }
                              </span>
                            }
                          </button>
                        }
                        {props.canCancel && <button className="contact-btn" type="button" onClick={handleHideTheModal}>Cancel<FontAwesomeIcon icon={['fas', 'times']} /></button>}
                      </section>
                    }
                  </form>
                </div>
              </Fragment>
              : (props.isEvent ?
                <Fragment>
                  <div className="backdrop" onClick={handleHideTheModal}></div>
                  <div className="modal event-modal">
                    <div className="event-modal-image"
                      style={props.event.image_url_banner ? {
                        "backgroundImage": `url(${props.event.image_url_banner})`
                      } : { "backgroundImage": "url(/image-placeholder.jpg)" }}>

                      <CloseButton styleClassName="close-modal" size="2x" title="Close event details window" action={handleHideTheModal} />

                      <div className="event-modal-titles">
                        <h1>{props.event.name}</h1>
                        <h2>{props.event.category_name} Event</h2>
                      </div>
                    </div>
                    <section className="event-modal-content">
                      <div className="event-modal-info">
                        <div className="flex-1">
                          {/* <FontAwesomeIcon className="event-small-icons" icon={['far', 'calendar-alt']} /> */}
                          <AddToCalendarButton
                            title="Add to my calendar"
                            eventFullStartTime={props.eventFullStartTime}
                            eventFullEndTime={props.eventFullEndTime}
                            details={props.event} />
                          <p>{props.eventDate},  <span>{props.eventTimeStart} - {props.eventTimeEnd}</span></p>
                        </div>
                        <div className="flex-1">
                          <FontAwesomeIcon className="event-small-icons" icon={['fas', 'map-marker-alt']} />
                          <p>{props.event.location_name}</p>
                        </div>
                        <div className="flex-1">
                          <p><b>Price:&nbsp;</b>
                            {(props.event.ticket_cost > 0) ?
                              <Fragment>£ {props.event.ticket_cost}</Fragment>
                              :
                              <Fragment>FREE,&nbsp;
                                {(props.event.location_name === "Online Event" ?
                                  <span>booking not required!</span> : <span>booking required!</span>
                                )}
                              </Fragment>
                            }
                          </p>
                        </div>
                        {(props.event.age_min && props.event.age_max && props.event.age_min !== -1 && props.event.age_max !== -1) &&
                          <div className="flex-1">
                            <p><b>Ages: </b><span>{props.event.age_min} - {props.event.age_max}</span></p>
                          </div>
                        }
                      </div>

                      <div className="event-modal-text" tabIndex="0">
                        <p>{props.event.description}</p><br />
                        {props.event.requirements &&
                          <p><b>Requirements</b> : {props.event.requirements}</p>
                        }
                      </div>

                      <div className="event-modal-inter-icons">
                        {props.event.is_fully_booked ?
                          <button disabled className="inter-icons-inner" style={{ cursor: "auto" }}>
                            <FontAwesomeIcon title="Fully booked!" style={{ opacity: .3 }} icon={['far', 'ticket']} />
                            <p className="event-modal-tickets full-text">FULL</p>
                          </button> :
                          <a href={`${props.event.url}#bookingsContainer`} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon title="Book Now" icon={['far', 'ticket']} />
                            <p className="event-modal-tickets">{props.event.tickets_max > 0 && props.event.tickets_max}</p>
                          </a>
                        }
                        <div className="inter-icons-inner">
                          <SocialShareButton isLinear isEvent applicationData={applicationData} id={props.event.event_id} event={props.event} />
                        </div>
                        <button disabled className="inter-icons-inner" title="Add to favourite" onClick={() => { props.toggleLikeDislike() }}>
                          {props.buttonLikeStatus ?
                            <FontAwesomeIcon icon={['fas', 'heart']} /> : <FontAwesomeIcon icon={['fal', 'heart']} />
                          }
                        </button>
                      </div>

                    </section>
                  </div>
                </Fragment>
                : ((props.isBook || props.isRecommendation) ?
                  <Fragment>
                    <div className="backdrop" onClick={handleHideTheModal}></div>
                    {isTabletOrMobile ?
                      <div className="modal ms-book-modal">
                        <BookInsider isBook {...props} />
                      </div> :
                      <div className="book-modal">
                        <BookInsider {...props} />
                      </div>
                    }
                  </Fragment>
                  : ((props.isLoan || props.isHold) ?
                    <Fragment>
                      <div className="backdrop" onClick={handleHideTheModal}></div>
                      <div className={isTabletOrMobile ? "modal ms-book-modal" : "book-modal"}>
                        <LoanInsider {...props} />
                      </div>
                    </Fragment>
                    : (props.isArchive ?
                      <Fragment>
                        <div className="backdrop" onClick={handleHideTheModal}></div>
                        <div className={isTabletOrMobile ? "modal ms-book-modal" : "book-modal"}>
                          <BookInsider {...props} />
                        </div>
                      </Fragment>
                      : (props.isLogoutConfirmation ?
                        <Fragment>
                          <div className="backdrop" onClick={handleHideTheModal}></div>
                          <div className="signout-modal">
                            <section className="modal-content">
                              <p className="dialogue-message">{props.dialogueMessage}</p>
                              <section className="contact-form-buttons">
                                {props.canConfirm && <button className="contact-btn" type="button" onClick={props.onConfirm}>OK</button>}
                              </section>
                            </section>
                          </div>
                        </Fragment>
                        : (props.isBlog ?
                          <Fragment>
                            <div className="backdrop" onClick={handleHideTheModal}></div>
                            <div className="modal event-modal">
                              <div>
                                <p>{props.blog.type}</p>
                              </div>
                              <div className="event-modal-image"
                                style={props.blog.image ? {
                                  "backgroundImage": `url(${props.blog.image})`
                                } : { "backgroundImage": "url(/image-placeholder.jpg)" }}>

                                <CloseButton styleClassName="close-modal" size="2x" title="Close this window" action={handleHideTheModal} />

                                <div className="event-modal-titles">
                                  <h1>{props.blog.title}</h1>
                                  <h2>by: {props.blog.blogger}</h2>
                                  <p className="blog-modal-date">{props.blog.date}</p>
                                </div>
                              </div>
                              <section className="event-modal-content">
                                <div className="blog-modal-info">
                                  <div>
                                    <p><b>Platform: </b><a href={props.blog.platformLink}>{props.blog.platform}</a></p>
                                  </div>
                                  <div>
                                    <p><b>Title: </b>{props.blog.title}</p>
                                  </div>
                                  <div>
                                    <p><b>Author: </b>{props.blog.author}</p>
                                  </div>
                                </div>
                                <div className="event-modal-text">
                                  <p>{props.blog.text}</p>
                                </div>
                                <div className="blog-modal-inter-icons">
                                  <a title="View the original post" href={props.blog.url} target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={['fas', 'eye']} />
                                  </a>
                                  <a>
                                    <SocialShareButton isLinear isBlog blog={props.blog} />
                                  </a>
                                  <a title="Add to favourite" onClick={() => { props.toggleLikeDislike() }} target="_blank" rel="noopener noreferrer">
                                    {props.buttonLikeStatus ?
                                      <FontAwesomeIcon icon={['fas', 'heart']} /> : <FontAwesomeIcon icon={['fal', 'heart']} />
                                    }
                                  </a>
                                </div>
                              </section>
                            </div>
                          </Fragment>
                          : (props.isContact ?
                            <Fragment>
                              <div className="backdrop" onClick={handleHideTheModal}></div>
                              <div className="contact-modal">
                                <CloseButton styleClassName="close-modal" size="2x" title="Close contact-us window" action={handleHideTheModal} />
                                <form className="contact-form" onSubmit={handleSubmit(onSubmitContactForm)}>
                                  <div className="contact-form-outer">
                                    <div className="contact-form-left">
                                      <div className="contact-form-input">
                                        <label className="contact-input-label" htmlFor="UserName">Name:</label>
                                        {props.isAuthenticated ?
                                          <input aria-labelledby="UserName" className="contact-input-field" type="text" readOnly disabled defaultValue={props.user.userName} name="UserName" {...register("UserName")} />
                                          :
                                          <input aria-labelledby="UserName" className="contact-input-field" type="text" title="Please enter your full name" placeholder="Your full name" name="UserName" {...register("UserName", { required: true })} />
                                        }
                                        {errors.UserName?.type === "required" && <span className="contact-error-msg">Your name is required</span>}
                                      </div>
                                      <div className="contact-form-input">
                                        <label className="contact-input-label" htmlFor="email">E-Mail:</label>
                                        {props.isAuthenticated ?
                                          <input aria-labelledby="email" className="contact-input-field" type="email" readOnly disabled defaultValue={props.user.emailAddress} name="email" {...register("email")} />
                                          :
                                          <input aria-labelledby="email" className="contact-input-field" type="email" title="Please enter your email address" placeholder="Your email address" name="email" {...register("email", { required: true })} />
                                        }
                                        {errors.email?.type === "required" && <span className="contact-error-msg">Your email is required</span>}
                                      </div>
                                      <div className="contact-form-input">
                                        <label className="contact-input-label" htmlFor="bNumber">Borrower number:</label>
                                        {props.isAuthenticated ?
                                          <input aria-labelledby="bNumber" className="contact-input-field" type="text" readOnly disabled defaultValue={props.user.userID} name="bNumber" {...register("bNumber")} />
                                          :
                                          <input aria-labelledby="bNumber" className="contact-input-field" type="text" title="Please enter your library card number" placeholder="Your library card number"
                                            name="bNumber" {...register("bNumber", { required: true, pattern: /[0-9]+/ })} />
                                        }
                                        {errors.bNumber?.type === "required" && <span className="contact-error-msg">Your library card number is required</span>}
                                        {errors.bNumber?.type === "pattern" && <span className="contact-error-msg">Only numbers allowed</span>}
                                      </div>
                                      <div className="contact-form-input">
                                        <label className="contact-input-label" htmlFor="library">Library:</label>
                                        <FontAwesomeIcon className="arrow-icon" icon={['far', 'chevron-down']} />
                                        <select aria-labelledby="library" className="contact-input-field" type="text" title="Please choose your library" defaultValue="default" name="library" {...register("library", {
                                          required: true,
                                          validate: validateSelection
                                        })}>
                                          <option value="default" disabled>Please select one</option>
                                          {libraries.map((library, index) => {
                                            return (
                                              <option value={library} key={`library-name-${index}`}>{library}</option>
                                            )
                                          })}
                                        </select>
                                        {errors.library?.type === "validate" && <span className="contact-error-msg">Your library is required</span>}
                                      </div>
                                    </div>
                                    <div className="contact-form-right">
                                      <div className="contact-form-input">
                                        <label className="contact-input-label" htmlFor="subject">Subject:</label>
                                        <FontAwesomeIcon className="arrow-icon" icon={['far', 'chevron-down']} />
                                        <select aria-labelledby="subject" className="contact-input-field" type="text" title="Please choose a subject" defaultValue="default" name="subject" {...register("subject", {
                                          required: true,
                                          validate: validateSelection
                                        })}>
                                          <option value="default" disabled>Please select one</option>
                                          <option value="book-suggestion">Suggest a book/material</option>
                                          <option value="comment">Comment or suggestion</option>
                                          <option value="pin">Request a new pin</option>
                                          <option value="general">General query</option>
                                        </select>
                                        {errors.subject?.type === "validate" && <span className="contact-error-msg">The subject is required</span>}
                                      </div>
                                      {!props.contactUsResponseMessage &&
                                        <div className="contact-form-input">
                                          <label className="contact-input-label" htmlFor="message">Message:</label>
                                          <textarea title="Your message" aria-labelledby="message" name="message" style={{ 'resize': 'none' }} className="contact-input-field message-area" cols="30" rows="7" {...register("message", { required: true })} />
                                          {errors.message?.type === "required" && <span className="contact-error-msg">Your message is required</span>}
                                        </div>
                                      }
                                      {props.contactUsResponseMessage &&
                                        <div className="error-msg" style={{ color: props.contactUsResponseMessage === 'Thank you! Your message has been sent successfully, someone will be in touch with you shortly.' && 'var(--success-green)' }}>
                                          {props.contactUsResponseMessage}
                                        </div>
                                      }
                                    </div>
                                  </div>
                                  {props.contactUsResponseMessage ?
                                    <section className="contact-form-buttons">
                                      <button className="contact-btn" type="button" onClick={handleAfterSendingMessage}>OK</button>
                                    </section> :
                                    <section className="contact-form-buttons">
                                      {props.canConfirm &&
                                        <button className="contact-btn" type="submit" disabled={props.isWaiting || !formState.isValid} onClick={props.onConfirm}>Send<FontAwesomeIcon icon={['fas', 'paper-plane']} />
                                          {props.isWaiting &&
                                            <span className="login-waiting">
                                              {props.isTabletOrMobile ?
                                                <SyncLoader size={5} margin={0} color='var(--primary)' /> :
                                                <SyncLoader size={10} margin={1} color='var(--primary)' />
                                              }
                                            </span>
                                          }
                                        </button>
                                      }
                                      {props.canCancel &&
                                        <button className="contact-btn" type="button" onClick={handleHideTheModal}>Cancel<FontAwesomeIcon icon={['fas', 'times']} />
                                        </button>
                                      }
                                    </section>
                                  }
                                </form>
                              </div>
                            </Fragment>
                            : (props.isRenewAll ?
                              <Fragment>
                                <div className="backdrop" onClick={handleHideTheModal}></div>
                                <div className="contact-modal">
                                  <CloseButton styleClassName="close-modal" size="2x" title="Close contact-us window" action={handleHideTheModal} />
                                  <RenewAll
                                    canConfirm
                                    canCancel
                                    isTabletOrMobile={isTabletOrMobile}
                                    axiosInstanceWithPatronToken={axiosInstanceWithPatronToken}
                                    source={currentLoans}
                                    onCancel={handleHideTheModal}
                                  />
                                </div>
                              </Fragment>
                              : (props.isVideo ?
                                <Fragment>
                                  <div className="backdrop" onClick={handleHideTheModal}></div>
                                  <div className="video-modal">
                                    <CloseButton styleClassName="close-modal" size="2x" title="Close contact-us window" action={handleHideTheModal} />
                                    <h1 className="video-title">{props.videoTitle || ''}</h1>
                                    <video width="100%" controls>
                                      <source src={props.videoURL} type="video/mp4" />
                                      Your browser does not support HTML video.
                                    </video>

                                  </div>
                                </Fragment>
                                : (props.isAvatarSelector ?
                                  <Fragment>
                                    <div className="backdrop" onClick={handleHideTheModal}></div>
                                    <div className="modal avatars-modal">
                                      <CloseButton styleClassName="close-modal" size="2x" title="Close contact-us window" action={handleHideTheModal} />
                                      <div className="modal-header">
                                        <h1>User Avatar Selector</h1>
                                      </div>
                                      <div className="modal-content">
                                        <div className="avatars-container">
                                          {userSavedAvatar ?
                                            <div className="current-avatar">
                                              {clearedAvatar ? <FontAwesomeIcon className="current-user-avatar" title="current avatar" icon={["fad", "user"]} /> :
                                                <img title="current avatar" className="avatar-image" src={`data:image/svg+xml;base64,${userSavedAvatar}`}
                                                  alt='User&apos;s avatar' />
                                              }
                                              {(selectedAvatar === '' && !clearedAvatar) &&
                                                <button
                                                  title="Delete user's current avatar preference"
                                                  className="edit-btn"
                                                  onClick={() => setClearedAvatar(true)}>Clear <FontAwesomeIcon icon={["fas", "trash"]} />
                                                </button>
                                              }
                                              {(selectedAvatar === '' && clearedAvatar) &&
                                                <button
                                                  title="Revert back to the saved avatar"
                                                  className="edit-btn"
                                                  onClick={() => setClearedAvatar(false)}>Undo <FontAwesomeIcon icon={["far", "undo"]} />
                                                </button>
                                              }
                                            </div> : <FontAwesomeIcon className="current-user-avatar" title="current avatar" icon={["fad", "user"]} />
                                          }
                                          {selectedAvatar &&
                                            <Fragment>
                                              <FontAwesomeIcon className="middle-arrow" icon={["far", "arrow-right"]} />
                                              <div className="current-avatar">
                                                <img title="current avatar" className="avatar-image" src={selectedAvatar} alt='User&apos;s avatar' />
                                                <button
                                                  title="Delete avatar selection"
                                                  className="edit-btn"
                                                  onClick={() => setSelectedAvatar('')}>Clear <FontAwesomeIcon icon={["fas", "trash"]} />
                                                </button>
                                              </div>
                                            </Fragment>
                                          }
                                        </div>
                                        <p className="avatar-text">Please select a new avatar from the gallery below:</p>

                                        <Avatars selectedAvatar={selectedAvatar} setSelectedAvatar={setSelectedAvatar} setClearedAvatar={setClearedAvatar} />

                                      </div>
                                      <div className="modal-actions">
                                        <button className="contact-btn" disabled={selectedAvatar === '' && !clearedAvatar} type="button"
                                          onClick={clearedAvatar ? () => { deleteUserAvatar(); props.onCancel() } :
                                            () => { saveUserAvatar(selectedAvatar); props.onCancel() }}>Save</button>
                                        <button className="contact-btn" type="button" onClick={handleHideTheModal}>Cancel</button>
                                      </div>
                                    </div>
                                  </Fragment>
                                  : <Fragment>
                                    <div className="backdrop"></div>
                                    <div className="modal">
                                      <header className="modal-header">
                                        <CloseButton styleClassName="close-modal" title="Close this window" action={handleHideTheModal} />
                                      </header>
                                      <section className="modal-content">
                                        {/* This is the content for anything else */}
                                      </section>
                                    </div>
                                  </Fragment>
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        }
      </div >
    </FocusTrap >
  )
};

export default Modal;