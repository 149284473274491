import React, { useState, useContext, useEffect, Fragment } from 'react';

import { useHistory } from 'react-router-dom';
import moment from 'moment';

import EventsBar from '../common/carousel-navbars/EventsBar';
import EventsCatBar from '../common/carousel-navbars/EventsCatBar';
import EventsSubBar from '../common/carousel-navbars/EventsSubBar';
import EventsCarousel from './EventsCarousel';

import Meta from '../common/Meta';

import { AuthContext } from '../../AuthContext';

const Events = (props) => {
  const { isAuthenticated, setLoading, appID, applicationData, eventsTab, isTabletOrMobile, libraryOGimage, axiosInstance,
    originalEventsCategories, originalEventsLocations, tabsToShow, setModalPayload, setModalStatus, jacketsWidth } = useContext(AuthContext);

  const history = useHistory();

  const [eventsBarTabs, setEventsBarTabs] = useState([{ title: "All Events", id: "all_events", canonicalTitle: "all" }, { title: "Events by Category", id: "by_category", canonicalTitle: "category" }, { title: "Events by Location", id: "by_library", canonicalTitle: "location" }]);

  const [theEvents, setTheEvents] = useState([]);
  const [noEvents, setNoEvents] = useState(false);

  const [loadingEvents, setLoadingEvents] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState('');

  const [loadingLevelOne, setLoadingLevelOne] = useState(false);
  const [loadingLevelTwo, setLoadingLevelTwo] = useState(false);
  const [loadingLevelThree, setLoadingLevelThree] = useState(false);
  const [selectedIDForLoading, setSelectedIDForLoading] = useState('');

  const [levelOneCanonicalTitle, setLevelOneCanonicalTitle] = useState('');
  const [levelTwoCanonicalTitle, setLevelTwoCanonicalTitle] = useState('');
  const [levelThreeCanonicalTitle, setLevelThreeCanonicalTitle] = useState('');

  const [selectedParentTab, setSelectedParentTab] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState([]);
  const [selectedThirdLevel, setSelectedThirdLevel] = useState([]);
  const [secondRowSelectedID, setSecondRowSelectedID] = useState('');
  const [secondRowShrinked, setSecondRowShrinked] = useState(false);

  const [categories, setCategories] = useState([]);

  const [eventsLocations, setEventsLocations] = useState([]);

  const [subcategories, setSubcategories] = useState([]);

  // for the arrow below the first row's selected item
  const [selectedFirst, setSelectedFirst] = useState(false);
  const [selectedSecond, setSelectedSecond] = useState(false);
  const [selectedFirstId, setSelectedFirstId] = useState('');
  const [selectedSecondId, setSelectedSecondId] = useState('');

  const metaData = {
    title: `${applicationData.publishedTitle} | Events`,
    description: `Welcome to ${applicationData.publishedTitle} | Events`,
    type: 'website',
    image: libraryOGimage,
    canonical: ''
  }

  useEffect(() => {
    window.scrollTo(0, 0); // to activate the navbar highlighting function
  }, []);

  // function to set the categories and subcategories based on bar selection
  function getCategory(parentID) {
    // setRoot(parent)
    if (parentID === "by_category") {
      getFirstLevel(parentID);
      setEventsLocations([]);
      setTheEvents([]);

      setLevelOneCanonicalTitle('category');
      history.push({
        pathname: `/${eventsTab.canonicalTitle}/${levelOneCanonicalTitle}/`
      })
    } else if (parentID === "by_library") {
      getLibraryEvents();
      setTheEvents([]);
      setCategories([])

      setLevelOneCanonicalTitle('location');
      history.push({
        pathname: `/${eventsTab.canonicalTitle}/${levelOneCanonicalTitle}/`
      })
    } else if (parentID === "all_events") {
      setSelectedIDForLoading(parentID);
      getAllEvents();
      setEventsLocations([]);
      setCategories([])

      setLevelOneCanonicalTitle('all');
      history.push({
        pathname: `/${eventsTab.canonicalTitle}/${levelOneCanonicalTitle}/`
      })
    }
    setSubcategories([]);
    setSelectedFirst(false);
    setSelectedSecond(false);
    setSelectedFirstId('');
    setSelectedSecondId('');
  }

  useEffect(() => {
    if (props.location.pathname) {
      // console.log(props.location.pathname)
      stripOffTheURL();
    }
  }, [props.location.pathname]);

  const stripOffTheURL = () => {
    // console.log(props.location.pathname);
    let urlArray = props.location.pathname.split('/')
    // console.log('URL Stripped!');
    // console.table(urlArray);
    if (urlArray && urlArray.length > 0) {
      const levelOne = urlArray[2];
      const levelTwo = urlArray[3];
      const levelThree = urlArray[4];
      if (levelOne === "view" && levelTwo) {
        getSingleEvent(levelTwo);

      } else if (levelOne === "category") {
        setLevelOneCanonicalTitle(levelOne)
        // console.log('level one ' + levelOneCanonicalTitle);
        if (levelTwo) {
          setLevelTwoCanonicalTitle(levelTwo)
          // console.log('level two ' + levelTwoCanonicalTitle);
          if (levelThree) {
            // console.log('level three ' + levelThreeCanonicalTitle);
            setLevelThreeCanonicalTitle(levelThree)
          }
        }
      } else {
        // handleNoURLfragment();
      }
    }
  }

  useEffect(() => {
    if (levelOneCanonicalTitle) {
      // handleURLfragment();
    }
  }, [levelOneCanonicalTitle]);


  const handleURLfragment = () => {
    // console.log('levelOneCanonicalTitle is ' + levelOneCanonicalTitle);
    const tab = eventsBarTabs.filter(item => item.canonicalTitle === levelOneCanonicalTitle)
    if (tab.length > 0) {
      const id = tab[0].id;
      getFirstLevel(id);
    } else {
      // handleNoURLfragment();
    }
  }

  // Function to get the date in a readable format 
  const getUTCDate = (timestamp) => {
    return moment(timestamp).format('ddd D-MMM-YY');
  }
  // Function to get the time in a readable format 
  const getUTCTime = (timestamp) => {
    return moment(timestamp).format('kk:mm');
  }

  const getSingleEvent = async (id) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/Events/${id}`)
      setLoading(false);

      const eventData = response.data;
      const eventFullStartTime = eventData.start_time;
      const eventFullEndTime = eventData.end_time;
      const eventDate = getUTCDate(eventData.start_time);
      const eventTimeStart = getUTCTime(eventData.start_time);
      const eventTimeEnd = getUTCTime(eventData.end_time);

      setModalStatus(true);
      setModalPayload({
        isEvent: true, event: eventData, eventDate, eventTimeStart, eventTimeEnd, eventFullStartTime,
        eventFullEndTime, canCancel: true, onCancel: hideModalEventHandler
      });

    } catch (error) {
      console.error(error.message);
      setLoading(false);
    }
  }

  const hideModalEventHandler = () => {
    setModalStatus(false);
    history.push({
      pathname: `/${eventsTab.canonicalTitle}`
    })
  }

  const getAllEvents = async () => {
    setLoadingEvents(true);

    try {
      const response = await axiosInstance.get('/Events/query')

      setTheEvents(response.data)
      setLoadingEvents(false);

    } catch (error) {
      console.error(error.message);
      setLoadingEvents(false);
    }
  }

  const getEventsByLocation = async (locationId) => {

    setLoadingEvents(true);
    setSelectedIDForLoading(locationId);

    setSelectedItemId(locationId)

    // update the URL
    setLevelTwoCanonicalTitle(locationId);
    history.push({
      pathname: `/${eventsTab.canonicalTitle}/${levelOneCanonicalTitle}/${locationId}/`
    })

    try {
      const response = await axiosInstance.get(`/Events/query?LocationFilters=${locationId}`)

      if (response.data === []) {
        setNoEvents(true) // TODO this is not working
        setTheEvents(response.data)
      } else {
        setTheEvents(response.data)
      }
      setLoadingEvents(false);

    } catch (error) {
      console.error(error.message);
      setLoadingEvents(false);
    }
  }

  const getEventsByCategory = async (categoryId) => {
    // console.log('received: ' + categoryId);

    setLoadingEvents(true);
    setSelectedIDForLoading(categoryId);

    try {
      const response = await axiosInstance.get(`/Events/query?CategoryFilters=${categoryId}`)

      setTheEvents(response.data)
      setLoadingEvents(false);

    } catch (error) {
      console.error(error.message);
      setLoadingEvents(false);
    }
  }

  const getLibraryEvents = () => {
    // console.log("Getting Events by ")
    if (originalEventsLocations && originalEventsLocations.length > 0) {
      setEventsLocations(originalEventsLocations);
    }
  }

  // function to set the categories based on the Services-Bar selection
  const getFirstLevel = async (id) => {
    setSelectedIDForLoading(id);
    setLoadingLevelOne(true);

    if (eventsTab) {
      await axiosInstance.get(`/Tabs/${eventsTab.id}/children`).then((response) => {
        if (response.status === 200) {
          const filteredTabs = response.data.filter(item => (item.authVisibility !== 'neverauth' || item.authVisibility === null))
          setCategories(filteredTabs)
          setLoadingLevelOne(false)

        } else {
          console.error(response.message);
          setLoadingLevelOne(false);
        }
      })
    }

    // setRoot(categorySelection)
    // setSelectedParentTab(categorySelection.canonicalTitle)
    // console.log('found ' + root + ' and ID: ' + root.id);

    setSubcategories([]);
    setSelectedSubcategory([]);
    setSelectedFirst(false);
    setSelectedSecond(false);
    setSecondRowShrinked(false);
    setSelectedFirstId('');
    setSelectedSecondId('');
    setSelectedThirdLevel([]);
    setSecondRowSelectedID('');
  }

  // function to set subcategories based on category selection
  const getSecondLevel = async (id, selection) => {
    setSelectedIDForLoading(id);
    setLoadingLevelTwo(true);
    setSubcategories([]);
    setSelectedFirstId(id);


    // update the URL
    setLevelTwoCanonicalTitle(selection.canonicalTitle);
    history.push({
      pathname: `/${eventsTab.canonicalTitle}/${levelOneCanonicalTitle}/${selection.canonicalTitle}/`
    })

    await axiosInstance.get(`/Tabs/${id}/children`).then((response) => {
      if (response.status === 200) {
        setLoadingLevelTwo(false);

        if (response.data.length > 0) {
          const filtered = response.data.filter(item => (item.authVisibility !== 'neverauth' || item.authVisibility === null))
          setSubcategories(filtered)
          setSelectedFirst(true);
        } else {
          getEventsByCategory(selection.contentText)
          setSelectedFirst(true);
        }

      } else {
        console.error(response.message);
        setLoadingLevelTwo(false);
      }
    })

    setSelectedSecond(false);
    setTheEvents([]);

    setSelectedSubcategory([]);
    setSecondRowShrinked(false);
    setSelectedSecondId('');
    setSelectedThirdLevel([]);
    setSecondRowSelectedID('');
  }

  // function to set subcategories based on category selection
  const getThirdLevel = async (id, selection) => {
    setSelectedIDForLoading(id);
    setLoadingLevelThree(true);
    setSelectedSecondId(id);

    // update the URL
    setLevelTwoCanonicalTitle(selection.canonicalTitle);
    history.push({
      pathname: `/${eventsTab.canonicalTitle}/${levelOneCanonicalTitle}/${selection.canonicalTitle}/`
    })

    await axiosInstance.get(`/Tabs/${id}/children`).then((response) => {
      if (response.status === 200) {
        setLoadingLevelThree(false);

        if (response.data.length > 0) {
          const filtered = response.data.filter(item => (item.authVisibility !== 'neverauth' || item.authVisibility === null))
          setSubcategories(filtered)
          setSelectedSecond(true);
        } else {
          getEventsByCategory(selection.contentText)
          setSelectedSecond(true);
        }

      } else {
        console.error(response.message);
        setLoadingLevelThree(false);
      }
    })
  }

  return (
    <div className="events-container" role="main">
      <Meta data={metaData} />

      <div className="lsp-positioner-evt">
        <h1 className="visuallyhidden">{applicationData.publishedTitle} Events</h1>
        {/* The main navigation bar at the top of Events Screen */}
        <EventsBar
          loading={loadingLevelOne}
          loadingEvents={loadingEvents}
          selectedIDForLoading={selectedIDForLoading}
          source={eventsBarTabs}
          tabWidth={isTabletOrMobile ? "100" : jacketsWidth}
          cat={getCategory}
          setLevelTwoCanonicalTitle={setLevelTwoCanonicalTitle}
          setLevelThreeCanonicalTitle={setLevelThreeCanonicalTitle}
        />
      </div>

      {eventsLocations.length > 0 &&
        // A carousel for level two of Events-By-Location 
        <EventsCatBar
          loadingEvents={loadingEvents}
          selectedIDForLoading={selectedIDForLoading}
          source={eventsLocations}
          tabWidth={isTabletOrMobile ? "100" : jacketsWidth}
          cat={getEventsByLocation}
          selectedItemId={selectedItemId}
          levelOneCanonicalTitle={levelOneCanonicalTitle}
          setLevelTwoCanonicalTitle={setLevelTwoCanonicalTitle}
          setLevelThreeCanonicalTitle={setLevelThreeCanonicalTitle}
        />
      }

      {/* A carousel for level one of Events-By-Category */}
      {categories.length > 0 &&
        <div className={selectedFirst ? "first-row-outer collapsed" : "first-row-outer"}>
          <EventsSubBar
            tabWidth={isTabletOrMobile ? "100" : jacketsWidth}
            source={categories}
            selected={selectedFirstId}

            selectedIDForLoading={selectedIDForLoading}
            loading={loadingLevelTwo}
            get={getSecondLevel}
            levelOneCanonicalTitle={levelOneCanonicalTitle}
          />
        </div>
      }

      {/* A carousel for level two of Events-By-Category */}
      {subcategories.length > 0 &&
        <div className={selectedSecond ? "first-row-outer collapsed" : "first-row-outer"}>
          <EventsSubBar
            tabWidth={isTabletOrMobile ? "100" : jacketsWidth}
            source={subcategories}
            selected={selectedSecondId}

            selectedIDForLoading={selectedIDForLoading}
            loading={loadingLevelThree}
            get={getThirdLevel}
            levelOneCanonicalTitle={levelOneCanonicalTitle}
            levelTwoCanonicalTitle={levelTwoCanonicalTitle}
          />
        </div>
      }

      {/* the main events carousel */}
      <EventsCarousel
        noEventsMessage={noEvents ? "No events to show for that category yet" : "Click on one of the tabs above to check for events"}
        source={theEvents}
        tabsToShow={tabsToShow}
      />

      {/* ----------- the rows below ------------- */}
      {/* Area Events */}
      <EventsCarousel
        rowTitle="Events in your area"
        noEventsMessage="No events yet"
        source={[]}
        tabsToShow={tabsToShow}
      />

      {isAuthenticated &&
        <Fragment>
          {/* Interested Events */}
          <EventsCarousel
            rowTitle="Events you might be interested in"
            noEventsMessage="No events yet"
            source={[]}
            tabsToShow={tabsToShow}
          />

          {/* User Booking Events */}
          <EventsCarousel
            rowTitle="My Bookings"
            noEventsMessage="No events yet"
            source={[]}
            tabsToShow={tabsToShow}
          />

          {/* User Favourite Events */}
          <EventsCarousel
            rowTitle="My Favourite Events"
            noEventsMessage="No events yet"
            source={[]}
            tabsToShow={tabsToShow}
          />
        </Fragment>
      }
    </div >
  );
}

export default Events;
