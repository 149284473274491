import React, { useContext, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animateScroll as scroll } from 'react-scroll'
import { AuthContext } from '../../AuthContext';


const ScrollToTop = () => {
  const { pageHeight } = useContext(AuthContext);
  const scrollUp = () => {
    scroll.scrollToTop();
  }
  return (
    <Fragment>
      {pageHeight > 3000 &&
        <div className="scroll-up-container">
          <button className="scroll-up-btn" title="Back to the top of the page" onClick={() => scrollUp()}>
            <FontAwesomeIcon icon={['far', 'chevron-up']} size="lg" /><br />
            <p>Top</p>
          </button>
        </div>
      }
    </Fragment>
  );
}

export default ScrollToTop;