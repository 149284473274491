import React, { useState, useEffect, Fragment } from 'react'
import { useSpring, a } from '@react-spring/web'

function FlippingCover(props) {
  const [record, setRecord] = useState([]);

  const [recordISBN, setRecordISBN] = useState('');
  const [recordPubInfo, setRecordPubInfo] = useState('');
  const [recordPubYear, setRecordPubYear] = useState('');
  const [recordLanguage, setRecordLanguage] = useState('');
  const [recordSubject, setRecordSubject] = useState('');
  const [recordSeries, setRecordSeries] = useState('');
  const [recordFormat, setRecordFormat] = useState('');
  const [recordAuthors, setRecordAuthors] = useState([]);
  const [flipped, setflipped] = useState(true)

  useEffect(() => {
    if (props.recordData) {
      setRecord(props.recordData);
    }
  }, [props.recordData])

  useEffect(() => {
    if (record && record.mainMetadata) {
      const isbn = record.mainMetadata.filter(x => x.key === 'ISBN');
      const pub = record.mainMetadata.filter(y => y.key === 'PUBLICATION_INFO');
      const langu = record.mainMetadata.filter(z => z.key === 'Language');
      const subjects = record.mainMetadata.filter(a => a.key === 'Subject');
      const series = record.mainMetadata.filter(b => b.key === 'Series');
      const authors = record.mainMetadata.filter(c => c.key === 'AUTHOR');
      const year = record.mainMetadata.filter(d => d.key === 'PUBDATE');
      const format = record.mainMetadata.filter(d => d.key === 'ERC_FORMAT');

      if (year.length > 0) {
        setRecordPubYear(year[0].value);
      }

      if (isbn.length > 0) {
        let v = isbn[0];
        setRecordISBN(v.value);
      }
      if (pub.length > 0) {
        let v = pub[0];
        setRecordPubInfo(v.value);
      }
      if (langu.length > 0) {
        let v = langu[0];
        setRecordLanguage(v.value);
      }
      if (subjects.length > 0) {
        let v = subjects[0];
        setRecordSubject(v.value);
      }
      if (series.length > 0) {
        let v = series[0];
        setRecordSeries(v.value);
      }
      if (authors.length > 0) {
        setRecordAuthors(authors);
      }
      if (format.length > 0) {
        let v = format[0];
        setRecordFormat(v.value);
      }
    }
  }, [record])

  // flip bookcover animation
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 }
  })

  return (
    <button className="jacket-wrap" onClick={() => setflipped(state => !state)}>
      <a.div className={props.isTabletOrMobile ? "ms-jacket ms-front" : "jacket front"} id="jacket-front" style={{ opacity, transform: transform.to(t => `${t} rotateY(180deg)`) }} >
        {props.recordData.imageUrl !== '' ?
          <img id="jacket-image" src={props.recordData.imageUrl} alt={record.title} />
          : <img id="jacket-image" src='/image-placeholder.jpg' alt='book cover' />
        }
        {/* {record.averageRating &&
                <div className="stars-inner-wrap">
                  <div className={`stars-container stars-${(record.averageRating) * 2}`}>☆☆☆☆☆</div>
                  <span>{record.ratingsCount} Ratings</span>
                </div>
              } */}
      </a.div>
      <a.div className={props.isTabletOrMobile ? "ms-jacket ms-back" : "jacket back"} id="jacket-back" style={{ opacity: opacity.to(o => 1 - o), transform }}>
        <div
          style={{
            filter: 'blur(5px)',
            width: '100%',
            height: '100%',
            zIndex: -1
          }}>
          {props.recordData.imageUrl !== '' ?
            <img src={props.recordData.imageUrl} alt={record.title}
              style={{
                width: '100%',
                height: '100%',
                zIndex: -1
              }} /> : <img src='/image-placeholder.jpg' alt='book cover'
                style={{
                  width: '100%',
                  height: '100%',
                  zIndex: -1
                }} />
          }
        </div>
        <div className={props.isTabletOrMobile ? "record-info small" : "record-info"}>
          <ul>
            {record.title && <li> <b>Title:</b> <p> {record.title} </p></li>}

            {(record.author || recordAuthors.length > 0) &&
              <Fragment>
                {record.author ?
                  <li> <b>Author:</b><p> {record.author}</p></li> :
                  <li> <b>Author(s):</b>
                    {recordAuthors.map((author, index) => (
                      <p key={`author-${index}`}>{author.value} </p>
                    ))}
                  </li>
                }
              </Fragment>
            }
            {(recordPubInfo || props.recordPubYear) && <li> <b>Publication info:</b><p> {recordPubInfo || props.recordPubYear} </p> </li>}
            {recordSubject && <li> <b>Subject:</b><p>{recordSubject} </p> </li>}
            {recordSeries && <li> <b>Series:</b><p>{recordSeries} </p> </li>}
            {recordLanguage && <li> <p><b>Language:</b> {recordLanguage} </p></li>}
            {(record.format || recordFormat) && <li> <p><b>Format:</b> {record.format || recordFormat} </p></li>}
            {recordISBN && <li> <p><b>ISBN:</b> {recordISBN} </p> </li>}
          </ul>

        </div>
      </a.div>
    </button>
  )
};

export default FlippingCover;