import React, { useState, useEffect, useContext, Fragment } from 'react'
import { AuthContext } from '../../../AuthContext';

const PickupLocations = (props) => {
  const { userPreferredLocation, axiosInstanceWithPatronToken, toast } = useContext(AuthContext);

  const { record, setLoadingHold, pickupLocation, setPickupLocation, setpLocations } = props;

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    fetchPickupLocations();
  }, [])

  useEffect(() => {
    if (userPreferredLocation) {
      setPickupLocation(userPreferredLocation);
    }
  }, [userPreferredLocation])

  const fetchPickupLocations = async () => {
    setLoadingHold(true);
    try {
      const response = await axiosInstanceWithPatronToken.get(`/Authorities?recordID=${record.recordID}`)
      if (response.status === 200) {
        setLocations(response.data)
        setpLocations(response.data)
        setLoadingHold(false);
      } else {
        console.log("Error with fetching Pickup Locations - no content");
        setLoadingHold(false);
      }
    } catch (err) {
      toast.warn("Error with fetching Pickup Locations, please try again - " + err.message);
      setLoadingHold(false);
    }
  }

  const changePickupLocation = (event) => {
    // console.log(event.target.value);
    setPickupLocation(event.target.value);
  }

  return (
    <Fragment>
      <div className="pickuplocation-div">
        <h2 className="sorting-title">Pickup locations: </h2>
        {(locations && Object.keys(locations).length > 0) ?
          <select name="pickupLocation" id="pickupLocation" value={pickupLocation} onChange={changePickupLocation}>
            {locations && locations.map(item => (
              <option value={item.locationID} key={item.locationID}>{item.locationName}</option>
            ))}
          </select> :
          <p className="pickuplocation-text">No pickup locations found for this item.</p>
        }
      </div>
    </Fragment>
  )
};

export default PickupLocations;