import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SocialShareButton from '../social-share-button/SocialShareButton'

const UserInteractionIcons = (props) => {

  return (
    <div className="inter-icons-container">
      <button disabled className="inter-icons-inner" title="Like">
        <FontAwesomeIcon className="inter-icons" icon={['fal', 'thumbs-up']} />
      </button>
      <button disabled className="inter-icons-inner" title="Dislike">
        <FontAwesomeIcon className="inter-icons" icon={['fal', 'thumbs-down']} />
      </button>
      <button disabled className="inter-icons-inner" title="Add to favourite">
        <FontAwesomeIcon className="inter-icons" icon={['far', 'plus']} />
      </button>
      <div className="inter-icons-inner">
        <SocialShareButton isLinear isBook {...props} />
      </div>
      <button disabled className="inter-icons-inner" title="The full series">
        <FontAwesomeIcon className="inter-icons" icon={['fad', 'album-collection']} />
      </button>
      <button disabled className="inter-icons-inner" title="Read the review">
        <FontAwesomeIcon className="inter-icons" icon={['fal', 'newspaper']} />
      </button>
    </div>
  )
};

export default UserInteractionIcons;