import React, { useState, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom';
import './Preferences.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import luciData from '../../../luciConfig.json';

function PreferencesOne(props) {
  const [selectedAvatar, setSelectedAvatar] = useState('');
  const [savededAvatar, setSavedAvatar] = useState('');
  const [avatarAsDataURI, setAvatarAsDataURI] = useState('');
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0); // to activate the navbar highlighting function
    if (props.savedAvatarCode) {
      setSelectedAvatar(props.savedAvatarCode);
      setSavedAvatar(props.savedAvatarCode); // for comparison and make a decision to save at the end
    }
  }, [props.savedAvatarCode])

  // save and go back to user account area
  const onSave = () => {
    if (JSON.stringify(selectedAvatar) !== JSON.stringify(savededAvatar)) {
      props.setUserSavedAvatar(avatarAsDataURI); // to update the avatar on the User's Menu while still logged in      
      props.saveUserAvatar(avatarAsDataURI, selectedAvatar); // to update the actual API 
    }
    history.push("/user/account");
  }

  // save and go to Genres selecting screen
  const onNext = () => {
    if (JSON.stringify(selectedAvatar) !== JSON.stringify(savededAvatar)) {
      props.setUserSavedAvatar(avatarAsDataURI); // to update the avatar on the User's Menu while still logged in
      props.saveUserAvatar(avatarAsDataURI, selectedAvatar); // to update the actual API 
    }
    // go to the next screen
    props.nextStep();
  }

  const convertToData = (svgId) => {
    const svgToDataURL = require('svg-to-dataurl')
    var svgElement = document.getElementById(svgId);
    var svgString = new XMLSerializer().serializeToString(svgElement);
    // var decoded = unescape(encodeURIComponent(svgElement));
    const dataUrl = svgToDataURL(svgString)

    // var base64 = btoa(decoded);

    console.log(svgString)
    // setAvatarAsDataURI(base64);
  }

  const handleOnClick = (data, code) => {
    setSelectedAvatar(code);
    setAvatarAsDataURI(data);
  }

  const onCancel = () => {
    history.push("/user/account");
  }

  return (
    <div className="preferences-outer-container" role="main">
      <div className="preferences-background-overly"></div>
      <div className="preferences-inner-container">
        <h3>Please select an icon which represents you:</h3>
        <div className="preferences-avatars-container">
          {luciData.userOptions[0].avatars.map((avatar, index) => {
            return (
              <div key={avatar.code} onClick={() => handleOnClick(`${avatar.data}`, `${avatar.code}`)}
                className={(selectedAvatar === `${avatar.code}`) ? "avatar-outer avatar-chosen" : "avatar-outer"} >
                <img id={avatar.id} className="image-fit" src={`data:image/svg+xml;base64,${avatar.data}`} alt={avatar.code} />
              </div>
            )
          })}
        </div>

        {/*  BUTTONS  */}
        <div className="preferences-buttons">
          <div className="short-container justify-center">
            <div className="short">
              {selectedAvatar.length > 0 &&
                <Fragment>
                  {/* <button className="next-btn" type='button' onClick={() => onNext()}>Save &amp; Next (Genres)<FontAwesomeIcon className="next-icon" icon={['far', 'chevron-right']} /></button> */}
                  <button className="next-btn" type='button' onClick={() => onSave()}>Save &amp; back to account<FontAwesomeIcon className="next-icon" icon={['far', 'chevron-right']} /></button>
                </Fragment>
              }
              <button className="next-btn" type='button' onClick={() => onCancel()}>Cancel<FontAwesomeIcon className="next-icon" icon={['far', 'chevron-right']} /></button>
            </div>
          </div>
        </div>

        {/* <div className="skip-link">
          <a onClick={() => onSkip()}>Set preferences later on... 	&gt;</a>
        </div> */}
      </div>
    </div>
  );
}


export default PreferencesOne;
