import React, { useState, useEffect, useContext, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SyncLoader } from 'react-spinners'
import { AuthContext } from '../../../AuthContext';

import moment from 'moment';

const RenewAll = (props) => {
  const { toast, updateASingleLoan } = useContext(AuthContext);

  const [localLoansList, setLocalLoansList] = useState([])
  const [checkAllBtn, setCheckAllBtn] = useState(true)
  const [loading, setLoading] = useState(false)
  const [currentChecking, setCurrentChecking] = useState('')
  const [renewalsCounter, setRenewalsCounter] = useState(0)

  useEffect(() => {
    if (props.source.length > 0) {
      let newArray = [];
      props.source.forEach(element => {
        if (element.loanDetails.canRenew) {
          element.checked = true;
          element.success = false;
          newArray.push(element);
        }
      });
      setLocalLoansList(newArray)
    }
    return () => {
      setLocalLoansList([])
    }
  }, [props.source])

  // observe the checked status so if the user check/uncheck 
  // them all manually to update the check all button status
  useEffect(() => {
    if (localLoansList && localLoansList.length > 0) {
      let counter = 0;
      localLoansList.forEach(element => {
        if (element.checked) {
          counter++;
        }
      });
      if (counter === localLoansList.length) {
        setCheckAllBtn(true)
      } else {
        setCheckAllBtn(false)
      }
      setRenewalsCounter(counter);
    }
  }, [localLoansList])

  // function to check uncheck all at once
  const handleCheckAllBtn = (e) => {
    setCheckAllBtn(!checkAllBtn)
    if (e.target.checked) {
      localLoansList.forEach(loan => {
        loan.checked = true;
      });
      setRenewalsCounter(localLoansList.length)
    } else {
      localLoansList.forEach(loan => {
        loan.checked = false;
      });
      setRenewalsCounter(0)
    }
  }

  // const [renewalList, setRenewalList] = useState([])
  const updateRenewalList = (index) => {
    const updatedArray = [...localLoansList];
    updatedArray[index].checked = !updatedArray[index].checked
    setLocalLoansList(updatedArray);
  }

  // function to return the date in a readable format
  const fixUTCDate = (timestamp) => {
    return moment(timestamp).format('DD-MM-YYYY');
  }

  // function to get the bookjacket image from the currentLoans list (the source)
  const getImageURL = (id) => {
    let instance = props.source.filter(item => item.loanDetails.recordID === id)
    if (instance && instance.length > 0) {
      return instance[0].imageUrl
    }
    return '/image-placeholder-bookcover.jpg'
  }

  const renewAllLoans = (LoansIdArray) => {
    setLoading(true);
    let internalArray = [];

    LoansIdArray.forEach(element => {
      if (element.checked) {
        internalArray.push(element)
      }
    });

    async function send(i) {
      const _id = internalArray[i].loanDetails.loanID
      const source = internalArray[i].loanDetails.source

      var data = {
        // "id": user.userID,
        "id": _id,
        "source": source
      }
      setCurrentChecking(_id);
      try {
        const response = await props.axiosInstanceWithPatronToken.post(`/Loans/${_id}/renew`, data)
        if (response.status === 200) {
          internalArray[i].success = true;
          updateASingleLoan(_id);
        } else {
          toast.warn('Your loan renewal was unsuccessful, please try again later.')
        }
      } catch (err) {
        console.log("Error with renewing the loan - " + err);
      }

      if (i < internalArray.length - 1) {
        setTimeout(() => {
          i++;
          send(i);
        }, 1000);
      } else {
        toast.success('All selected loans has been renewed successfully')
        setTimeout(() => {
          props.onCancel();
        }, 1000);
        setCurrentChecking('');
        setLoading(false);
      }
    }
    send(0);
  }

  return (
    <div className="renewall-wrp">
      <h1>Loans renew</h1>
      <form onSubmit={(e) => { e.preventDefault(); renewAllLoans(localLoansList) }}>
        {localLoansList.length > 0 &&
          <Fragment>
            <p>Please select all the items you want to renew from the list below:</p>
            <div className="checkbox-wrapper checkall-wrapper" title={checkAllBtn ? "Unselect all your loans" : "Select all your loans"}>
              <input type="checkbox" checked={checkAllBtn} id="checkall_checkbox" onChange={(e) => handleCheckAllBtn(e)} />
              <label htmlFor="checkall_checkbox">{checkAllBtn ? "Uncheck all" : "Check all"}</label>
            </div>
          </Fragment>
        }
        <ul className="renewall-list">
          {localLoansList.map((loan, index) => (
            <li title={loan.title} className="checkbox-wrapper" key={loan.loanDetails.loanID}>
              <input type="checkbox"
                checked={loan.checked}
                id={`userLoan-${index}`}
                disabled={!loan.loanDetails.canRenew}
                onChange={() => updateRenewalList(index)}
                aria-checked={loan.checked ? "true" : "false"}
              />
              <label htmlFor={`userLoan-${index}`}>
                <div className="row">
                  <div className="col-md-3" style={{ position: "relative" }}>
                    <div className="loan-cover-wrp">
                      {(loading && currentChecking === loan.loanDetails.loanID) &&
                        <span className="renew-waiting">
                          <SyncLoader size={10} margin={1} color='var(--primary)' />
                        </span>
                      }
                      {loan.success &&
                        <FontAwesomeIcon className="success-check" icon={['fas', 'check-circle']} />
                      }
                      {(loan.success || (loading && currentChecking === loan.loanDetails.loanID)) &&
                        <div className="renewal-cover-overlay" />
                      }
                      <img className="renewal-cover" src={getImageURL(loan.recordID)} alt={`${loan.title} book cover`} />
                    </div>
                  </div>
                  <div className="col-md-9">
                    <p>{loan.title}</p>
                    <p><span><b>Due in: </b>{fixUTCDate(loan.dueObject)} {loan.overdue && <Fragment><b>Overdue? </b>Yes</Fragment>}</span></p>
                    <p><span><b>Renewal count/remaining: </b>{loan.renewalCount > -1 ? loan.renewalCount : "NA"} / {loan.renewalsRemaining > -1 ? loan.renewalsRemaining : "NA"}</span></p>
                  </div>
                </div>
              </label>
            </li>
          ))}
        </ul>

        <section className="login-dialogue-buttons p-0">
          {props.canConfirm &&
            <button className="contact-btn small" type="submit" disabled={!renewalsCounter > 0}>Renew Selected<FontAwesomeIcon icon={['far', 'arrow-right']} />
            </button>
          }
          {props.canCancel &&
            <button className="contact-btn small" type="button" onClick={() => props.onCancel()}>Cancel<FontAwesomeIcon icon={['fas', 'times']} />
            </button>
          }
        </section>
      </form>
    </div>
  )
};

export default RenewAll;