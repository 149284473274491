import React, { useState, useEffect, Fragment, useContext } from 'react'
import './Preferences.css';
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../../../AuthContext';

import luciData from '../../../luciConfig.json';

function PreferencesTwo(props) {
  const { convertToString, convertToArray } = useContext(AuthContext);

  const [selectedGenres, setSelectedGenres] = useState([]);
  const [savedGenres, setSavedGenres] = useState([]);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0); // to activate the navbar highlighting function

    setSelectedGenres(convertToArray(props.userSavedGenres).sort());
    setSavedGenres(convertToArray(props.userSavedGenres).sort()); // for comparison and make a decision to save at the end
  }, [])

  const addAndRemoveGenres = (genre) => {
    if (!selectedGenres.includes(genre)) {
      setSelectedGenres(selectedGenres.concat(genre));
    } else {
      const currState = [...selectedGenres];
      var index = currState.indexOf(genre)
      if (index !== -1 && selectedGenres.length > 2) { // at least two condition
        currState.splice(index, 1);
        setSelectedGenres(currState);
      }
    }
  }

  const onPrevious = () => {
    saveTheGenres();
    // go to the previous screen
    props.prevStep();
  }
  const onNext = () => {
    saveTheGenres();
    // go to the next screen
    props.nextStep();
  }
  const onSkip = () => {
    // go to bookshelf screen directly
    history.push("/user/account");
  }
  const onCancel = () => {
    history.push("/user/account");
  }
  const onBack = () => {
    saveTheGenres();
    history.push("/user/account");
  }
  const saveTheGenres = () => {
    if (JSON.stringify(selectedGenres) !== JSON.stringify(savedGenres)) {
      props.saveUserPreferenceGenres(convertToString(selectedGenres));
    }
  }
  return (
    <div className="preferences-outer-container" role="main">
      <div className="preferences-background-overly"></div>
      <div className="preferences-inner-container">
        <h3>Please select at least two of your favourite genre's/categories:</h3>
        <div className="preferences-genres-container">
          {luciData.userOptions[1].genres.map((genre, index) => {
            return (
              <div key={genre.id} onClick={() => addAndRemoveGenres(`${genre.id}`)}
                className={selectedGenres.includes(genre.id) ? "genres-outer genres-chosen" : "genres-outer"} >
                <p>{genre.name}</p>
              </div>
            )
          })}
        </div>

        {/*  BUTTONS  */}
        <div className="preferences-buttons">
          <div className="short-container justify-center">
            <div className="short">
              {selectedGenres.length > 1 &&
                <Fragment>
                  {/* <button className="next-btn" type='button' onClick={onPrevious}>Save &amp; back to Avatars<FontAwesomeIcon className="prev-icon" icon={['far', 'chevron-left']} /></button> */}
                  {/* <button className="next-btn" type='button' onClick={onNext}>Save &amp; next to Formats<FontAwesomeIcon className="next-icon" icon={['far', 'chevron-right']} /></button> */}
                  <button className="next-btn" type='button' onClick={onBack}>Save &amp; back to Account<FontAwesomeIcon className="next-icon" icon={['far', 'chevron-right']} /></button>
                </Fragment>
              }
              <button className="next-btn" type='button' onClick={onCancel}>Cancel<FontAwesomeIcon className="next-icon" icon={['far', 'chevron-right']} /></button>
            </div>
          </div>
        </div>
        {/* <div className="skip-link">
          <a onClick={() => onSkip()}>Complete preferences later on... 	&gt;</a>
        </div> */}
      </div>
    </div>
  );
}

export default PreferencesTwo;
