import React, { useEffect, useContext, useState, Fragment } from 'react';
import './Bookshelf.css';

import { useHistory } from 'react-router-dom';

import ServicesBar from '../common/carousel-navbars/ServicesBar';
import Addslider from './Addslider';
import Meta from '../common/Meta';
import Loans from '../common/Loans';
import Recommendations from '../common/Recommendations';

import { AuthContext } from '../../AuthContext';

const Bookshelf = (props) => {
  const { isAuthenticated, applicationData, adCaruselData, width0, isTabletOrMobile, loadingLevelOne, tabsToShow, parentTabs, digitalContentTab, libraryOGimage,
    loadingHolds, currentHolds, userLoans, userHolds, currentLoans, loadingLoans, loadingGlobalRecommendations, globalRecommendations, loadingUserRecommendations,
    userRecommendations, loadingLibraryRecommendations, libraryRecommendations, bookshelfTab, jacketsWidth, jacketsHeight, setModalStatus, setModalPayload } = useContext(AuthContext);
  let history = useHistory();

  const [levelTwoCanonicalTitle, setLevelTwoCanonicalTitle] = useState('');
  const [levelThreeCanonicalTitle, setLevelThreeCanonicalTitle] = useState('');
  const [metaData, setMetaData] = useState('');
  const [channelExtraction, setChannelExtraction] = useState([]);
  const [parentTabsClone, setParentTabsClone] = useState([]);


  useEffect(() => {
    window.scrollTo(0, 0); // to activate the navbar highlighting function

    checkCurrentLocation();

  }, []);

  useEffect(() => {
    if (parentTabs) {
      setParentTabsClone(parentTabs)
    }
  }, [parentTabs])

  useEffect(() => {
    if (applicationData && applicationData.publishedTitle && libraryOGimage) {
      setMetaData({
        title: `${applicationData.publishedTitle} | ${bookshelfTab.canonicalTitle}`,
        description: `Welcome to ${applicationData.publishedTitle} | ${bookshelfTab.canonicalTitle}`,
        type: 'website',
        image: libraryOGimage,
        canonical: ''
      })
    }
  }, [applicationData, libraryOGimage]);


  const checkCurrentLocation = () => {
    const { state } = props.location;
    if (state && state.from && isAuthenticated) {
      console.log(state.from ? state.from.pathname : "not found")
      // window.location = state.from.pathname
      // window.location = state ? state.from.pathname : "/";
      history.push({
        pathname: state.from.pathname,
      });
    }
  }

  const getCategory = (id, object) => {
    // console.log(object);
    history.push({
      pathname: `/${digitalContentTab.canonicalTitle}/${object.canonicalTitle}/`,
      // state: { goToID: id, goToObject: object }
    });
  }

  return (
    <div className="bookshelf-container" role="main">
      <Meta data={metaData} />
      <div className="skip-link-div">
        <a className="lsp-skip-link" href='#Library_Recommendations'>Skip to the content</a>
      </div>
      <Addslider isTabletOrMobile={isTabletOrMobile} width0={width0} source={adCaruselData} setModalStatus={setModalStatus} setModalPayload={setModalPayload} />
      <h1 className="visuallyhidden">{applicationData.publishedTitle} {bookshelfTab.canonicalTitle}</h1>
      <div className="rows-wrapper">
        <div className="skip-link-div">
          <a className="lsp-skip-link" href='#Library_Recommendations'>Skip to the content</a>
        </div>
        {parentTabs.length > 0 &&
          <ServicesBar
            tabIsLoading={loadingLevelOne}
            tabWidth={isTabletOrMobile ? "100" : jacketsWidth}
            action={getCategory}
            setLevelTwoCanonicalTitle={setLevelTwoCanonicalTitle}
            setLevelThreeCanonicalTitle={setLevelThreeCanonicalTitle}
            tabsToShow={tabsToShow}
            selectedTab=''
            setChannelExtraction={setChannelExtraction}
            source={parentTabs}
          />
        }

        {isAuthenticated &&
          // User Recommendations 
          <Recommendations
            rowTitle="We Think You May Like"
            noRecommendationsMessage="No user recommendations to show yet"
            loading={loadingUserRecommendations}
            source={userRecommendations}
            tabsToShow={tabsToShow}
            currentAnchor="User_Recommendations"
            nextAnchor="User_Loans"
          />
        }

        {/* Library Recommendations */}
        <Recommendations
          rowTitle={isAuthenticated ? "Your Library's Most Popular" : `${applicationData.publishedTitle} Most Popular`}
          noRecommendationsMessage="No library recommendations to show yet"
          loading={loadingLibraryRecommendations}
          source={libraryRecommendations}
          tabsToShow={tabsToShow}
          currentAnchor="Library_Recommendations"
          nextAnchor="Global_Recommendations"
        />

        {/* Global Recommendations */}
        <Recommendations
          rowTitle="Trending Worldwide"
          noRecommendationsMessage="No global recommendations to show yet"
          loading={loadingGlobalRecommendations}
          source={globalRecommendations}
          tabsToShow={tabsToShow}
          currentAnchor="Global_Recommendations"
          nextAnchor="User_Recommendations"
        />

        {isAuthenticated &&
          <Fragment>
            {/* <User Loans /> */}
            <Loans
              isBig
              isLoan
              rowTitle="My Loans"
              noItemsMessage="You don't have any loans currently"
              loading={loadingLoans}
              source={currentLoans}
              counter={userLoans.length}
              tabsToShow={tabsToShow}
              tabWidth={isTabletOrMobile ? "105" : jacketsWidth + 5}
              tabHeight={isTabletOrMobile ? "160" : jacketsHeight}
              currentAnchor="User_Loans"
              nextAnchor="User_Holds"
            />

            {/* <User Holds /> */}
            <Loans
              isBig
              isHold
              rowTitle="My Reservations"
              noItemsMessage="You don't have any reservations currently"
              loading={loadingHolds}
              source={currentHolds}
              counter={userHolds.length}
              tabsToShow={tabsToShow}
              tabWidth={isTabletOrMobile ? "105" : jacketsWidth + 5}
              tabHeight={isTabletOrMobile ? "160" : jacketsHeight}
              currentAnchor="User_Holds"
              nextAnchor="footer"
            />
          </Fragment>
        }
      </div>
    </div>
  );
}


export default Bookshelf;
