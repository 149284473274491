import React, { useState, useEffect, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Panel } from 'rc-collapse';

const FilterPanel = (props) => {
  const { handleScrollDown, handleScrollUp, updateFiltersPool, loading, title, plural, counter, forKey, source } = props;
  const [instant, setInstant] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [limit, setLimit] = useState(0);
  const [showAllToggle, setShowAllToggle] = useState(false);

  useEffect(() => {
    if (source.length > 0) {
      if (source.length > 15) {
        setLimit(10)
      }
      const reduced = source.filter(item => instant === '' || item.facetName.toLowerCase().includes(instant.toLowerCase()))
      setFilteredItems(reduced)
    }
    return () => {
      setFilteredItems([])
    }
  }, [source, instant])

  const onChangeMiniFilter = (e) => {
    setInstant(e.target.value)
  }

  const increaseTheLimit = () => {
    let counter = limit + 10
    setLimit(counter)
    if (counter >= source.length) {
      setShowAllToggle(true)
    }
  }
  const resetTheLimit = () => {
    setShowAllToggle(false);
    if (source.length > 15) {
      setLimit(10)
    } else {
      setLimit(0)
    }
  }
  const InternalButtons = () => {
    return (
      <div className="filters-container-buttons inside">
        <button
          className="filters-container-btn"
          title={`Show more 10 ${plural}`}
          onClick={() => increaseTheLimit()}
          disabled={limit >= source.length}
        >More {plural}<FontAwesomeIcon icon={["fas", "plus"]} /></button>
        <button
          className="filters-container-btn"
          title={showAllToggle ? `Reset to few ${plural} in the list` : `Show all the ${plural} in the list`}
          onClick={showAllToggle ? () => { resetTheLimit() } : () => { setLimit(source.length); setShowAllToggle(true) }}
        >{showAllToggle ? `Few ${plural}` : `All ${plural}`}{showAllToggle ? <FontAwesomeIcon icon={["fad", "ball-pile"]} /> : <FontAwesomeIcon icon={["fas", "ball-pile"]} />}</button>
      </div>
    )
  }

  return (
    <Panel {...props} header={`${title} ${loading ? " ( ... ) " : `( ${counter > 0 ? counter + ' / ' : ''}` + `${source.length}` + ' )'}`}>
      <div className="filters-cat-window">
        {(source.length > 6 && !loading) &&
          <Fragment>
            <input className="internal-filtering" value={instant} placeholder={`Filter ${title} list`} type="text" onChange={e => onChangeMiniFilter(e)} />
            {instant &&
              <Fragment>
                <button title="Clear this internal filter" className="clear-inter-filter" onClick={() => setInstant('')}>
                  <FontAwesomeIcon icon={["fal", "times-circle"]} size="lg" />
                </button>
                <p className="mini-filter-count">{filteredItems.length}</p>
              </Fragment>
            }
          </Fragment>
        }
        {(filteredItems.length > 0 && !loading) ?
          <Fragment>
            {filteredItems.length > 4 &&
              <button className="arrow-up" onClick={() => handleScrollDown(forKey)} title="Scroll up">
                <FontAwesomeIcon icon={["fas", "sort-up"]} />
              </button>
            }
            <ul className="filter-list" id={forKey}>
              {filteredItems.map((item, index) => (
                <Fragment key={`${forKey}-listItem-${index}`}>
                  {(index < limit || limit === 0) &&
                    <li title={item.facetName} className="checkbox-wrapper" >
                      <input type="checkbox" checked={item.checked} aria-checked={item.checked ? "true" : "false"} value={item.facetValue} id={`${forKey}-${index}`}
                        onChange={(e) => { updateFiltersPool(e, item, item.facetType) }} />
                      <label htmlFor={`${forKey}-${index}`} >{item.facetName} {item.facetCount > 0 && <span className="count-segment">({item.facetCount})</span>}</label>
                    </li>
                  }
                </Fragment>
              ))}
            </ul>
            {filteredItems.length > 4 &&
              <button className="arrow-down" onClick={() => handleScrollUp(forKey)} title="Scroll down">
                <FontAwesomeIcon icon={["fas", "sort-down"]} />
              </button>
            }
            {(source.length > 15 && instant === '') &&
              <InternalButtons />
            }
          </Fragment> : <p className="no-items">{loading ? "loading..." : `No ${title} items to show`}</p>
        }
      </div>
    </Panel>
  );
}

export default FilterPanel;