import React, { useContext, useEffect, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SyncLoader } from 'react-spinners'
import { AuthContext } from '../../../AuthContext';
import { useForm } from 'react-hook-form';

export default function ResetPassword(props) {
  const { passwordMessage, isWaiting, isTabletOrMobile, newPassword, clearMessages } = useContext(AuthContext);
  const history = useHistory();

  const { register, errors, handleSubmit, watch } = useForm({
    mode: 'all'
  });

  const pin_password = useRef({});
  pin_password.current = watch("pin_password", "");

  // reCAPTCHA handling
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
      if (!isScriptExist) {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
      if (isScriptExist && callback) callback();
    }

    // loading the recaptcha script
    loadScriptByURL("recaptcha-script", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`, function () {
      // console.log("Recaptcha script loaded successfully!");
    });

    // cleaning up the script and badge after use
    return () => {
      let script = document.getElementById("recaptcha-script");
      let badge = document.querySelector(".grecaptcha-badge");
      if (script && badge) {
        script.remove();
        let nextNode = badge.nextSibling;
        if (nextNode) {
          nextNode.remove();
        }
        badge.remove();
        // console.log("Recaptcha script removed!");
      }
    }
  }, []);


  const onSubmitPasswordForm = (data) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(token => {
        changePassword(data, token)
      });
    });
  };

  const changePassword = (data, token) => {
    // call a backend API to verify reCAPTCHA response
    fetch('/verify', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        'g-recaptcha-response': token
      })
    }).then(res => res.json()).then(res => {
      // console.log(res);
      // console.log(data);
      if (res.success) {
        let currentUrl = props.location.pathname
        let urlArray = currentUrl.split('/')
        const levelTwo = urlArray[3];
        newPassword(data.pin_password, levelTwo);
      }
    });
  }

  const handleExit = () => {
    history.push('/')
  }

  const handleFormReset = () => {
    clearMessages();
  }

  return (
    <div className="wcard-outer-container">
      <div className="wcard-container">
        <h2 className="form-title">Reset Password</h2>
        <form className="login-form" onSubmit={handleSubmit(onSubmitPasswordForm)} onReset={handleFormReset}>
          <section className="modal-content" style={{ flexWrap: "wrap", flexDirection: "row" }}>

            <div className="select-container" style={{ width: "100%" }}>
              <label className="input-label" htmlFor="pin_password">New Password:</label>
              <input aria-labelledby="pin_password"
                name="pin_password"
                className="input-field"
                type="password"
                title={`Please enter your new password`}
                autoComplete="off"
                ref={register({
                  required: { value: true, message: `Your new password is required` },
                  minLength: { value: 4, message: `Password must have at least 4 characters` },
                  maxLength: { value: 8, message: `Password must have no more than 8 characters` }
                })} />
              {errors.pin_password && errors.pin_password.type === "required" && <p className="error-msg">{errors.pin_password.message}</p>}
              {errors.pin_password && errors.pin_password.type === "minLength" && <p className="error-msg">{errors.pin_password.message}</p>}
              {errors.pin_password && errors.pin_password.type === "maxLength" && <p className="error-msg">{errors.pin_password.message}</p>}

              <label className="input-label" htmlFor="confirm_password">Confirm new password:</label>
              <input aria-labelledby="confirm_password" name="confirm_password" className="input-field" type="password" title={`Please confirm your new password`}
                autoComplete="off"
                ref={register({
                  required: true,
                  validate: value =>
                    value === pin_password.current || "The passwords do not match"
                })} />
              {errors.confirm_password && <p className="error-msg">{errors.confirm_password.message}</p>}
              {passwordMessage &&
                <p className="error-msg" style={{ color: passwordMessage === 'Success!' && 'var(--success-green)' }}>
                  {passwordMessage}</p>
              }
            </div>

            <div className="login-dialogue-buttons">
              <button
                className="contact-btn"
                type="submit"
                disabled={isWaiting || passwordMessage !== ""}
              >
                {!isWaiting &&
                  <Fragment> Save Password <FontAwesomeIcon icon={['fas', 'arrow-alt-right']} /> </Fragment>
                }
                {isWaiting &&
                  <span className="login-waiting">
                    {isTabletOrMobile ?
                      <SyncLoader size={5} margin={0} color='var(--primary)' /> :
                      <SyncLoader size={10} margin={1} color='var(--primary)' />
                    }
                  </span>
                }
              </button>
              <button className="contact-btn" type="reset" disabled={passwordMessage === ""}>Reset<FontAwesomeIcon icon={['fas', 'undo']} /></button>
              <button className="contact-btn" type="button" onClick={handleExit}> {passwordMessage === "" ? "Cancel" : "Ok"}<FontAwesomeIcon icon={passwordMessage === "" ? ['fas', 'times'] : ['far', 'check']} /></button>
            </div>
          </section>
        </form>
      </div>
    </div>
  );
}
