import React, { useState, useEffect, useContext, Fragment } from 'react';
import { AuthContext } from './AuthContext';

const Themes = () => {
  const { isAuthenticated, userSavedTheme, setUserSavedTheme, applicationData, theApiTheme, theUserChoiceTheme } = useContext(AuthContext);

  const [appThemes, setAppThemes] = useState([]);
  const [currentTheme, setCurrentTheme] = useState('');

  useEffect(() => {
    if (applicationData) {
      setAppThemes(applicationData.themes);
    }
  }, [applicationData])

  useEffect(() => {
    if (appThemes && appThemes.length > 0 && theApiTheme) {
      const value = getInitialTheme();
      // console.log('theme received ' + value);
      changeTheme(value)
    }
  }, [appThemes, theApiTheme, userSavedTheme])

  useEffect(() => {
    if (theUserChoiceTheme !== '') {
      changeTheme(theUserChoiceTheme)
    }
  }, [theUserChoiceTheme])

  const changeTheme = (value) => {
    if (appThemes && appThemes.length > 0 && currentTheme !== value) {
      setCurrentTheme(value)
      // console.log('changing to ' + value);
      if (value !== 'auto') {
        const selectedTheme = appThemes.filter(theme => (theme.name === value));
        const themeObject = selectedTheme[0];

        if (themeObject !== undefined) {
          document.documentElement.style.setProperty("--primary", themeObject.primaryColour !== '#0' ? themeObject.primaryColour : '#000')
          document.documentElement.style.setProperty("--secondary", themeObject.secondaryColour !== '#0' ? themeObject.secondaryColour : '#afafaf')
          document.documentElement.style.setProperty("--background-color", themeObject.pageBackgroundColour !== '#0' ? themeObject.pageBackgroundColour : '#FFF')
          document.documentElement.style.setProperty("--footer-color", themeObject.footerBackgroundColour !== '#0' ? themeObject.footerBackgroundColour : '#FFF')
          document.documentElement.style.setProperty("--card-color", themeObject.cardsBackgroundColour !== '#0' ? themeObject.cardsBackgroundColour : '#FFF')
          document.documentElement.style.setProperty("--navbar-icons-color", themeObject.navBarIconColour !== '#0' ? themeObject.navBarIconColour : '#999')
          document.documentElement.style.setProperty("--sticky-navbar-icons-color", themeObject.stickyNavBarIconColour !== '#0' ? themeObject.stickyNavBarIconColour : '#999')
          document.documentElement.style.setProperty("--background-pattern", themeObject.background !== null ? `url(data:image/png;base64,${themeObject.background.fileData})` : '')
          document.documentElement.style.setProperty("--logo-main", themeObject.mainLogo !== null ? `url(data:image/png;base64,${themeObject.mainLogo.fileData})` : 'url(/generic-logo.svg)')
          document.documentElement.style.setProperty("--logo-sticky", themeObject.stickyLogo !== null ? `url(data:image/png;base64,${themeObject.stickyLogo.fileData})` : 'url(/generic-logo.svg)')
          document.documentElement.style.setProperty("--navbar-color", getNavbarGradient(themeObject))
          document.documentElement.style.setProperty("--mobile-navbar-color", themeObject.navBarGradientColour !== '#0' ? themeObject.navBarGradientColour : (themeObject.pageBackgroundColour !== '#0' ? themeObject.pageBackgroundColour : "#000"))
          document.documentElement.style.setProperty("--transparent-primary", `rgba(${hexToRgb(themeObject.primaryColour).r} ,${hexToRgb(themeObject.primaryColour).g} ,${hexToRgb(themeObject.primaryColour).b} , 0.30)`)
          document.documentElement.style.setProperty("--left-arrow-bg", `linear-gradient(90deg, rgba(${hexToRgb(themeObject.pageBackgroundColour).r}, ${hexToRgb(themeObject.pageBackgroundColour).g}, ${hexToRgb(themeObject.pageBackgroundColour).b}, 1) 0%, rgba(${hexToRgb(themeObject.pageBackgroundColour).r}, ${hexToRgb(themeObject.pageBackgroundColour).g}, ${hexToRgb(themeObject.pageBackgroundColour).b}, 0) 100% )`)
          document.documentElement.style.setProperty("--right-arrow-bg", `linear-gradient(270deg, rgba(${hexToRgb(themeObject.pageBackgroundColour).r}, ${hexToRgb(themeObject.pageBackgroundColour).g}, ${hexToRgb(themeObject.pageBackgroundColour).b}, 1) 10%, rgba(${hexToRgb(themeObject.pageBackgroundColour).r}, ${hexToRgb(themeObject.pageBackgroundColour).g}, ${hexToRgb(themeObject.pageBackgroundColour).b}, 0) 100%)`)
          document.documentElement.style.setProperty("--promo-carousel-bg", `radial-gradient(circle at top left, rgba(${hexToRgb(themeObject.pageBackgroundColour).r}, ${hexToRgb(themeObject.pageBackgroundColour).g}, ${hexToRgb(themeObject.pageBackgroundColour).b}, 1) 40%, rgba(${hexToRgb(themeObject.pageBackgroundColour).r}, ${hexToRgb(themeObject.pageBackgroundColour).g}, ${hexToRgb(themeObject.pageBackgroundColour).b}, 0) 100%)`)

          if (themeObject.name === "Light Theme") {
            document.documentElement.style.setProperty("--overlay-color", `rgba(${hexToRgb(themeObject.pageBackgroundColour).r}  ,${hexToRgb(themeObject.pageBackgroundColour).g} ,${hexToRgb(themeObject.pageBackgroundColour).b} , 0.90)`)
            document.documentElement.style.setProperty("--book-shading", 'linear-gradient(-90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 1%, rgba(255, 255, 255, 0.1) 2%, rgba(0, 0, 0, 0.1) 78%, rgba(255, 255, 255, 0.6) 90%, rgba(0, 0, 0, 0.1) 95%, rgba(0, 0, 0, 0.1) 97%, rgba(0, 0, 0, 0.55) 100% )')
          } else {
            document.documentElement.style.setProperty("--overlay-color", `rgba(${hexToRgb(themeObject.pageBackgroundColour).r}  ,${hexToRgb(themeObject.pageBackgroundColour).g} ,${hexToRgb(themeObject.pageBackgroundColour).b} , 0.85)`)
            document.documentElement.style.setProperty("--book-shading", 'linear-gradient(-90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.3) 1%, rgba(255, 255, 255, 0.1) 2%, rgba(0, 0, 0, 0.2) 78%, rgba(255, 255, 255, 0.6) 90%, rgba(0, 0, 0, 0.2) 95%, rgba(0, 0, 0, 0.4) 97%, rgba(0, 0, 0, 0.85) 100% )')
          }
        } else { // the defaults when no connection with the server or an error occurred with the theme object
          document.documentElement.style.setProperty("--primary", 'pink')
          document.documentElement.style.setProperty("--secondary", '#afafaf')
          document.documentElement.style.setProperty("--background-color", '#FFF')
          document.documentElement.style.setProperty("--footer-color", '#FFF')
          document.documentElement.style.setProperty("--card-color", '#FFF')
          document.documentElement.style.setProperty("--navbar-icons-color", '#999')
          document.documentElement.style.setProperty("--sticky-navbar-icons-color", '#999')
          document.documentElement.style.setProperty("--background-pattern", '')
          document.documentElement.style.setProperty("--logo-main", 'url(/generic-logo.svg)')
          document.documentElement.style.setProperty("--logo-sticky", 'url(/generic-logo.svg)')
          document.documentElement.style.setProperty("--navbar-color", 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%)')
          document.documentElement.style.setProperty("--mobile-navbar-color", '#FFF')
          document.documentElement.style.setProperty("--overlay-color", 'rgba(255 , 255, 255, 0.95)')
          document.documentElement.style.setProperty("--left-arrow-bg", 'linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100% )')
          document.documentElement.style.setProperty("--right-arrow-bg", 'linear-gradient(270deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%)')
          document.documentElement.style.setProperty("--promo-carousel-bg", 'radial-gradient(circle at top left, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0) 100%)')
        }
      } else {
        const newValue = getInitialTheme();
        // console.log('theme for auto ' + newValue);
        changeTheme(newValue)
      }
    }
  }

  const getNavbarGradient = (themeObject) => {
    const darkRed = hexToRgb(themeObject.pageBackgroundColour).r || 0
    const darkGreen = hexToRgb(themeObject.pageBackgroundColour).g || 0
    const darkBlue = hexToRgb(themeObject.pageBackgroundColour).b || 0
    const lightRed = hexToRgb(themeObject.pageBackgroundColour).r || 255
    const lightGreen = hexToRgb(themeObject.pageBackgroundColour).g || 255
    const lightBlue = hexToRgb(themeObject.pageBackgroundColour).b || 255

    const darkGradient = `linear-gradient(0deg, rgba(${darkRed},${darkGreen},${darkBlue},0) 0%, rgba(${darkRed},${darkGreen},${darkBlue},1) 50%, rgba(${darkRed},${darkGreen},${darkBlue},1) 100%)`
    const lightGradient = `linear-gradient(0deg, rgba(${lightRed},${lightGreen},${lightBlue},0) 0%, rgba(${lightRed},${lightGreen},${lightBlue},1) 50%, rgba(${lightRed},${lightGreen},${lightBlue},1) 100%)`

    if (themeObject.navBarGradientColour !== '' && themeObject.navBarGradientColour !== undefined) {
      // return themeObject.navBarGradientColour
      const red = hexToRgb(themeObject.navBarGradientColour).r || 0
      const green = hexToRgb(themeObject.navBarGradientColour).g || 0
      const blue = hexToRgb(themeObject.navBarGradientColour).b || 0
      return `linear-gradient(0deg, rgba(${red},${green},${blue},0) 0%, rgba(${red},${green},${blue},1) 50%, rgba(${red},${green},${blue},1) 100%)`
    } else if (theUserChoiceTheme) { // on CMS theme is Auto? 
      if (theUserChoiceTheme === 'Dark Theme') {
        return darkGradient
      } else if (theUserChoiceTheme === 'Light Theme') {
        return lightGradient
      } else if (theUserChoiceTheme === 'auto') {
        const userPrefersDark = getPreferColorScheme();
        if (userPrefersDark) {
          return darkGradient;
        } else {
          return lightGradient;
        }
      }
    } else if (theApiTheme) {
      if (theApiTheme === 'Dark Theme') {
        return darkGradient
      } else if (theApiTheme === 'Light Theme') {
        return lightGradient
      } else if (theApiTheme === 'auto') {
        const userPrefersDark = getPreferColorScheme();
        if (userPrefersDark) {
          return darkGradient;
        } else {
          return lightGradient;
        }
      }
    } else {
      return darkGradient
    }
  }

  const getInitialTheme = () => {
    if (isAuthenticated) {  // only registered users
      if (userSavedTheme !== '') {
        if (theApiTheme === 'auto') {
          if (userSavedTheme === 'auto') {
            const userPrefersDark = getPreferColorScheme();
            if (userPrefersDark) {
              // console.log('CMS is auto & UserChoice is auto & Auth users browser return Dark ')
              return 'Dark Theme';
            } else {
              // console.log('CMS is auto & UserChoice is auto & Auth users browser return Light ')
              return 'Light Theme';
            }
          } else {
            // console.log('CMS is auto & User choice theme is not auto -> ' + userSavedTheme)
            return userSavedTheme
          }
        } else if (userSavedTheme === 'auto') {
          // console.log('CMS is not auto => CMS choice theme selected -> ' + theApiTheme)
          return theApiTheme
        } else {
          // console.log('User choice theme selected -> ' + userSavedTheme)
          return userSavedTheme
        }
      } else {
        setUserSavedTheme(theApiTheme) // first time auth visitors those who has no Theme preference yet
      }
    } else { // Unregistered users
      if (theApiTheme === 'auto') {
        const userPrefersDark = getPreferColorScheme();
        if (userPrefersDark) {
          // console.log('Unregistered user & CMS is auto & users browser return Dark ')
          return 'Dark Theme';
        } else {
          // console.log('Unregistered user & CMS is auto & users browser return Light ')
          return 'Light Theme';
        }
      } else {
        // console.log('Unregistered user & CMS is not auto -> CMS choice theme selected -> ' + theApiTheme)
        return theApiTheme
      }
    }
  }

  const getLibraryDefaultTheme = () => {
    try {
      const initialTheme = applicationData.defaultTheme;
      // const initialSkin = libaryData.skins.filter(theme => theme.default === true);
      if (initialTheme) {
        if (initialTheme === 'auto') {
          const userPrefersDark = getPreferColorScheme();
          if (userPrefersDark) {
            // console.log('User browser return Dark ')
            return 'Dark Theme';
          } else {
            return 'Light Theme';
          }
        }
        // console.log('Initial Theme from the server is:' + initialTheme)
        return initialTheme
      }
    } catch (err) {
      console.error(err)
    }
  }

  const getPreferColorScheme = () => {
    // First, check if the user's browser supports Match-Media feature if not return true to activate Dark mode
    if (!window.matchMedia) return true;
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  }

  const hexToRgb = (hex) => {
    if (hex !== undefined) {
      if (hex === "#0") { // if the theme still return the default value
        return { r: 0, g: 0, b: 0 }
      } else {
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
          return r + r + g + g + b + b;
        });

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }
    } else {
      return { r: 0, g: 0, b: 0 }
    }
  }

  return (
    <Fragment></Fragment>
  )
}

export default Themes;