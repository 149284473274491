import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Arrow = (props) => {
  const { styleClassName, direction, clickHandler, isTabletOrMobile, carouselName, disableTheArrow } = props;
  return (
    <button
      className={styleClassName}
      onClick={e => clickHandler(direction)}
      aria-label={direction === "right" ? `Slide ${carouselName} carousel to the right` : `Slide ${carouselName} carousel to the left`}
      title={direction === "right" ? `Slide ${carouselName} carousel to the right` : `Slide ${carouselName} carousel to the left`}
      disabled={disableTheArrow}
    >
      {!isTabletOrMobile &&
        <FontAwesomeIcon className={`carousel-${direction}`} icon={['fal', `chevron-${direction}`]} />
      }
    </button>
  );
}

export default Arrow;