import React, { useState, useContext, useEffect, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../../../AuthContext';
import FocusTrap from 'focus-trap-react';
import { css } from '@emotion/core';
import { ScaleLoader } from 'react-spinners';

const FormatPreferences = () => {
  const { convertToString, convertToArray, userSavedFormats, saveUserPreferenceFormats, luciUserFormats, loadingPreference } = useContext(AuthContext);

  const [resetTrigger, setResetTrigger] = useState(false);
  const [activeFormatsTrap, setActiveFormatsTrap] = useState(false);
  const [formatPreferences, setFormatPreferences] = useState([]);
  const [selectedFormatsCounter, setSelectedFormatsCounter] = useState(0);

  useEffect(() => {
    if (formatPreferences) {
      countFormatPreferences();
    }
  }, [formatPreferences])

  useEffect(() => {
    if (resetTrigger) {
      initiateArrays(userSavedFormats)
    }
    return () => {
      setResetTrigger(false);
    }
  }, [resetTrigger])

  useEffect(() => {
    initiateArrays(userSavedFormats)
  }, [userSavedFormats])

  const initiateArrays = (str) => {
    const originalArray = convertToArray(str);

    if (originalArray.length > 0) {
      let newFormatsArray = []

      luciUserFormats.forEach(item => {
        if (originalArray.includes(item.code)) {
          item.checked = true;
        } else {
          item.checked = false;
        }
        newFormatsArray.push(item)
      });

      setFormatPreferences(newFormatsArray);

    } else { // reset if no new data been found
      setFormatPreferences(luciUserFormats)
    }
  }

  const generateArray = () => {
    let newUserFormatsArray = []

    formatPreferences.forEach(item => {
      if (item.checked) {
        newUserFormatsArray.push(item.code)
      }
    });

    return newUserFormatsArray.sort();
  }

  const mountMiniTrap = () => {
    setActiveFormatsTrap(true);
  }
  const unmountMiniTrap = () => {
    setActiveFormatsTrap(false);
  }
  const cancelMiniTrap = () => {
    setSelectedFormatsCounter(0);
    setResetTrigger(true);
    unmountMiniTrap();
  }

  const onSubmitFormat = (e) => {
    e.preventDefault();
    if (selectedFormatsCounter > 0) {
      saveUserPreferenceFormats(convertToString(generateArray()));
      if (!loadingPreference) {
        unmountMiniTrap();
      }
    } else {
      console.log("Select one at least")
    }
  }

  const addRemoveFormatOptions = (request_code) => {
    let updatedArray = [...formatPreferences]
    let index = updatedArray.findIndex(x => x.code === request_code)
    updatedArray[index].checked = !updatedArray[index].checked
    setFormatPreferences(updatedArray);
  }

  const countFormatPreferences = () => {
    let counter = 0;
    formatPreferences.forEach(item => {
      if (item.checked) {
        counter = counter + 1;
      }
    });
    setSelectedFormatsCounter(counter);
  }

  const loaderCSS = css`
      position: absolute;
      opacity: 0.4;
      top: 40%;
      left: 45%;
      z-index: 1;
    `;

  return (
    <div className={activeFormatsTrap ? "settings-column active" : "settings-column"}>
      {loadingPreference && <ScaleLoader css={loaderCSS} height={50} width={6} color='var(--primary)' />}
      <FocusTrap
        active={activeFormatsTrap}
        focusTrapOptions={{
          onDeactivate: () => cancelMiniTrap(),
          initialFocus: false,
          clickOutsideDeactivates: true,
          escapeDeactivates: false,
        }}
      >
        <form onSubmit={(e) => onSubmitFormat(e)} onReset={cancelMiniTrap}>
          <section className="settings-title">
            <div className="title-wrp top-aligned">
              <h2>Preferred Formats</h2>
              {!activeFormatsTrap &&
                <button title="Edit user's preferred Formats" className="edit-btn" onClick={() => mountMiniTrap()}>Edit <FontAwesomeIcon icon={["fas", "pen"]} /></button>
              }
              {activeFormatsTrap &&
                <div className="settings-buttons">
                  <button title="Save user's preferred Formats" type="submit"
                    disabled={userSavedFormats === convertToString(generateArray()) || selectedFormatsCounter === 0}
                  >Save <FontAwesomeIcon icon={["fas", "save"]} /></button>
                  <button title="Cancel" type="reset">Cancel <FontAwesomeIcon icon={["far", "times"]} /></button>
                </div>
              }
            </div>
          </section>
          <ul className="preference-list pref-list-ver">
            {(selectedFormatsCounter > 0 || activeFormatsTrap) ?
              <Fragment>
                {formatPreferences.map((format, index) =>
                (<li className="checkbox-wrapper" key={`format-${index}`}>
                  <input
                    type="checkbox"
                    id={format.id}
                    onChange={() => addRemoveFormatOptions(format.code)}
                    disabled={!activeFormatsTrap}
                    checked={format.checked}
                    aria-checked={format.checked ? "true" : "false"}
                  />
                  <label className="preference-label" htmlFor={format.id}>{format.value}</label>
                </li>
                ))}
              </Fragment> : <li className="preference-line"> No preferences yet!</li>
            }
          </ul>
          {(selectedFormatsCounter === 0 && activeFormatsTrap) &&
            <p className="preference-warning"><FontAwesomeIcon icon={['fal', 'exclamation-triangle']} /> Please select ONE at least!</p>
          }
        </form>
      </FocusTrap>
    </div>
  );

}

export default FormatPreferences;