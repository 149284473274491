import React, { Component, Fragment } from 'react'

import { css } from '@emotion/core'
import { ScaleLoader } from 'react-spinners'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavHashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../../AuthContext';
import MiniJacket from './book-jackets/MiniJacket';
import BookJacket from './book-jackets/BookJacket';
import Arrow from './Arrow';

export default class Loans extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.changeSlide = this.changeSlide.bind(this)
    this.clickHandler = this.clickHandler.bind(this)
    this.setArrowDisplay = this.setArrowDisplay.bind(this)
    this.setTheVisibleJacketsArray = this.setTheVisibleJacketsArray.bind(this);
    this.getMiniJacketsCount = this.getMiniJacketsCount.bind(this);
    this.handleReloadCarousel = this.handleReloadCarousel.bind(this);

    this.state = {
      activeIndex: 0,
      displayRightArrow: false,
      displayLeftArrow: false,
      bookJacketsToScroll: 0,
      visibleJacketsArray: [],
      localCounter: 0,
      carouselLimit: 14 // initial items to load when first access the 
    }
  }

  setArrowDisplay = (currentSlide) => {
    const displayLeftArrow = currentSlide !== 0;
    const displayRightArrow = (currentSlide + this.context.jacketsCountPerCarousel) <= this.props.source.length;

    this.setState({ displayRightArrow, displayLeftArrow });
  };

  setTheVisibleJacketsArray = (startingIndex, arraylength) => {
    let localArray = []

    for (let index = 0; index < arraylength; index++) {
      localArray.push(startingIndex)
      startingIndex++
    }

    this.setState({ visibleJacketsArray: localArray });
  };

  componentDidMount() {
    this.setArrowDisplay(0);
    this.setTheVisibleJacketsArray(0, this.context.isTabletOrMobile ? this.getMiniJacketsCount() : this.context.jacketsCountPerCarousel);

    this.setState({
      bookJacketsToScroll:
        this.props.tabsToShow >= 5 ? this.props.tabsToShow - 2 : this.props.tabsToShow,
      localCounter: this.props.counter
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.tabsToShow !== prevProps.tabsToShow) {
      // console.log(this.context.tabsToShow)
      this.setState({
        bookJacketsToScroll: this.props.tabsToShow >= 5 ? this.props.tabsToShow - 2 : this.props.tabsToShow
      });
    }
    if (this.props.source !== prevProps.source) {
      this.setArrowDisplay(this.state.activeIndex);
    }
    if (this.props.counter !== prevProps.counter) {
      // console.log("Counter changed")
      this.setState({ localCounter: this.props.counter });
    }
  }

  changeSlide = (index) => {
    this.setState({
      activeIndex: index
    });
  }

  clickHandler = (direction) => {
    if (direction === 'left') {
      this.slider.slickPrev();
    } else if (direction === 'right') {
      this.slider.slickNext();
    }
  };

  handleReloadCarousel = () => {
    if (this.props.isLoan) {
      this.context.refreshUserLoans()
    } else if (this.props.isHold) {
      this.context.refreshUserHolds()
    } else if (this.props.isArchive) {
      this.context.refreshUserArchive()
    }
    this.setState({ activeIndex: 0 })
  }

  handleAfterChange = (index) => {
    this.setState({ activeIndex: index, carouselLimit: this.state.carouselLimit + this.state.bookJacketsToScroll });
    this.setArrowDisplay(index);

    // load more from the api if required (when click on the right arrow or swipe to the right)
    if (this.state.localCounter > 11) {
      if (this.props.isLoan) {
        if ((index + this.context.jacketsCountPerCarousel) > (this.context.lazyArchiveLoadingCounter - this.state.bookJacketsToScroll)) {
          this.context.getMoreLoans(this.context.lazyLoansLoadingCounter, this.state.bookJacketsToScroll);
        }
      } else if (this.props.isHold) {
        if ((index + this.context.jacketsCountPerCarousel) > (this.context.lazyArchiveLoadingCounter - this.state.bookJacketsToScroll)) {
          this.context.getMoreHolds(this.context.lazyHoldsLoadingCounter, this.state.bookJacketsToScroll);
        }
      } else if (this.props.isArchive) {
        if ((index + this.context.jacketsCountPerCarousel) > (this.context.lazyArchiveLoadingCounter - this.state.bookJacketsToScroll)) {
          this.context.getMoreArchives(this.context.lazyArchiveLoadingCounter, this.state.bookJacketsToScroll);
        }
      }
    }
  }

  scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -160;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }

  getMiniJacketsCount = () => {
    const width = window.innerWidth
    return Math.floor(width / 110)
  }

  render() {
    const loaderCSS = css`
    position: absolute;
    opacity: 0.4;
    z-index: 9;
    top: 30%;
    left: 35%;
      `;

    const loaderMyAccount = css`
    position: absolute;
    opacity: 0.4;
    z-index: 9;
    top: 70px;
    left: 50px;
      `;

    const loaderCSSmini = css`
    position: absolute;
    opacity: 0.4;
    z-index: 9;
    top: 50px;
    left: 35px;
      `;

    const sliderSetting = {
      dots: false,
      arrows: false,
      accessibility: true,
      draggable: true,
      speed: process.env.REACT_APP_CAROUSEL_SPEED,
      slidesToScroll: this.context.isTabletOrMobile ? 2 : this.state.bookJacketsToScroll,
      slidesToShow: this.context.isTabletOrMobile ? this.getMiniJacketsCount() : (this.props.isFromUserAccount ? this.context.jacketsCountPerCarousel - 4 : this.context.jacketsCountPerCarousel - 1),
      swipeToSlide: true,
      variableWidth: true,
      infinite: false,
      nextArrow: null,
      prevArrow: null,
      beforeChange: (oldIndex, newIndex) => { this.setTheVisibleJacketsArray(newIndex, this.context.isTabletOrMobile ? this.getMiniJacketsCount() : this.context.jacketsCountPerCarousel) },
      afterChange: newIndex => { this.handleAfterChange(newIndex) }
    }

    return (
      <Fragment>
        <div className="title-wrp" style={{ marginTop: "30px" }}>
          <h2 className={this.props.isFromUserAccount ? "row-title mini-row-title" : "row-title"} id={this.props.currentAnchor}>{this.props.rowTitle} {this.state.localCounter > 0 && <span>( {this.state.localCounter} )</span>} </h2>
          {/* {!this.props.loading && */}
          <button onClick={() => this.handleReloadCarousel()} className="reload-crsl-btn" title={`Click to reload ${this.props.rowTitle}`}><FontAwesomeIcon icon={['far', 'sync']} spin={this.props.loading ? true : false} /></button>
          {/* } */}

          {(this.props.isLoan && this.props.source.length > 2) && <button onClick={() => this.context.setModalRenewAllStatus(true)} className="lsp-title-btn">Renew all</button>}

          <a className="lsp-skip-link" href={`#${this.props.nextAnchor}`}>Skip to the next row</a>
        </div>
        <div className={this.props.isFromUserAccount ? "mini-books-row" : "books-row"}>
          {this.props.source.length > 0 ?
            <Fragment>
              <Arrow
                styleClassName={this.state.displayRightArrow ? 'lsp-rightbtn-containter' : 'lsp-rightbtn-containter hidden'}
                direction="right"
                clickHandler={this.clickHandler}
                isTabletOrMobile={this.context.isTabletOrMobile}
                carouselName={this.props.rowTitle}
                disableTheArrow={this.props.isLoan ? this.context.loadingLoans : (this.props.isHold ? this.context.loadingHolds : (this.props.isArchive ? this.context.loadingArchive : false))}
              />
              <Arrow
                styleClassName={this.state.displayLeftArrow ? 'lsp-leftbtn-containter' : 'lsp-leftbtn-containter hidden'}
                direction="left"
                clickHandler={this.clickHandler}
                isTabletOrMobile={this.context.isTabletOrMobile}
                carouselName={this.props.rowTitle}
              />

              {/* User's loans/holds on BookShelf */}
              {(this.props.isBig && !this.props.isFromUserAccount) &&
                <Slider {...sliderSetting} ref={loansSlider => this.slider = loansSlider}>
                  {this.props.source.slice(0, this.state.carouselLimit).map((record, index) => {
                    return (
                      <BookJacket
                        {...this.props}
                        loanDetails={record.loanDetails || null}
                        holdDetails={record.holdDetails || null}
                        recordData={record}
                        modal_isOpen={false}
                        key={`${index}-${record.recordID}`}
                        id={`${index}-${record.recordID}`}
                        isTabletOrMobile={this.context.isTabletOrMobile}
                        visible={this.state.visibleJacketsArray.includes(index)}
                      />
                    )
                  })}

                  {/* this is the loan-history button which takes the user to their account */}
                  {(this.props.isLoan && this.context.currentArchive.length > 0) &&
                    <NavHashLink tabIndex={this.state.visibleJacketsArray.includes(this.state.visibleJacketsArray.length) ? "" : "-1"} smooth scroll={this.scrollWithOffset} to="/user/account#loan-history">
                      <div className="history-icon" style={{ width: this.props.tabWidth + "px", height: this.props.tabHeight + "px" }}>
                        <FontAwesomeIcon icon={['fad', 'history']} />
                        <p>My Loan<br /> History</p>
                      </div>
                    </NavHashLink>
                  }
                </Slider>
              }

              {/* User's loans/holds/archives on MyAccount */}
              {this.props.isFromUserAccount &&
                <Slider {...sliderSetting} ref={loansSlider => this.slider = loansSlider}>
                  {this.props.source.slice(0, this.state.carouselLimit).map((record, index) => {
                    return (
                      <MiniJacket
                        {...this.props}
                        isFromUserAccount
                        loanDetails={record.loanDetails || null}
                        holdDetails={record.holdDetails || null}
                        archiveDetails={record.archiveDetails || null}
                        recordData={record}
                        modal_isOpen={true}
                        key={`${index}-${record.recordID}`}
                        id={`${index}-${record.recordID}`}
                        isTabletOrMobile={this.context.isTabletOrMobile}
                        visible={this.state.visibleJacketsArray.includes(index)}
                      />
                    )
                  })}
                </Slider>
              }
            </Fragment> :
            <div className="empty-row">
              <div style={!this.props.isFromUserAccount ? { width: this.props.tabWidth + "px", height: this.props.tabHeight + "px", position: "relative" } : {}}>
                {/* the loading spinner */}
                {this.props.loading &&
                  <Fragment>
                    {(this.props.isBig && !this.context.isTabletOrMobile) ?
                      <ScaleLoader css={loaderCSS} height={100} width={7} radius={0} color='var(--primary)' />
                      : (this.context.isTabletOrMobile ?
                        <ScaleLoader css={loaderCSSmini} height={60} width={3} radius={0} color='var(--primary)' /> :
                        <ScaleLoader css={loaderMyAccount} height={100} width={7} radius={0} color='var(--primary)' />
                      )
                    }
                  </Fragment>
                }
                <div className={this.props.isFromUserAccount ? "mini-placeholder-container" : "placeholder-container"}>
                  {!this.props.loading && <p>{this.props.noItemsMessage}</p>}
                </div>
              </div>

              {/* this is the loan-history button which takes the user to their account */}
              {(this.props.isLoan && !this.props.isFromUserAccount && this.context.currentArchive.length > 0) &&
                <NavHashLink tabIndex={this.state.visibleJacketsArray.includes(this.state.visibleJacketsArray.length) ? "" : "-1"} smooth scroll={this.scrollWithOffset} to="/user/account#loan-history" style={{ marginTop: "10px", marginLeft: "10px" }}>
                  <div className="history-icon" style={{ width: this.props.tabWidth + "px", height: this.props.tabHeight + "px" }}>
                    <FontAwesomeIcon icon={['fad', 'history']} />
                    <p>My Loan<br /> History</p>
                  </div>
                </NavHashLink>
              }
            </div>
          }
        </div>
      </Fragment>
    )
  }
}
