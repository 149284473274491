import React, { Component } from 'react';
import AddToCalendar from 'react-add-to-calendar';
import './AddToCalendarButton.css';
import FocusTrap from 'focus-trap-react'

export default class AddToCalendarButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {
        title: props.details.name,
        description: props.details.description,
        location: props.details.location_name,
        startTime: props.eventFullStartTime,
        endTime: props.eventFullEndTime
      }
    };
  }
  render() {
    return (
      <button title="Add this event to your calendar">
        <AddToCalendar event={this.state.event} buttonTemplate={{ 'calendar-plus': 'left' }} buttonLabel="" />
      </button>
    )
  };
}