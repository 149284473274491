import React, { Component, Fragment } from 'react';
import './Navbar.css';
import { NavLink, withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SideMenu from './SideMenu';
import UserMenu from './UserMenu';

import { AuthContext } from '../../AuthContext';

class Navbar extends Component {
  static contextType = AuthContext;

  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      modalContactStatus: false,
      modalLogInStatus: false,
      smallDevicesMenuOn: false,
      visible: false,
      searchBoxValue: ''
    };
    this.closingSideMenuHandler = this.closingSideMenuHandler.bind(this);
    this.showSearchBox = this.showSearchBox.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  onChangeHandler = (event) => {
    var userInput = event.target.value.replace()
    this.setState({ searchBoxValue: userInput });
  }

  onSubmit = (event) => {
    event.preventDefault();
    // alert(this.state.searchBoxValue);
    if (this.state.searchBoxValue === '') {
      return this.setState({ visible: false });
    }
    this.props.history.push({
      pathname: `/search/${this.state.searchBoxValue}/`
    });
    this.setState({ visible: false, searchBoxValue: '', smallDevicesMenuOn: false });
  }

  showSearchBox = () => {
    if (this.state.visible) {
      this.setState({ visible: false, searchBoxValue: '' })
    } else {
      setTimeout(function () { document.getElementById("search-box").focus() }, 500)
      this.setState({ visible: true })
    }
  }

  closingSideMenuHandler = () => {
    this.setState({
      smallDevicesMenuOn: false,
      visible: false, searchBoxValue: ''
    })
  }

  render() {
    // no navbar in home screen
    if (this.props.location.pathname === '/') {
      return null;
    }
    const { applicationData, wildCard, eventsTab, digitalContentTab, bookshelfTab, userAccountTab, searchTab, isTabletOrMobile, isTop, checkCurrentUserStatus } = this.context;
    return (
      <Fragment>
        {this.state.smallDevicesMenuOn &&
          <div className="overlay"></div>
        }

        <nav className="navbar-container" role="navigation"
          style={{ "background": isTop ? '' : 'var(--mobile-navbar-color)', "boxShadow": isTop ? '' : (this.state.smallDevicesMenuOn ? '' : '0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f') }}
        >
          <NavLink className="brand-link" to='/' title={`${applicationData.publishedTitle} Home`} aria-label={`${applicationData.publishedTitle} Home-Screen`}>
            <div className="navbar-logo" style={{ "backgroundImage": isTop ? 'var(--logo-main)' : 'var(--logo-sticky)' }} />
          </NavLink>

          {/* the hamburger button */}
          {!this.state.smallDevicesMenuOn &&
            <button style={{ color: isTop ? "var(--primary)" : "var(--sticky-navbar-icons-color)" }} title="Open the side menu" className="menu-icon" onClick={() =>
              this.setState({
                smallDevicesMenuOn: true,
                searchBoxValue: ''
              })}>
              <FontAwesomeIcon icon={["far", "bars"]} />
            </button>
          }

          {/* this is the nav menu */}
          <div aria-haspopup="true" aria-expanded={this.state.smallDevicesMenuOn ? "true" : "false"} className={this.state.smallDevicesMenuOn ? 'nav-menu active' : 'nav-menu'} aria-label="Main Navigation">
            {this.state.smallDevicesMenuOn ?

              // the side menu for mobile devices
              <SideMenu closingSideMenuHandler={this.closingSideMenuHandler} /> :

              // or the regular navigation tabs for desktops
              <Fragment>
                {searchTab &&
                  <Fragment>
                    {/* Hide the search icon/field on Search screen & show it on other screens */}
                    {this.props.location.pathname.slice(0, 7) === "/search" ?
                      <div style={{ color: isTop ? "var(--navbar-icons-color)" : "var(--sticky-navbar-icons-color)" }}>
                        <div className='active-nav'>
                          <section className='nav-links' style={{ cursor: "default" }}>
                            <FontAwesomeIcon icon={["fad", "search"]} />
                            <p className='nav-links-titles'>{searchTab.title}</p>
                          </section>
                        </div>
                      </div> :
                      <Fragment>
                        {!this.state.smallDevicesMenuOn &&
                          <Fragment>
                            {/* The animated search input field*/}
                            <div className="search-box-wrap">
                              <div className={(this.state.visible && !this.state.smallDevicesMenuOn) ? 'search-box-container active' : 'search-box-container'}>
                                <form id="search-form" onSubmit={this.onSubmit}>
                                  {searchTab.buttonIconName ?
                                    <FontAwesomeIcon icon={[searchTab.buttonIconName.substring(0, 3), searchTab.buttonIconName.substring(7)]} /> :
                                    <FontAwesomeIcon icon={["fad", "search"]} />
                                  }
                                  <input disabled={!this.state.visible} id="search-box" name="search-box" autoComplete="off" onChange={this.onChangeHandler} value={this.state.searchBoxValue} placeholder="Search catalogue" type="text" />
                                </form>
                              </div>
                            </div>
                            <button onClick={this.showSearchBox} id="search-icon" style={{ color: isTop ? "var(--navbar-icons-color)" : "var(--sticky-navbar-icons-color)", paddingTop: "10px" }}>
                              <section className='nav-links' >
                                {searchTab.buttonIconName ?
                                  <FontAwesomeIcon icon={[searchTab.buttonIconName.substring(0, 3), searchTab.buttonIconName.substring(7)]} /> :
                                  <FontAwesomeIcon icon={["fad", "search"]} />
                                }
                                <p className='nav-links-titles'>{searchTab.title}</p>
                              </section>
                            </button>
                          </Fragment>
                        }
                      </Fragment>
                    }
                  </Fragment>
                }

                {/* Bookshelf */}
                {bookshelfTab &&
                  <NavLink
                    to={`/${bookshelfTab.canonicalTitle}`}
                    onClick={() => { this.setState({ searchBoxValue: '', visible: false, smallDevicesMenuOn: false }); checkCurrentUserStatus() }}
                    style={{ color: isTop ? "var(--navbar-icons-color)" : "var(--sticky-navbar-icons-color)" }}
                    activeClassName="active-nav"
                  >
                    <section className='nav-links' >
                      {bookshelfTab.buttonIconName ?
                        <FontAwesomeIcon icon={[bookshelfTab.buttonIconName.substring(0, 3), bookshelfTab.buttonIconName.substring(7)]} /> :
                        <FontAwesomeIcon icon={["fad", "books"]} />
                      }
                      <p className='nav-links-titles'>{bookshelfTab.title}</p>
                    </section>
                  </NavLink>
                }

                {/* Digital-Content (Libraries) */}
                {digitalContentTab &&
                  <NavLink
                    to={`/${digitalContentTab.canonicalTitle}`}
                    onClick={() => { this.setState({ searchBoxValue: '', visible: false, smallDevicesMenuOn: false }); checkCurrentUserStatus() }}
                    style={{ color: isTop ? "var(--navbar-icons-color)" : "var(--sticky-navbar-icons-color)" }}
                    activeClassName="active-nav"
                    aria-hidden={isTabletOrMobile ? "true" : "false"}
                  >
                    <section className='nav-links' >
                      {digitalContentTab.buttonIconName ?
                        <FontAwesomeIcon icon={[digitalContentTab.buttonIconName.substring(0, 3), digitalContentTab.buttonIconName.substring(7)]} /> :
                        <FontAwesomeIcon icon={["fad", "book-reader"]} />
                      }
                      <p className='nav-links-titles'>{digitalContentTab.title}</p>
                    </section>
                  </NavLink>
                }

                {/* Events */}
                {eventsTab &&
                  <NavLink
                    to={`/${eventsTab.canonicalTitle}`}
                    onClick={() => { this.setState({ searchBoxValue: '', visible: false, smallDevicesMenuOn: false }); checkCurrentUserStatus() }}
                    style={{ color: isTop ? "var(--navbar-icons-color)" : "var(--sticky-navbar-icons-color)" }}
                    activeClassName="active-nav"
                  >
                    <section className='nav-links' >
                      {eventsTab.buttonIconName ?
                        <FontAwesomeIcon icon={[eventsTab.buttonIconName.substring(0, 3), eventsTab.buttonIconName.substring(7)]} /> :
                        <FontAwesomeIcon icon={["fad", "calendar-star"]} />
                      }
                      <p className='nav-links-titles'>{eventsTab.title}</p>
                    </section>
                  </NavLink>
                }

                {/* The Wildcard */}
                {wildCard &&
                  <NavLink
                    to={`/${wildCard.canonicalTitle}`}
                    onClick={() => { this.setState({ searchBoxValue: '', visible: false, smallDevicesMenuOn: false }); checkCurrentUserStatus() }}
                    style={{ color: isTop ? "var(--navbar-icons-color)" : "var(--sticky-navbar-icons-color)" }}
                    activeClassName="active-nav"
                    key={wildCard.id}
                  >
                    <section className='nav-links' >
                      {wildCard.buttonIconName ?
                        <FontAwesomeIcon icon={[wildCard.buttonIconName.substring(0, 3), wildCard.buttonIconName.substring(7)]} /> :
                        <FontAwesomeIcon icon={["fas", "star"]} />
                      }
                      <p className='nav-links-titles'>{wildCard.title}</p>
                    </section>
                  </NavLink>
                }

                {/* dropdown usermenu in desktop screen sizes */}
                {(userAccountTab && !this.state.smallDevicesMenuOn) &&
                  <UserMenu />
                }

              </Fragment>
            }
          </div>
        </nav>
      </Fragment>
    )
  }
}

export default withRouter(Navbar);