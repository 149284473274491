import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useMediaQuery } from 'react-responsive'

import luciData from './luciConfig.json';

import { useScrollPosition } from '@n8tb1t/use-scroll-position'

export const AppContext = createContext();

export const AppProvider = props => {
  const appFormats = luciData.app_formats;

  const [apiURL, setApiURL] = useState('');
  const [appID, setAppID] = useState('');
  const [applicationData, setApplicationData] = useState([]);
  const [channelData, setChannelData] = useState([]);
  const [adCaruselData, setAdCaruselData] = useState([]); // 5
  const [socialLinks, setSocialLinks] = useState([]);
  const [searchConfiguration, setSearchConfiguration] = useState([]);
  const [theApiTheme, setTheApiTheme] = useState('');
  const [originalEventsCategories, setOriginalEventsCategories] = useState([]);
  const [originalEventsLocations, setOriginalEventsLocations] = useState([]); // 10

  const [loading, setLoading] = useState(false);

  const [libraryRecommendations, setLibraryRecommendations] = useState([]);
  const [globalRecommendations, setGlobalRecommendations] = useState([]);
  const [loadingLibraryRecommendations, setLoadingLibraryRecommendations] = useState(false);
  const [loadingGlobalRecommendations, setLoadingGlobalRecommendations] = useState(false); // 15

  const [pageWidth, setPageWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);

  const [patronFields, setPatronFields] = useState([]);

  // Media queries
  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 })
  const isPortrait = useMediaQuery({ orientation: 'portrait' })
  const isRetina = useMediaQuery({ minResolution: '2dppx' })
  const width8 = useMediaQuery({ minWidth: 3401 }) // 4K and above
  const width7 = useMediaQuery({ minWidth: 2401, maxWidth: 3400 })
  const width6 = useMediaQuery({ minWidth: 1801, maxWidth: 2400 }) // 2K
  const width5 = useMediaQuery({ minWidth: 1501, maxWidth: 1800 })
  const width4 = useMediaQuery({ minWidth: 1201, maxWidth: 1500 })
  const width3 = useMediaQuery({ minWidth: 992, maxWidth: 1200 })
  const width2 = useMediaQuery({ minWidth: 769, maxWidth: 991 })
  const width1 = useMediaQuery({ maxWidth: 768 })
  const width0 = useMediaQuery({ maxWidth: 425 })

  const [libraryOGimage, setLibraryOGimage] = useState('')
  const [tabsToShow, setTabsToShow] = useState('')
  const [isTop, setIsTop] = useState(true);

  const [jacketsCountPerCarousel, setJacketsCountPerCarousel] = useState(8); // this is hard coded for now, but it could be a library choice
  const [jacketsWidth, setJacketsWidth] = useState(0);
  const [jacketsHeight, setJacketsHeight] = useState(0);

  const OGImages = luciData.librariesOGImages;  // this is for Sutton in development only TODO: add an API for all libraries names.

  useEffect(() => {
    if (Object.keys(applicationData).length > 0) {
      const name = OGImages.filter(library => library.SName === applicationData.name || library.PTitle === applicationData.publishedTitle)
      if (name.length > 0) {
        setLibraryOGimage(`logos/${name[0].image}`)
      } else {
        setLibraryOGimage('Luci-icon.png')
      }
    }
  }, [applicationData])

  useEffect(() => {
    if (Object.keys(channelData).length > 0) {
      if (channelData.zones && channelData.zones[0] && Object.keys(channelData.zones[0]).length > 0) {
        setAdCaruselData(channelData.zones[0].contentItems)
      }
    }
    return () => {
      setAdCaruselData([])
    }
  }, [channelData])

  // observe the page height to show/hide back-to-top button where needed
  // observe the page width to update the jackets width and height
  useEffect(() => {
    setPageHeight(document.body.clientHeight);
  }, [document.body.clientHeight]);

  const handleResize = (e) => {
    setPageWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  useEffect(() => {
    if ((pageWidth && pageWidth !== 0) || (jacketsCountPerCarousel && jacketsCountPerCarousel > 5)) {
      let jacketWidth = Math.floor(pageWidth / jacketsCountPerCarousel);
      let jacketHeight = Math.floor(jacketWidth * 1.53);
      setJacketsWidth(jacketWidth)
      setJacketsHeight(jacketHeight)
    }
  }, [pageWidth, jacketsCountPerCarousel]);

  useEffect(() => {
    if (width8) {
      setTabsToShow(12)
    } else if (width7) {
      setTabsToShow(10)
    } else if (width6) {
      setTabsToShow(7)
    } else if (width5) {
      setTabsToShow(6)
    } else if (width4) {
      setTabsToShow(5)
    } else if (width3) {
      setTabsToShow(4)
    } else if (width2) {
      setTabsToShow(5)
    } else if (width1) {
      setTabsToShow(4)
    } else {
      setTabsToShow(4)
    }
  }, [width1, width2, width3, width4, width5, width6, width7, width8])

  useEffect(() => {
    const urlElements = window.location.href.split('/')

    async function fetchApp() {
      setLoading(true);

      setApiURL(process.env.REACT_APP_SERVER_ENDPOINT)
      const endpointURL = process.env.REACT_APP_SERVER_ENDPOINT

      try {
        const response = await axios.get(`${endpointURL}/applications?domain=${urlElements[2]}`)
        const app = await response.data;

        const currentAppId = app.id
        const currentChannelId = app.primaryChannel
        const appSocialLinks = app.socialLinks

        setApplicationData(app);
        setAppID(currentAppId);
        setOriginalEventsCategories(app.eventCategories)
        setOriginalEventsLocations(app.eventLocations)
        setTheApiTheme(app.defaultTheme);
        setSocialLinks(appSocialLinks);

        const axiosInstance = axios.create({
          baseURL: endpointURL,
          headers: { 'solus-app-id': currentAppId }
        });

        const channelResponse = await axiosInstance.get(`/channels/${currentChannelId}`)
        setChannelData(channelResponse.data)

        const searchConfigResponse = await axios.get(`${endpointURL}/applications/${currentAppId}/searchConfiguration`)
        setSearchConfiguration(searchConfigResponse.data)

        const patronFieldsResponse = await axiosInstance.get(`/Patrons/fields`)
        setPatronFields(patronFieldsResponse.data)

        setLoading(false);

      } catch (err) {
        setLoading(false);
        console.error(err);
      };
    }

    fetchApp()
  }, [])

  // to show the top navbar background
  useScrollPosition(({ currPos }) => {
    if (currPos.y >= -20) {
      setIsTop(true)
    } else {
      setIsTop(false)
    }
  })

  useEffect(() => {
    if (appID !== undefined && appID !== '') {
      fetchLibraryRecommendations();
      fetchGlobalRecommendations();
    }
  }, [appID]);

  const libraryInstance = axios.create({
    baseURL: apiURL,
    headers: { 'solus-app-id': appID },
    params: {
      RecommendationType: 'library',
      // ItemID: '' // this to be added later on to have the Recommendations based on a title
    }
  });

  const globalInstance = axios.create({
    baseURL: apiURL,
    headers: { 'solus-app-id': appID },
    params: {
      RecommendationType: 'global',
      // ItemID: '' // this to be added later on to have the Recommendations based on a title
    }
  });

  const fetchLibraryRecommendations = async () => {
    // console.log('Library Recommendations is loading')
    setLoadingLibraryRecommendations(true);
    try {
      const response = await libraryInstance.get('/Manifestations/recommendations');
      setLibraryRecommendations(shuffleArray(response.data));
      setLoadingLibraryRecommendations(false);
    } catch (err) {
      toast.error("Error with getting library's recommendations - " + err.message);
      setLoadingLibraryRecommendations(false);
    }
  }

  const fetchGlobalRecommendations = async () => {
    // console.log('Global Recommendations is loading')
    setLoadingGlobalRecommendations(true);
    try {
      const response = await globalInstance.get('/Manifestations/recommendations')
      setGlobalRecommendations(shuffleArray(response.data))
      setLoadingGlobalRecommendations(false)
    } catch (err) {
      toast.error("Error with getting the global recommendations - " + err.message);
      setLoadingGlobalRecommendations(false);
    }
  }

  const shuffleArray = (array) => {
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  return (
    <AppContext.Provider value={{
      appID, apiURL, loading, setLoading, applicationData, channelData, adCaruselData, socialLinks, searchConfiguration, theApiTheme, jacketsWidth, jacketsHeight,
      width0, isTabletOrMobile, isPortrait, isRetina, tabsToShow, isTop, libraryOGimage, originalEventsCategories, originalEventsLocations,
      libraryRecommendations, globalRecommendations, loadingLibraryRecommendations, appFormats, jacketsCountPerCarousel, toast,
      loadingGlobalRecommendations, pageHeight, pageWidth, patronFields
    }}>
      {props.children}

      <ToastContainer
        position="top-center"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      // theme="dark"
      />

    </AppContext.Provider>
  )
}
