import React, { Component } from 'react';
import { SyncLoader } from 'react-spinners'
import { Link } from 'react-router-dom';
import "./ServicesBar.css"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import TheArrowUnderneath from '../TheArrowUnderneath'
import Arrow from '../Arrow';

import { AuthContext } from '../../../AuthContext';

export default class ServicesBar extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.changeSlide = this.changeSlide.bind(this)
    this.getIndex = this.getIndex.bind(this)
    this.getID = this.getID.bind(this)
    this.clickHandler = this.clickHandler.bind(this)

    this.state = {
      activeIndex: -1, //to have none selected while in Bookshelf screen
      displayRightArrow: false,
      displayLeftArrow: false
    }

    this.sliderSetting = {
      dots: false,
      arrows: false,
      accessibility: true,
      draggable: true,
      speed: process.env.REACT_APP_CAROUSEL_SPEED,
      slidesToScroll: 2,
      infinite: false,
      swipeToSlide: true,
      variableWidth: true,
      adaptiveHeight: false,
      nextArrow: null,
      prevArrow: null,
      afterChange: currentSlide => { this.setArrowDisplay(currentSlide) }
    }
  }

  setArrowDisplay = slideIndex => {
    // console.log("currentSlide: " + slideIndex,
    //   "active index: " + this.state.activeIndex, "tabsToShow: " + this.context.tabsToShow,
    //   "parents array length:" + this.props.source.length)

    const displayLeftArrow = slideIndex !== 0;
    const displayRightArrow = slideIndex + this.context.tabsToShow <= this.props.source.length;
    // console.log(displayLeftArrow, displayRightArrow)

    this.setState({ displayRightArrow, displayLeftArrow });
  };

  componentDidMount() {
    this.setArrowDisplay(0)
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedTab === '' || this.props.selectedTab === undefined) {
      return
    }
    if (this.props.selectedTab !== prevProps.selectedTab) {
      let index = this.getIndex(this.props.selectedTab);
      let factor = (this.context.tabsToShow / 2).toFixed(0) - 1

      this.setState({
        activeIndex: index
      });

      this.setArrowDisplay(index);

      const string = this.getID(this.props.selectedTab)
      if (factor >= 3) {
        this.slider.slickGoTo(index - factor);
        document.getElementById(string).focus(); // to keep the focus on the clicked tab...
      } else if (factor <= 2) {
        this.slider.slickGoTo(index - factor + 1);
        document.getElementById(string).focus(); // ... between bookshelf and digital-content
      }
    }
  }

  clickHandler = (direction) => {
    if (direction === 'left') {
      this.slider.slickPrev();
    } else if (direction === 'right') {
      this.slider.slickNext();
    }
  };

  getIndex = (canonicalTitle) => {
    return this.props.source.findIndex(tab => tab.canonicalTitle === canonicalTitle);
  }

  getID = (canonicalTitle) => {
    const tab = this.props.source.filter(tab => tab.canonicalTitle === canonicalTitle);
    if (tab && tab.length > 0) {
      return tab[0].id
    }
  }

  changeSlide = (index) => {
    this.setState({
      activeIndex: index
    });
  }



  render() {
    return (
      <div className="lib-serv-bar">
        <div className="lsp-slider-bar">
          <div className="lsp-slider-wrap" style={{ minHeight: "100px" }}>
            <Arrow
              styleClassName={this.state.displayLeftArrow ? 'lsp-leftbtn-containter' : 'lsp-leftbtn-containter hidden'}
              direction="left"
              clickHandler={this.clickHandler}
              isTabletOrMobile={this.context.isTabletOrMobile}
              carouselName="library digital-content main tabs navbar"
            />
            <Arrow
              styleClassName={this.state.displayRightArrow ? 'lsp-rightbtn-containter' : 'lsp-rightbtn-containter hidden'}
              direction="right"
              clickHandler={this.clickHandler}
              isTabletOrMobile={this.context.isTabletOrMobile}
              carouselName="library digital-content main tabs navbar"
            />
            <Slider key="lsp-slider" {...this.sliderSetting} ref={servicesSlider => this.slider = servicesSlider}>
              {this.props.source.map((topTab, index) => {
                return (
                  <div key={`${topTab.id}-item`}>
                    <Link to={`/${this.context.digitalContentTab.canonicalTitle}/${topTab.canonicalTitle}/`}
                      style={{ width: this.props.tabWidth + "px" }}
                      title={`${topTab.title} library digital-content tab-item`}
                      onClick={() => { this.changeSlide(index); this.props.action(`${topTab.id}`, topTab); this.props.setLevelThreeCanonicalTitle(''); this.props.setLevelTwoCanonicalTitle(''); this.props.setChannelExtraction([]); }}
                      className={this.state.activeIndex !== index ? 'lsp-links' : 'lsp-links selected'}
                      id={topTab.id}
                    >
                      <p>{topTab.title}</p>
                      {(this.props.tabIsLoading && this.state.activeIndex === index) &&
                        <span className="tabs-loading">
                          {this.props.isTabletOrMobile ?
                            <SyncLoader size={8} margin={0} color='var(--primary)' /> :
                            <SyncLoader size={10} margin={1} color='var(--primary)' />
                          }
                        </span>
                      }
                      {this.state.activeIndex !== index &&
                        <div className="arrow-outer">
                          <TheArrowUnderneath />
                        </div>
                      }
                      <div className="pointy"></div>
                    </Link>
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    )
  }
}
