import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { AppProvider } from './AppContext'
import { AuthProvider } from './AuthContext'
import Themes from './Themes.js';

ReactDOM.render(
    <AppProvider>
        <AuthProvider>
            <Themes />
            <App />
        </AuthProvider>
    </AppProvider>
    , document.getElementById('root')
);

