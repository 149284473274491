import React, { Component } from 'react';
import { SyncLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TheArrowUnderneath from '../TheArrowUnderneath'
import Arrow from '../Arrow';
import { AuthContext } from '../../../AuthContext';

export default class EventsBar extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.changeSlide = this.changeSlide.bind(this)

    this.state = {
      activeIndex: -1,
      displayRightArrow: false,
      displayLeftArrow: false,
    }

    this.sliderSetting = {
      dots: false,
      arrows: false,
      accessibility: true,
      draggable: true,
      speed: process.env.REACT_APP_CAROUSEL_SPEED,
      slidesToScroll: 2,
      infinite: false,
      swipeToSlide: true,
      variableWidth: true,
      adaptiveHeight: false,
      focusOnSelect: false,
      nextArrow: null,
      prevArrow: null,
      afterChange: currentSlide => { this.setArrowDisplay(currentSlide) }
    };
  }

  componentDidMount() {
    this.setArrowDisplay(0)
  }

  changeSlide = (index) => {
    this.setState({
      activeIndex: index
    });
    // this.slider.slickGoTo(index);
  }

  setArrowDisplay = currentSlide => {
    // console.log("currentSlide: " + currentSlide, "active index: " + this.state.activeIndex)
    const displayLeftArrow = currentSlide !== 0;
    const displayRightArrow = currentSlide + this.context.tabsToShow <= this.props.source.length;

    this.setState({ displayRightArrow, displayLeftArrow });
  };

  clickHandler = (direction) => {
    if (direction === 'left') {
      this.slider.slickPrev();
    } else if (direction === 'right') {
      this.slider.slickNext();
    }
  };

  render() {
    return (
      <div className="lib-evn-bar">
        <div className="lib-slider-bar">
          <div className="lsp-slider-wrap" style={{ minHeight: "100px" }}>
            <Arrow
              styleClassName={this.state.displayLeftArrow ? 'lsp-leftbtn-containter' : 'lsp-leftbtn-containter hidden'}
              direction="left"
              clickHandler={this.clickHandler}
              isTabletOrMobile={this.context.isTabletOrMobile}
              carouselName="library events main tabs navbar"
            />
            <Arrow
              styleClassName={this.state.displayRightArrow ? 'lsp-rightbtn-containter' : 'lsp-rightbtn-containter hidden'}
              direction="right"
              clickHandler={this.clickHandler}
              isTabletOrMobile={this.context.isTabletOrMobile}
              carouselName="library events main tabs navbar"
            />
            <Slider {...this.sliderSetting} ref={eventsSlider => this.slider = eventsSlider}>
              {this.props.source.map((item, index) => {
                return (
                  <div style={{ width: this.props.tabWidth + "px", marginRight: "2px" }}
                    key={`events-bar-item-${item.id}`}>
                    <Link
                      to={`/${this.context.eventsTab.canonicalTitle}/${item.canonicalTitle}/`}

                      onClick={() => { this.changeSlide(index); this.props.cat(item.id); this.props.setLevelThreeCanonicalTitle(''); this.props.setLevelTwoCanonicalTitle('') }}
                      className={this.state.activeIndex !== index ? 'lsp-links' : 'lsp-links selected'}
                    >
                      <p>{item.title}</p>
                      {((this.props.loading || this.props.loadingEvents) && this.props.selectedIDForLoading === item.id) &&
                        <span className="subtabs-loading">
                          {this.context.isTabletOrMobile ?
                            <SyncLoader size={8} margin={0} color='var(--primary)' /> :
                            <SyncLoader size={10} margin={1} color='var(--primary)' />
                          }
                        </span>
                      }

                      {this.state.activeIndex !== index &&
                        <div className="arrow-outer">
                          <TheArrowUnderneath />
                        </div>
                      }
                      <div className="pointy"></div>
                    </Link>
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
