import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';

const JoinBanner = (props) => {
  const { location } = props;

  if (location.pathname === '/user/register' || location.pathname === '/user/resetaccount/:resettoken*') { // no banner on some screens
    return null;
  } else {
    return (
      <div className="joinin-outer">
        <div className="joinin-left">
          <div className="joinin-left-content">
            <p>Join {props.libraryname} </p>
            <button
              className='button-join'
              title={`Click here to join ${props.libraryname}`}
              onClick={() => props.handleSignUpModal()}>join<FontAwesomeIcon icon={['fas', 'caret-right']} />
            </button>
          </div>
        </div>
        <div className="joinin-right"></div>
      </div>
    )
  }
}

export default withRouter(JoinBanner);