import React, { useState, useContext, Fragment } from 'react'
import './BookJacket.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css } from '@emotion/core'
import { ScaleLoader } from 'react-spinners'
import moment from 'moment';
import { DatePicker, DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates'
import { enGB } from 'date-fns/locale'
import 'react-nice-dates/build/style.css'

import { AuthContext } from '../../../AuthContext';
import FlippingCover from './FlippingCover';
import UserInteractionIcons from './UserInteractionIcons';
import CloseButton from '../../common/CloseButton';
import PickupLocations from './PickupLocations';

const loaderCSS = css`
  position: absolute;
  opacity: 0.4;
  top: 40%;
  left: 40%;
  z-index: 999;
`;

const LoanInsider = (props) => {
  const { user, applicationData, socialLinks, isTabletOrMobile, userPreferredLocation, axiosInstanceWithPatronToken,
    deleteASingleHold, toast, updateASingleLoan, updateASingleHold } = useContext(AuthContext);

  const [record, setRecord] = useState(props.recordData);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [pickupLocation, setPickupLocation] = useState(userPreferredLocation);
  const [cancelHoldConfirmationPanel, setCancelHoldConfirmationPanel] = useState(false);
  const [suspendConfirmationPanel, setSuspendConfirmationPanel] = useState(false);
  const [changePickuplocationPanel, setChangePickuplocationPanel] = useState(false);
  const [resumePanel, setResumePanel] = useState(false);
  const [currentActiveButton, setCurrentActiveButton] = useState(0)

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState()
  const [pLocations, setpLocations] = useState([]);

  const renewALoan = async (_loanID, source) => {
    setLoadingLocal(true);

    var data = {
      "id": _loanID,
      "source": source
    }

    try {
      const response = await axiosInstanceWithPatronToken.post(`/Loans/${_loanID}/renew`, data)
      if (response.status === 200) {
        toast.success('Your loan has been renewed successfully')
        updateASingleLoan(_loanID)
      } else {
        toast.warn('Your loan renewal was unsuccessful, please try again later.')
      }
      setLoadingLocal(false);
      props.onCancel();
    } catch (err) {
      console.log("Error with renewing the loan - " + err);
      setLoadingLocal(false);
      props.onCancel();
    }
  }

  const cancelAHold = async (_holdID) => {
    setLoadingLocal(true);

    try {
      await axiosInstanceWithPatronToken.delete(`/reservations/${_holdID}`)
      toast.success('Your reservation has been cancelled successfully!')
      deleteASingleHold(_holdID)
      setLoadingLocal(false);
    } catch (err) {
      console.log("Error with cancelling the reservation - " + err);
      setLoadingLocal(false);
    }
    props.onCancel();
  }

  const suspendAHold = async (_holdID, holdSource) => {
    setLoadingLocal(true);

    var data = {
      "holdID": _holdID,
      "userID": user.userID,
      "source": holdSource,
      "startDate": startDate || "",
      "endDate": endDate || "",
      "suspendState": "suspend"
    }

    try {
      const response = await axiosInstanceWithPatronToken.patch(`/reservations/${_holdID}`, data)
      if (response.status === 200) {
        toast.success('Your reservation has been suspended successfully')
        updateASingleHold(_holdID)
      } else {
        toast.warn('Your reservation suspending was unsuccessful, please try again later.')
      }
      setLoadingLocal(false);
      props.onCancel();
    } catch (err) {
      toast.error(err.message);
      setLoadingLocal(false);
      props.onCancel();
    }
  }

  const resumeAHold = async (_holdID, holdSource) => {
    setLoadingLocal(true);

    var data = {
      "holdID": _holdID,
      "userID": user.userID,
      "source": holdSource,
      "suspendState": "resume"
    }

    try {
      const response = await axiosInstanceWithPatronToken.patch(`/reservations/${_holdID}`, data)
      if (response.status === 200) {
        toast.success('Your reservation has been resumed successfully')
        updateASingleHold(_holdID)
      } else {
        toast.warn('Resuming your reservation was unsuccessful, please try again later.')
      }
      setLoadingLocal(false);
      props.onCancel();
    } catch (err) {
      toast.error(err.message);
      setLoadingLocal(false);
      props.onCancel();
    }
  }

  const changeAHold = async (_holdID, holdSource) => {
    setLoadingLocal(true);

    var data = {
      "holdID": _holdID,
      "userID": user.userID,
      "source": holdSource,
      "locationID": pickupLocation,
      "startDate": "",
      "endDate": "",
      "suspendState": ""
    }

    try {
      const response = await axiosInstanceWithPatronToken.patch(`/reservations/${_holdID}`, data)
      if (response.status === 200) {
        toast.success('Your reservation pickup location has been changed successfully')
        updateASingleHold(_holdID)
      } else {
        toast.warn('Your reservation pickup location changing was unsuccessful, please try again.')
      }
      setLoadingLocal(false);
      props.onCancel();
    } catch (err) {
      toast.error(err.message);
      setLoadingLocal(false);
      props.onCancel();
    }
  }

  const handleSuspendAHold = () => {
    setCancelHoldConfirmationPanel(false)
    setChangePickuplocationPanel(false)
    setSuspendConfirmationPanel(true)
    setResumePanel(false)
    setCurrentActiveButton(1)
  }

  const proceedSuspendAHold = () => {
    suspendAHold(props.holdDetails.holdID, props.holdDetails.source);
  }

  const handleChangeAHold = () => {
    setSuspendConfirmationPanel(false)
    setCancelHoldConfirmationPanel(false)
    setChangePickuplocationPanel(true)
    setResumePanel(false)
    setCurrentActiveButton(2)
  }

  const proceedChangeLocation = () => {
    changeAHold(props.holdDetails.holdID, props.holdDetails.source);
  }

  const handleShowHoldDetails = () => {
    setSuspendConfirmationPanel(false)
    setChangePickuplocationPanel(false)
    setCancelHoldConfirmationPanel(false)
    setResumePanel(false)
    setCurrentActiveButton(0)
  }

  const handleCancelAHold = () => {
    setSuspendConfirmationPanel(false)
    setChangePickuplocationPanel(false)
    setCancelHoldConfirmationPanel(true)
    setResumePanel(false)
    setCurrentActiveButton(3)
  }

  const proceedCancelAHold = () => {
    cancelAHold(props.holdDetails.holdID);
  }
  const proceedResumeAReservation = () => {
    resumeAHold(props.holdDetails.holdID, props.holdDetails.source);
  }

  const handleResumeAHold = () => {
    setSuspendConfirmationPanel(false)
    setChangePickuplocationPanel(false)
    setCancelHoldConfirmationPanel(false)
    setResumePanel(true)
    setCurrentActiveButton(4)
  }

  const handleRenewALoan = () => {
    renewALoan(props.loanDetails.loanID, props.loanDetails.source);
  }

  const fixUTCDate = (timestamp) => {
    return moment(timestamp).format('DD-MM-YYYY');
  }

  const getUTCDate = (timestamp) => {
    return moment(timestamp).format('YYYY-MM-DD');
  }

  // Function to get the date of today
  const getToday = () => {
    const date = new Date();
    return date.getDate();
  }

  return (
    <Fragment>
      <div className={isTabletOrMobile ? "mobile-card-container" : "desktop-card-container"}>
        {/* left side  */}
        <div className={isTabletOrMobile ? "ms-book-bookcover" : ""}>
          <FlippingCover isTabletOrMobile={isTabletOrMobile} recordData={record} />
        </div>

        {/* right side  */}
        <div className={isTabletOrMobile ? "mobile-right-container" : "outer-right-container"}>

          {/* the loading spinner */}
          {loadingLocal && <ScaleLoader css={loaderCSS} height={50} width={6} color='var(--primary)' />}

          {/* The close button */}
          <CloseButton styleClassName="closecard-btn dimmed-before" size="2x" title="Close this record view" action={() => props.onCancel()} />

          <UserInteractionIcons applicationData={applicationData} socialLinks={socialLinks} record={record} />

          {(props.isLoan && props.loanDetails) &&
            <Fragment>
              <div className='loans-info-div'>
                <div className="chcp-wrp">
                  <h1>Loan details:</h1>
                  {/* {props.loanDetails.consumptionAction === 'read' &&
                  <div className="loan-format" title="Books">
                    <FontAwesomeIcon className="loans-top-icon" icon={['fad', 'book-open']} />
                  </div>
                } */}
                  {/* <h1 className='loans-info-title'>Loan: {props.loanDetails.loanID}</h1> */}
                  <ul>
                    {props.loanDetails.title &&
                      <li>
                        <b>Title: </b>
                        <p>{props.loanDetails.title}</p>
                      </li>
                    }
                    <li>
                      <b>Due in: </b>
                      <p>{props.loanDue.message}</p>
                    </li>
                    <li>
                      <b>Due date: </b> <p> {fixUTCDate(props.loanDetails.dueObject)}</p>
                    </li>
                    {props.loanDetails.canRenew &&
                      <Fragment>
                        <li>
                          <b>Renewal cost: </b> <p>£ {props.loanDetails.renewalCost > 0 ? props.loanDetails.renewalCost : "0"}</p>
                        </li>


                        <li>
                          <b>Renewal count: </b> <p> {props.loanDetails.renewalCount > -1 ? props.loanDetails.renewalCount : "--"}</p>
                        </li>

                        <li>
                          <b>Renewals Remaining: </b> <p> {props.loanDetails.renewalsRemaining > -1 ? props.loanDetails.renewalsRemaining : "N/A"}</p>
                        </li>
                      </Fragment>
                    }
                  </ul>
                </div>
              </div>
              <div className="loans-icons-div" >
                {props.loanDetails.readUrl !== '' &&
                  <a className="loans-icon" title="Open in the eReader" href={props.loanDetails.readUrl} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fas', 'eye']} />
                    <p>Read</p>
                  </a>
                }
                {props.loanDetails.canReturn &&
                  <button className="loans-icon" title="Return this item">
                    <FontAwesomeIcon icon={['far', 'arrow-left']} />
                    <p>Return</p>
                  </button>
                }
                {props.loanDetails.canRenew &&
                  <button className="loans-icon" title="Re-new this item" onClick={handleRenewALoan}>
                    <FontAwesomeIcon icon={['fad', 'history']} />
                    <p>Renew</p>
                  </button>
                }
                {/* <button className="loans-icon" onClick={() => props.onCancel()} title="Close this window">
                  <FontAwesomeIcon icon={['far', 'times']} />
                  <p>Close</p>
                </button> */}
              </div>
            </Fragment>
          }

          {(props.isHold && props.holdDetails) &&
            <Fragment>
              <div className='loans-info-div'>
                {props.holdDetails.consumptionAction === 'read' && // the icon in case we will have it in future, currently not working
                  <div className="loan-format" title="Books">
                    <FontAwesomeIcon className="loans-top-icon" icon={['fad', 'book-open']} />
                  </div>
                }

                {/* Hold details panel */}
                {(!cancelHoldConfirmationPanel && !suspendConfirmationPanel && !changePickuplocationPanel && !resumePanel) &&
                  <div className="chcp-wrp">
                    <h1>Reservation details:</h1>
                    <ul>
                      {props.holdDetails.title &&
                        <li>
                          <b>Title: </b> <p> {props.holdDetails.title}</p>
                        </li>
                      }
                      {(props.holdDetails.hasPickupLocation && !props.holdDetails.canBeCollected) &&
                        <li>
                          <b>Pickup Location: </b> <p> {props.holdDetails.pickupLocation || props.holdDetails.pickupLocationID}</p>
                        </li>
                      }
                      {(props.holdDetails.holdExpiryDisplay && !props.holdDetails.pickupExpiry) &&
                        <li>
                          <b>Reservation Expiry: </b> <p> {fixUTCDate(props.holdDetails.holdExpiry)}</p>
                        </li>
                      }
                      {props.holdDetails.pickupExpiry &&
                        <li>
                          <b>Pickup Expiry: </b> <p> {fixUTCDate(props.holdDetails.pickupExpiry)}</p>
                        </li>
                      }
                      {props.holdDetails.status &&
                        <li>
                          <b>Status: </b> <p> {props.holdDetails.status}</p>
                        </li>
                      }
                      {(props.holdDetails.suspendStart && props.holdDetails.suspendEnd) &&
                        <li>
                          <b>Suspend From / To: </b> <p> {fixUTCDate(props.holdDetails.suspendStart)} ~ {fixUTCDate(props.holdDetails.suspendEnd)}</p>
                        </li>
                      }
                      {(props.holdDetails.queuePosition && !props.holdDetails.pickupExpiry && !props.holdDetails.suspendStart && !props.holdDetails.suspendEnd) &&
                        <li>
                          <b>Position in the queue: </b> <p> {props.holdDetails.queuePosition}</p>
                        </li>
                      }
                      {props.holdDetails.canBeCollected &&
                        <li className="pickup-note">
                          <b>The item is ready to be collected from: </b> <p>{props.holdDetails.pickupLocation || props.holdDetails.pickupLocationID}</p>
                        </li>
                      }
                    </ul>
                  </div>
                }

                {/* Cancel a hold confirmation panel */}
                {cancelHoldConfirmationPanel &&
                  <div className="chcp-wrp">
                    <h1>Cancel a reservation:</h1>
                    <p>Are you sure you want to cancel this reservation?</p>
                    <div className="chcp-btns">
                      <button className="chcp-btn" onClick={proceedCancelAHold}>Proceed
                        <FontAwesomeIcon icon={['far', 'arrow-right']} /></button>
                      <button className="chcp-btn" onClick={() => { setCancelHoldConfirmationPanel(false); setCurrentActiveButton(0) }}>Keep
                        <FontAwesomeIcon icon={['far', 'times']} /></button>
                    </div>
                  </div>
                }

                {/* Suspend a hold confirmation panel */}
                {suspendConfirmationPanel &&
                  <div className="chcp-wrp">
                    <h1>Suspend a reservation:</h1>
                    {props.holdDetails.suspendType === "start,end" ?
                      <Fragment>
                        <p>Please select when to start/end the suspending</p>
                        {/* 
                    <input type="date" id="Suspend_Start" name="Suspend_Start"
                      defaultValue={getToday()} min={getToday()} onChange={(e) => handleSuspendStartDate(e)} />
                    <input type="date" id="Suspend_Start" name="Suspend_Start"
                      onChange={(e) => handleSuspendEndDate(e)} /> */}

                        <DateRangePicker
                          startDate={startDate}
                          endDate={endDate}
                          onStartDateChange={setStartDate}
                          onEndDateChange={setEndDate}
                          minimumDate={new Date()}
                          minimumLength={1}
                          format='dd MMM yyyy'
                          locale={enGB}
                        >
                          {({ startDateInputProps, endDateInputProps, focus }) => (
                            <div className='date-range'>
                              <input
                                className={'input' + (focus === START_DATE ? ' -focused' : '')}
                                {...startDateInputProps}
                                placeholder='Start date'
                              />
                              <span className='date-range_arrow' />
                              <input
                                className={'input' + (focus === END_DATE ? ' -focused' : '')}
                                {...endDateInputProps}
                                placeholder='End date'
                              />
                            </div>
                          )}
                        </DateRangePicker>
                        <div className="chcp-btns">
                          <button className="chcp-btn" disabled={!startDate || !endDate} onClick={proceedSuspendAHold}>Proceed
                            <FontAwesomeIcon icon={['far', 'arrow-right']} /></button>
                          <button className="chcp-btn" onClick={() => { setSuspendConfirmationPanel(false); setCurrentActiveButton(0); setStartDate(new Date()); setEndDate('') }}>Cancel
                            <FontAwesomeIcon icon={['far', 'times']} /></button>
                        </div>
                      </Fragment> : (props.holdDetails.suspendType === "end" ?
                        <Fragment>
                          <p>Please select when to end the suspending</p>
                          <DatePicker date={endDate} onDateChange={setEndDate} locale={enGB}>
                            {({ inputProps, focused }) => (
                              <input
                                className={'input' + (focused ? ' -focused' : '')}
                                {...inputProps}
                              />
                            )}
                          </DatePicker>
                          <div className="chcp-btns">
                            <button className="chcp-btn" disabled={!endDate} onClick={proceedSuspendAHold}>Proceed
                              <FontAwesomeIcon icon={['far', 'arrow-right']} /></button>
                            <button className="chcp-btn" onClick={() => { setSuspendConfirmationPanel(false); setCurrentActiveButton(0) }}>Cancel
                              <FontAwesomeIcon icon={['far', 'times']} /></button>
                          </div>
                        </Fragment> : <p>Unable to suspend this reservation!</p>
                      )
                    }
                  </div>
                }

                {/* Resume a hold confirmation panel */}
                {resumePanel &&
                  <div className="chcp-wrp">
                    <h1>Resume a reservation:</h1>

                    <ul>
                      <li>
                        <b>Suspend Start: </b> <p> {fixUTCDate(props.holdDetails.suspendStart)}</p>
                      </li>
                      <li>
                        <b>Suspend End: </b> <p> {fixUTCDate(props.holdDetails.suspendEnd)}</p>
                      </li>
                    </ul>

                    <div className="chcp-btns">
                      <button className="chcp-btn" onClick={proceedResumeAReservation} title="Proceed resuming a reservation">Proceed
                        <FontAwesomeIcon icon={['far', 'arrow-right']} /></button>
                      <button className="chcp-btn" onClick={() => { setResumePanel(false); setCurrentActiveButton(0) }} title="Cancel resuming a reservation">Cancel
                        <FontAwesomeIcon icon={['far', 'times']} /></button>
                    </div>
                  </div>
                }

                {/* Change hold's pickup location panel */}
                {changePickuplocationPanel &&
                  <div className="chcp-wrp">
                    <h1>Change reservation's pickup location:</h1>
                    <p>Current pickup location is: <b>{props.holdDetails.pickupLocation || props.holdDetails.pickupLocationID}</b>, please select a new pickup location from the list below:</p>
                    <PickupLocations
                      record={record}
                      pickupLocation={pickupLocation}
                      setPickupLocation={setPickupLocation}
                      setLoadingHold={setLoadingLocal}
                      setpLocations={setpLocations}
                    />
                    <div className="chcp-btns">
                      <button className="chcp-btn" onClick={proceedChangeLocation} title="Change reservation's pickup location" disabled={!pLocations.length > 0 || pickupLocation === props.holdDetails.pickupLocationID}>Proceed
                        <FontAwesomeIcon icon={['far', 'arrow-right']} /></button>
                      <button className="chcp-btn" onClick={() => { setChangePickuplocationPanel(false); setCurrentActiveButton(0) }} title="Cancel changing reservation's pickup location">Cancel
                        <FontAwesomeIcon icon={['far', 'times']} /></button>
                    </div>
                  </div>
                }
              </div>

              <div className="loans-icons-div" >
                <button tabIndex={currentActiveButton === 0 ? "-1" : ""} className={currentActiveButton === 0 ? "loans-icon active" : "loans-icon"} title="Show reservation details" onClick={handleShowHoldDetails}>
                  <FontAwesomeIcon icon={['fas', 'info']} />
                  <p>Details</p>
                </button>
                {(props.holdDetails.canSuspend && !props.holdDetails.canResume) &&
                  <button tabIndex={currentActiveButton === 1 ? "-1" : ""} className={currentActiveButton === 1 ? "loans-icon active" : "loans-icon"} title="Suspend this reservation" onClick={handleSuspendAHold}>
                    <FontAwesomeIcon icon={['fas', 'pause']} />
                    <p>Suspend</p>
                  </button>
                }
                {props.holdDetails.canResume &&
                  <button tabIndex={currentActiveButton === 4 ? "-1" : ""} className={currentActiveButton === 4 ? "loans-icon active" : "loans-icon"} title="Resume this reservation" onClick={handleResumeAHold}>
                    <FontAwesomeIcon icon={['fas', 'play']} />
                    <p>Resume</p>
                  </button>
                }
                {props.holdDetails.canChange &&
                  <button tabIndex={currentActiveButton === 2 ? "-1" : ""} className={currentActiveButton === 2 ? "loans-icon active" : "loans-icon"} title="Change pickup location of this reservation" onClick={handleChangeAHold}>
                    <FontAwesomeIcon icon={['fas', 'edit']} />
                    <p>Change</p>
                  </button>
                }
                {props.holdDetails.canCancel &&
                  <button tabIndex={currentActiveButton === 3 ? "-1" : ""} className={currentActiveButton === 3 ? "loans-icon active" : "loans-icon"} title="Cancel this reservation" onClick={handleCancelAHold}>
                    <FontAwesomeIcon icon={['fas', 'ban']} />
                    <p>Cancel</p>
                  </button>
                }
              </div>
            </Fragment>
          }
        </div>
      </div>
    </Fragment>
  )
};

export default LoanInsider