import React, { useContext } from 'react';
import Meta from './common/Meta';
import { AppContext } from '../AppContext';


const LoadingPage = () => {
  const { applicationData, libraryOGimage, loading } = useContext(AppContext);

  const metaData = {
    title: `${applicationData.publishedTitle} | page is loading`,
    description: 'page is loading!',
    type: 'website',
    image: libraryOGimage,
    canonical: ''
  }

  return (
    <div className="wcard-outer-container" role="main">
      <Meta data={metaData} />
      <h1 className="visuallyhidden">{applicationData.publishedTitle} Loading</h1>
      {(applicationData && !loading) &&
        <div className="wcard-container">
          <h1 className="wcard-screen-title">Loading...</h1>
          <p className="wcard-message">Almost there</p>
        </div>
      }
    </div>
  );
}

export default LoadingPage;
