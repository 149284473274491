import React, { Component } from 'react';
import { SyncLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import TheArrowUnderneath from '../TheArrowUnderneath';
import Arrow from '../Arrow';

import { AuthContext } from '../../../AuthContext';

export default class EventsSubBar extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.changeSlide = this.changeSlide.bind(this)
    this.state = {
      activeIndex: 0,
      displayRightArrow: false,
      displayLeftArrow: false,
    }

    this.sliderSetting = {
      dots: false,
      arrows: false,
      accessibility: true,
      draggable: true,
      speed: process.env.REACT_APP_CAROUSEL_SPEED,
      slidesToScroll: 2,
      infinite: false,
      swipeToSlide: true,
      variableWidth: true,
      adaptiveHeight: false,
      focusOnSelect: false,
      nextArrow: null,
      prevArrow: null,
      afterChange: currentSlide => { this.setArrowDisplay(currentSlide) }
    };
  }
  componentDidMount() {
    this.setArrowDisplay(0)
  }
  componentDidUpdate(prevProps) {
    if (this.props.source !== prevProps.source) {
      this.setArrowDisplay(0)
    }
  }

  changeSlide(index) {
    this.setState({
      activeIndex: index
    });
    // this.slider.slickGoTo(index);
  }

  setArrowDisplay = currentSlide => {
    // console.log("currentSlide: " + currentSlide, "active index: " + this.state.activeIndex)
    const displayLeftArrow = currentSlide !== 0;
    const displayRightArrow = currentSlide + this.context.tabsToShow <= this.props.source.length;

    this.setState({ displayRightArrow, displayLeftArrow });
  };

  clickHandler = (direction) => {
    if (direction === 'left') {
      this.slider.slickPrev();
    } else if (direction === 'right') {
      this.slider.slickNext();
    }
  };

  render() {
    return (
      <div className="lib-slider-bar">
        <div className="lsp-slider-wrap" style={{ minHeight: "270px" }}>
          <Arrow
            styleClassName={this.state.displayLeftArrow ? 'lsp-leftbtn-containter' : 'lsp-leftbtn-containter hidden'}
            direction="left"
            clickHandler={this.clickHandler}
            isTabletOrMobile={this.context.isTabletOrMobile}
            carouselName="library events level three tabs navbar"
          />
          <Arrow
            styleClassName={this.state.displayRightArrow ? 'lsp-rightbtn-containter' : 'lsp-rightbtn-containter hidden'}
            direction="right"
            clickHandler={this.clickHandler}
            isTabletOrMobile={this.context.isTabletOrMobile}
            carouselName="library events level three tabs navbar"
          />
          <Slider {...this.sliderSetting} ref={eventsSubCatSlider => this.slider = eventsSubCatSlider}>
            {this.props.source.map(category => {
              return (
                <div key={category.id} style={{ width: this.props.tabWidth + "px", marginRight: "2px" }}>
                  <Link
                    to={this.props.levelTwoCanonicalTitle ? `/${this.context.eventsTab.canonicalTitle}/${this.props.levelOneCanonicalTitle}/${this.props.levelTwoCanonicalTitle}/${category.canonicalTitle}/` :
                      `/${this.context.eventsTab.canonicalTitle}/${this.props.levelOneCanonicalTitle}/${category.canonicalTitle}/`}
                    onClick={() => { scroll.scrollTo(150); this.props.get(category.id, category); }}
                  >
                    {this.props.selected === category.id ?
                      <div className="first-row-items collapsed the-one"
                        style={{ "border": "3px solid var(--primary)", "height": "84px", "cursor": "pointer" }}>
                        <div className="first-row-inner vignette"
                          style={category.image ? { "backgroundImage": `url(data:image/jpeg;base64,${category.image})` }
                            : { "backgroundImage": `url("/no-img-ph2.jpg")` }}>
                          <div className="pointy"></div>
                          <div className="service-name" style={{ "color": "var(--primary)" }}>
                            <p>{category.title}</p>
                            {(this.props.loading && this.props.selectedIDForLoading === category.id) &&
                              <span className="subtabs-loading">
                                {this.context.isTabletOrMobile ?
                                  <SyncLoader size={8} margin={0} color='var(--primary)' /> :
                                  <SyncLoader size={10} margin={1} color='var(--primary)' />
                                }
                              </span>
                            }
                          </div>
                        </div>
                      </div> :
                      <div className="first-row-items collapsed others">
                        <div className="first-row-inner vignette"
                          style={category.image ? { "backgroundImage": `url(data:image/jpeg;base64,${category.image})` }
                            : { "backgroundImage": `url("/no-img-ph2.jpg")` }}>
                          <div className="service-name">
                            <p>{category.title}</p>
                            {(this.props.loading && this.props.selectedIDForLoading === category.id) &&
                              <span className="subtabs-loading">
                                {this.context.isTabletOrMobile ?
                                  <SyncLoader size={8} margin={0} color='var(--primary)' /> :
                                  <SyncLoader size={10} margin={1} color='var(--primary)' />
                                }
                              </span>
                            }
                          </div>
                          <div className="arrow-container">
                            <TheArrowUnderneath />
                          </div>
                        </div>
                      </div>
                    }
                  </Link>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    );
  }
}
