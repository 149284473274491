import React, { useContext, useEffect, Fragment } from 'react';
import './WildCard.css';

import Meta from '../common/Meta';

import { AuthContext } from '../../AuthContext';

const WildCard = () => {
  const { wildCard, applicationData, libraryOGimage } = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0); // to activate the navbar highlighting function
  }, []);

  const metaData = {
    title: `${applicationData.publishedTitle} | ${wildCard.title}`,
    description: wildCard.contentText ? wildCard.contentText : `Welcome to ${applicationData.publishedTitle} | ${wildCard.title}`,
    type: 'website',
    image: libraryOGimage,
    canonical: ''
  }

  return (
    <div className="wcard-outer-container" role="main">
      <Meta data={metaData} />
      <div className="wcard-container">
        <h1 className="visuallyhidden">{applicationData.publishedTitle} {wildCard.title}</h1>

        {wildCard &&
          <Fragment>
            <h2 className="wcard-screen-title">{wildCard.title}</h2>
            <div className="wcard-message" dangerouslySetInnerHTML={{ __html: wildCard.contentText }} />
          </Fragment>
        }
      </div>
    </div>
  );
}

export default WildCard;